import {
  createContext,
  FC,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { AccountSearch } from 'crono-fe-common/types/DTO/accountSearch';
import {
  EmailStatuses,
  ProspectSearch,
} from 'crono-fe-common/types/DTO/prospectSearch';
import ExternalPropertyFilter from 'crono-fe-common/types/externalPropertyFilter';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import {
  FilterResumeWrapper,
  RemainingFilterResumeWrapper,
  SocialFilterWrapper,
} from './style';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import { AccountStatus } from 'crono-fe-common/types/account';
import ExternalPropertyComponentRemovable from 'crono-fe-common/components/ExternalPropertyRemovable';
import { useAuth } from 'context/auth';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import {
  getExternalPropertyLabels,
  getStringFromAccountStatus,
  getStringFromProspectStatus,
} from 'crono-fe-common/utils';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import { ProspectStatus } from 'crono-fe-common/types/prospect';
import { Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import useGetImports from 'hooks/services/import/useGetImports';
import { Constants } from 'crono-fe-common/constants/constants';
import {
  SequenceStatusTypeDTO,
  showSequenceStatusDTO,
} from 'crono-fe-common/types/enums/prospectSequenceStatus';
import { externalInformationAcceptedAccount } from 'pages/dashboard/filters/accountFilters';
import { externalInformationAcceptedProspect } from 'pages/dashboard/filters/prospectFilters';
import useGetStrategyById from 'hooks/services/sequence/useGetStrategyById';
import { useGetStatusesOptions } from 'utils/fe-utils';
import { filterNullProperties } from 'utils/fe-utils';
import {
  DefaultCompanyStatusOptions,
  DefaultContactStatusOptions,
  getLabelFromUnknownOption,
} from 'crono-fe-common/types/model';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import MinusSmallIcon from 'crono-fe-common/icons/Icon-Minus-Small';
import { DashboardTabType } from '../../dashboard';
import classNames from 'classnames';

interface FiltersContextType {
  defaultFilters: AccountSearch | ProspectSearch;
  searchAccountParameters: AccountSearch;
  setSearchAccountParameters: React.Dispatch<
    React.SetStateAction<AccountSearch>
  >;
  resetAccountFilters: (saveInPreferences?: boolean) => void;
  areThereAccountFiltersApplied: boolean;
  resetAccountFiltersAndOwnedFalse: (saveInPreferences?: boolean) => void;
  searchProspectParameters: ProspectSearch;
  setSearchProspectParameters: React.Dispatch<
    React.SetStateAction<ProspectSearch>
  >;
  searchLeadParameters: ProspectSearch;
  setSearchLeadParameters: React.Dispatch<React.SetStateAction<ProspectSearch>>;
  resetProspectFilters: (
    saveInPreferences?: boolean,
    tabState?: DashboardTabType,
  ) => void;
  resetProspectFiltersAndOwnedFalse: (
    saveInPreferences?: boolean,
    tabState?: DashboardTabType,
  ) => void;
  areThereProspectFiltersApplied: boolean;
  areThereLeadFiltersApplied: boolean;
  getResumeFilters: (
    tabState: DashboardTabType,
    nToShow: number,
    onRemainingClick: () => void,
  ) => any;
  updateUserPreferences: (
    tabState: DashboardTabType,
    newFilters: any,
    saveInPreferences?: boolean,
  ) => void;
}

export const FiltersContext = createContext<FiltersContextType | undefined>(
  undefined,
);

FiltersContext.displayName = 'FiltersContext';

interface IProps {
  tabState: DashboardTabType;
  filter: [string, any];
}

const FiltersProvider: FC<{ children: any }> = ({ children }) => {
  const { user, enabledContextCalls } = useAuth();
  const defaultFilters = {
    limit: Constants.defaultLimit,
    status: null,
    name: null,
    offset: 0,
    userId: user?.id ?? null,
  };

  const [searchAccountParameters, setSearchAccountParameters] =
    useState<AccountSearch>(defaultFilters as AccountSearch);

  const resetAccountFilters = (saveInPreferences = false) => {
    const newSearchParameters = {
      ...defaultFilters,
      sort: searchAccountParameters.sort ?? 'Name',
      name: searchAccountParameters.name,
      selected: searchAccountParameters.selected,
    };
    setSearchAccountParameters(newSearchParameters);
    if (saveInPreferences) {
      updateUserPreferences('Company', newSearchParameters);
    }
  };

  const resetAccountFiltersAndOwnedFalse = (saveInPreferences = false) => {
    const newSearchParameters = {
      ...defaultFilters,
      sort: searchAccountParameters.sort ?? 'Name',
      name: searchAccountParameters.name,
      selected: searchAccountParameters.selected,
      userId: null,
    };
    setSearchAccountParameters(newSearchParameters);
    if (saveInPreferences) {
      updateUserPreferences('Company', newSearchParameters);
    }
  };

  //This is used to show different empty states in the table
  const areThereAccountFiltersApplied = useMemo(() => {
    //If there is a userId different from mine it means that a filter is applied. If not we exclude the userId, since we want this to be false if myContacts is selected
    if (
      searchAccountParameters.userId &&
      searchAccountParameters.userId !== user?.id
    )
      return true;
    //Exclude the name, the userId, the sort and the selected from the filters before comparing
    const { name, sort, selected, userId, ...rest } = searchAccountParameters;
    const {
      name: defaultName,
      userId: userIdDefault,
      ...defaultRest
    } = defaultFilters;
    //Remove all the properties that have null value
    return (
      JSON.stringify(filterNullProperties(rest)) !==
      JSON.stringify(filterNullProperties(defaultRest))
    );
  }, [searchAccountParameters]);
  const [searchProspectParameters, setSearchProspectParameters] =
    useState<ProspectSearch>({
      ...defaultFilters,
      fromContact: true,
    } as ProspectSearch);
  const [searchLeadParameters, setSearchLeadParameters] =
    useState<ProspectSearch>({
      ...defaultFilters,
      fromContact: false,
    } as ProspectSearch);

  const resetProspectFilters = (
    saveInPreferences = false,
    tabState: DashboardTabType = 'Contact',
  ) => {
    let newSearchParameters: ProspectSearch | null = null;

    if (tabState === 'Contact') {
      newSearchParameters = {
        ...defaultFilters,
        sort: searchProspectParameters.sort ?? 'Name',
        name: searchProspectParameters.name,
        selected: searchProspectParameters.selected,
        fromContact: true,
      };
      setSearchProspectParameters(newSearchParameters);
    } else if (tabState === 'Lead') {
      newSearchParameters = {
        ...defaultFilters,
        sort: searchLeadParameters.sort ?? 'Name',
        name: searchLeadParameters.name,
        selected: searchLeadParameters.selected,
        fromContact: false,
      };
      setSearchLeadParameters(newSearchParameters);
    }

    if (saveInPreferences && newSearchParameters) {
      updateUserPreferences(tabState, newSearchParameters);
    }
  };

  const resetProspectFiltersAndOwnedFalse = (
    saveInPreferences = false,
    tabState: DashboardTabType = 'Contact',
  ) => {
    let newSearchParameters: ProspectSearch | null = null;

    if (tabState === 'Contact') {
      newSearchParameters = {
        ...defaultFilters,
        sort: searchProspectParameters.sort ?? 'Name',
        name: searchProspectParameters.name,
        selected: searchProspectParameters.selected,
        userId: null,
        fromContact: true,
      };
      setSearchProspectParameters(newSearchParameters);
    } else if (tabState === 'Lead') {
      newSearchParameters = {
        ...defaultFilters,
        sort: searchLeadParameters.sort ?? 'Name',
        name: searchLeadParameters.name,
        selected: searchLeadParameters.selected,
        userId: null,
        fromContact: false,
      };
      setSearchLeadParameters(newSearchParameters);
    }

    if (saveInPreferences && newSearchParameters) {
      updateUserPreferences(tabState, newSearchParameters);
    }
  };

  //This is used to show different empty states in the table
  const areThereProspectFiltersApplied = useMemo(() => {
    //If there is a userId different from mine it means that a filter is applied. If not we exclude the userId, since we want this to be false if myContacts is selected
    if (
      searchProspectParameters.userId &&
      searchProspectParameters.userId !== user?.id
    )
      return true;
    //Exclude the name, the userId, the sort and the selected from the filters before comparing
    const { name, sort, selected, userId, fromContact, ...rest } =
      searchProspectParameters;
    const {
      name: defaultName,
      userId: userIdDefault,
      ...defaultRest
    } = defaultFilters;
    //Remove all the properties that have null value
    return (
      JSON.stringify(filterNullProperties(rest)) !==
      JSON.stringify(filterNullProperties(defaultRest))
    );
  }, [searchProspectParameters]);

  const areThereLeadFiltersApplied = useMemo(() => {
    //If there is a userId different from mine it means that a filter is applied. If not we exclude the userId, since we want this to be false if myContacts is selected
    if (searchLeadParameters.userId && searchLeadParameters.userId !== user?.id)
      return true;
    //Exclude the name, the userId, the sort and the selected from the filters before comparing
    const { name, sort, selected, userId, fromContact, ...rest } =
      searchLeadParameters;
    const {
      name: defaultName,
      userId: userIdDefault,
      ...defaultRest
    } = defaultFilters;
    //Remove all the properties that have null value
    return (
      JSON.stringify(filterNullProperties(rest)) !==
      JSON.stringify(filterNullProperties(defaultRest))
    );
  }, [searchLeadParameters]);

  const { data: users } = useGetUsers(enabledContextCalls);

  const { data: strategySelected } = useGetStrategyById(
    searchProspectParameters.strategyId ?? 0,
    (searchProspectParameters.strategyId ?? 0) > 0,
  );

  const filtersForResume = (property: string): boolean => {
    return (
      property !== 'sort' &&
      property !== 'limit' &&
      property !== 'offset' &&
      property !== 'selected' &&
      property !== 'strategyId' &&
      //I use the min
      property !== 'numberOfEmployeesMax' &&
      property !== 'name' &&
      property !== 'createdDateMax' &&
      property !== 'lastActivityDateMax' &&
      //I only show the selected sequence with the status inside the same tag
      property !== 'sequenceStatus' &&
      //In previous versions this was used, now no more but it is saved in some userPreferences
      property !== 'IsManager' &&
      property !== 'fromContact' &&
      property !== 'importName'
    );
  };

  const { data: externalProperties } = useGetExternalProperty(
    null,
    true,
    null,
    null,
    enabledContextCalls,
  );
  const externalPropertiesAccount = useMemo(() => {
    if (!externalProperties) return null;
    return externalProperties.data?.data?.filter(
      (externalProperty) => externalProperty?.tableType === 'Account',
    );
  }, [externalProperties]);
  const externalPropertiesProspect = useMemo(() => {
    if (!externalProperties) return null;
    return externalProperties.data?.data?.filter(
      (externalProperty) => externalProperty?.tableType !== 'Account',
    );
  }, [externalProperties]);

  const getExternalProperty = (externalPropertyId: number) => {
    let index = externalPropertiesAccount?.findIndex(
      (externalProperty) => externalProperty?.id === externalPropertyId,
    );
    let externalProperty;
    if (index === -1 && externalPropertiesProspect) {
      index = externalPropertiesProspect?.findIndex(
        (externalProperty) => externalProperty?.id === externalPropertyId,
      );
      externalProperty = externalPropertiesProspect[index ?? 0];
    } else if (externalPropertiesAccount) {
      externalProperty = externalPropertiesAccount[index ?? 0];
    }
    return { externalProperty, index };
  };

  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  const updateUserPreferences = (
    tabState: DashboardTabType,
    newFilters: ProspectSearch | AccountSearch,
    saveInPreferences = true,
  ) => {
    const filtersToSave = { ...newFilters, name: undefined };
    switch (tabState) {
      case 'Company':
        const userPreferencesStringCompany = JSON.stringify(filtersToSave);
        if (user) {
          if (user.userPreferences) {
            user.userPreferences.accountTableFilters =
              userPreferencesStringCompany;
          } else {
            user.userPreferences = {
              accountTableFilters: userPreferencesStringCompany,
            };
          }
        }
        if (saveInPreferences) {
          patchUserPreferences({
            accountTableFilters: userPreferencesStringCompany,
          });
        }
        break;
      case 'Contact':
        const userPreferencesStringContact = JSON.stringify(filtersToSave);
        if (user) {
          if (user.userPreferences) {
            user.userPreferences.prospectTableFilters =
              userPreferencesStringContact;
          } else {
            user.userPreferences = {
              prospectTableFilters: userPreferencesStringContact,
            };
          }
        }
        if (saveInPreferences) {
          patchUserPreferences({
            prospectTableFilters: userPreferencesStringContact,
          });
        }
        break;
      case 'Lead':
        const userPreferencesStringLead = JSON.stringify(filtersToSave);
        if (user) {
          if (user.userPreferences) {
            user.userPreferences.leadTableFilters = userPreferencesStringLead;
          } else {
            user.userPreferences = {
              leadTableFilters: userPreferencesStringLead,
            };
          }
        }
        if (saveInPreferences) {
          patchUserPreferences({
            leadTableFilters: userPreferencesStringLead,
          });
        }
        break;
    }
  };

  const removeFilter = ({ tabState, filter: [key, value] }: IProps) => {
    let newValues: any = null;

    switch (tabState) {
      case 'Company':
        if (key === 'status') {
          newValues = searchAccountParameters?.[key]?.filter(
            (status: DefaultCompanyStatusOptions) => status !== value,
          );
          if (newValues?.length === 0) {
            newValues = null;
          }
        }
        if (key === 'externalProperties') {
          let externalProperty = searchAccountParameters?.[key]?.find(
            (externalProperty: ExternalPropertyFilter) =>
              externalProperty?.externalPropertyId ===
              value?.externalPropertyId,
          );
          const newValuesProperty = externalProperty?.values?.filter(
            (v: string) => v !== value.value,
          );
          if (newValuesProperty?.length === 0 || !newValuesProperty) {
            externalProperty = undefined;
          } else if (externalProperty) {
            externalProperty.values = newValuesProperty;
          }
          newValues = searchAccountParameters?.[key]?.filter(
            (externalProperty: ExternalPropertyFilter) =>
              externalProperty?.externalPropertyId !==
              value?.externalPropertyId,
          );
          if (externalProperty) {
            newValues?.push(externalProperty);
          }
          if (newValues?.length === 0) {
            newValues = null;
          }
        }
        setSearchAccountParameters((prev) => {
          const newFilters = {
            ...prev,
            offset: 0,
            [key]: newValues,
            ...(key === 'numberOfEmployeesMin' && {
              numberOfEmployeesMax: null,
            }),
            ...(key === 'createdDateMin' && {
              createdDateMax: undefined,
            }),
            ...(key === 'lastActivityDateMin' && {
              lastActivityDateMax: undefined,
            }),
            ...(key === 'importId' && {
              importName: undefined,
            }),
          };

          updateUserPreferences('Company', newFilters);
          return newFilters;
        });
        break;
      case 'Contact':
      case 'Lead':
        const searchParametersToUse =
          tabState === 'Contact'
            ? searchProspectParameters
            : searchLeadParameters;
        const setSearchParametersToUse =
          tabState === 'Contact'
            ? setSearchProspectParameters
            : setSearchLeadParameters;
        //This should be not used anymore, left for security
        if (key === 'status') {
          newValues = searchParametersToUse?.[key]?.filter(
            (status: ProspectStatus) => status !== value,
          );
          if (newValues?.length === 0) {
            newValues = null;
          }
        }
        if (key === 'verificationStatuses') {
          newValues = searchProspectParameters?.[key]?.filter(
            (status: EmailStatuses) => status !== value,
          );
          if (newValues?.length === 0) {
            newValues = null;
          }
        }
        if (key === 'actualStatus') {
          newValues = searchParametersToUse?.[key]?.filter(
            (status: DefaultContactStatusOptions) => status !== value,
          );
          if (newValues?.length === 0) {
            newValues = null;
          }
        }
        if (key === 'accountStatus') {
          newValues = searchParametersToUse?.[key]?.filter(
            (status: DefaultCompanyStatusOptions) => status !== value,
          );
          if (newValues?.length === 0) {
            newValues = null;
          }
        }
        if (
          key === 'externalProperties' ||
          key === 'accountExternalProperties'
        ) {
          let externalProperty = searchParametersToUse?.[key]?.find(
            (externalProperty: ExternalPropertyFilter) =>
              externalProperty?.externalPropertyId ===
              value?.externalPropertyId,
          );
          const newValuesProperty = externalProperty?.values?.filter(
            (v: string) => v !== value.value,
          );
          if (newValuesProperty?.length === 0 || !newValuesProperty) {
            externalProperty = undefined;
          } else if (externalProperty) {
            externalProperty.values = newValuesProperty;
          }
          newValues = searchParametersToUse?.[key]?.filter(
            (externalProperty: ExternalPropertyFilter) =>
              externalProperty?.externalPropertyId !==
              value?.externalPropertyId,
          );
          if (externalProperty) {
            newValues?.push(externalProperty);
          }
          if (newValues?.length === 0) {
            newValues = null;
          }
        }
        if (key === 'inSequence') {
          newValues = undefined;
        }
        setSearchParametersToUse((prev) => {
          const newFilters = {
            ...prev,
            offset: 0,
            [key]: newValues,
            ...(key === 'numberOfEmployeesMin' && {
              numberOfEmployeesMax: null,
            }),
            ...(key === 'inSequence' && {
              strategyId: undefined,
              sequenceStatus: null,
            }),
            ...(key === 'createdDateMin' && {
              createdDateMax: undefined,
            }),
            ...(key === 'lastActivityDateMin' && {
              lastActivityDateMax: undefined,
            }),
            ...(key === 'importId' && {
              importName: undefined,
            }),
            fromContact: tabState === 'Contact',
          };

          updateUserPreferences(tabState, newFilters);
          return newFilters;
        });
        break;
    }
  };

  const {
    externalPropertyStatusId: externalPropertyStatusIdProspect,
    isExternalPropertyStatus: isExternalPropertyStatusProspect,
    statuses: statusesProspect,
  } = useGetStatusesOptions('prospect', enabledContextCalls);

  const {
    externalPropertyStatusId: externalPropertyStatusIdAccount,
    isExternalPropertyStatus: isExternalPropertyStatusAccount,
    statuses: statusesAccount,
  } = useGetStatusesOptions('account', enabledContextCalls);

  //Given a set of externalProperties, it creates the resume for the externalProperties
  const createResumeForExternalProperties = (
    values: any,
    tabState: DashboardTabType,
    key: 'accountExternalProperties' | 'externalProperties',
  ) => {
    return values
      .map(
        (externalPropertyFilter: ExternalPropertyFilter, indexMain: number) => {
          const externalProperty = getExternalProperty(
            externalPropertyFilter.externalPropertyId,
          );
          //If we found no externalProperty let's use the statuses
          if (!externalProperty.externalProperty) {
            if (
              externalPropertyFilter.externalPropertyId ===
              externalPropertyStatusIdAccount
            ) {
              return externalPropertyFilter?.values?.map(
                (accountStatus: string) => {
                  const externalProperty = statusesAccount.find(
                    (status) => status.option === accountStatus,
                  );
                  if (!externalProperty) return null;
                  return (
                    <FilterResumeWrapper
                      key={accountStatus}
                      onClick={() =>
                        removeFilter({
                          tabState,
                          filter: [
                            key,
                            {
                              value: accountStatus,
                              externalPropertyId:
                                externalPropertyFilter.externalPropertyId,
                            },
                          ],
                        })
                      }
                    >
                      <Typography noWrap>{externalProperty?.label}</Typography>
                      <CloseMIcon
                        className="remove-filter-icon"
                        color={colors.grey11}
                      />
                    </FilterResumeWrapper>
                  );
                },
              );
            } else if (
              externalPropertyFilter.externalPropertyId ===
              externalPropertyStatusIdProspect
            ) {
              return externalPropertyFilter?.values?.map(
                (prospectStatus: string) => {
                  const externalProperty = statusesProspect.find(
                    (status) => status.option === prospectStatus,
                  );
                  if (!externalProperty) return null;
                  return (
                    <FilterResumeWrapper
                      key={prospectStatus}
                      onClick={() =>
                        removeFilter({
                          tabState,
                          filter: [
                            key,
                            {
                              value: prospectStatus,
                              externalPropertyId:
                                externalPropertyFilter.externalPropertyId,
                            },
                          ],
                        })
                      }
                    >
                      <Typography noWrap>{externalProperty?.label}</Typography>
                      <CloseMIcon
                        className="remove-filter-icon"
                        color={colors.grey11}
                      />
                    </FilterResumeWrapper>
                  );
                },
              );
            }
          }
          return externalPropertyFilter?.values?.map(
            (value: string, indexNested: number) => {
              const property = externalProperties?.data?.data?.find(
                (property) =>
                  property?.id === externalPropertyFilter?.externalPropertyId,
              );
              let label;
              if (property) {
                const indexLabel = property.options?.findIndex(
                  (option) => option === value,
                );
                if (indexLabel !== undefined)
                  label = getExternalPropertyLabels(property).at(indexLabel);
              }
              return (
                <ExternalPropertyComponentRemovable
                  key={`${indexMain} + ${indexNested}`}
                  index={externalProperty.index ?? 0}
                  indexOption={externalProperty.externalProperty?.options?.indexOf(
                    value,
                  )}
                  value={label ?? value}
                  showRemove={true}
                  customStyle={{
                    height: 40,
                    borderRadius: 8,
                    maxWidth: 150,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                  onClick={() =>
                    removeFilter({
                      tabState,
                      filter: [
                        key,
                        {
                          value: value,
                          externalPropertyId:
                            externalPropertyFilter.externalPropertyId,
                        },
                      ],
                    })
                  }
                />
              );
            },
          );
        },
      )
      .filter((value: any) => value !== null);
  };

  const renderFilterResume = ({
    tabState,
    filter: [key, value],
  }: IProps): any | any[] => {
    const currentTabSearchParameters =
      tabState === 'Company'
        ? searchAccountParameters
        : tabState === 'Contact'
          ? searchProspectParameters
          : searchLeadParameters;

    switch (key) {
      case 'accountExternalProperties':
      case 'externalProperties':
        return createResumeForExternalProperties(value, tabState, key);
      case 'accountStatus':
      case 'status':
      case 'actualStatus':
        return value.map(
          (
            accountStatus:
              | AccountStatus
              | ProspectStatus
              | DefaultContactStatusOptions
              | DefaultCompanyStatusOptions,
          ) => {
            return (
              <FilterResumeWrapper
                key={accountStatus}
                onClick={() =>
                  removeFilter({ tabState, filter: [key, accountStatus] })
                }
              >
                <Typography noWrap>
                  {getStringFromStatus(accountStatus)}
                </Typography>
                <CloseMIcon
                  className="remove-filter-icon"
                  color={colors.grey11}
                />
              </FilterResumeWrapper>
            );
          },
        );
      case 'hasPhone':
      case 'hasEmail':
      case 'hasLinkedin':
      case 'hasMobilePhone':
      case 'hasWebsite':
        const color = value ? colors.green : colors.inactiveHover;
        return (
          <SocialFilterWrapper
            onClick={() => removeFilter({ tabState, filter: [key, value] })}
            style={{
              borderColor: color,
              color: color,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              {value ? (
                <PlusSmallIcon color={colors.green} />
              ) : (
                <MinusSmallIcon color={colors.inactive} />
              )}

              <Typography noWrap>
                {getStringFromExternalInformation(key)}
              </Typography>
            </div>

            <CloseMIcon className="remove-filter-icon" color={colors.grey11} />
          </SocialFilterWrapper>
        );
      case 'title':
      case 'country':
      case 'industry':
        return (
          <FilterResumeWrapper
            onClick={() => removeFilter({ tabState, filter: [key, value] })}
          >
            <Typography noWrap>{value}</Typography>
            <CloseMIcon className="remove-filter-icon" color={colors.grey11} />
          </FilterResumeWrapper>
        );
      case 'numberOfEmployeesMin':
        const maxNEmployees: number | string =
          currentTabSearchParameters?.numberOfEmployeesMax ?? '';

        return (
          <FilterResumeWrapper
            onClick={() => removeFilter({ tabState, filter: [key, value] })}
          >
            <Typography noWrap>{`${value}${
              maxNEmployees ? `-${maxNEmployees}` : '+'
            }`}</Typography>
            <CloseMIcon className="remove-filter-icon" color={colors.grey11} />
          </FilterResumeWrapper>
        );
      case 'createdDateMin':
        const maxCreatedDate = currentTabSearchParameters?.createdDateMax ?? '';

        return (
          <FilterResumeWrapper
            onClick={() => removeFilter({ tabState, filter: [key, value] })}
            style={{ maxWidth: '200px' }}
          >
            <Typography noWrap>{`CD: ${moment(value).format('DD/MM')}-${moment(
              maxCreatedDate,
            ).format('DD/MM')}`}</Typography>
            <CloseMIcon className="remove-filter-icon" color={colors.grey11} />
          </FilterResumeWrapper>
        );
      case 'lastActivityDateMin':
        const maxLastActivityDate =
          currentTabSearchParameters?.lastActivityDateMax ?? '';

        return (
          <FilterResumeWrapper
            onClick={() => removeFilter({ tabState, filter: [key, value] })}
            style={{ maxWidth: '200px' }}
          >
            <Typography noWrap>{`LAD: ${moment(value).format('DD/MM')}-${moment(
              maxLastActivityDate,
            ).format('DD/MM')}`}</Typography>
            <CloseMIcon className="remove-filter-icon" color={colors.grey11} />
          </FilterResumeWrapper>
        );
      case 'verificationStatuses':
        return value.map((status: EmailStatuses) => (
          <FilterResumeWrapper
            onClick={() => removeFilter({ tabState, filter: [key, status] })}
            style={{ maxWidth: '200px' }}
            key={status}
          >
            <div
              className={classNames('email-phone-status-icon', {
                'email-phone-status-icon--green': status === 'Valid',
                'email-phone-status-icon--yellow': status === 'AcceptAll',
                'email-phone-status-icon--red': status === 'Invalid',
                'email-phone-status-icon--grey': status === 'Unknown',
              })}
            />

            <Typography whiteSpace={'nowrap'}>
              {'Email ' + (status === 'AcceptAll' ? 'Accept all' : status)}
            </Typography>

            <CloseMIcon className="remove-filter-icon" color={colors.grey11} />
          </FilterResumeWrapper>
        ));
      case 'isPhoneValid':
        return (
          <FilterResumeWrapper
            onClick={() => removeFilter({ tabState, filter: [key, value] })}
            style={{ maxWidth: '200px' }}
          >
            <div
              className={classNames('email-phone-status-icon', {
                'email-phone-status-icon--green': value,
                'email-phone-status-icon--red': !value,
              })}
            />

            <Typography whiteSpace={'nowrap'}>
              {`Phone ${!value ? 'invalid' : 'valid'}`}
            </Typography>

            <CloseMIcon className="remove-filter-icon" color={colors.grey11} />
          </FilterResumeWrapper>
        );
      case 'userId':
        //If the value is not null and not mine (since it is already shown by the switch)
        //If it's null, it means that all are being shown
        if (value !== null && users?.data?.data && value !== user?.id) {
          const userSelected = users?.data?.data.find(
            (user) => user.id === value,
          );
          if (!userSelected) return;
          return (
            <FilterResumeWrapper
              onClick={() => removeFilter({ tabState, filter: [key, value] })}
            >
              <Typography noWrap>
                Owner: {userSelected.firstName} {userSelected.lastName}
              </Typography>
              <CloseMIcon
                className="remove-filter-icon"
                color={colors.grey11}
              />
            </FilterResumeWrapper>
          );
        }
        return;
      case 'ownedProspects':
        if (!value) return;
        return (
          <FilterResumeWrapper
            onClick={() => removeFilter({ tabState, filter: [key, value] })}
          >
            <Typography noWrap>CC_Owned</Typography>
            <CloseMIcon className="remove-filter-icon" color={colors.grey11} />
          </FilterResumeWrapper>
        );

      case 'inSequence':
        if (value === undefined || value === null) return;
        if (!value) {
          return (
            <FilterResumeWrapper
              onClick={() => removeFilter({ tabState, filter: [key, value] })}
            >
              <Typography noWrap>Not in strategy</Typography>
              <CloseMIcon
                className="remove-filter-icon"
                color={colors.grey11}
              />
            </FilterResumeWrapper>
          );
        }

        const stringStrategyResume = `(${showSequenceStatusDTO(
          searchProspectParameters.sequenceStatus ??
            SequenceStatusTypeDTO.ACTIVE,
        )}) ${strategySelected?.data?.data.name}`;
        return (
          <FilterResumeWrapper
            onClick={() => removeFilter({ tabState, filter: [key, value] })}
            style={{ maxWidth: '200px' }}
          >
            {strategySelected === undefined ? (
              <Typography noWrap>In strategy</Typography>
            ) : (
              <Tooltip
                title={stringStrategyResume}
                placement="top"
                enterDelay={500}
                enterNextDelay={500}
              >
                <Typography noWrap>{stringStrategyResume}</Typography>
              </Tooltip>
            )}
            <CloseMIcon className="remove-filter-icon" color={colors.grey11} />
          </FilterResumeWrapper>
        );
      case 'importId':
        return (
          <FilterResumeWrapper
            onClick={() => removeFilter({ tabState, filter: [key, value] })}
          >
            <Typography noWrap>
              {currentTabSearchParameters.importName ?? 'Import file'}
            </Typography>
            <CloseMIcon className="remove-filter-icon" color={colors.grey11} />
          </FilterResumeWrapper>
        );
      default:
        break;
    }
  };

  const isAccountStatus = (status: any): status is AccountStatus => {
    return Object.values(AccountStatus).includes(status);
  };

  const isProspectStatus = (status: any): status is ProspectStatus => {
    return Object.values(ProspectStatus).includes(status);
  };

  const getStringFromStatus = (
    status:
      | AccountStatus
      | ProspectStatus
      | DefaultCompanyStatusOptions
      | DefaultContactStatusOptions,
  ) => {
    if (isAccountStatus(status)) {
      return getStringFromAccountStatus(status);
    } else if (isProspectStatus(status)) {
      return getStringFromProspectStatus(status);
    } else {
      return getLabelFromUnknownOption(status);
    }
  };

  const getStringFromExternalInformation = (
    option:
      | keyof externalInformationAcceptedAccount
      | keyof externalInformationAcceptedProspect,
  ) => {
    switch (option) {
      case 'hasEmail':
        return 'Email';
      case 'hasLinkedin':
        return 'Linkedin';
      case 'hasMobilePhone':
        return 'Mobile';
      case 'hasPhone':
        return 'Phone';
      case 'hasWebsite':
        return 'Website';
      default:
        break;
    }
  };

  const getResumeFilters = (
    tabState: DashboardTabType,
    nToShow: number,
    onRemainingClick: () => void,
  ) => {
    let totalFilters: any[] = [];
    switch (tabState) {
      case 'Company':
        Object.entries(searchAccountParameters)
          .filter(
            ([key, value]) =>
              filtersForResume(key) &&
              value !== null &&
              value !== '' &&
              value !== undefined &&
              //Special cases that has to be managed differently because we don't want to show them in the resume when they have particular values
              !(key === 'userId' && value === user?.id) &&
              !(key === 'status' && isExternalPropertyStatusAccount),
          )
          .forEach((filter, index) => {
            const newValues = renderFilterResume({ filter, tabState });

            Array.isArray(newValues)
              ? (totalFilters = totalFilters.concat(...newValues))
              : totalFilters.push(newValues);
          });
        break;
      case 'Contact':
        Object.entries(searchProspectParameters)
          .filter(
            ([key, value]) =>
              filtersForResume(key) &&
              value !== null &&
              value !== '' &&
              value !== undefined &&
              //Special cases that has to be managed differently because we don't want to show them in the resume when they have particular values
              !(key === 'userId' && value === user?.id) &&
              !(key === 'inSequence' && value === undefined) &&
              !(key === 'status' && isExternalPropertyStatusProspect) &&
              !(key === 'accountStatus' && isExternalPropertyStatusAccount),
          )
          .forEach((filter, index) => {
            const newValues = renderFilterResume({ filter, tabState });
            Array.isArray(newValues)
              ? (totalFilters = totalFilters.concat(...newValues))
              : totalFilters.push(newValues);
          });
        break;
      case 'Lead':
        Object.entries(searchLeadParameters)
          .filter(
            ([key, value]) =>
              filtersForResume(key) &&
              value !== null &&
              value !== '' &&
              value !== undefined &&
              //Special cases that has to be managed differently because we don't want to show them in the resume when they have particular values
              !(key === 'userId' && value === user?.id) &&
              !(key === 'inSequence' && value === undefined) &&
              !(key === 'status' && isExternalPropertyStatusProspect) &&
              !(key === 'accountStatus' && isExternalPropertyStatusAccount),
          )
          .forEach((filter, index) => {
            const newValues = renderFilterResume({ filter, tabState });

            Array.isArray(newValues)
              ? (totalFilters = totalFilters.concat(...newValues))
              : totalFilters.push(newValues);
          });
    }

    const remaining =
      totalFilters.length - nToShow > 0 ? (
        <RemainingFilterResumeWrapper onClick={onRemainingClick}>
          <Typography fontSize={14} fontWeight={500} lineHeight={'18px'}>
            {totalFilters.length - Math.floor(nToShow)}+
          </Typography>
        </RemainingFilterResumeWrapper>
      ) : null;
    return {
      filters: totalFilters.slice(0, nToShow),
      remaining: remaining,
    };
  };

  return (
    <FiltersContext.Provider
      value={{
        defaultFilters: defaultFilters as AccountSearch,
        searchAccountParameters,
        setSearchAccountParameters,
        resetAccountFiltersAndOwnedFalse,
        resetAccountFilters,
        areThereAccountFiltersApplied,
        searchProspectParameters,
        setSearchProspectParameters,
        resetProspectFilters,
        resetProspectFiltersAndOwnedFalse,
        areThereProspectFiltersApplied,
        areThereLeadFiltersApplied,
        searchLeadParameters,
        setSearchLeadParameters,
        getResumeFilters,
        updateUserPreferences,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersProvider;

export function useFilters() {
  const context = useContext(FiltersContext);
  if (context === undefined) {
    throw new Error(`useFilters must be used within a FiltersProvider`);
  }

  return context;
}

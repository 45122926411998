import {
  Avatar,
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import RemoveSmallIcon from 'crono-fe-common/icons/Icon-Remove-Small';
import { CustomTooltip } from 'pages/dashboard/contactTable';
import SequenceButton from 'pages/dashboard/contactTable/SequenceButton';
import LastActivityProspect from 'pages/dashboard/contactTable/components/lastActivity';
import ProgressProspect from 'pages/dashboard/contactTable/components/progress';
import ResumeSequence from 'pages/dashboard/contactTable/resumeSequence';
import SequenceSelector from 'pages/dashboard/contactTable/sequenceSelector';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getError, getNewOptionsWhenSelecting } from 'crono-fe-common/utils';
import ExternalPropertyNotTag from '../externalPropertyNotTag';
import { Prospect, Timezones } from 'crono-fe-common/types/prospect';
import useStopProspectSequence from 'hooks/services/sequence/useStopProspectSequence';

import { ReactComponent as NotOwnedIcon } from 'crono-fe-common/icons/Not-owned-by-the-user.svg';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { colors } from 'crono-fe-common/theme';
import useGetStrategyById from 'hooks/services/sequence/useGetStrategyById';
import { AddTasksOptionsWrapper, ProspectCardWrapper } from './style';
import useDisableProspect from 'hooks/services/prospect/useDisableProspect';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import { useAuth } from 'context/auth';
import { useSelectedAccount } from 'pages/home/context/account';
import { useWindow } from 'context/sideTab';
import { useConditionalSnackBar } from 'context/snackbar';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import SearchLinkedin from 'pages/taskBoard/suggestionsBox/suggestionCards/preconditionLinkedinCard/SearchLinkedin';
import FindEmailPreconditionButton from 'pages/taskBoard/suggestionsBox/suggestionCards/findEmailPreconditionButton';
import { ReactComponent as CRMIcon } from 'crono-fe-common/icons/CRM.svg';
import { ReactComponent as RedirectIcon } from 'crono-fe-common/icons/Redirect.svg';
import usePatchTask from 'hooks/services/task/usePatchTask';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import SequenceInstanceDisplay from 'pages/taskBoard/sequenceInstance';
import RescheduleCalendar from 'pages/taskBoard/rescheduleCalendar';
import { useFormik } from 'formik';
import { ContactInformation, getInformation, validationSchema } from './models';
import { useJuneAnalytics } from 'context/june';
import AddTaskContainer, {
  TaskOption,
} from 'pages/addComponents/addTaskContainer';
import ActivitiesFromTotals from 'pages/dashboard/activitiesFromTotals';
import IconBottomArrow from 'crono-fe-common/icons/Icon-Bottom-Arrow';
import IconTopArrow from 'crono-fe-common/icons/Icon-Top-Arrow';
import IconCopy from 'crono-fe-common/icons/Icon-Copy';
import {
  capitalizeFirstLetter,
  checkIfValidEmail,
  generateProspectCrmLink,
  prospectName,
  stringAvatarProspect,
  transformToValidUrl,
} from 'utils/fe-utils';
import IconXSCheckmark from 'crono-fe-common/icons/Icon-XS-CheckMark';
import FindPhonePreconditionButton from 'pages/taskBoard/suggestionsBox/suggestionCards/findPhonePreconditionButton';
import useFindVerify from 'hooks/services/scrape/useFindVerify';
import VerificationStatusType, {
  EmailScrapeResult,
} from 'crono-fe-common/types/enums/verificationStatusType';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import { CronoMenuItem, CronoSelect } from 'crono-fe-common/components/Select';
import useGetUsers from 'hooks/services/subscription/useGetUsers';
import { isComplete } from 'crono-fe-common/types/sequenceInstance';
import { useConfirmModal } from 'context/confirmModal';
import CrmPipedriveIcon from 'crono-fe-common/icons/Icon-CRM-Pipedrive';
import CrmSalesforceIcon from 'crono-fe-common/icons/Icon-CRM-Salesforce';
import CrmHubspotIcon from 'crono-fe-common/icons/Icon-CRM-Hubspot';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import LinkedinIconMessage from 'crono-fe-common/icons/Icon-Linkedin-Message';
import LinkedinIconInMailCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-New';
import { CustomOptionsTooltip } from 'pages/settings/Strategy';
import { createPortal } from 'react-dom';
import { parseTab } from 'context/sideTab/types';
import CronoStatus from 'components/CronoStatus';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import LinkedinIconInvoice from 'crono-fe-common/icons/Icon-Linkedin-Invoice';
import {
  IInformation,
  contactInformation,
  renderContactInformationIcon,
} from './iconWithValidityBadge';
import { SequenceInstanceStatus } from 'crono-fe-common/types/enums/sequenceInstanceStatus';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import EmailNotFoundState from './emailNotFoundState';
import { useUpgradeModal } from 'context/upgradeModal';

interface NewTaskOption {
  type: TaskTodoType;
  subtype: TaskTodoSubtype | null;
  text: string;
  icon: JSX.Element;
}

const changeStepOption: NewTaskOption[] = [
  {
    type: TaskTodoType.CALL,
    subtype: null,
    text: 'Call',
    icon: <CallIcon />,
  },
  {
    type: TaskTodoType.EMAIL,
    subtype: null,
    text: 'Email',
    icon: <EmailIcon />,
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinInvitation,
    text: 'Linkedin Invitation',
    icon: <LinkedinInvitationIcon />,
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinMessage,
    text: 'Linkedin Message',
    icon: <LinkedinIconMessage />,
  },
  {
    type: TaskTodoType.LINKEDIN,
    subtype: TaskTodoSubtype.LinkedinVoiceNote,
    text: 'Linkedin Voice Note',
    icon: <LinkedinIconInvoice />,
  },
  {
    type: TaskTodoType.IN_MAIL,
    subtype: null,
    text: 'InMail',
    icon: <LinkedinIconInMailCompass />,
  },
];

interface IProps {
  prospect: Prospect;
  selectedToExpand: string;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
}

enum EInputField {
  FirstName = 'firstName',
  LastName = 'lastName',
  JobTitle = 'title',
  PhoneNumber = 'phone',
  MobileNumber = 'mobilePhone',
  Email = 'email',
  Linkedin = 'linkedin',
}

const ProspectCard = ({ prospect, setTabValue, selectedToExpand }: IProps) => {
  const { user, hasSalesNav } = useAuth();

  const { closeWindow } = useWindow();

  const analytics = useJuneAnalytics();

  const [currentFieldHovered, setCurrentFieldHovered] =
    useState<EInputField | null>(null);
  const [currentFieldSelected, setCurrentFieldSelected] =
    useState<EInputField | null>(null);

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const jobTitleRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const mobileNumberRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const linkedinRef = useRef<HTMLInputElement>(null);

  const handleIconClick = useCallback(
    (field: EInputField, elementRef: HTMLInputElement | null) => {
      setCurrentFieldHovered(null);
      setCurrentFieldSelected(field);
      setTimeout(() => {
        if (elementRef) elementRef.focus();
      }, 0);
    },
    [],
  );

  const {
    mutate: updateProspect,
    error: updateProspectError,
    isSuccess: isSuccessEditProspect,
  } = useEditProspect();
  const { mutate: deleteSequence, error: errorStopProspectSequence } =
    useStopProspectSequence();
  const [open, setOpen] = useState(false);

  const [expand, setExpand] = useState<boolean>(
    selectedToExpand === prospect.objectId,
  );

  // keeps track of successful edited fields
  const [prospectFieldValidation, setProspectFieldValidation] = useState<{
    isInvalid: boolean;
    fieldName?: string;
  }>({ isInvalid: false });
  const [showBadge, setShowBadge] = useState<boolean>(false);
  const [editedProspect, setEditedProspect] = useState<keyof Prospect | null>(
    null,
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isSuccessEditProspect) {
      setShowBadge(isSuccessEditProspect);
      timeout = setTimeout(() => {
        setEditedProspect(null);
        setShowBadge(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccessEditProspect]);

  useEffect(() => {
    if (selectedToExpand === prospect.objectId) {
      setExpand(true);
    } else {
      setExpand(false);
    }
  }, [selectedToExpand]);

  const { data: externalProperties } = useGetExternalProperty(
    !prospect.fromContact &&
      user?.integrationType === IntegrationType.SALESFORCE
      ? 'Lead'
      : 'Prospect',
    false,
    false,
  );

  const { data: strategy } = useGetStrategyById(
    prospect.sequenceInstance?.strategyId,
  );

  const { account } = useSelectedAccount();

  const handleTooltipClose = () => {
    if (open) {
      setOpen(false);
    }
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const stopSequence = (prospectId: string, sequenceInstanceId: number) => {
    deleteSequence({ sequenceInstanceId, prospectId });
    if (analytics) {
      analytics.track('stop-sequence', {});
    }
  };

  const { mutateAsync: verifyEmail } = useFindVerify();

  function showFieldValidationError(fieldName: string) {
    setProspectFieldValidation({ isInvalid: true, fieldName });
    setTimeout(() => {
      setProspectFieldValidation({ isInvalid: false });
    }, 2000);
  }

  function showInformativeMessage(message: string) {
    setInformativeMessage(message);
    setTimeout(() => {
      setInformativeMessage(null);
    }, 2000);
  }

  //Function to handle the change of information of a prospect
  //This is called when the enter is pressed
  const handleChange = async (prospectId: string, event: any) => {
    setShowBadge(false);
    setEditedProspect(null);
    const { name, value } = event.target;

    if (!prospect.owned) {
      showInformativeMessage(
        "You can't edit this contact, it's not owned by you",
      );
      return;
    }

    // * onBlur retriggers the request if enter was pressed previously, even for the same value
    // This is to avoid invoking updateProspect by the on blur event, if it was triggered before by on keydown
    if ((prospect[name as keyof Prospect] ?? '') !== value) {
      let scrapeResult = null;
      if (name === 'email' && value) {
        if (!checkIfValidEmail(value)) {
          showFieldValidationError('email');
          return;
        }
        try {
          const { data } = await verifyEmail({
            email: value,
            linkedinUrl: prospect.linkedin,
          });
          if (data?.isSuccess && data?.data?.status) {
            scrapeResult = data?.data?.status;
          } else {
            scrapeResult = VerificationStatusType.UNKNOWN;
          }
        } catch (_) {
          scrapeResult = VerificationStatusType.UNKNOWN;
        }
      }

      //I update the prospect with the new information
      // ideally it should not update if fields are invalid (e.g.: linkedin profile)

      updateProspect({
        prospectId: prospectId,
        [name]: value,
        ...(scrapeResult ? { emailStatus: scrapeResult } : {}),
      });
      setEditedProspect(name);
    }
  };

  const {
    mutate: disableProspect,
    isLoading: loadingDisableProspect,
    error: errorDisableProspect,
  } = useDisableProspect();

  const { openWindow, windowContent } = useWindow();
  const modalVisible = windowContent?.modalVisible;

  const { openModal: openConfirmModal } = useConfirmModal();

  const [informativeMessage, setInformativeMessage] = useState<string | null>(
    null,
  );

  const {
    mutate: patchTask,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
    error: errorPatchTask,
  } = usePatchTask();

  useConditionalSnackBar([
    {
      condition: !!informativeMessage,
      message: informativeMessage || 'Action not allowed',
      severity: 'info',
    },
    {
      condition: !!errorPatchTask,
      message: getError(errorPatchTask) ?? 'Error updating task',
      severity: 'error',
    },
    {
      condition: !!updateProspectError,
      message: getError(updateProspectError) ?? 'Error updating prospect',
      severity: 'error',
    },
    {
      condition: prospectFieldValidation.isInvalid,
      message:
        prospectFieldValidation?.fieldName === 'email'
          ? 'Email format is not valid. Please use a valid format to save the email'
          : `${capitalizeFirstLetter(
              prospectFieldValidation?.fieldName,
            )} is not saved since it is invalid. Please provide a valid ${prospectFieldValidation?.fieldName}`,
      severity: 'error',
    },
    {
      condition: !!errorStopProspectSequence,
      message:
        getError(errorStopProspectSequence) ??
        'Error stopping prospect strategy',
      severity: 'error',
    },
    {
      condition: !!errorDisableProspect,
      message: getError(errorDisableProspect) ?? 'Error disabling prospect',
      severity: 'error',
    },
  ]);

  const openTaskTab = (
    action: 'email' | 'linkedin' | 'call',
    secondaryInfo?: 'phone' | 'mobilePhone',
  ) => {
    //To change the current page and show the one asked for
    openWindow({
      windowType: 'account',
      tab: action,
      modalVisible: action,
      selectedProspectId: prospect.objectId,
      task: null,
      initialPhoneSelected: secondaryInfo,
    });
    setTabValue(parseTab(action));
  };

  // To handle the trigger of an action
  const handleExecuteTask = (
    action: 'email' | 'linkedin' | 'call',
    secondaryInfo?: 'phone' | 'mobilePhone',
  ) => {
    // Check if the prospect has the information needed to execute the action
    if (
      action === 'linkedin' &&
      (!prospect.linkedin || prospect.linkedin === '')
    ) {
      showInformativeMessage(
        "The prospect does not have linkedin. Check the linkedin field in the prospect's profile",
      );
      return;
    }

    if (modalVisible) {
      openConfirmModal({
        title:
          'A task is already in progress, do you want to perform a new task?',
        text: '',
        confirmFunction: () => {
          openTaskTab(action, secondaryInfo);
        },
        cancelFunction: () => null,
      });
    } else {
      openTaskTab(action, secondaryInfo);
    }
  };

  const [showLinkedinSelection, setShowLinkedinSelection] =
    useState<boolean>(false);

  const handleSearchInformation = (name: string) => {
    if (name === 'linkedin') {
      setShowLinkedinSelection(true);
    }
  };

  const callOnSuccess = () => {
    setShowLinkedinSelection(false);
  };

  const prospectCrmLink = generateProspectCrmLink(
    account,
    prospect,
    user ?? null,
  );

  //SEQUENCE INSTANCE VIEW AND MODIFY FORM PROGRESS -------------

  const [
    selectedSequenceInstanceToDisplay,
    setSelectedSequenceInstanceToDisplay,
  ] = useState<{ sequenceInstanceId: number; prospect: Prospect } | null>(null);

  const handleShowSequenceInstance = (
    sequenceInstanceId: number,
    prospect: Prospect,
  ) => {
    setSelectedSequenceInstanceToDisplay({ sequenceInstanceId, prospect });
  };
  const [showRescheduleTask, setShowRescheduleTask] = useState<{
    index: number;
    initialDate: Date;
  } | null>(null);

  useEffect(() => {
    if (patchSuccess) setShowRescheduleTask(null);
  }, [patchSuccess]);

  const handleOpenDateCalendar = (task: TaskTodo) => {
    setShowRescheduleTask({ index: task.id, initialDate: task.activityDate });
  };
  const handleChangeTaskDate = (id: number, newDate: Date) => {
    patchTask({
      id: id,
      activityDate: newDate,
    });

    if (analytics) {
      analytics.track('patch-task', {
        type: 'reschedule',
      });
    }
  };
  const handleChangeDate = (newDate: Date) => {
    handleChangeTaskDate(showRescheduleTask!.index, newDate);
  };

  const formik = useFormik<ContactInformation>({
    initialValues: {
      email: prospect.email || '',
      linkedin: prospect.linkedin || '',
      phone: prospect.phone || '',
      mobilePhone: prospect.mobilePhone || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    formik.setFieldValue('linkedin', prospect.linkedin || '');
  }, [prospect.linkedin]);
  useEffect(() => {
    formik.setFieldValue('email', prospect.email || '');
  }, [prospect.email]);
  useEffect(() => {
    formik.setFieldValue('phone', prospect.phone || '');
  }, [prospect.phone]);
  useEffect(() => {
    formik.setFieldValue('mobilePhone', prospect.mobilePhone || '');
  }, [prospect.mobilePhone]);

  //New task from prospect card
  const [showNewTask, setShowNewTask] = useState<TaskOption | null>(null);

  const handleCreateNewTask = (task: TaskOption) => {
    setShowNewTask(task);
  };

  const { data: usersDb } = useGetUsers();

  const users = useMemo(() => {
    return usersDb?.data?.data ?? [];
  }, [usersDb]);

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index?: number,
  ) => {
    const externalPropertyId = externalProperty.id;
    const finalOption = getNewOptionsWhenSelecting({
      currentExternalValues: prospect.externalValues,
      option,
      externalPropertyId: externalPropertyId,
      valueType: externalProperty.valueType,
    });

    updateProspect({
      prospectId: prospect.objectId,
      externalValues: {
        [externalPropertyId]: finalOption,
      },
    });
  };

  const renderOnClickHandler = (name: IInformation['name']): (() => void) => {
    let handler = () => {};
    switch (name) {
      case 'phone':
      case 'mobilePhone':
        handler = () => handleExecuteTask('call', name);
        break;
      case 'email':
        handler = () => handleExecuteTask('email');
        break;
      case 'linkedin':
        handler = () => handleExecuteTask('linkedin');
        break;
    }
    return handler;
  };

  const renderUserCrmIcon = (type: IntegrationType | undefined) => {
    let icon = null;
    switch (type) {
      case IntegrationType.SALESFORCE:
        icon = (
          <CrmSalesforceIcon
            className="crm-info-icon salesforce-icon"
            viewBox="-1 -1 17 17"
          />
        );
        break;
      case IntegrationType.PIPEDRIVE:
        icon = (
          <CrmPipedriveIcon
            className="crm-info-icon pipedrive-icon"
            viewBox="-1 -1 18 18"
          />
        );
        break;
      case IntegrationType.HUBSPOT:
        icon = (
          <CrmHubspotIcon
            className="crm-info-icon hubspot-icon"
            viewBox="-2 -2 20 20"
          />
        );
        break;
      default:
        icon = <CRMIcon className="info-icon" />;
    }
    return icon;
  };

  // TODO Refactor logic here and contactInformation array to ensure hover over icon both shows bagde border and icon hover style

  const renderPossibleStepType = () => {
    return changeStepOption
      .filter((step) => {
        if (step.type === TaskTodoType.IN_MAIL && !hasSalesNav) {
          return false;
        }
        return true;
      })
      .map((option) => {
        return (
          <div key={`${option.type}-${option.subtype}`}>
            <Button
              className="option-button"
              color="primary"
              fullWidth
              endIcon={option.icon}
              onClick={() => {
                if (prospect.owned) handleCreateNewTask(option);
              }}
            >
              {option.text}
            </Button>
          </div>
        );
      });
  };

  const onClickBuyMore = () => {
    closeWindow();
  };

  const { isUpgradeModalVisible } = useUpgradeModal();

  return (
    <ProspectCardWrapper>
      {selectedSequenceInstanceToDisplay && (
        <SequenceInstanceDisplay
          sequenceInstanceId={
            selectedSequenceInstanceToDisplay.sequenceInstanceId
          }
          prospect={selectedSequenceInstanceToDisplay.prospect}
          onClose={() => setSelectedSequenceInstanceToDisplay(null)}
          handleOpenDateCalendar={handleOpenDateCalendar}
        />
      )}
      {showRescheduleTask && (
        <RescheduleCalendar
          isRescheduleLoading={patchLoading}
          close={() => setShowRescheduleTask(null)}
          handleChangeDate={handleChangeDate}
          initialDate={showRescheduleTask.initialDate}
        />
      )}
      {showLinkedinSelection && account && (
        <div className="search-linkedin-container">
          <SearchLinkedin
            account={account}
            prospect={prospect}
            setOpenModal={setShowLinkedinSelection}
            callOnSuccess={callOnSuccess}
          />
        </div>
      )}
      {!isUpgradeModalVisible &&
        showNewTask !== null &&
        createPortal(
          <AddTaskContainer
            close={() => setShowNewTask(null)}
            initialContact={prospect}
            initialTask={showNewTask}
          />,
          document.body,
        )}
      <div style={{ display: 'flex', width: '100%' }}>
        <Avatar
          key={prospect.objectId}
          //src={require("./defaultImage.jpg")}
          className="prospect-avatar-row"
        >
          {stringAvatarProspect(prospect)}
        </Avatar>
        <div className="prospect-info-container">
          <div className="top-container-name-title-status">
            <div
              className="left left-side"
              style={{ width: prospect.owned ? 'inherit' : '100%' }}
            >
              <div
                className="left left-name-lead"
                onClick={() => setExpand(!expand)}
              >
                <div style={{ display: 'flex' }}>
                  <Tooltip
                    arrow
                    title={prospectName(prospect)}
                    enterDelay={800}
                    enterNextDelay={800}
                  >
                    <Typography
                      fontSize={14}
                      lineHeight={'18px'}
                      fontWeight={600}
                      noWrap
                    >
                      {prospectName(prospect)}
                    </Typography>
                  </Tooltip>
                  {user?.company?.integrationType ===
                    IntegrationType.SALESFORCE &&
                    !prospect.fromContact && (
                      <div className="lead-badge-container">
                        <span className="lead-badge">Lead</span>
                      </div>
                    )}
                </div>
                <Tooltip
                  arrow
                  title={prospect.title}
                  enterDelay={800}
                  enterNextDelay={800}
                >
                  <Typography
                    fontSize={12}
                    fontWeight={400}
                    lineHeight={'18px'}
                    color={colors.grey11}
                    className={'prospect-title'}
                    noWrap
                  >
                    {prospect.title}
                  </Typography>
                </Tooltip>
              </div>
              <div className="right right-status-not-owned-icon">
                <CronoStatus
                  type="prospect"
                  currentActualStatus={prospect.actualStatus}
                  currentExternalValues={prospect.externalValues}
                  objectToUpdate={prospect.objectId}
                  disabled={!prospect.owned}
                  style={{
                    padding: '4px 16px',
                  }}
                />
                {!prospect.owned && (
                  /*If the prospect is not owned */

                  <Tooltip
                    arrow
                    title={`The owner is ${prospect.user}`}
                    placement="top"
                    enterDelay={400}
                    enterNextDelay={400}
                  >
                    <NotOwnedIcon
                      style={{
                        width: 26,
                        height: 26,
                        minWidth: 26,
                        minHeight: 26,
                        marginLeft: 8,
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
            {/*Sequence handling */}
            <div
              className="right right-side-sequence-handling"
              style={{ width: prospect.owned ? 'inherit' : '0' }}
            >
              <div
                className={
                  prospect.sequenceInstance &&
                  !isComplete(prospect.sequenceInstance)
                    ? prospect.sequenceInstance.active
                      ? 'pause-sequence-handling-container'
                      : 'resume-sequence-handling-container'
                    : 'add-sequence-handling-container'
                }
              >
                {prospect.owned &&
                  (prospect.sequenceInstance &&
                  !isComplete(prospect.sequenceInstance) ? (
                    prospect.sequenceInstance.active ? (
                      /*If there is an active sequenceInstance */
                      <div
                        className="sequence-button-wrapper"
                        onClick={() => {
                          if (prospect.sequenceInstance)
                            stopSequence(
                              prospect.objectId,
                              prospect.sequenceInstance.id,
                            );
                        }}
                      >
                        <SequenceButton
                          action="stop"
                          size="small"
                          disableHover
                          disableTooltip
                        />
                        <Tooltip
                          arrow
                          title="Stop strategy"
                          placement="top"
                          enterDelay={400}
                          enterNextDelay={400}
                        >
                          <Typography
                            fontSize={'14px'}
                            className="label sequence-label"
                            fontWeight={500}
                          >
                            {strategy?.data?.data.name ?? ''}
                          </Typography>
                        </Tooltip>
                      </div>
                    ) : (
                      /*If there is an inactive sequenceInstance */
                      <>
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                          <div
                            className="sequence-button-wrapper"
                            onClick={handleTooltipOpen}
                          >
                            <CustomTooltip
                              title={
                                <ResumeSequence
                                  prospect={prospect}
                                  //This has to be passed in order to set the state to false after the action performed
                                  //without this the tooltip would open again after the next click on the pause button
                                  setOpenTooltip={setOpen}
                                  addSequenceOrientation={'left'}
                                />
                              }
                              onClose={handleTooltipClose}
                              open={open}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                            >
                              <SequenceButton
                                action="start"
                                size="small"
                                disableHover
                                disableTooltip
                              />
                            </CustomTooltip>
                            <Tooltip
                              arrow
                              title="Resume strategy"
                              placement="top"
                              enterDelay={400}
                              enterNextDelay={400}
                            >
                              <Typography
                                fontSize={'14px'}
                                className="label sequence-label"
                                fontWeight={500}
                              >
                                {strategy?.data?.data.name ?? ''}
                              </Typography>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>
                      </>
                    )
                  ) : (
                    /*If there is no sequanceInstance */
                    <>
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div
                          className="sequence-button-wrapper add-to-strategy"
                          style={{ width: 'fit-content' }}
                          onClick={handleTooltipOpen}
                        >
                          <CustomTooltip
                            title={
                              <SequenceSelector
                                prospectId={prospect.objectId}
                                accountId={prospect.accountId}
                                setOpenTooltip={setOpen}
                              />
                            }
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            style={{ margin: 0 }}
                          >
                            <SequenceButton
                              action="add"
                              size="small"
                              iconFill={colors.darkGreen}
                              disableHover
                              disableTooltip
                            />
                          </CustomTooltip>
                          <Typography
                            className="label sequence-label"
                            fontSize={'14px'}
                            fontWeight={500}
                            lineHeight={'18px'}
                          >
                            Add to strategy
                          </Typography>
                        </div>
                      </ClickAwayListener>
                    </>
                  ))}
              </div>
            </div>
          </div>
          <div className="bottom-container-tags-action-icons">
            <div className="left left-tags-container">
              <TagsBoxShowAll
                type={prospect.fromContact ? 'Prospect' : 'Lead'}
                blockIsEditable={true}
                handleClickTag={handleClickTag}
                currentSituationTags={
                  prospect.externalValues
                    ?.filter((ep) => ep.isTag && ep.value !== null)
                    .map((ep) => {
                      return {
                        externalPropertyId: ep.externalPropertyId,
                        option: ep.value!,
                      };
                    }) ?? []
                }
                disableEdit={!prospect.owned}
                showRemovable={false}
              />
            </div>
            <div className="right right-icons-container">
              <div style={{ display: 'flex', gap: '8px' }}>
                <CustomOptionsTooltip
                  maxHeight={220}
                  width={190}
                  left={-38}
                  title={
                    prospect.owned && (
                      <AddTasksOptionsWrapper>
                        {renderPossibleStepType()}
                      </AddTasksOptionsWrapper>
                    )
                  }
                >
                  <div
                    className={
                      prospect.owned
                        ? 'add-task-container'
                        : 'disabled-add-task-container'
                    }
                  >
                    <Typography
                      fontSize={'14px'}
                      fontWeight={500}
                      lineHeight={'18px'}
                      color={colors.black}
                    >
                      New task
                    </Typography>
                  </div>
                </CustomOptionsTooltip>

                {(user?.company?.integrationType === IntegrationType.CRONO ||
                  (!account?.isSynced && user?.otherSettings?.hasLateSync)) &&
                  (loadingDisableProspect ? (
                    <CircularProgress size={20} />
                  ) : (
                    <div>
                      <Tooltip
                        arrow
                        title={
                          prospect.owned
                            ? 'Delete contact'
                            : "You can't disable this contact"
                        }
                        enterDelay={400}
                        enterNextDelay={400}
                      >
                        <IconButton
                          className="delete-icon"
                          onClick={() => {
                            disableProspect({
                              AccountId: prospect.accountId,
                              ProspectId: prospect.objectId,
                            });
                          }}
                          sx={{
                            '&.MuiIconButton-root': {
                              padding: '0px',
                            },
                          }}
                        >
                          <RemoveSmallIcon
                            color={colors.inactive}
                            className="trash-icon"
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ))}
              </div>
              {expand ? (
                <IconTopArrow
                  className="expand-arrow-icon"
                  onClick={() => {
                    setExpand(false);
                  }}
                  color={colors.grey11}
                />
              ) : (
                <IconBottomArrow
                  className="expand-arrow-icon"
                  onClick={() => {
                    setExpand(true);
                  }}
                  color={colors.grey11}
                />
              )}
            </div>
          </div>
          {/* <div className="bottom-container-tags-action-icons">
            <div className="left left-tags-container">
              <TagsBoxShowAll
                type="Prospect"
                blockIsEditable={true}
                handleClickTag={handleClickTag}
                currentSituationTags={
                  prospect.externalValues
                    ?.filter((ep) => ep.isTag && ep.value !== null)
                    .map((ep) => {
                      return {
                        externalPropertyId: ep.externalPropertyId,
                        option: ep.value!
                      };
                    }) ?? []
                }
                disableEdit={!prospect.owned}
                showRemovable={false}
              />
            </div>
            <div className="right right-icons-container">
              <div style={{ display: "flex", marginLeft: "12px" }}>
                <span
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    if (prospect.owned) handleExecuteTask("call");
                  }}
                >
                  <CallIcon
                    className={
                      prospect.owned ? "type-icon" : "disabled-type-icon"
                    }
                  />
                </span>
                <span
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    if (prospect.owned) handleExecuteTask("email");
                  }}
                >
                  <EmailIcon
                    className={
                      prospect.owned ? "type-icon" : "disabled-type-icon"
                    }
                  />
                </span>
                <span
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    if (prospect.owned) handleExecuteTask("linkedin");
                  }}
                >
                  <LinkedinIcon
                    className={
                      prospect.owned ? "type-icon" : "disabled-type-icon"
                    }
                  />
                </span>
              </div>

              {expand ? (
                <IconTopArrow
                  className="expand-arrow-icon"
                  onClick={() => {
                    setExpand(false);
                  }}
                  color={colors.black}
                />
              ) : (
                <IconBottomArrow
                  className="expand-arrow-icon"
                  onClick={() => {
                    setExpand(true);
                  }}
                  color={colors.black}
                />
              )}
            </div>
          </div> */}
        </div>
      </div>
      {/*Additional information */}
      {expand && (
        <>
          <div className="divider" />

          <div
            style={{
              width: '100%',
              display: 'flex',
            }}
          >
            <div className="inner-expand inner-expand-left">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="information-editable-container"
              >
                <div className="editable-div">
                  <Tooltip
                    arrow
                    title={
                      prospect.owned
                        ? ''
                        : 'You are not the owner of this contact'
                    }
                    placement="bottom"
                    enterDelay={400}
                    enterNextDelay={400}
                  >
                    <TextField
                      inputRef={firstNameRef}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name={EInputField.FirstName}
                      autoComplete="off"
                      fullWidth
                      defaultValue={prospect.firstName}
                      disabled={!prospect.owned}
                      placeholder="Add a first name"
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChange(prospect.objectId, ev);
                        }
                      }}
                      onBlur={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChange(prospect.objectId, ev);
                      }}
                      sx={{
                        '& .Mui-focused': {
                          background: colors.grey6,
                          borderRadius: '8px',
                          padding: '3px 0px',
                        },
                      }}
                      onFocus={() =>
                        setCurrentFieldSelected(EInputField.FirstName)
                      }
                    />
                  </Tooltip>

                  {showBadge && editedProspect === 'firstName' && (
                    <p className="edited-field-badge">
                      Saved
                      <IconXSCheckmark color={colors.primaryDark} />
                    </p>
                  )}

                  {currentFieldSelected !== EInputField.FirstName && (
                    <span
                      onMouseEnter={() =>
                        setCurrentFieldHovered(EInputField.FirstName)
                      }
                      onMouseLeave={() => setCurrentFieldHovered(null)}
                      onClick={() =>
                        handleIconClick(
                          EInputField.FirstName,
                          firstNameRef.current,
                        )
                      }
                      className="pencil-edit-icon-container"
                    >
                      <EditIcon
                        className="pencil-edit-icon"
                        color={
                          currentFieldHovered !== EInputField.FirstName
                            ? colors.grey2
                            : colors.black
                        }
                      />
                    </span>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="information-editable-container"
              >
                <div className="editable-div">
                  <Tooltip
                    arrow
                    title={
                      prospect.owned
                        ? ''
                        : 'You are not the owner of this contact'
                    }
                    placement="bottom"
                    enterDelay={400}
                    enterNextDelay={400}
                  >
                    <TextField
                      inputRef={lastNameRef}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name={EInputField.LastName}
                      autoComplete="off"
                      fullWidth
                      disabled={!prospect.owned}
                      defaultValue={prospect.lastName}
                      placeholder="Add a last name"
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChange(prospect.objectId, ev);
                        }
                      }}
                      onBlur={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChange(prospect.objectId, ev);
                      }}
                      onFocus={() =>
                        setCurrentFieldSelected(EInputField.LastName)
                      }
                    />
                  </Tooltip>

                  {showBadge && editedProspect === 'lastName' && (
                    <p className="edited-field-badge">
                      Saved
                      <IconXSCheckmark color={colors.primaryDark} />
                    </p>
                  )}

                  {currentFieldSelected !== EInputField.LastName && (
                    <span
                      onMouseEnter={() =>
                        setCurrentFieldHovered(EInputField.LastName)
                      }
                      onMouseLeave={() => setCurrentFieldHovered(null)}
                      onClick={() =>
                        handleIconClick(
                          EInputField.LastName,
                          lastNameRef.current,
                        )
                      }
                      className="pencil-edit-icon-container"
                    >
                      <EditIcon
                        className="pencil-edit-icon"
                        color={
                          currentFieldHovered !== EInputField.LastName
                            ? colors.grey2
                            : colors.black
                        }
                      />
                    </span>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="information-editable-container"
              >
                <div className="editable-div">
                  <Tooltip
                    arrow
                    title={
                      prospect.owned
                        ? ''
                        : 'You are not the owner of this contact'
                    }
                    placement="bottom"
                    enterDelay={400}
                    enterNextDelay={400}
                  >
                    <TextField
                      inputRef={jobTitleRef}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      name={EInputField.JobTitle}
                      autoComplete="off"
                      fullWidth
                      disabled={!prospect.owned}
                      defaultValue={prospect.title?.toString() || ''}
                      placeholder="Add a title"
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChange(prospect.objectId, ev);
                        }
                      }}
                      onBlur={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChange(prospect.objectId, ev);
                      }}
                      onFocus={() =>
                        setCurrentFieldSelected(EInputField.JobTitle)
                      }
                    />
                  </Tooltip>

                  {showBadge && editedProspect === 'title' && (
                    <p className="edited-field-badge">
                      Saved
                      <IconXSCheckmark color={colors.primaryDark} />
                    </p>
                  )}

                  {currentFieldSelected !== EInputField.JobTitle && (
                    <span
                      onMouseEnter={() =>
                        setCurrentFieldHovered(EInputField.JobTitle)
                      }
                      onMouseLeave={() => setCurrentFieldHovered(null)}
                      onClick={() =>
                        handleIconClick(
                          EInputField.JobTitle,
                          jobTitleRef.current,
                        )
                      }
                      className="pencil-edit-icon-container"
                    >
                      <EditIcon
                        className="pencil-edit-icon"
                        color={
                          currentFieldHovered !== EInputField.JobTitle
                            ? colors.grey2
                            : colors.black
                        }
                      />
                    </span>
                  )}
                </div>
              </div>

              {contactInformation.map((field, index) => {
                const link = prospect[field.name] as string;

                const getInputRef = (fieldName: EInputField) => {
                  switch (fieldName) {
                    case EInputField.FirstName:
                      return firstNameRef;
                    case EInputField.LastName:
                      return lastNameRef;
                    case EInputField.JobTitle:
                      return jobTitleRef;
                    case EInputField.PhoneNumber:
                      return phoneNumberRef;
                    case EInputField.MobileNumber:
                      return mobileNumberRef;
                    case EInputField.Email:
                      return emailRef;
                    case EInputField.Linkedin:
                      return linkedinRef;
                    default:
                      return null;
                  }
                };
                const inputRef = getInputRef(field.name as EInputField);
                const isEmailNotFound =
                  field.name === 'email' && prospect.lastEmailNotFoundDate;
                return (
                  <div
                    key={field.name}
                    style={{
                      display: 'flex',
                      alignItems: isEmailNotFound ? 'flex-start' : 'center',
                      gap: '12px',
                    }}
                  >
                    <div
                      className="icon-badge-container"
                      style={{
                        paddingTop: isEmailNotFound ? '3px' : '0px',
                      }}
                    >
                      {renderContactInformationIcon(
                        field,
                        prospect,
                        renderOnClickHandler,
                      )}
                    </div>
                    {isEmailNotFound ? (
                      <EmailNotFoundState
                        prospectId={prospect.objectId}
                        website={prospect.account?.website ?? null}
                        accountId={account?.objectId ?? null}
                      />
                    ) : (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                        className="editable-div"
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                            width: '100%',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              flex: 1,
                            }}
                          >
                            <Tooltip
                              arrow
                              title={
                                prospect.owned
                                  ? ''
                                  : 'You are not the owner of this contact'
                              }
                              placement="bottom"
                              enterDelay={400}
                              enterNextDelay={400}
                            >
                              <TextField
                                inputRef={inputRef}
                                name={field.name}
                                variant="standard"
                                autoComplete="off"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                style={{ flex: 1 }}
                                placeholder={
                                  'Insert ' +
                                  (field.name === 'phone'
                                    ? 'phone number'
                                    : field.name === 'mobilePhone'
                                      ? 'mobile number'
                                      : field.name === 'email'
                                        ? 'email'
                                        : field.name === 'linkedin'
                                          ? 'linkedin URL'
                                          : 'information')
                                }
                                onChange={(ev) => {
                                  formik.setFieldValue(
                                    field.name,
                                    ev.target.value,
                                  );
                                }}
                                disabled={!prospect.owned}
                                value={
                                  formik.values[getInformation(field.name)]
                                }
                                onKeyDown={(ev) => {
                                  if (ev.key === 'Enter') {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                    handleChange(prospect.objectId, ev);
                                  }
                                }}
                                onBlur={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                  handleChange(prospect.objectId, ev);
                                }}
                                onFocus={() =>
                                  setCurrentFieldSelected(
                                    field.name as EInputField,
                                  )
                                }
                              />
                            </Tooltip>
                            {field.name === 'email' && !prospect.emailValid && (
                              <p className="invalid-email-badge">Invalid</p>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 4,
                          }}
                        >
                          {showBadge && editedProspect === field.name && (
                            <p className="edited-field-badge">
                              Saved
                              <IconXSCheckmark color={colors.primaryDark} />
                            </p>
                          )}

                          {currentFieldSelected !==
                            (field.name as EInputField) && (
                            <span
                              onMouseEnter={() =>
                                setCurrentFieldHovered(
                                  field.name as EInputField,
                                )
                              }
                              onMouseLeave={() => setCurrentFieldHovered(null)}
                              onClick={() => {
                                if (inputRef) {
                                  handleIconClick(
                                    field.name as EInputField,
                                    inputRef.current,
                                  );
                                }
                              }}
                              className="pencil-edit-icon-container"
                            >
                              <EditIcon
                                className="pencil-edit-icon"
                                color={
                                  currentFieldHovered !== field.name
                                    ? colors.grey2
                                    : colors.black
                                }
                              />
                            </span>
                          )}

                          {field.name === 'phone' ? (
                            !link || link === '' ? (
                              <FindPhonePreconditionButton
                                account={account}
                                prospect={prospect}
                                iconColor={colors.white}
                                hasLabel
                                makeBEPatchProspect={true}
                              />
                            ) : (
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(link);
                                }}
                              >
                                <IconCopy />
                              </IconButton>
                            )
                          ) : field.name === 'email' ? (
                            !link || link === '' ? (
                              <FindEmailPreconditionButton
                                account={account}
                                prospect={prospect}
                                iconColor={colors.white}
                                hasLabel
                                onClickBuyMore={onClickBuyMore}
                                makeBEPatchProspect={true}
                              />
                            ) : (
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(link);
                                }}
                              >
                                <IconCopy />
                              </IconButton>
                            )
                          ) : field.name === 'linkedin' ? (
                            link && link !== '' ? (
                              <IconButton
                                onClick={() => {
                                  if (
                                    link &&
                                    transformToValidUrl(link) !== null
                                  )
                                    window.open(
                                      transformToValidUrl(link)!,
                                      '_blank',
                                    );
                                }}
                              >
                                <RedirectIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => {
                                  handleSearchInformation(field.name);
                                }}
                                sx={{
                                  padding: '3px 2px 3px 6px !important',
                                  backgroundColor: colors.primary,
                                  width: '76px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  gap: '6px',
                                  borderRadius: '34px',
                                  cursor: undefined,
                                  '&.MuiIconButton-root:hover': {
                                    backgroundColor: colors.primaryDark,
                                  },
                                }}
                              >
                                <Typography
                                  fontSize={'14px'}
                                  color={colors.white}
                                >
                                  Find
                                </Typography>

                                <SearchIcon color={colors.white} />
                              </IconButton>
                            )
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              {/*CRM field */}
              {user?.company?.integrationType !== IntegrationType.CRONO && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (
                        prospectCrmLink &&
                        transformToValidUrl(prospectCrmLink) !== null
                      )
                        window.open(
                          transformToValidUrl(prospectCrmLink)!,
                          '_blank',
                        );
                    }}
                  >
                    {renderUserCrmIcon(user?.company?.integrationType)}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    className="editable-div"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden',
                        width: '100%',
                      }}
                    >
                      <TextField
                        name="crm"
                        variant="standard"
                        autoComplete="off"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        fullWidth
                        defaultValue={prospectCrmLink}
                        disabled
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 4,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          if (
                            prospectCrmLink &&
                            transformToValidUrl(prospectCrmLink) !== null
                          )
                            window.open(
                              transformToValidUrl(prospectCrmLink)!,
                              '_blank',
                            );
                        }}
                      >
                        <RedirectIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 12,
                  paddingLeft: 4,
                }}
              >
                <Typography
                  fontSize={14}
                  lineHeight={'18px'}
                  color={colors.grey11}
                >
                  Time zone:
                </Typography>
                <CronoSelect
                  value={prospect.timeZone}
                  sx={{
                    height: '32px',
                    borderRadius: '8px',
                    flex: 1,
                  }}
                  onChange={(ev) => {
                    handleChange(prospect.objectId, ev);
                  }}
                  name={'timeZone'}
                >
                  {Timezones.map((timezone) => {
                    return (
                      <CronoMenuItem value={timezone} key={timezone}>
                        UTC{timezone}
                      </CronoMenuItem>
                    );
                  })}
                </CronoSelect>
              </div>
              {(externalProperties?.data?.data || [])
                .filter((externalProperty) => !externalProperty.isTag)
                .map((externalProperty, index) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '4px',
                    }}
                    className="information-editable-container"
                    key={
                      '' +
                      index +
                      externalProperty?.valueType +
                      prospect.objectId
                    }
                  >
                    <div className="external-properties-tag">
                      <Tooltip
                        arrow
                        title={externalProperty.publicName}
                        enterDelay={400}
                        enterNextDelay={400}
                      >
                        <Typography className="information-label">
                          {externalProperty.publicName}:
                        </Typography>
                      </Tooltip>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ExternalPropertyNotTag
                        type="Prospect"
                        externalValues={prospect.externalValues}
                        externalProperty={externalProperty}
                        objectId={prospect.objectId}
                        owned={prospect.owned}
                        key={index}
                      />
                    </div>
                  </div>
                ))}
            </div>

            <div className="inner-expand inner-expand-right">
              <div>
                <Typography className="label" fontWeight={500}>
                  Activities:
                </Typography>
                <ActivitiesFromTotals data={prospect} justifyContent="left" />
              </div>
              <div>
                <Typography className="label" fontWeight={500}>
                  Last Activity:
                </Typography>
                <LastActivityProspect prospect={prospect} />
              </div>
              <div>
                <Typography className="label" fontWeight={500}>
                  Progress:
                </Typography>
                {prospect.sequenceInstance?.active && prospect.owned ? (
                  <ProgressProspect
                    prospect={prospect}
                    onClick={() => {
                      if (
                        prospect.sequenceInstance?.id &&
                        prospect.sequenceInstance?.active
                      )
                        handleShowSequenceInstance(
                          prospect.sequenceInstance.id,
                          prospect,
                        );
                    }}
                  />
                ) : (
                  <Tooltip
                    arrow
                    title={
                      prospect.owned
                        ? prospect.sequenceInstance?.status ===
                          SequenceInstanceStatus.Completed
                          ? 'Strategy completed'
                          : 'Strategy paused'
                        : 'You are not the owner of this prospect'
                    }
                    placement="top-start"
                    enterDelay={400}
                    enterNextDelay={400}
                  >
                    <ProgressProspect prospect={prospect} />
                  </Tooltip>
                )}
              </div>
              <div>
                <Typography className="label" fontWeight={500}>
                  Owner:
                </Typography>
                <CronoSelect
                  value={prospect.userId}
                  onChange={(ev) => {
                    updateProspect({
                      prospectId: prospect.objectId,
                      userId: ev.target.value as number,
                      sync: true,
                    });
                  }}
                  style={{ height: 32 }}
                  MenuProps={{
                    disablePortal: true,
                  }}
                >
                  {users.map((user) => {
                    return (
                      <CronoMenuItem value={user.id} key={user.id}>
                        {user.firstName} {user.lastName}
                      </CronoMenuItem>
                    );
                  })}
                </CronoSelect>
              </div>
            </div>
          </div>
        </>
      )}
    </ProspectCardWrapper>
  );
};

export default ProspectCard;

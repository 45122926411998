import {
  Button,
  CircularProgress,
  ClickAwayListener,
  TextField,
  Typography,
} from '@mui/material';
import {
  MainPrimaryButton,
  RedButton,
} from 'crono-fe-common/components/CronoButton';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  GreyOwnerBadgeWrapper,
  SequenceActionTooltipWrapper,
  TableFooterWrapper,
} from '../footerStyle';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import {
  getError,
  getNewOptionsWhenSelectingWithStrings,
} from 'crono-fe-common/utils';
import useEditAccountsMultiple from 'hooks/services/account/useEditAccountsMultiple';
import { UpdateAccountMultiple } from 'crono-fe-common/types/DTO/updateAccountMultiple';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { useConditionalSnackBar } from 'context/snackbar';
import { Constants } from 'crono-fe-common/constants/constants';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import useDisableMultipleAccounts from 'hooks/services/account/useDisableAccountMultiple';
import { useConfirmModal } from 'context/confirmModal';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import useGetUsers, {
  UserSubscription,
} from 'hooks/services/subscription/useGetUsers';
import CloseSIcon from 'crono-fe-common/icons/Icon-Close-S';
import OwnerIcon from 'crono-fe-common/icons/Icon-Owner';
import { fullname } from 'utils/fe-utils';
import { TooltipFooter } from '../contactTable/contactTableFooter/prospectSelectionBottom';
import useSyncAccounts from 'hooks/services/account/useSyncAccounts';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import CronoStatus from 'components/CronoStatus';
import useGetExternalPropertyStatus from 'hooks/services/externalProperty/useGetExternalPropertyStatus';
import useLookSync from 'hooks/services/sync/useLookSync';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';
import { DefaultCompanyStatusOptions } from 'crono-fe-common/types/model';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import IconNotOwned from 'crono-fe-common/icons/Icon-Not-Owned';
import EditPencilIconS from 'crono-fe-common/icons/Icon-Edit-Pencil-S';
import { StatusTagButtonWrapper } from 'components/CronoStatus/style';
import TagIcon from 'crono-fe-common/icons/Icon-Tag-Settings';
import IconTopArrow from 'crono-fe-common/icons/Icon-Top-Arrow';
import IconBottomArrow from 'crono-fe-common/icons/Icon-Bottom-Arrow';

interface IProps {
  selected?: string[];
  selectedOwned?: string[];
  clearSelected: () => void;
  selectedExternal: string[];
  onExternalAdd: () => void;
  onCompleteSync: () => void;
}

const AccountSelectionBottom = ({
  selected,
  selectedOwned,
  clearSelected,
  selectedExternal,
  onExternalAdd,
  onCompleteSync,
}: IProps) => {
  const { user } = useAuth();
  const { data: externalProperties } = useGetExternalProperty(
    'Account',
    true,
    false,
  );

  const [externalValues, setExternalValues] = useState<(string | null)[]>(
    new Array(Constants.numberOfTags).fill(null),
  );

  const { data: externalPropertyStatus } =
    useGetExternalPropertyStatus('Account');

  const isExternalPropertyStatusId =
    externalPropertyStatus?.data?.data?.id ?? null;

  const {
    mutate: editAccounts,
    error: accountsUpdateError,
    isLoading: accountsUpdating,
    isSuccess: accountsUpdated,
  } = useEditAccountsMultiple();

  const handleApply = () => {
    const newExternalValues: {
      [key: number]: string | null;
    } = {};
    externalValues.forEach((value, index) => {
      if (value && externalProperties?.data?.data[index].id) {
        newExternalValues[externalProperties?.data?.data[index].id] = value;
      }
    });
    //In case the status is not the default one of Crono but an externalProperty
    if (isExternalPropertyStatusId !== null && statusSelected !== null) {
      newExternalValues[isExternalPropertyStatusId] = statusSelected;
    }
    const data: UpdateAccountMultiple[] =
      selected?.map((accountId) => ({
        accountId: accountId,
        externalValues: newExternalValues,
        //If case the status is the default one of Crono
        ...(isExternalPropertyStatusId === null
          ? { status: statusSelected as DefaultCompanyStatusOptions }
          : {}),
        ...(selectedUser ? { userId: selectedUser.id } : {}),
      })) ?? [];
    editAccounts(data);
  };

  const [statusSelected, setStatusSelected] = useState<string | null>(null);

  const {
    mutate: disableMultipleAccounts,
    isLoading: disablingMultipleAccounts,
    isSuccess: disabledMultipleAccounts,
    error: disableMultipleAccountsError,
  } = useDisableMultipleAccounts();

  const { mutateAsync: syncAccountAsync, error: errorSyncingAccounts } =
    useSyncAccounts();

  const { mutateAsync: lookSyncAsync } = useLookSync();

  const [isSyncing, setIsSyncing] = useState<boolean>(false);

  const [errorImportingExternal, setErrorImportingExternal] = useState<
    string | null
  >(null);

  const startLookSync = (id: number) => {
    const t = setInterval(async () => {
      const res = await lookSyncAsync(id);
      if (res.data?.data.status === ImportStatusType.CompletedWithErrors) {
        clearInterval(t);
        setErrorImportingExternal('Error syncing some companies');
        setTimeout(() => {
          setErrorImportingExternal(null);
        }, 3000);
        setTimeout(() => {
          onCompleteSync();
        }, 500);
      } else if (res.data?.data.status === ImportStatusType.Completed) {
        onCompleteSync();
        clearInterval(t);
      }
    }, 5000);
  };

  const handleSyncAllAccounts = async () => {
    setIsSyncing(true);

    const id = await syncAccountAsync({
      data: selectedExternal ?? [],
    });
    if (id.data?.data) {
      startLookSync(id.data.data);
    }
    if (onExternalAdd) onExternalAdd();
    setIsSyncing(false);
    clearSelected();
  };

  useConditionalSnackBar([
    {
      condition: !!accountsUpdateError,
      message: getError(accountsUpdateError) ?? 'Error updating companies',
      severity: 'error',
    },
    {
      condition: !!accountsUpdated,
      message: 'Companies correctly updated',
      severity: 'success',
    },
    {
      condition: !!disabledMultipleAccounts,
      message: 'Companies correctly deleted',
      severity: 'success',
    },
    {
      condition: !!disableMultipleAccountsError,
      message:
        getError(disableMultipleAccountsError) ?? 'Error deleting companies',
      severity: 'error',
    },
    {
      condition: !!errorSyncingAccounts,
      message: getError(errorSyncingAccounts) ?? 'Error adding copmanies',
      severity: 'error',
    },
    {
      condition: !!errorImportingExternal,
      message: errorImportingExternal ?? 'Error syncing some companies',
      severity: 'error',
    },
  ]);

  useEffect(() => {
    if (accountsUpdated) {
      clearFooter();
      clearSelected();
    }
  }, [accountsUpdated]);

  const handleChangeStatus = (status: string) => {
    if (statusSelected === status) {
      setStatusSelected(null);
    } else {
      setStatusSelected(status);
    }
  };

  const [openTooltipTags, setOpenTooltipTags] = useState<boolean>(false);

  const clearFooter = () => {
    setStatusSelected(null);
    setExternalValues(new Array(Constants.numberOfTags).fill(null));
    setSelectedUser(null);
    setSearchUserName(null);
    setOpenTooltipTags(false);
  };

  const { openModal: openConfirmModal } = useConfirmModal();

  const handleDeleteMultipleAccounts = () => {
    openConfirmModal({
      cancelFunction: () => {},
      confirmFunction: () => {
        disableMultipleAccounts({
          data: selectedOwned ?? [],
        });
      },
      title: 'Delete companies',
      text: 'Are you sure you want to delete these companies?',
      confirmText: 'Delete',
      cancelText: 'Cancel',
    });
  };

  useEffect(() => {
    if (disabledMultipleAccounts) {
      clearFooter();
      clearSelected();
    }
  }, [disabledMultipleAccounts]);

  useEffect(() => {
    if (selected?.length === 0) {
      clearFooter();
    }
  }, [selected]);

  useEffect(() => {
    return () => {
      clearFooter();
    };
  }, []);

  const { data: usersData } = useGetUsers();
  const users = useMemo(() => usersData?.data?.data ?? [], [usersData]);

  const [searchUserName, setSearchUserName] = useState<string | null>(null);

  const filteredUsers = useMemo(() => {
    if (searchUserName) {
      return users?.filter((user) =>
        fullname(user.firstName, user.lastName)
          .toLowerCase()
          .includes(searchUserName.toLowerCase()),
      );
    }
    return users;
  }, [searchUserName]);

  const [openTooltipOwner, setOpenTooltipOwner] = useState<boolean>(false);

  const handleTooltipOwnerClose = () => {
    setOpenTooltipOwner(false);
  };
  const handleTooltipOwnerOpen = () => {
    setOpenTooltipOwner(true);
  };

  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (openTooltipOwner && textFieldRef.current) {
      textFieldRef.current?.focus();
    }
  }, [textFieldRef.current, openTooltipOwner]);

  const [selectedUser, setSelectedUser] = useState<UserSubscription | null>(
    null,
  );

  const handleSelectUser = (userId: number) => {
    setSelectedUser(users?.find((user) => user.id === userId) ?? null);
    handleTooltipOwnerClose();
    setSearchUserName('');
  };

  const onEnterOwner = () => {
    if (filteredUsers?.length >= 1) {
      setSelectedUser(filteredUsers[0]);
      handleTooltipOwnerClose();
      setSearchUserName('');
    }
  };

  const handleRemoveUser = () => {
    setSelectedUser(null);
  };

  //If this is true something changed and we should show the apply button
  const showApply = useMemo(() => {
    return (
      externalValues.some((value) => value !== null) ||
      statusSelected ||
      selectedUser
    );
  }, [externalValues, statusSelected, selectedUser]);

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const finalOption = getNewOptionsWhenSelectingWithStrings({
      currentValue: externalValues[index],
      option,
      valueType: externalProperty.valueType,
    });
    const newExternalValues = [...externalValues];
    newExternalValues[index] = finalOption;
    setExternalValues(newExternalValues);
  };

  return selected && selected.length > 0 ? (
    <TableFooterWrapper>
      <Button
        className="selected-button"
        variant="contained"
        color="secondary"
        endIcon={<CloseMIcon color={colors.grey11} />}
        disableElevation
        onClick={() => {
          if (clearSelected) {
            clearSelected();
          }
        }}
      >
        {selected.length} selected
      </Button>
      <div className="divider" />
      {/*Owner picker */}

      <ClickAwayListener onClickAway={handleTooltipOwnerClose}>
        <div>
          <TooltipFooter
            title={
              <SequenceActionTooltipWrapper>
                {filteredUsers?.map((user) => {
                  return (
                    <Button
                      key={user.id}
                      className={`sequence-action-tooltip-button ${
                        user.id === selectedUser?.id ? 'selected-user' : ''
                      }`}
                      onClick={() => handleSelectUser(user.id)}
                    >
                      {fullname(user.firstName, user.lastName)}
                    </Button>
                  );
                })}
              </SequenceActionTooltipWrapper>
            }
            onClose={handleTooltipOwnerClose}
            open={openTooltipOwner}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <div>
              <GreyOwnerBadgeWrapper
                startIcon={
                  selectedUser?.id === user?.id || !selectedUser?.id ? (
                    <OwnerIcon
                      className="sequence-action-icon"
                      color={colors.mainDark}
                    />
                  ) : (
                    <IconNotOwned
                      className="sequence-action-icon"
                      color={colors.mainDark}
                    />
                  )
                }
                color="secondary"
                onClick={() => {
                  if (openTooltipOwner) {
                    handleTooltipOwnerClose();
                  } else {
                    handleTooltipOwnerOpen();
                  }
                }}
                endIcon={
                  <EditPencilIconS
                    color={colors.grey11}
                    className="sequence-action-icon"
                  />
                }
              >
                {openTooltipOwner ? (
                  <TextField
                    value={searchUserName}
                    inputRef={textFieldRef}
                    placeholder="Type..."
                    onChange={(ev) => {
                      if (setSearchUserName) setSearchUserName(ev.target.value);
                    }}
                    autoComplete="false"
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onKeyDown={(ev) => {
                      if (ev.key === 'Enter') {
                        onEnterOwner();
                      }
                    }}
                    inputProps={{
                      style: {
                        padding: 0,
                        fontSize: 12,
                      },
                    }}
                    sx={{
                      height: '16px',
                      width: '60px',
                    }}
                  />
                ) : selectedUser ? (
                  <>
                    <Typography
                      fontSize={12}
                      lineHeight={'16px'}
                      fontWeight={400}
                      noWrap
                    >
                      {fullname(selectedUser.firstName, selectedUser.lastName)}
                    </Typography>
                    <CloseSIcon
                      color={colors.grey11}
                      onClick={(ev: any) => {
                        ev.stopPropagation();
                        ev.preventDefault();
                        handleRemoveUser();
                      }}
                    />
                  </>
                ) : (
                  'Owner'
                )}
              </GreyOwnerBadgeWrapper>
            </div>
          </TooltipFooter>
        </div>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={() => setOpenTooltipTags(false)}>
        <div>
          <TooltipFooter
            title={
              <div style={{ padding: 8 }}>
                <TagsBoxShowAll
                  type="Account"
                  currentSituation={externalValues}
                  handleClickTag={handleClickTag}
                  customStyle={{
                    maxWidth: 100,
                  }}
                />
              </div>
            }
            open={openTooltipTags}
            onClose={() => setOpenTooltipTags(false)}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <StatusTagButtonWrapper
              onClick={() => setOpenTooltipTags((prev) => !prev)}
            >
              <TagIcon className="status-settings-icon" />
              <Typography fontSize={12} lineHeight={'16px'} noWrap>
                Tags{' '}
                {externalValues.some((value) => value !== null)
                  ? `(${selected.length}): ${externalValues
                      .map((value, index) => {
                        if (value === null) return null;
                        return `${externalProperties?.data?.data[index].publicName}`;
                      })
                      .filter((val) => val)
                      .join(', ')}`
                  : ''}
              </Typography>
              {openTooltipTags ? (
                <IconTopArrow className="tag-arrow" />
              ) : (
                <IconBottomArrow className="tag-arrow" />
              )}
            </StatusTagButtonWrapper>
          </TooltipFooter>
        </div>
      </ClickAwayListener>
      <CronoStatus
        type="account"
        currentPickedStatus={statusSelected}
        currentActualStatus={null}
        currentExternalValues={null}
        nullAsTagPicker={true}
        handleSetStatus={handleChangeStatus}
        fixedWidth={true}
      />
      {user?.company?.integrationType === IntegrationType.CRONO &&
        (disablingMultipleAccounts ? (
          <CircularProgress />
        ) : (
          <RedButton
            disabled={(selectedOwned?.length ?? 0) === 0}
            style={{ height: 28, paddingInline: 12, fontSize: 14 }}
            startIcon={<TrashCanDeleteIcon color={colors.white} />}
            onClick={handleDeleteMultipleAccounts}
          >
            Delete{' '}
            {selectedOwned?.length ? '(' + selectedOwned?.length + ')' : ''}
          </RedButton>
        ))}
      {showApply &&
        (accountsUpdating ? (
          <CircularProgress style={{ marginLeft: 8 }} />
        ) : (
          <MainPrimaryButton className="apply-button" onClick={handleApply}>
            Apply
          </MainPrimaryButton>
        ))}
    </TableFooterWrapper>
  ) : selectedExternal && selectedExternal.length > 0 ? (
    <TableFooterWrapper>
      <Button
        className="selected-button"
        variant="contained"
        color="secondary"
        endIcon={<CloseMIcon color={colors.grey11} />}
        disableElevation
        onClick={() => {
          if (clearSelected) {
            clearSelected();
          }
        }}
      >
        {selectedExternal.length} selected
      </Button>
      {isSyncing ? (
        <CircularProgress />
      ) : (
        <MainPrimaryButton
          className="apply-button"
          onClick={handleSyncAllAccounts}
          startIcon={<PlusSmallIcon color={colors.white} />}
          style={{ marginRight: 0 }}
        >
          Add {selectedExternal.length} to Crono
        </MainPrimaryButton>
      )}
    </TableFooterWrapper>
  ) : (
    <></>
  );
};

export default AccountSelectionBottom;

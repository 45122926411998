import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TuneRounded } from '@mui/icons-material';
import { TableWrapper } from './style';
import CompanyTable from './companyTable';
import ContactTable from './contactTable';
import { colors } from 'crono-fe-common/theme';
import Filters from './filters';
import { useFilters } from 'pages/home/context/filters';
import { TabSelectionButton } from 'crono-fe-common/components/CronoButton';
import { useAuth } from 'context/auth';
import { AccountSearch } from 'crono-fe-common/types/DTO/accountSearch';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { ProspectSearch } from 'crono-fe-common/types/DTO/prospectSearch';
import IconMContact from 'crono-fe-common/icons/Icon-M-Contact';
import IconMLead from 'crono-fe-common/icons/Icon-M-Lead';
import IconMCompany from 'crono-fe-common/icons/Icon-M-Company';
import SearchInput from './searchInput';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { SettingsTooltip } from 'pages/home';
import AddManuallyTooltip from 'pages/home/AddManuallyTooltip';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import AddCompanyContainer from 'pages/addComponents/addCompanyContainer';
import AddContactContainer from 'pages/addComponents/addContactContainer';
import AddTaskContainer from 'pages/addComponents/addTaskContainer';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import useSearchAccounts from 'hooks/services/account/useSearchAccounts';
import useSearchProspects from 'hooks/services/prospect/useSearchProspects';
import ExportDataModalCompanies from './exportDataModal/exportDataModalCompanies';
import ExportDataModalContacts from './exportDataModal/exportDataModalContacts';
import { useUpgradeModal } from 'context/upgradeModal';
import useGetSubscription from 'hooks/services/subscription/useGetSubscription';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';

export type DashboardTabType = 'Lead' | 'Contact' | 'Company';

const Dashboard = () => {
  const [tabState, setTabState] = useState<DashboardTabType | null>(null);
  const { user } = useAuth();
  const { data: subscription } = useGetSubscription();

  const {
    searchAccountParameters,
    searchProspectParameters,
    searchLeadParameters,
    setSearchAccountParameters,
    setSearchProspectParameters,
    setSearchLeadParameters,
    updateUserPreferences,
    getResumeFilters,
  } = useFilters();

  useEffect(() => {
    if (user?.userPreferences?.accountTableFilters) {
      const accountParameters: AccountSearch = JSON.parse(
        user?.userPreferences?.accountTableFilters,
      );
      if (accountParameters.selected) {
        setTabState('Company');
        return;
      }
    }
    if (user?.userPreferences?.prospectTableFilters) {
      const prospectParameters: ProspectSearch = JSON.parse(
        user?.userPreferences?.prospectTableFilters,
      );
      if (prospectParameters.selected) {
        setTabState('Contact');
        return;
      }
    }
    if (user?.userPreferences?.leadTableFilters) {
      const leadParameters: ProspectSearch = JSON.parse(
        user?.userPreferences?.leadTableFilters,
      );
      if (leadParameters.selected) {
        setTabState('Lead');
        return;
      }
    }

    setTabState('Company');
  }, [user]);

  const { data: accounts } = useSearchAccounts({
    ...searchAccountParameters,
    searchIncludeProspects: true,
  });
  const { data: prospects } = useSearchProspects({
    ...(tabState === 'Lead' ? searchLeadParameters : searchProspectParameters),
    fromContact:
      user?.integrationType === IntegrationType.SALESFORCE
        ? tabState === 'Lead'
          ? searchLeadParameters.fromContact
          : searchProspectParameters.fromContact
        : undefined,
  });

  const [exportInProgress, setExportInProgress] = useState<boolean>(false);

  const [exportCompaniesModalOpen, setExportCompaniesModalOpen] =
    useState<boolean>(false);
  const [exportContactsModalOpen, setExportContactsModalOpen] =
    useState<boolean>(false);

  const downloadCSV = (name: string, data: any, columns: any) => {
    const csv = Papa.unparse(data, { columns });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, name);
    setExportInProgress(false);
  };

  const [isFiltersLoaded, setIsFiltersLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!isFiltersLoaded) {
      if (user?.userPreferences?.prospectTableFilters) {
        const searchParameters = JSON.parse(
          user.userPreferences.prospectTableFilters,
        ) as ProspectSearch;
        searchParameters.fromContact = true;
        setSearchProspectParameters((prev) => ({
          ...prev,
          ...searchParameters,
        }));
      }

      if (user?.userPreferences?.leadTableFilters) {
        const searchParameters = JSON.parse(
          user.userPreferences.leadTableFilters,
        ) as ProspectSearch;
        searchParameters.fromContact = false;
        setSearchLeadParameters((prev) => ({
          ...prev,
          ...searchParameters,
        }));
      }

      if (user?.userPreferences?.accountTableFilters) {
        const savedFilters = JSON.parse(
          user.userPreferences.accountTableFilters,
        );
        setSearchAccountParameters((prev) => ({
          ...prev,
          ...savedFilters,
        }));
      }

      setIsFiltersLoaded(true);
    }
  }, [user]);

  const { mutate: patchUserPreferences } = usePatchUserPreferences();

  // to save selected tab inside userPreferences
  useEffect(() => {
    if (tabState === null || !isFiltersLoaded) return;

    const newAccountParameters = {
      ...searchAccountParameters,
      selected: tabState === 'Company',
    };
    updateUserPreferences('Company', newAccountParameters, false);
    const newProspectParameters = {
      ...searchProspectParameters,
      selected: tabState === 'Contact',
    };
    updateUserPreferences('Contact', newProspectParameters, false);
    const newLeadParameters = {
      ...searchLeadParameters,
      selected: tabState === 'Lead',
    };
    updateUserPreferences('Lead', newLeadParameters, false);

    patchUserPreferences({
      accountTableFilters: JSON.stringify({
        ...newAccountParameters,
        name: undefined,
      }),
      prospectTableFilters: JSON.stringify({
        ...newProspectParameters,
        name: undefined,
      }),
      leadTableFilters: JSON.stringify({
        ...newLeadParameters,
        name: undefined,
      }),
    });
  }, [tabState]);

  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);

  const filtersRef = useRef<HTMLDivElement>(null);

  const [nFiltersToShow, setNFiltersToShow] = useState<number>(0);

  useEffect(() => {
    if (filtersRef?.current?.clientWidth) {
      setNFiltersToShow(filtersRef?.current?.clientWidth / 160);
    }
  }, [filtersRef?.current?.clientWidth, window.innerWidth]);

  const onRemainingClick = () => {
    setFiltersVisible(true);
  };

  const [onlyMine, setOnlyMine] = useState<boolean>(false);

  //To keep updated the switch with the values in the filters
  useEffect(() => {
    if (tabState !== 'Company' || !user) return;
    setOnlyMine(searchAccountParameters.userId === user.id);
  }, [searchAccountParameters, tabState]);

  useEffect(() => {
    if (tabState !== 'Contact' || !user) return;
    setOnlyMine(searchProspectParameters.userId === user.id);
  }, [searchProspectParameters, tabState]);

  useEffect(() => {
    if (tabState !== 'Lead' || !user) return;
    setOnlyMine(searchLeadParameters.userId === user.id);
  }, [searchLeadParameters, tabState]);

  //When the switch for mine is clicked. The value has also to be saved in the userPreferences, since the filters container does not provided auto patch due to too many requests
  const handleChangeOnlyMine = (newValue: boolean) => {
    if (!user) return;
    const userId = newValue ? user.id : null;
    switch (tabState) {
      case 'Company':
        setSearchAccountParameters({
          ...searchAccountParameters,
          userId,
        });
        updateUserPreferences(tabState, {
          ...searchAccountParameters,
          userId,
        });
        break;
      case 'Contact':
        setSearchProspectParameters({
          ...searchProspectParameters,
          fromContact: true,
          userId,
        });
        updateUserPreferences(tabState, {
          ...searchProspectParameters,
          fromContact: true,
          userId,
        });
        break;
      case 'Lead':
        setSearchLeadParameters({
          ...searchLeadParameters,
          fromContact: false,
          userId,
        });
        updateUserPreferences(tabState, {
          ...searchLeadParameters,
          fromContact: false,
          userId,
        });
        break;
    }
  };

  //I get the filter resume, containing the filters that are applied and the remaining ones
  const filtersResume = useMemo(() => {
    if (tabState === null || !user) return null;
    return getResumeFilters(tabState, nFiltersToShow, onRemainingClick);
  }, [tabState, nFiltersToShow, onRemainingClick]);
  //To show the add manually tooltips
  const [addCompanyManually, setAddCompanyManually] = useState<boolean>(false);
  //To show the add manually tooltips
  const [addContactManually, setAddContactManually] = useState<boolean>(false);
  //To show the add manually tooltips
  const [addTaskManually, setAddTaskManually] = useState<boolean>(false);
  const [addManuallyOpen, setAddManuallyOpen] = useState(false);

  const handleAddManuallyTooltipClose = () => {
    if (addManuallyOpen) {
      setAddManuallyOpen(false);
    }
  };

  const { isUpgradeModalVisible } = useUpgradeModal();

  const tabLabel = useMemo(() => {
    switch (tabState) {
      case 'Company':
        return 'companies';
      case 'Contact':
        return 'contacts';
      case 'Lead':
        return 'leads';
      default:
        return '';
    }
  }, [tabState]);

  return (
    <>
      {exportCompaniesModalOpen && (
        <ExportDataModalCompanies
          onClose={() => setExportCompaniesModalOpen(false)}
          total={accounts?.data?.total ?? 0}
          exportInProgress={exportInProgress}
          setExportInProgress={setExportInProgress}
          downloadCSV={downloadCSV}
        />
      )}
      {exportContactsModalOpen && (
        <ExportDataModalContacts
          onClose={() => setExportContactsModalOpen(false)}
          total={prospects?.data?.total ?? 0}
          exportInProgress={exportInProgress}
          setExportInProgress={setExportInProgress}
          downloadCSV={downloadCSV}
          isLeadExport={tabState === 'Lead'}
        />
      )}
      {!isUpgradeModalVisible && addCompanyManually && (
        <AddCompanyContainer close={() => setAddCompanyManually(false)} />
      )}
      {!isUpgradeModalVisible && addContactManually && (
        <AddContactContainer close={() => setAddContactManually(false)} />
      )}
      {!isUpgradeModalVisible && addTaskManually && (
        <AddTaskContainer close={() => setAddTaskManually(false)} />
      )}
      <TableWrapper>
        {filtersVisible && tabState !== null && (
          <Filters tabState={tabState} close={() => setFiltersVisible(false)} />
        )}
        <Grid item className="buttons-group">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <SearchInput tabState={tabState} />
            {filtersResume?.filters && filtersResume?.filters?.length > 0 ? (
              <IconButton
                className="filter-icon-button"
                color="secondary"
                onClick={() => setFiltersVisible(true)}
              >
                <TuneRounded />
              </IconButton>
            ) : (
              <Button
                variant="outlined"
                className="filter-button"
                endIcon={<TuneRounded />}
                color="secondary"
                onClick={() => setFiltersVisible(true)}
              >
                Filters
              </Button>
            )}
          </div>
          <div className="filters-space" ref={filtersRef}>
            {filtersResume?.filters} {filtersResume?.remaining}
          </div>
          <div className="switch-my-contacts-dashboard">
            <Typography
              fontWeight={500}
              lineHeight={'16px'}
              fontSize={12}
              color={colors.grey11}
              whiteSpace={'nowrap'}
            >
              My {tabLabel}
            </Typography>
            <CronoSwitch
              checked={onlyMine}
              onChange={(ev) => {
                handleChangeOnlyMine(ev.target.checked);
              }}
            />
          </div>
          <div
            style={{
              padding: '18px 8px 18px 18px',
            }}
          >
            <div className="dashboard-tabs-container">
              {subscription?.data?.data?.company?.integrationType ===
                IntegrationType.SALESFORCE && (
                <TabSelectionButton
                  selected={tabState === 'Lead'}
                  startIcon={
                    <IconMLead
                      color={tabState === 'Lead' ? colors.white : colors.black}
                    />
                  }
                  onClick={() => setTabState('Lead')}
                  disableElevation
                  style={{
                    minWidth: '127px',
                  }}
                >
                  Leads
                </TabSelectionButton>
              )}
              <TabSelectionButton
                selected={tabState === 'Contact'}
                startIcon={
                  <IconMContact
                    color={tabState === 'Contact' ? colors.white : colors.black}
                  />
                }
                onClick={() => setTabState('Contact')}
                disableElevation
                style={{
                  minWidth: '127px',
                }}
              >
                Contacts
              </TabSelectionButton>

              <TabSelectionButton
                selected={tabState === 'Company'}
                startIcon={
                  <IconMCompany
                    color={tabState === 'Company' ? colors.white : colors.black}
                  />
                }
                onClick={() => setTabState('Company')}
                disableElevation
                style={{
                  minWidth: '127px',
                }}
              >
                Companies
              </TabSelectionButton>
            </div>
          </div>

          <ClickAwayListener onClickAway={handleAddManuallyTooltipClose}>
            <div style={{ position: 'relative' }}>
              <SettingsTooltip
                title={
                  <AddManuallyTooltip
                    showManualCompany={() => {
                      setAddCompanyManually(true);
                      handleAddManuallyTooltipClose();
                    }}
                    showManualContact={() => {
                      setAddContactManually(true);
                      handleAddManuallyTooltipClose();
                    }}
                    showManualTask={() => {
                      setAddTaskManually(true);
                      handleAddManuallyTooltipClose();
                    }}
                  />
                }
                onClose={handleAddManuallyTooltipClose}
                open={addManuallyOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement="bottom-end"
              >
                <IconButton
                  id="add-manually-button"
                  className="add-manually-button"
                  size="large"
                  onClick={() => setAddManuallyOpen((prev) => !prev)}
                >
                  <PlusIcon color={colors.white} />
                </IconButton>
              </SettingsTooltip>
            </div>
          </ClickAwayListener>
          <div className={'export-button-container'}>
            {exportInProgress ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton
                onClick={() => {
                  if (tabState === 'Company') {
                    setExportCompaniesModalOpen(true);
                  } else {
                    setExportContactsModalOpen(true);
                  }
                }}
              >
                <DownloadIcon />
              </IconButton>
            )}
          </div>
        </Grid>
        {tabState === 'Company' ? (
          <CompanyTable />
        ) : (
          <ContactTable tabState={tabState ?? 'Contact'} />
        )}
      </TableWrapper>
    </>
  );
};

export default Dashboard;

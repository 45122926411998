import React, { useEffect, useMemo, useRef, useState } from 'react';
import { InsertMultipleWrapper, SelectionUserTooltipWrapper } from './style';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { colors } from 'crono-fe-common/theme';
import {
  Typography,
  Avatar,
  Select,
  MenuItem,
  Checkbox,
  Tooltip,
  TextField,
  Button,
  ClickAwayListener,
} from '@mui/material';
import {
  CancelButton,
  MainButton,
  TabSelectionButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { ProspectInsert } from 'crono-fe-common/types/DTO/prospectInsert';
import useSearchStrategies from 'hooks/services/sequence/useSearchStrategies';
import SequenceCard from 'pages/dashboard/contactTable/sequenceCard';
import { FeConstants } from 'constants/FeConstants';
import { useConditionalSnackBar } from 'context/snackbar';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { useEnqueueAsyncJob } from 'crono-fe-common/hooks/crono-extension/gateway';
import { fullname } from 'utils/fe-utils';
import { Constants } from 'crono-fe-common/constants/constants';
import useGetAccount from 'hooks/services/account/useGetAccount';
import IconChecked from 'crono-fe-common/icons/Icon-Checked';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import useGetUserCredits from 'hooks/services/user/useGetUserCredits';
import moment from 'moment';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { SequenceSelectorWrapper } from 'pages/searchComponent/style';
import { AsyncJobImportProspect } from 'crono-fe-common/types/crono-extension/background-script';
import { Add } from '@mui/icons-material';
import { useJuneAnalytics } from 'context/june';
import { ContactForSearchTable } from '../model';
import IconFindPhone from 'crono-fe-common/icons/Icon-Find-Phone';
import useGetUsers, {
  UserSubscription,
} from 'hooks/services/subscription/useGetUsers';
import OwnerIcon from 'crono-fe-common/icons/Icon-Owner';
import { TooltipFooter } from 'pages/dashboard/contactTable/contactTableFooter/prospectSelectionBottom';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import IconFindEmail from 'crono-fe-common/icons/Icon-Find-Email';
import IconRevenue from 'crono-fe-common/icons/Icon-Revenue';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { SettingsTabs } from 'pages/settings';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import { IExternalValueInsert } from 'pages/searchComponent/searchCompanyTab/insertCompany/model';
import InputExternalPropertyNotTag from 'pages/accountTab/externalPropertyNotTag/inputExternalPropertyNotTag';
import { getNewOptionsWhenSelectingWithStrings } from 'crono-fe-common/utils';
import classNames from 'classnames';
import RemainingTokensBadge from './remainingTokensBadge';
import IconNotOwned from 'crono-fe-common/icons/Icon-Not-Owned';
import EditPencilIconS from 'crono-fe-common/icons/Icon-Edit-Pencil-S';

const selectCustomMenuProps = {
  style: {
    zIndex: 35001,
  },
  PaperProps: {
    sx: {
      borderRadius: '8px',
      paddingInline: '6px',
      border: '1px solid #dadde9',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      '& .MuiMenuItem-root': {
        fontSize: '12px',
        lineHeight: '16px',
        height: 'fit-content',
        padding: '8px',
        cursor: 'pointer',
        width: '100%',
        borderRadius: '8px',
        '&:hover': {
          background: colors.primaryLight,
          color: colors.primary,
        },
      },
      '& .Mui-selected': {
        color: colors.primary,
        backgroundColor: 'transparent !important',
      },
    },
  },
};

interface IProps {
  close: () => void;
  contacts: ContactForSearchTable[];
  onSubmit: () => void;
  selectedAccountId?: string;
}

const InsertMultipleContacts = ({
  close,
  contacts,
  onSubmit,
  selectedAccountId,
}: IProps) => {
  const { user, hasSalesNavWithCookie } = useAuth();
  const [externalValuesTag, setExternalValuesTag] = useState<(string | null)[]>(
    new Array(Constants.numberOfTags).fill(null),
  );

  const { data: selectedAccount } = useGetAccount(selectedAccountId);
  const [createAsLead, setCreateAsLead] = useState(false);

  const { data: externalProperties } = useGetExternalProperty(
    createAsLead && user?.integrationType === IntegrationType.SALESFORCE
      ? 'Lead'
      : 'Prospect',
    false,
    true,
  );

  const [addStrategyHovered, setAddStrategyHovered] = useState<boolean>(false);
  const [crossHovered, setCrossHovered] = useState<boolean>(false);

  const externalPropertiesTag = useMemo(() => {
    return (
      externalProperties?.data?.data.filter(
        (property) => property.isTag && property.isEditable,
      ) ?? []
    );
  }, [externalProperties]);

  const externalPropertyNotTags: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter(
        (property) => !property.isTag && property.isEditable,
      ) ?? []
    );
  }, [externalProperties]);

  const [externalValuesNotTag, setExternalValuesNotTag] = useState<
    IExternalValueInsert[]
  >([]);

  const handleSetExternalValueNotTag = (
    externalPropertyId: number,
    value: string,
  ) => {
    const newValues = [...externalValuesNotTag];
    const newExternalValue = {
      externalPropertyId,
      value: value,
      isTag: false,
    };
    const oldValue = newValues.filter(
      (val) => val.externalPropertyId === externalPropertyId,
    );
    if (oldValue.length > 0) {
      newValues.splice(newValues.indexOf(oldValue[0]), 1);

      newValues.push(newExternalValue);

      setExternalValuesNotTag(newValues);
    } else {
      newValues.push(newExternalValue);
      setExternalValuesNotTag(newValues);
    }
  };

  //Initialize the externalValuesNotTag that has default value
  useEffect(() => {
    if (!externalPropertyNotTags) return;
    const newExternalProperties: IExternalValueInsert[] = [];
    externalPropertyNotTags.forEach((property) => {
      if (property.defaultValue) {
        newExternalProperties.push({
          externalPropertyId: property.id,
          value: property.defaultValue,
          isTag: false,
        });
      }
    });
    setExternalValuesNotTag(newExternalProperties);
  }, [externalPropertyNotTags]);

  const [showOtherFields, setShowOtherFields] = useState<boolean>(false);

  //For the email and phone search
  const [searchEmails, setSearchEmails] = useState<boolean>(false);

  const [searchPhones, setSearchPhones] = useState<boolean>(false);
  const { data: userCredits } = useGetUserCredits();
  const credits = userCredits?.data?.data;

  //Insert multiple related
  const [prospectsToAdd, setProspectsToAdd] = useState<ContactForSearchTable[]>(
    [...contacts],
  );

  useEffect(() => {
    setProspectsToAdd([...contacts]);
  }, [contacts]);

  const [selectedStrategyId, setSelectedStrategyId] = useState<number | null>(
    -1,
  );

  //Initialize the externalValuesTag that has default value
  useEffect(() => {
    if (!externalPropertiesTag) return;
    const newExternalProperties: string[] = new Array(
      Constants.numberOfTags,
    ).fill(null);
    externalPropertiesTag.forEach((property, index) => {
      if (property.defaultValue) {
        newExternalProperties[index] = property.defaultValue;
      }
    });
    setExternalValuesTag(newExternalProperties);
  }, [externalPropertiesTag]);

  const [errorMandatoryFields, setErrorMandatoryFields] = useState<
    string | null
  >(null);

  //To clear the error after 5 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (errorMandatoryFields) {
      timeout = setTimeout(() => {
        setErrorMandatoryFields(null);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [errorMandatoryFields]);

  const analytics = useJuneAnalytics();

  const { call: enqueueAsyncJob } = useEnqueueAsyncJob();

  const handleInsertMultipleContact = async () => {
    //Check if the mandatory fields are filled
    const externalValuesToAdd: {
      [key: number]: string;
    } = {};
    externalValuesTag.forEach((value, index) => {
      if (value && externalPropertiesTag[index].id) {
        externalValuesToAdd[externalPropertiesTag[index].id] = value;
      }
    });
    externalValuesNotTag.forEach((value) => {
      if (value.value && value.externalPropertyId) {
        externalValuesToAdd[value.externalPropertyId] = value.value;
      }
    });
    const mandatoryToFill: string[] = [];
    externalProperties?.data?.data.forEach((property) => {
      if (property.isMandatory && !externalValuesToAdd[property.id]) {
        mandatoryToFill.push(
          property.publicName ?? property.externalName ?? 'Unnamed property',
        );
      }
    });
    //If anyone is missing, I show the error
    if (mandatoryToFill.length > 0) {
      setErrorMandatoryFields(mandatoryToFill.join(', '));
      return;
    }
    const newProspectsRequest: Omit<AsyncJobImportProspect, 'state' | 'id'>[] =
      prospectsToAdd.map((prospect) => {
        const newProspect: ProspectInsert = {
          firstName: prospect.firstName,
          lastName: prospect.lastName,
          email: prospect.email,
          emailStatus: prospect.emailStatus ?? undefined,
          phone: prospect.phone,

          //If the prospect has a company already in the database I can use the company information
          //and pass directly them so that they should not be searched again
          //I also set the flag to make for the extension easier to detect this
          ...(prospect.currentCompanyAlreadyInDatabase
            ? {
                company: prospect.currentCompanyAlreadyInDatabase.name,
                companyLinkedin: prospect.currentCompanyAlreadyInDatabase.url,
                companyLinkedinNumericId:
                  prospect.currentCompanyAlreadyInDatabase.numericId,
                companyWebsite:
                  prospect.currentCompanyAlreadyInDatabase.website,
                companyNumberOfEmployees:
                  prospect.currentCompanyAlreadyInDatabase.numberOfEmployees,
                currentCompanyAlreadyInDatabase: true,
              }
            : {
                company:
                  prospect.companyName ||
                  selectedAccount?.data?.data?.name ||
                  prospect.lastName,
                //If the user has not a companyName (so it is not a salesNav contact) I can use the linkedin of the selected account
                companyLinkedin: !prospect.companyName
                  ? selectedAccount?.data?.data?.linkedin
                  : null,
                companyLinkedinNumericId:
                  prospect.companyNumericId ||
                  selectedAccount?.data?.data?.linkedinNumericId,
                currentCompanyAlreadyInDatabase: false,
              }),

          title: prospect.title ?? prospect.linkedinDescription ?? null,
          linkedin: prospect.url,
          linkedinPublicId: !hasSalesNavWithCookie ? prospect.id : undefined,
          linkedinProfileId: !hasSalesNavWithCookie
            ? prospect.profileId
            : undefined,
          linkedinLeadId: hasSalesNavWithCookie ? prospect.leadId : undefined,
          externalValues: externalValuesToAdd,
          strategyId: selectedStrategyId === -1 ? null : selectedStrategyId,
          createAsLead: createAsLead,
          accountId: selectedAccountId,
          userId: selectedUser?.id ?? user?.id,
        };

        return {
          source: 'crono-fe',
          type: 'importProspect',
          prospect: newProspect,
          accountOnlyInCrm: false,
          findEmail: searchEmails,
          findPhone: searchPhones,
        };
      });
    if (searchEmails && analytics) {
      analytics.track('email-enrichment', {
        count: newProspectsRequest.length,
      });
    }
    if (searchPhones && analytics) {
      analytics.track('phone-enrichment', {
        count: newProspectsRequest.length,
      });
    }
    if (analytics) {
      analytics.track('import-multiple-prospects', {
        count: newProspectsRequest.length,
      });
    }
    await enqueueAsyncJob(newProspectsRequest);
    onSubmit();
  };

  const removeSelectedProspect = (id: string | undefined) => {
    const newProspects = prospectsToAdd.filter(
      (prospect) => prospect.id !== id,
    );
    setProspectsToAdd(newProspects);
  };

  const [search, setSearch] = useState<string>('');
  const { data: strategies } = useSearchStrategies({
    limit: FeConstants.strategyLimit,
    name: search,
    filterByTags: true,
    offset: 0,
  });

  const selectedStrategy = useMemo(() => {
    return strategies?.data?.data.find(
      (strategy) => strategy.id === selectedStrategyId,
    );
  }, [strategies, selectedStrategyId]);

  const handleSelectStrategy = (strategyId: number) => {
    setSelectedStrategyId(strategyId);
  };

  useEffect(() => {
    if (user?.company?.integrationType === IntegrationType.SALESFORCE) {
      setCreateAsLead(true);
    }
  }, [user]);

  const { mutate: patchUserPreferences } = usePatchUserPreferences();
  const handleChangeCreateAsLead = () => {
    const newLead = !createAsLead;
    setCreateAsLead(newLead);
    patchUserPreferences({
      createAsLead: newLead,
    });
    if (user) {
      if (user.userPreferences) {
        user.userPreferences.createAsLead = newLead;
      } else {
        user.userPreferences = {
          createAsLead: newLead,
        };
      }
    }
  };

  const [errorEmailLimitReached, setErrorEmailLimitReached] =
    useState<boolean>(false);

  const handleClickSearchEmails = () => {
    if (userCredits?.data?.data.emailLeft === 0) {
      setErrorEmailLimitReached(true);
      setTimeout(() => {
        setErrorEmailLimitReached(false);
      }, 3000);
      return;
    }
    setSearchEmails((prev) => !prev);
  };

  const [errorPhoneLimitReached, setErrorPhoneLimitReached] =
    useState<boolean>(false);

  const handleClickSearchPhones = () => {
    if (userCredits?.data?.data.phoneLeft === 0) {
      setErrorPhoneLimitReached(true);
      setTimeout(() => {
        setErrorPhoneLimitReached(false);
      }, 3000);
      return;
    }
    setSearchPhones((prev) => !prev);
  };

  useConditionalSnackBar([
    {
      condition: !!errorMandatoryFields,
      message: `The empty fields ${errorMandatoryFields} are mandatory. Add a value to continue`,
      severity: 'error',
    },
    {
      condition: errorEmailLimitReached,
      message: 'You have reached the limit of email searches',
      severity: 'error',
    },
    {
      condition: errorPhoneLimitReached,
      message: 'You have reached the limit of phone searches',
      severity: 'error',
    },
  ]);

  //User selection
  const { data: usersData } = useGetUsers();
  const users = useMemo(() => usersData?.data?.data ?? [], [usersData]);

  const [searchUserName, setSearchUserName] = useState<string | null>(null);

  const filteredUsers = useMemo(() => {
    if (searchUserName) {
      return users?.filter((user) =>
        fullname(user.firstName, user.lastName)
          .toLowerCase()
          .includes(searchUserName.toLowerCase()),
      );
    }
    return users;
  }, [searchUserName]);

  const [openTooltipOwner, setOpenTooltipOwner] = useState<boolean>(false);

  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (openTooltipOwner && textFieldRef.current) {
      textFieldRef.current?.focus();
    }
  }, [textFieldRef.current, openTooltipOwner]);

  const handleTooltipOwnerClose = () => {
    setOpenTooltipOwner(false);
  };
  const handleTooltipOwnerOpen = () => {
    setOpenTooltipOwner(true);
  };
  const [selectedUser, setSelectedUser] = useState<UserSubscription | null>(
    null,
  );

  useEffect(() => {
    if (user) {
      setSelectedUser(
        users?.find((userSubscription) => userSubscription.id === user.id) ??
          null,
      );
    }
  }, [user, users]);

  const handleSelectUser = (userId: number) => {
    setSelectedUser(users?.find((user) => user.id === userId) ?? null);
    handleTooltipOwnerClose();
    setSearchUserName('');
  };

  const onEnterOwner = () => {
    if (filteredUsers?.length >= 1) {
      setSelectedUser(filteredUsers[0]);
      handleTooltipOwnerClose();
      setSearchUserName('');
    }
  };

  const disableStrategySelection =
    selectedUser !== null && selectedUser.id !== user?.id;

  const disableOwnerPicker = !!selectedStrategy;

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const finalOption = getNewOptionsWhenSelectingWithStrings({
      currentValue: externalValuesTag[index],
      option,
      valueType: externalProperty.valueType,
    });
    const newExternalValues = [...externalValuesTag];
    newExternalValues[index] = finalOption;
    setExternalValuesTag(newExternalValues);
  };

  return (
    <BlurBackgroundDivFixed>
      <InsertMultipleWrapper>
        <CloseTabButton close={close} style={{ height: 24, marginRight: 24 }} />
        <div className="body-container">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 26,
            }}
          >
            <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
              Insert {prospectsToAdd.length}{' '}
              {prospectsToAdd.length > 1 ? 'Contacts' : 'Contact'}:
            </Typography>
          </div>

          <div className="section-wrapper">
            <div className="section-wrapper__title">
              <Typography fontSize={14} lineHeight={'22px'} fontWeight={600}>
                Find contact data:
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                gap: 8,
              }}
            >
              <div
                className="find-info-box"
                style={{
                  background: searchEmails
                    ? colors.sequenceCardHover
                    : colors.white,
                }}
              >
                <div className="find-info-box-inner">
                  <IconFindEmail
                    className="find-info-icon"
                    color={colors.primaryDark}
                  />
                  <div className="find-info-box-text-container">
                    <Typography fontSize={14} lineHeight={'24px'}>
                      Find email addresses
                    </Typography>
                    <Typography
                      fontSize={12}
                      lineHeight={'16px'}
                      color={colors.grey11}
                    >
                      <span style={{ fontWeight: 600 }}>
                        {contacts.length}{' '}
                        {contacts.length > 1 ? 'credits' : 'credit'}
                      </span>{' '}
                      will be consumed
                    </Typography>
                  </div>
                </div>
                <div className="find-info-box-inner">
                  <RemainingTokensBadge
                    requiredLength={contacts.length}
                    type="email"
                  />
                  <Checkbox
                    checked={searchEmails}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      handleClickSearchEmails();
                    }}
                    checkedIcon={<IconChecked color={colors.mainDark} />}
                    icon={<IconUnchecked />}
                    style={{ padding: 0 }}
                  />
                </div>
              </div>

              <div
                className="find-info-box"
                style={{
                  background: searchPhones
                    ? colors.sequenceCardHover
                    : colors.white,
                }}
              >
                <div className="find-info-box-inner">
                  <IconFindPhone
                    className="find-info-icon"
                    color={colors.primaryDark}
                  />
                  <div className="find-info-box-text-container">
                    <Typography fontSize={14} lineHeight={'24px'}>
                      Find phone numbers
                    </Typography>
                    <Typography
                      fontSize={12}
                      lineHeight={'16px'}
                      color={colors.grey11}
                    >
                      <span style={{ fontWeight: 600 }}>
                        {contacts.length}{' '}
                        {contacts.length > 1 ? 'credits' : 'credit'}
                      </span>{' '}
                      will be consumed
                    </Typography>
                  </div>
                </div>
                <div className="find-info-box-inner">
                  <RemainingTokensBadge
                    requiredLength={contacts.length}
                    type="phone"
                  />
                  <Checkbox
                    checked={searchPhones}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      handleClickSearchPhones();
                    }}
                    checkedIcon={<IconChecked color={colors.mainDark} />}
                    icon={<IconUnchecked />}
                    style={{ padding: 0 }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section-wrapper">
            {(externalPropertiesTag?.length ?? 0) !== 0 && (
              <>
                <div className="section-wrapper__title">
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    lineHeight={'22px'}
                  >
                    Add tags
                  </Typography>
                </div>
                <TagsBoxShowAll
                  currentSituation={externalValuesTag}
                  type={createAsLead ? 'Lead' : 'Prospect'}
                  handleClickTag={handleClickTag}
                  onlyInsert={true}
                  showMandatoryAsStar={true}
                />
              </>
            )}
            <div className="section-wrapper__others">
              {showOtherFields &&
                externalPropertyNotTags.map((property, index) => {
                  return (
                    <InputExternalPropertyNotTag
                      externalProperty={property}
                      key={index}
                      owned={true}
                      externalValues={externalValuesNotTag}
                      handleSetValue={handleSetExternalValueNotTag}
                    />
                  );
                })}
            </div>

            <div
              className="other-fields-button fsz-12"
              onClick={() => {
                setShowOtherFields((prev) => !prev);
              }}
            >
              Other fields{' '}
              {showOtherFields ? <TopArrowIcon /> : <BottomArrowIcon />}
            </div>
          </div>

          <div className="section-wrapper">
            <div className="section-wrapper__title">
              <Typography fontSize={14} lineHeight={'22px'} fontWeight={600}>
                Choose strategy:
              </Typography>
            </div>

            {user?.company?.integrationType === IntegrationType.SALESFORCE && (
              <div>
                <TabSelectionButton
                  selected={!createAsLead}
                  sx={{ position: 'relative', marginLeft: 0 }}
                  size="large"
                  onClick={() => handleChangeCreateAsLead()}
                  disableElevation
                >
                  Create as contact
                </TabSelectionButton>
                <TabSelectionButton
                  selected={createAsLead}
                  size="large"
                  sx={{ position: 'relative', margin: '6px', right: 10 }}
                  onClick={() => handleChangeCreateAsLead()}
                  disableElevation
                >
                  Create as lead
                </TabSelectionButton>
              </div>
            )}
            
            <div className="information-editable-container information-editable-container--big">
              <div
                className="select-wrapper"
                onMouseEnter={() => setAddStrategyHovered(true)}
                onMouseLeave={() => setAddStrategyHovered(false)}
              >
                <Select
                  IconComponent={() => null}
                  className={classNames(
                    'information-editable-container--big select-radius select-padding',
                    {
                      'green-sequence-button-disabled':
                        disableStrategySelection,
                      'green-sequence-button':
                        !disableStrategySelection && !selectedStrategy,
                      'green-sequence-button--selected':
                        !disableStrategySelection && selectedStrategy,
                    },
                  )}
                  MenuProps={{
                    ...selectCustomMenuProps,
                    PaperProps: {
                      sx: {
                        ...selectCustomMenuProps.PaperProps.sx,
                        minWidth: '400px !important',
                        maxHeight: '500px !important',
                      },
                    },
                  }}
                  sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-input': { padding: '0px !important' },
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                      borderColor: 'transparent !important',
                    },
                    '&.Mui-disabled': {
                      '& .MuiSelect-select': {
                        WebkitTextFillColor: 'inherit',
                        color: 'inherit',
                        opacity: 1,
                      },
                    },
                  }}
                  placeholder="Select contact strategy"
                  fullWidth
                  size="small"
                  onChange={(ev) =>
                    handleSelectStrategy(ev.target.value as any as number)
                  }
                  value={selectedStrategyId}
                  renderValue={(value) => (
                    <>
                      {selectedStrategy ? (
                        <div className="selected-strategy">
                          <div className="selected-strategy__info">
                            <div className="selected-strategy__title">
                              {selectedStrategy?.name}
                            </div>

                            <div className="selected-strategy__steps">
                              {`${selectedStrategy.numberOfSteps} steps`}
                            </div>
                          </div>

                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelectStrategy(-1);
                            }}
                            onMouseEnter={() => setCrossHovered(true)}
                            onMouseLeave={() => setCrossHovered(false)}
                            className="flex-center selected-strategy__icon-container"
                          >
                            <CloseMIcon
                              color={
                                !crossHovered ? colors.grey11 : colors.black
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="select-strategy">
                          <Tooltip
                            title={
                              disableStrategySelection
                                ? 'You cannot select a strategy when importing for another user'
                                : null
                            }
                            placement="top"
                            arrow
                          >
                            <span
                              className={classNames('icon-container', {
                                'icon-container--hovered': addStrategyHovered,
                              })}
                            >
                              <Add fontSize="small" />
                            </span>
                          </Tooltip>

                          <Typography fontWeight={500}>
                            {'Add to strategy'}
                          </Typography>
                        </div>
                      )}
                    </>
                  )}
                  disabled={disableStrategySelection || !!selectedStrategy}
                >
                  <SequenceSelectorWrapper
                    onKeyDown={(e: any) => {
                      e.stopPropagation();
                    }}
                  >
                    <SearchIcon color={colors.grey11} />
                    <TextField
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        endAdornment:
                          search === '' ? null : (
                            <CloseMIcon
                              className="remove-text-icon"
                              color={colors.grey11}
                              onClick={() => setSearch('')}
                            />
                          ),
                      }}
                      placeholder="Search..."
                      value={search}
                      onChange={(ev) => {
                        setSearch(ev.target.value);
                      }}
                      style={{
                        marginLeft: '12px',
                        marginTop: '4px',
                        width: '100%',
                      }}
                    />
                  </SequenceSelectorWrapper>

                  <MenuItem
                    value={-1}
                    style={{ fontWeight: 700, paddingLeft: '12px' }}
                  >
                    No strategy
                  </MenuItem>

                  {strategies?.data?.data.map((strategy) => (
                    <MenuItem value={strategy.id} key={strategy.id}>
                      <SequenceCard sequence={strategy} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          <div className="popup__footer">
            <ClickAwayListener onClickAway={handleTooltipOwnerClose}>
              <div>
                <TooltipFooter
                  title={
                    <SelectionUserTooltipWrapper>
                      {filteredUsers?.map((user) => {
                        return (
                          <Button
                            key={user.id}
                            className={`sequence-action-tooltip-button ${
                              user.id === selectedUser?.id
                                ? 'selected-user'
                                : ''
                            }`}
                            onClick={() => handleSelectUser(user.id)}
                          >
                            {fullname(user.firstName, user.lastName)}
                          </Button>
                        );
                      })}
                    </SelectionUserTooltipWrapper>
                  }
                  onClose={handleTooltipOwnerClose}
                  open={openTooltipOwner}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <div>
                    <Tooltip
                      title={
                        disableOwnerPicker
                          ? 'You are the contact owner. To change the owner pls remove the strategy'
                          : null
                      }
                      enterDelay={400}
                      enterNextDelay={400}
                      arrow
                    >
                      <div>
                        <Button
                          startIcon={
                            (selectedUser?.id === user?.id) || !selectedUser?.id ? (
                              <OwnerIcon
                                className="select-owner-icon"
                                color={colors.mainDark}
                              />
                            ) : (
                              <IconNotOwned
                                className="select-owner-icon"
                                color={colors.mainDark}
                              />
                            )
                          }
                          className="select-owner-button"
                          color="secondary"
                          onClick={() => {
                            if (openTooltipOwner) {
                              handleTooltipOwnerClose();
                            } else if (!disableOwnerPicker) {
                              handleTooltipOwnerOpen();
                            }
                          }}
                          endIcon={
                            <EditPencilIconS
                              color={colors.grey11}
                              className="sequence-action-icon"
                            />
                          }
                          style={{
                            maxWidth: 240,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            opacity: disableOwnerPicker ? 0.6 : 1,
                          }}
                          disabled={disableOwnerPicker}
                        >
                          {openTooltipOwner ? (
                            <TextField
                              value={searchUserName}
                              inputRef={textFieldRef}
                              placeholder="Type..."
                              onChange={(ev) => {
                                if (setSearchUserName)
                                  setSearchUserName(ev.target.value);
                              }}
                              autoComplete="false"
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              onKeyDown={(ev) => {
                                if (ev.key === 'Enter') {
                                  onEnterOwner();
                                }
                              }}
                              inputProps={{
                                style: {
                                  padding: 0,
                                  fontSize: 14,
                                  lineHeight: '18px',
                                  fontWeight: 500,
                                },
                              }}
                              sx={{
                                height: '16px',
                                width: '60px',
                              }}
                            />
                          ) : selectedUser ? (
                            <>
                              <Typography
                                fontSize={14}
                                lineHeight={'18px'}
                                fontWeight={500}
                                noWrap
                              >
                                {fullname(
                                  selectedUser.firstName,
                                  selectedUser.lastName,
                                )}
                              </Typography>
                            </>
                          ) : (
                            'Owner'
                          )}
                        </Button>
                      </div>
                    </Tooltip>
                  </div>
                </TooltipFooter>
              </div>
            </ClickAwayListener>

            <FlexDiv
              width="fit-content"
              height="fit-content"
              style={{ alignSelf: 'flex-end' }}
            >
              <CancelButton
                onClick={close}
                style={{
                  width: '120px',
                  height: '48px',
                }}
              >
                Cancel
              </CancelButton>
              <MainButton
                onClick={handleInsertMultipleContact}
                style={{
                  width: '120px',
                  height: '48px',
                }}
              >
                Insert
              </MainButton>
            </FlexDiv>
          </div>
        </div>
      </InsertMultipleWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default InsertMultipleContacts;

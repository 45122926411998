import { useEffect, useMemo, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { initialValues, validationSchema, MailInputs } from './model';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Avatar,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import ReactQuill from 'react-quill';
import EmailInput from 'crono-fe-common/components/FormComponents/EmailInput';
import React from 'react';
import { Template } from 'crono-fe-common/types/template';
import useSendEmail, { SendEmailDto } from 'hooks/services/email/useSendEmail';
import { useAuth } from 'context/auth';
import { useConditionalSnackBar } from 'context/snackbar';
import { MailerModalWrapper } from './styles';
import { adjustDateToTimezone, getError } from 'crono-fe-common/utils';
import { FeConstants } from 'constants/FeConstants';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FileContent } from 'use-file-picker/dist/interfaces';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import useTasks from 'hooks/services/event/useTasks';
import moment from 'moment';
import { useJuneAnalytics } from 'context/june';
import useScheduleEmail from 'hooks/services/email/useScheduleEmail';
import { useSelectedAccount } from 'pages/home/context/account';
import ScheduleIcon from 'crono-fe-common/icons/Icon-Schedule';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import { colors } from 'crono-fe-common/theme';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { useEmailThread } from 'context/EmailThreadTab';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { SettingsTabs } from 'pages/settings';
import { useWindow } from 'context/sideTab';
import FindEmailPreconditionButton from 'pages/taskBoard/suggestionsBox/suggestionCards/findEmailPreconditionButton';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import { CronoAttachment } from 'crono-fe-common/types/cronoAttachment';
import { isFileContent } from 'pages/settings/Template/model';
import useEmailIntegrationGuard from 'hooks/guards/useEmailIntegrationGuard';
import { useConfirmModal } from 'context/confirmModal';
import {
  replaceAllTemplateVariables,
  prospectName,
  formatHtmlContent,
  getMissingTemplateVariables,
  transformToValidUrl,
  stringAvatarProspect,
} from 'utils/fe-utils';
import RescheduleEmail from './RescheduleEmail';
import useGetTemplateAttachmentsMutate from 'hooks/services/templates/useGetTemplateAttachmentsMutate';
import VerificationStatusType, {
  EmailScrapeResult,
} from 'crono-fe-common/types/enums/verificationStatusType';
import useFindVerify from 'hooks/services/scrape/useFindVerify';
import ValidityBadgeEmailTask from './validityBadgeEmailTask';
import { useHideTaskConstruction } from '../hideTaskConstructionContext';
import useGetProspect from 'hooks/services/prospect/useGetProspect';
import { CronoMenuItem, CronoSelect } from 'crono-fe-common/components/Select';
import { CancelButton } from 'crono-fe-common/components/CronoButton';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import useGetScheduledEmail from 'hooks/services/email/useGetScheduledEmail';
import ClockIconTwelveFifteen from 'crono-fe-common/icons/Icon-Clock-12-15';
import { FileContentScheduled } from 'crono-fe-common/types/DTO/scheduledEmail';
import IconThread from 'crono-fe-common/icons/Icon-thread';
import usePatchTask from 'hooks/services/task/usePatchTask';
import ActivitiesBoxInsideActivityView from '../activitiesInsideActivityView';
import CronoEditor, {
  CustomButtonType,
} from 'components/CronoEditor/CronoEditor';
import { useAttachmentsFilePicker } from 'hooks/useAttachmentsFilePicker';
import InsightsCard, { RewriteSubmittedContext } from './insightsCard';
import TemplatesTooltipCard from './TemplatesTooltipCard';
import { ReactComponent as IconOpenAI } from 'assets/images/Icon_OpenAI.svg';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { Task } from 'crono-fe-common/types/task';
import useGetThreadsRecent from 'hooks/services/email/useGetThreadsRecent';
import useGetTemplateVariables from 'hooks/services/variables/useGetTemplateVariables';
import IconTimeZone from 'crono-fe-common/icons/Icon-Time-Zone';

interface IProps {
  task?: TaskTodo | null;
  selectedThreadId?: {
    threadId: string | null;
    toAll: boolean;
  } | null;
  selectedProspectId?: string | null;
  callOnSuccess?: (keepTemplatesOpened?: boolean) => void;
  suggestionId?: number | null;
  handleCloseModal: () => void;
  defaultOpenTemplateTab?: boolean;
  visibility: 'none' | 'visible';
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
}

const EmailView = ({
  task,
  selectedThreadId: threadId,
  selectedProspectId,
  callOnSuccess,
  suggestionId,
  handleCloseModal,
  defaultOpenTemplateTab,
  visibility,
  setTabValue,
}: IProps) => {
  const editorRef = useRef<ReactQuill | null>(null);

  const { account } = useSelectedAccount();

  const { data: prospectFromDB } = useGetProspect(selectedProspectId);

  const prospect = useMemo(() => {
    if (
      prospectFromDB?.data?.data &&
      prospectFromDB.data.data.accountId === account?.objectId
    ) {
      return prospectFromDB.data.data;
    }
    return null;
  }, [prospectFromDB, account?.objectId]);

  const { data: templateVariables } = useGetTemplateVariables();

  const navigate = useNavigate();
  //State that contains the current template picked (N.B.!! If changed there is no useEffect to populate the editor with the template content)
  const [templatePicked, setTemplatePicked] = useState<Template | null>(null);
  //This function should be called when we want to insert the content of a template inside the editor
  const handleInsertTemplateContent = (
    templatePicked: Template | null,
    replyMessage = false,
  ) => {
    if (!templatePicked) return;
    let subject = templatePicked?.subject || '';

    subject = replaceAllTemplateVariables(
      subject,
      prospect ?? null,
      account ?? null,
      user ?? null,
      templateVariables?.data?.data ?? null,
    );
    //Se ho selezionato una reply non sovrascrivo il subject
    if (!replyMessage) formik.setFieldValue('subject', subject || '');

    let content = templatePicked.content || '';
    content = replaceAllTemplateVariables(
      content,
      prospect ?? null,
      account ?? null,
      user ?? null,
      templateVariables?.data?.data ?? null,
    );
    content += user?.emailSettings?.signature
      ? '<br/>' + user?.emailSettings?.signature
      : '';
    formik.setFieldValue('message', content);
    loadAttachments(templatePicked.id)
      .then((response) => {
        let newFiles = files;
        newFiles = files.filter((file) => isFileContent(file));
        setFiles(newFiles.concat(response.data?.data ?? []));
      })
      .finally(() => {
        //In order to allow another selection for the same template and also because this state is just used to load the content in the editor
        setTemplatePicked(null);
      });
  };
  //This state is used to correctly load information inside the editor (primarly manages the laoding or not of the templates and of the content)
  const [lastIdLoaded, setLastIdLoaded] = useState<{
    taskId: number | null;
    prospectId: string;
  } | null>(null);

  const { mutateAsync: getScheduledEmail, error: errorGetScheduledEmail } =
    useGetScheduledEmail();

  const isScheduledEmail = useMemo(() => {
    return !!task?.scheduledEmailId && task.automatic;
  }, [task]);

  useEffect(() => {
    if (isScheduledEmail) {
      editorRef.current?.editor?.disable();
    } else {
      editorRef.current?.editor?.enable();
    }
  }, [isScheduledEmail]);

  const {
    mutate: patchTask,
    isSuccess: successfulPatchTask,
    error: errorPatchingTask,
  } = usePatchTask();
  //If the schedule is removed successfully I close the side panel
  useEffect(() => {
    if (successfulPatchTask && callOnSuccess) {
      callOnSuccess();
    }
  }, [successfulPatchTask]);

  const [scheduledDate, setScheduledDate] = useState<Date | null>(null);

  useEffect(() => {
    const handleLoadTask = async (task: TaskTodo | undefined | null) => {
      //If we are opening this as a scheduled email view
      if (task?.scheduledEmailId) {
        const scheduledEmail = await getScheduledEmail(task.scheduledEmailId);
        if (scheduledEmail?.data?.data.emailInfo) {
          const email = scheduledEmail.data.data.emailInfo;
          formik.setFieldValue('recipient', email.to);
          formik.setFieldValue('cc', email.cc);
          formik.setFieldValue('ccn', email.ccn);
          formik.setFieldValue('subject', email.subject);
          formik.setFieldValue('message', email.contentHtml);
          setScheduledDate(scheduledEmail.data.data.scheduleDatetime);
          setFiles(
            scheduledEmail?.data?.data?.attachments?.map((attachment) => {
              return {
                lastModified: attachment.lastModified,
                content: attachment.content,
                name: attachment.name,
              };
            }) ?? [],
          );
          setSelectedThreadId(scheduledEmail?.data?.data?.threadId ?? null);
          setSelectedReply(scheduledEmail?.data?.data?.threadId ?? null);
        }
        return;
      }
      //If there is no task or if the task has been already loaded
      else if (task?.template) {
        task.template.sequenceStepTemplateId = task.sequenceStepTemplateId;
        setTemplatePicked(task.template);
        //I set the template to show it as selected in the template picker
        setSelectedTemplate(task.template);
      } else {
        setTemplatePicked(null);
        setSelectedTemplate(null);
        setFiles([]);

        const message = user?.emailSettings?.signature
          ? '<br/>' + user?.emailSettings?.signature
          : '';
        if (!task?.replyToThread) {
          formik.setFieldValue('subject', '');
        }
        formik.setFieldValue('message', message);
      }
    };
    //This way if the prospect get updated I do not reload the task, but I reload it if I change task or if I change prospect
    if (
      (prospect?.objectId !== lastIdLoaded?.prospectId ||
        //Without the first check manual tasks would be always rerendered
        ((task?.id || lastIdLoaded?.taskId) &&
          task?.id !== lastIdLoaded?.taskId)) &&
      prospect
    ) {
      setShowInsightTab(false);
      setSelectedThreadId(null);
      setSelectedReply(null);
      setLastIdLoaded({
        prospectId: prospect.objectId,
        taskId: task?.id ?? null,
      });
      handleLoadTask(task);
    }
  }, [task, prospect]);

  //Set the initial recipient value. When the prospect is changed (for example from the emailFinder) this is fired too
  useEffect(() => {
    formik.setFieldValue(
      'recipient',
      prospect?.email ?? task?.prospect.email ?? '',
    );
  }, [prospect, task]);

  const { setHideProgressTask } = useHideTaskConstruction();

  useEffect(() => {
    if (prospect) {
      setHideProgressTask(false);
    }
    //The dependency of task is to allow the screen to be shut down even in case of two tasks directed to the same prospect and of the same type (the prospect would be already loaded
    //and the useEffect would not be fired)
  }, [prospect, task]);

  const { user } = useAuth();
  const analytics = useJuneAnalytics();

  const { data: accountTasks } = useTasks(
    account?.objectId || null,
    true,
    prospect?.objectId || null,
  );

  const { data: threadsRecent } = useGetThreadsRecent(
    prospect?.objectId ?? '',
    threadId?.threadId ?? null,
    !!prospect?.objectId,
  );
  const [files, setFiles] = useState<
    (FileContent | CronoAttachment | FileContentScheduled)[]
  >([]);
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(
    threadId?.threadId ?? null,
  );
  const [selectedReply, setSelectedReply] = useState<string | null>(null);

  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [scheduleEmailError, setScheduleEmailError] = useState<string | null>(
    null,
  );

  const [uploadFileError, setUploadFileError] = useState<string | null>(null);

  //State used to show in the picker which is the currently selectedThread (not used to insert the content in the editor)
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<Template | null>(null);

  const [showTemplateTab, setShowTemplateTab] = useState<boolean>(
    defaultOpenTemplateTab ?? false,
  );
  const [showInsightTab, setShowInsightTab] = useState<boolean>(false);

  const {
    isSuccess: emailIntegrated,
    verifyIntegration,
    data: emailData,
  } = useEmailIntegrationGuard();

  useEffect(() => {
    if (threadId && threadsRecent?.data?.data) {
      const thread = threadsRecent.data.data.find(
        (thread) => thread.threadId === threadId.threadId,
      );
      if (thread) {
        setSelectedThreadId(threadId.threadId);
        if (threadId.toAll) {
          formik.values.cc = thread.cc ?? undefined;
        } else {
          formik.values.cc = [];
        }
        formik.values.subject = thread.subject ?? undefined;
        setSelectedReply(thread.threadId);
      }
    }
  }, [threadId, threadsRecent]);

  useEffect(() => {
    if (task?.replyToThread && threadsRecent) {
      const lastThread = threadsRecent?.data?.data.at(0);
      // console.log("Last", lastThread);
      if (lastThread?.threadId) {
        setSelectedThreadId(lastThread.threadId);
        formik.values.subject = lastThread.subject ?? undefined;
        setSelectedReply(lastThread.threadId);
      } else {
        formik.setFieldValue('subject', '');
      }
    }
  }, [task, threadsRecent]);

  const formik = useFormik<MailInputs>({
    initialValues: {
      ...initialValues,
      recipient: prospect?.email ?? '',
      message: user?.emailSettings?.signature
        ? '<br/>' + user?.emailSettings?.signature
        : '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      composeAndProcessEmail(true);
    },
  });

  useEffect(() => {
    setShowBcc((formik.values.ccn?.length || 0) > 0);
    setShowCc((formik.values.cc?.length || 0) > 0);
  }, [formik.values.cc, formik.values.ccn]);

  const [rewriteSubmittedContext, setRewriteSubmittedContext] =
    useState<RewriteSubmittedContext | null>(null);

  const composeAndProcessEmail = (send: boolean, scheduleDateTime?: Date) => {
    if (rewriteSubmittedContext && analytics) {
      analytics.track('sent-with-rewrite', {
        ...rewriteSubmittedContext,
        actualMessage: formik.values.message,
      });
      setRewriteSubmittedContext(null);
    }

    const message = formatHtmlContent(formik.values.message ?? '');

    if (
      user &&
      emailIntegrated &&
      emailData?.data?.data?.email &&
      formik.values.recipient
    ) {
      if (account && prospect) {
        const sendEmailDto: SendEmailDto = {
          emailInfo: {
            taskTodoId: task?.id ?? null,
            suggestionId: suggestionId ?? null,
            prospectId: prospect?.objectId,
            accountId: account?.objectId,
            to: formik.values.recipient,
            cc: formik.values.cc ?? [],
            ccn: formik.values.ccn ?? [],
            subject: formik.values.subject ?? '',
            // content: editorRef.current?.getEditor().,
            content: '',
            contentHtml: message ?? '',
            templateId: selectedTemplate?.id ?? null,
            toName: prospectName(prospect),
            sequenceStepTemplateId:
              selectedTemplate?.sequenceStepTemplateId ?? null,
          },
          attachments: files.filter((file) =>
            isFileContent(file),
          ) as FileContent[],
          attachmentIds: (
            files.filter((file) => !isFileContent(file)) as CronoAttachment[]
          ).map((attachmentTemplate) => attachmentTemplate.id),
        };

        if (selectedThreadId) {
          sendEmailDto.threadId = selectedThreadId;
        }

        let event = '';

        if (send) {
          sendEmail(sendEmailDto);
          event = 'send-email';
        } else {
          if (scheduleDateTime) {
            const scheduleEmailDto = {
              ...sendEmailDto,
              scheduleDatetime: scheduleDateTime,
            };
            scheduleEmail(scheduleEmailDto);
            event = 'schedule-email';
          } else {
            return;
          }
        }
        //If the action performed is part of a sequence, I log it into the analytics
        if (task?.id) {
          if (analytics) {
            analytics.track('sequence-task', {});
          }
        }
        if (analytics) {
          analytics.track(event, {});
        }
      }
    }
  };

  //Verify the email status on opening
  useEffect(() => {
    if (user?.otherSettings?.hasEmail) {
      verifyIntegration();
    }
  }, []);

  //When a template is picked I set the selectedTemplate (to show it as selected in the picker) and I call the function to insert the content
  useEffect(() => {
    if (templatePicked) {
      setSelectedTemplate(templatePicked);
      //When a template is selected I load it into the editor
      handleInsertTemplateContent(
        templatePicked,
        selectedThreadId !== null ||
          //This is not that good, a small hack to prevent the template to set the subject where the task is a reply to thread (strategy) the first time
          task?.replyToThread === true,
      );
    }
  }, [templatePicked]);

  const { mutateAsync: loadAttachments } = useGetTemplateAttachmentsMutate();

  const {
    mutate: sendEmail,
    isSuccess,
    isLoading,
    error: errorSendEmail,
  } = useSendEmail();
  const {
    mutate: scheduleEmail,
    isSuccess: isScheduleSuccess,
    isLoading: isScheduleLoading,
    error: errorSchedule,
  } = useScheduleEmail();

  //When the mail is correctly sent or scheduled
  useEffect(() => {
    if (!isSuccess && !isScheduleSuccess) return;
    if (callOnSuccess) {
      callOnSuccess(showTemplateTab);
    }
    handleCloseModal();
  }, [isSuccess, isScheduleSuccess]);

  const [openFileSelector, { filesContent, errorMessage, errors }] =
    useAttachmentsFilePicker();

  const removeAttachment = (index: number) => {
    const newFiles = files.filter((file, i) => i !== index);
    setFiles(newFiles);
  };

  const handleScheduleEmail = (scheduleDateTime: Date | null) => {
    // console.log(scheduleDate, scheduleTime);
    if (scheduleDateTime) {
      validationSchema.isValid(formik.values).then((valid) => {
        if (valid) {
          composeAndProcessEmail(false, scheduleDateTime);
        } else {
          setScheduleEmailError('Please fill all the required fields');
          setTimeout(() => {
            setScheduleEmailError(null);
          }, 2000);
        }
      });
    } else {
      setScheduleEmailError('Please select date and time');
      setTimeout(() => {
        setScheduleEmailError(null);
      }, 2000);
    }
  };

  useEffect(() => {
    if (filesContent) {
      setFiles((prev) => prev.concat(filesContent));
    }
  }, [filesContent]);

  useEffect(() => {
    if (errors) {
      if (errors.length > 0) {
        if (errors[0].fileSizeToolarge) {
          setUploadFileError(
            'The uploaded file is too large, limit is ' +
              FeConstants.uploadFileLimit +
              ' MB',
          );
        } else if (
          errors[0].imageHeightTooBig ||
          errors[0].imageWidthTooBig ||
          errors[0].imageHeightTooSmall ||
          errors[0].imageWidthTooSmall
        ) {
          setUploadFileError('The uploaded image size is not supported');
        } else if (errors[0].maxLimitExceeded) {
          setUploadFileError('You can upload up to 5 files');
        } else {
          setUploadFileError('Error while uploading file');
        }
      }
      setTimeout(() => {
        setUploadFileError(null);
      });
    }
  }, [errors]);

  const handleSelectReplyChange = (threadId: string) => {
    setSelectedReply(threadId);
    const thread = threadsRecent?.data?.data.find(
      (thread) => thread.threadId === threadId,
    );
    if (thread?.threadId) {
      setSelectedThreadId(thread.threadId);
      formik.values.subject = thread.subject ?? undefined;
    }
  };

  const { setEmailThreadContent } = useEmailThread();

  const { openWindow, closeWindow } = useWindow();

  const [emailPrecondition, setEmailPrecondition] =
    useState<EmailScrapeResult | null>(null);

  const { mutate: updateProspect, error: errorUpdatingProspect } =
    useEditProspect();

  useEffect(() => {
    if (emailPrecondition) {
      formik.setFieldValue('recipient', emailPrecondition.email);

      setTimeout(() => {
        setEmailPrecondition(null);
      }, 1000);
    }
  }, [emailPrecondition]);

  //For the moment the desired behavior is that to delete the scheduled we set it to automatic false
  const handleDeleteScheduledEmail = () => {
    if (!task?.id) return;
    patchTask({
      id: task?.id,
      automatic: false,
    });
    if (analytics) {
      analytics.track('patch-task', {
        type: 'automatic',
      });
    }
  };

  useConditionalSnackBar([
    {
      condition: !!uploadFileError,
      message: uploadFileError!,
      severity: 'error',
    },
    {
      condition: !!isSuccess,
      message: 'Email sent successfully',
      severity: 'success',
    },
    {
      condition: !!isScheduleSuccess,
      message: 'Email scheduled successfully',
      severity: 'success',
    },
    {
      condition: !!scheduleEmailError,
      message: scheduleEmailError!,
      severity: 'error',
    },
    {
      condition: !!errorSchedule,
      message: getError(errorSchedule) ?? 'Error while scheduling email',
      severity: 'error',
    },
    {
      condition: !!errorSendEmail,
      message: getError(errorSendEmail) ?? 'Error while sending email',
      severity: 'error',
    },
    {
      condition: !!errorUpdatingProspect,
      message:
        getError(errorUpdatingProspect) ??
        'Error while updating the prospect information',
      severity: 'error',
    },
    {
      condition: !!errorPatchingTask,
      message:
        getError(errorPatchingTask) ?? 'Error while deleting scheduled email',
      severity: 'error',
    },
    {
      condition: !!successfulPatchTask,
      message: 'Scheduled email deleted successfully',
      severity: 'success',
    },
    {
      condition: !!errorMessage,
      message: errorMessage ?? 'Error uploading file',
      severity: 'error',
    },
  ]);

  useEffect(() => {
    if (errorUpdatingProspect) {
      formik.setFieldValue('recipient', '');
    }
  }, [errorUpdatingProspect]);

  const { openModal: openConfirmModal } = useConfirmModal();

  const { mutateAsync: verifyEmail } = useFindVerify();
  if (!user?.otherSettings?.hasEmail) {
    return (
      <MailerModalWrapper
        style={{ display: visibility === 'visible' ? undefined : 'none' }}
      >
        <div className="message-channel-container">
          <h2>Email channel not enabled</h2>
        </div>
      </MailerModalWrapper>
    );
  }

  const handleManuallySubject = (newSubject: string) => {
    setSelectedReply(null);
    setSelectedThreadId(null);
    formik.setFieldValue('subject', newSubject);
  };

  //When the reply button on the thread is pressed I close it
  const handleCloseThread = () => {
    setEmailThreadContent(null);
  };

  const handleReplyThread = (threadId: string | null, toAll = false) => {
    //In this case, since the thread is opened from the email tab
    //I just need to close the thread setting the selectedThreadId to the one opened in the thread modal
    //Finding also the one to reply to
    setSelectedThreadId(threadId);
    if (threadsRecent?.data?.data) {
      const thread = threadsRecent.data.data.find(
        (thread) => thread.threadId === threadId,
      );
      if (thread) {
        // setSelectedThreadId(threadId);
        if (toAll) {
          formik.values.cc = thread.cc ?? undefined;
        } else {
          formik.values.cc = [];
        }
        formik.values.subject = thread.subject ?? undefined;
        setSelectedReply(thread.threadId);
      }
    }
    handleCloseThread();
  };

  const handleOpenEmailThread = () => {
    setEmailThreadContent({
      account,
      openReply: handleReplyThread,
      owned: true,
      prospect,
      threadId: selectedThreadId,
    });
  };

  const handleOpenSettings = () => {
    openWindow(null);
    navigate(PATH.SETTINGS, {
      state: { tab: SettingsTabs.Integrations },
    });
  };

  //Check if any of the variables from template is missing, show confirm modal in case
  const checkMissingVariables = (dateTime?: Date | null) => {
    const missingVariables: string[] = getMissingTemplateVariables(
      formik.values.message ?? '',
      formik.values.subject,
    );

    if (missingVariables.length > 0) {
      openConfirmModal({
        title: 'Variable value missing',
        text: `${
          missingVariables.length === 1 ? 'Variable' : 'Variables'
        } ${missingVariables.map((variable) => variable).join(', ')} ${
          missingVariables.length === 1 ? 'is' : 'are'
        } missing from the email, are you sure you want to continue?`,
        confirmText: `${!dateTime ? 'Send' : 'Schedule'} anyway`,
        cancelText: 'Go back',
        cancelFunction: () => null,
        confirmFunction: () => {
          if (!dateTime) {
            formik.handleSubmit();
          } else {
            handleScheduleEmail(dateTime);
          }
        },
      });
    } else {
      if (!dateTime) {
        formik.handleSubmit();
      } else {
        handleScheduleEmail(dateTime);
      }
    }
  };

  const handleClickSubmit = (dateTime?: Date | null) => {
    if (!formik.values.subject) {
      openConfirmModal({
        title: 'Email subject is empty',
        text: 'You are sending an email without subject, are you sure you want to continue?',
        confirmText: 'Send without subject',
        cancelText: 'Go back',
        cancelFunction: () => null,
        confirmFunction: () => {
          setTimeout(() => {
            checkMissingVariables(dateTime);
          }, 600);
        },
      });
    } else {
      checkMissingVariables(dateTime);
    }
  };

  const handleSaveEmail = async (ev: any) => {
    if (!prospect) return;
    const { value } = ev.target;
    if (!value) return;
    let status = VerificationStatusType.UNKNOWN;
    try {
      const { data } = await verifyEmail({
        email: value,
        firstName: prospect.firstName,
        lastName: prospect.lastName,
        linkedinUrl: prospect.linkedin,
      });
      if (data?.isSuccess && data?.data?.status) {
        status = data?.data?.status;
      }
    } catch (_) {
      /* empty */
    }
    updateProspect({
      prospectId: prospect?.objectId,
      email: value,
      emailStatus: status,
    });
  };

  const [rewriteEnabled, setRewriteEnabled] = useState<boolean>(false);

  const toggleInsightsTab = () => {
    setShowInsightTab((prev) => {
      if (!prev) {
        if (analytics) {
          analytics.track('open-insights', {});
        }
      }

      // we set to initial state (false) on insights close to keep all logic persistant
      setRewriteEnabled(false);

      return !prev;
    });
  };

  const onRewriteWithAIClick = () => {
    setShowInsightTab((prevShowInsightTab) => {
      setRewriteEnabled((prev) => !(prevShowInsightTab && prev));

      return !(prevShowInsightTab && rewriteEnabled);
    });
  };

  const [selectedText, setSelectedText] = useState<string | undefined>(
    undefined,
  );
  const [selection, setSelection] = useState({ start: 0, length: 0 });

  const handleChangeSelection = (range: any, source: any, editor: any) => {
    const quill = editorRef.current?.getEditor();
    if (quill && range && range.length > 0) {
      const selectedDelta = quill.getContents(range.index, range.length);
      const converter = new QuillDeltaToHtmlConverter(selectedDelta.ops ?? [], {
        inlineStyles: true,
      });
      const selectedHtml = converter.convert();
      setSelection({ start: range.index, length: range.length });
      setSelectedText(selectedHtml);
    } else {
      const totalLength = quill?.getLength() ?? 0;
      setSelection({ start: 0, length: totalLength });
      setSelectedText(quill?.root.innerHTML ?? '');
    }
  };

  //HACK to trigger handleSelect function when needed
  useEffect(() => {
    const editor = editorRef.current?.getEditor();
    if (editor && showInsightTab) {
      editor.focus();
    }
  }, [showInsightTab, selectedTemplate, rewriteEnabled]);

  const showSelection = () => {
    const editor = editorRef.current?.getEditor();
    if (editor) {
      editor.setSelection(selection.start, selection.length);
    }
  };

  const replaceSelectedText = (html: string) => {
    const editor = editorRef.current?.getEditor();
    if (editor) {
      editor.deleteText(selection.start, selection.length);
      editor.clipboard.dangerouslyPasteHTML(selection.start, html);
    }
  };

  const onClickBuyMore = () => {
    closeWindow();
  };

  const threadsToShow = useMemo(() => {
    if (!threadsRecent?.data?.data || !prospect) return [];
    return threadsRecent.data.data;
  }, [threadsRecent?.data?.data, prospect]);

  const currentDate = useMemo(() => {
    if (!prospect?.timeZone) return;
    return adjustDateToTimezone(new Date(), prospect.timeZone);
  }, [prospect, prospect?.timeZone]);

  return (
    <>
      {/*Picker to schedule the email */}
      {showSchedule && (
        <RescheduleEmail
          handleSchedule={handleClickSubmit}
          close={() => setShowSchedule(false)}
          isScheduleLoading={isScheduleLoading}
        />
      )}
      {/*Tabs where templates are shown */}
      {/* {showTemplateTab && (
        <TemplatesCard
          accountId={account?.objectId}
          prospectId={prospect?.objectId}
          selectedTemplateId={selectedTemplate?.id ?? null}
          setSelectedTemplate={(template: Template) =>
            setTemplatePicked(template)
          }
          hideShared={false}
          hideAll={false}
          selectedType={TemplateType.EMAIL}
          showAnalytics={true}
          usedTemplateIds={
            ((
              accountTasks?.data?.data &&
              prospect &&
              accountTasks.data.data
                .filter(
                  (task) =>
                    task.prospectId === prospect.objectId &&
                    task.emailLog &&
                    !task.emailLog.isBounced,
                )
                .map((task) => task.emailLog?.templateId)
            )?.filter((t) => t) ?? []) as number[]
          }
          prospect={prospect}
          account={account}
          close={() => setShowTemplateTab(false)}
          visibility={visibility}
          sequenceStepTemplateId={task?.sequenceStepTemplateId ?? null}
        />
      )} */}
      {/*Insight shown on the left side of the tab */}
      {showInsightTab && (
        <InsightsCard
          close={() => {
            setRewriteEnabled(false);
            setShowInsightTab(false);
          }}
          prospect={prospect}
          account={account}
          rewriteEnabled={rewriteEnabled}
          wholeMessage={formik.values.message}
          partToRewrite={selectedText}
          showSelection={showSelection}
          replaceSelectedText={replaceSelectedText}
          isRewriteResponseHtml={true}
          setRewriteSubmittedContext={setRewriteSubmittedContext}
        />
      )}
      <MailerModalWrapper
        id="email-view-component"
        style={{
          display: visibility === 'visible' ? 'flex' : 'none',
          padding: '16px 18px 56px 18px',
          height: '100%',
        }}
      >
        <div style={{ width: '100%', height: '100%' }}>
          <div className="prospect-top-container">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <Avatar key={prospect?.objectId} className="prospect-avatar-row">
                {stringAvatarProspect(prospect)}
              </Avatar>

              <div className="prospect-top">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize={14}
                    lineHeight={'18px'}
                    fontWeight={600}
                    noWrap
                  >
                    {prospect?.name}
                  </Typography>

                  <Tooltip
                    title={
                      !prospect?.linkedin ? 'No Linkedin profile URL found' : ''
                    }
                    placement="top"
                    arrow
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        style={{ width: 20, height: 20, padding: 2 }}
                        disabled={!prospect?.linkedin}
                        onClick={() => {
                          if (!prospect?.linkedin) return;
                          const url = transformToValidUrl(prospect?.linkedin);
                          if (url) window.open(url, '_blank');
                        }}
                      >
                        <LinkedinIcon
                          color={
                            !prospect?.linkedin ? colors.grey3 : colors.blue
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize={12}
                    color={colors.grey11}
                    lineHeight={'16px'}
                    noWrap
                  >
                    {prospect?.title}
                  </Typography>
                  {prospect?.timeZone && (
                    <>
                      <div className="small-divider" />
                      <IconTimeZone className="timezone-icon" />
                      <Typography
                        fontSize={12}
                        lineHeight={'16px'}
                        color={colors.grey11}
                        whiteSpace={'nowrap'}
                      >
                        {moment.parseZone(currentDate).format('HH:mm')} local
                        time
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </div>
            <ActivitiesBoxInsideActivityView
              setTabValue={setTabValue}
              selectedProspectId={
                prospect?.objectId ??
                task?.prospectId ??
                task?.prospect.objectId ??
                null
              }
            />
          </div>
          <form
            onSubmit={formik.handleSubmit}
            style={{ width: '100%', height: '100%' }}
          >
            <Grid container style={{ width: '100%', height: '100%' }}>
              {/* {prospect?.objectId &&
              !isLoadingScheduledEmails &&
              isSuccessScheduledEmails &&
              scheduledEmails?.data?.data &&
              scheduledEmails.data.data.length > 0 && (
                <Grid item>
                  <p className="scheduled-action-info">
                    <InfoIcon color="primary" />
                    You already have an email scheduled for this contact on{" "}
                    {formatDateTime(
                      new Date(scheduledEmails.data.data[0].scheduleDatetime)
                    )}
                  </p>
                </Grid>
              )} */}
              <Grid
                container
                item
                xs={12}
                style={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Grid
                  container
                  item
                  direction={'column'}
                  rowGap={0}
                  height={'100%'}
                >
                  <Grid item container>
                    <div className="header-email-line bottom-line">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          overflow: 'hidden',
                          gap: 8,
                        }}
                      >
                        {/*We show the badge only if the email is the same as the one in the formik, otherwise it means that the values is being modified or no longer
                        the same as the one of which we know the status */}
                        {prospect?.email === formik.values.recipient && (
                          <ValidityBadgeEmailTask
                            emailValid={prospect?.emailValid}
                            emailStatus={prospect?.emailStatus ?? null}
                          />
                        )}

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          {formik.touched.recipient &&
                            formik.errors.recipient &&
                            Boolean(formik.errors.recipient) && (
                              <FormHelperText
                                style={{
                                  marginLeft: 0,
                                  color: colors.inactive,
                                }}
                              >
                                {formik.errors.recipient}
                              </FormHelperText>
                            )}
                          <EmailInput
                            className="form-field"
                            id="recipient"
                            name="recipient"
                            disabled={isScheduledEmail}
                            placeholder="To:"
                            style={{ flex: 1 }}
                            value={formik.values.recipient ?? ''}
                            onChange={(ev) => {
                              formik.handleChange(ev);
                            }}
                            onKeyDown={(ev) => {
                              if (ev.key === 'Enter') {
                                ev.preventDefault();
                                ev.stopPropagation();
                                handleSaveEmail(ev);
                              }
                            }}
                            onBlur={(ev) => {
                              ev.preventDefault();
                              ev.stopPropagation();
                              handleSaveEmail(ev);
                            }}
                            // helperText={
                            //   formik.touched.recipient && formik.errors.recipient
                            // }
                          />
                        </div>
                      </div>
                      {(formik.values.recipient === '' ||
                        !formik.values.recipient) && (
                        <FindEmailPreconditionButton
                          account={account}
                          prospect={prospect}
                          setEmail={setEmailPrecondition}
                          onClickBuyMore={onClickBuyMore}
                          hasLabel={true}
                          iconColor={colors.white}
                          makeBEPatchProspect={true}
                        />
                      )}
                      <p
                        className={showCc ? 'cc-button-selected' : 'cc-button'}
                        onClick={() => setShowCc(!showCc)}
                      >
                        Cc:
                      </p>
                      <p
                        className={showBcc ? 'cc-button-selected' : 'cc-button'}
                        onClick={() => setShowBcc(!showBcc)}
                      >
                        Bcc:
                      </p>
                    </div>
                  </Grid>
                  {showCc && (
                    <Grid item container className="header-email-line">
                      <Autocomplete
                        autoSelect
                        freeSolo
                        multiple
                        id="cc"
                        fullWidth
                        value={formik.values.cc}
                        onChange={(_, value) => {
                          formik.setFieldValue('cc', value);
                        }}
                        disabled={isScheduledEmail}
                        options={[]}
                        renderInput={(params) => (
                          <EmailInput
                            {...params}
                            className="form-field"
                            disabled={isScheduledEmail}
                            fullWidth
                            placeholder="cc: "
                            error={
                              formik.touched.cc && Boolean(formik.errors.cc)
                            }
                          />
                        )}
                      />
                      {formik.touched.cc && formik.errors.cc && (
                        <p className="error-p">Enter valid email/s</p>
                      )}
                    </Grid>
                  )}
                  {showBcc && (
                    <Grid item container className="header-email-line">
                      <Autocomplete
                        autoSelect
                        freeSolo
                        multiple
                        fullWidth
                        id="ccn"
                        value={formik.values.ccn}
                        onChange={(_, value) => {
                          formik.setFieldValue('ccn', value);
                        }}
                        disabled={isScheduledEmail}
                        options={[]}
                        renderInput={(params) => (
                          <EmailInput
                            {...params}
                            disabled={isScheduledEmail}
                            className="form-field"
                            fullWidth
                            placeholder="bcc: "
                            error={
                              formik.touched.ccn && Boolean(formik.errors.ccn)
                            }
                          />
                        )}
                      />
                      {formik.touched.ccn && formik.errors.ccn && (
                        <p className="error-p">Enter valid email/s</p>
                      )}
                    </Grid>
                  )}
                  <Grid
                    item
                    className="header-email-line"
                    style={{ alignItems: 'flex-end' }}
                  >
                    <div className="subject-selector-container bottom-line">
                      {selectedThreadId || formik.values.subject?.length
                        ? task?.sequenceStepTemplateId && (
                            <Typography
                              fontWeight={500}
                              fontSize={'12px'}
                              color={colors.purpleHover}
                              className="ab-tag"
                            >
                              A/B
                            </Typography>
                          )
                        : null}
                      <EmailInput
                        fullWidth
                        id="subject"
                        disabled={isScheduledEmail}
                        name="subject"
                        placeholder="Subject: "
                        value={formik.values.subject}
                        onChange={(ev) => {
                          handleManuallySubject(ev.target.value);
                          formik.handleChange(ev);
                        }}
                        helperText={
                          formik.touched.subject && formik.errors.subject
                        }
                        required={false}
                      />
                      {selectedThreadId ? (
                        <>
                          <Button
                            endIcon={<IconThread color={colors.grey11} />}
                            className="thread-button"
                            color="secondary"
                            onClick={handleOpenEmailThread}
                          >
                            Go to thread
                          </Button>
                        </>
                      ) : null}
                    </div>
                    {threadsRecent?.data?.data &&
                      threadsRecent?.data?.data.length > 0 &&
                      prospect && (
                        <FlexDiv width={'200px'} height="fit-content">
                          {/* <FlexDiv>
                          <span>Go to thread:</span>
                          &nbsp;
                          {selectedReply && selectedThreadId && (
                            <RemoveCircleOutlineIcon
                              color="primary"
                              onClick={() => removeSelectedReplyChange()}
                            />
                          )}
                        </FlexDiv> */}
                          {/*If no thread is selected I show the list of threads */}
                          {!selectedReply ? (
                            <CronoSelect
                              disabled={
                                !!task?.sequenceStepTemplateId ||
                                isScheduledEmail
                              }
                              fullWidth
                              size="small"
                              value={selectedReply || 'no thread selected'}
                              renderValue={(value) => {
                                if (value === 'no thread selected') {
                                  return <span>New thread</span>;
                                }
                                return <span>Go to thread</span>;
                              }}
                              onChange={(e) => {
                                handleSelectReplyChange(
                                  e.target.value as string,
                                );
                              }}
                              disableUnderline={true}
                              sx={{
                                boxShadow: 'none',
                                '.MuiOutlinedInput-notchedOutline': {
                                  borderRadius: '8px',
                                  border: `1px solid ${colors.grey444}`,
                                },
                                fontSize: 14,
                                lineHeight: '20px',
                                fontWeight: 500,
                              }}
                              MenuProps={{
                                style: { zIndex: 3501 },
                                PaperProps: {
                                  sx: {
                                    borderRadius: '8px',
                                    paddingInline: '6px',
                                    border: '1px solid #dadde9',
                                    boxShadow:
                                      '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                                    '& .MuiMenuItem-root': {
                                      fontSize: '12px',
                                      lineHeight: '16px',
                                      height: 'fit-content',
                                      padding: '8px',
                                      cursor: 'pointer',
                                      width: '100%',
                                      borderRadius: '8px',
                                      '&:hover': {
                                        background: colors.primaryLight,
                                        color: colors.primary,
                                      },
                                    },
                                    '& .Mui-selected': {
                                      color: colors.primary,
                                      backgroundColor: 'transparent !important',
                                    },
                                  },
                                },
                              }}
                            >
                              <CronoMenuItem
                                className="task-item"
                                value={'no thread selected'}
                              ></CronoMenuItem>
                              {threadsRecent?.data?.data &&
                                prospect &&
                                threadsToShow.map((thread, index) => (
                                  <CronoMenuItem
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      width: 300,
                                    }}
                                    className="task-item"
                                    key={index}
                                    value={thread.threadId}
                                  >
                                    <div>{thread.subject}</div>
                                    {thread.activityDate && (
                                      <div>
                                        {moment(
                                          new Date(thread.activityDate),
                                        ).format('LL')}{' '}
                                      </div>
                                    )}
                                  </CronoMenuItem>
                                ))}
                            </CronoSelect>
                          ) : (
                            // <Button
                            //   endIcon={<ThreadIcon />}
                            //   className="thread-button"
                            //   onClick={handleOpenEmailThread}
                            // >
                            //   Go to thread
                            // </Button>
                            <CancelButton
                              endIcon={<RemoveIcon color={colors.mainDark} />}
                              className="remove-thread-button"
                              disabled={isScheduledEmail}
                              onClick={() => {
                                if (!isScheduledEmail)
                                  handleManuallySubject('');
                              }}
                            >
                              Remove thread
                            </CancelButton>
                          )}
                        </FlexDiv>
                      )}
                  </Grid>

                  {files && files.length > 0 ? (
                    <div className="file-preview">
                      {files.map((file, index) => (
                        <div
                          className={`file ${
                            isScheduledEmail ? 'disabled-file' : ''
                          }`}
                          key={index}
                        >
                          <CloseMIcon
                            className="delete-attachments-icon"
                            onClick={() => {
                              if (!isScheduledEmail) removeAttachment(index);
                            }}
                          />
                          {file.name}
                        </div>
                      ))}
                    </div>
                  ) : null}
                  <div className="editor-container">
                    <CronoEditor
                      toolbarId={'email-editor-toolbar'}
                      editorRef={editorRef}
                      value={formik.values.message}
                      imageSupport={true}
                      onChange={(text) => {
                        if (text.length === 0) {
                          setSelectedTemplate(null);
                        }
                        formik.setFieldValue('message', text);
                      }}
                      onAttachmentDrop={(filesContent) => {
                        setFiles((prev) => prev.concat(filesContent));
                      }}
                      onChangeSelection={handleChangeSelection}
                      placeholder={'Type email...'}
                      customButtons={[
                        {
                          type: 'attachments',
                          onClick: openFileSelector,
                        },
                        {
                          type: 'templates',
                          showLabel: true,
                          onClick: () => {
                            setShowTemplateTab((prev) => !prev);
                          },
                        },
                        {
                          type: 'insights' as CustomButtonType,
                          showLabel: true,
                          onClick: toggleInsightsTab,
                        },
                      ]}
                      closeTemplateTooltip={() => setShowTemplateTab(false)}
                      showTemplateTab={showTemplateTab}
                      templateComponent={
                        <TemplatesTooltipCard
                          accountId={account?.objectId}
                          prospectId={prospect?.objectId}
                          selectedTemplateId={selectedTemplate?.id ?? null}
                          setSelectedTemplate={(template: Template) =>
                            setTemplatePicked(template)
                          }
                          hideShared={false}
                          hideAll={false}
                          selectedType={TemplateType.EMAIL}
                          showAnalytics={true}
                          usedTemplateIds={
                            ((
                              accountTasks?.data?.data &&
                              prospect &&
                              accountTasks.data.data
                                .filter(
                                  (task) =>
                                    task.prospectId === prospect.objectId &&
                                    task.emailLog &&
                                    !task.emailLog.isBounced,
                                )
                                .map((task) => task.emailLog?.templateId)
                            )?.filter((t) => t) ?? []) as number[]
                          }
                          prospect={prospect}
                          account={account}
                          close={() => setShowTemplateTab(false)}
                          visibility={visibility}
                          sequenceStepTemplateId={
                            task?.sequenceStepTemplateId ?? null
                          }
                        />
                      }
                    />
                    {isScheduledEmail ? (
                      <div className="scheduled-email-cancel-banner">
                        <ClockIconTwelveFifteen color={colors.grey11} />
                        <Typography fontSize={14} lineHeight={'24px'}>
                          This email will be sent{' '}
                          {moment(scheduledDate).format('ll')} at{' '}
                          {moment(scheduledDate).format('HH:mm')}
                        </Typography>
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          className="click-to-cancel-text"
                          onClick={() => {
                            if (task?.scheduledEmailId)
                              handleDeleteScheduledEmail();
                          }}
                        >
                          Click here to Cancel scheduling
                        </Typography>
                      </div>
                    ) : (
                      <>
                        {emailIntegrated ? (
                          <div className="button-container">
                            <Button
                              variant="contained"
                              color="secondary"
                              className="mail-button send-button"
                              onClick={() => handleClickSubmit()}
                              disableElevation
                            >
                              {!isLoading ? (
                                'Send'
                              ) : (
                                <CircularProgress
                                  style={{
                                    height: 30,
                                    width: 30,
                                    marginInline: 7,
                                    color: colors.white,
                                  }}
                                />
                              )}
                            </Button>
                            <IconButton
                              className="mail-icon-button schedule-button"
                              onClick={() => setShowSchedule(true)}
                            >
                              <ScheduleIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <div className="button-container">
                            <Typography>
                              {user?.otherSettings?.hasEmail ? (
                                <>
                                  You need to connect your account to send
                                  emails. Go to{' '}
                                  <b
                                    style={{ cursor: 'pointer' }}
                                    onClick={handleOpenSettings}
                                  >
                                    Settings
                                  </b>
                                </>
                              ) : (
                                'Email channel not enabled.'
                              )}
                            </Typography>
                          </div>
                        )}
                        {user && (
                          <button
                            type={'button'}
                            className={'rewrite-with-ai-button'}
                            style={{
                              position: 'absolute',
                              right: 8,
                              bottom: 10,
                            }}
                            onClick={onRewriteWithAIClick}
                          >
                            <IconOpenAI />
                            <p>Rewrite with AI</p>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </MailerModalWrapper>
    </>
  );
};

export default EmailView;

import { AccountSearchColumns } from 'crono-fe-common/types/DTO/accountSearchForExport';
import React, { useCallback, useEffect, useState } from 'react';
import { BasePropsExportDataModal, ExportDataModal, IColumnsToSelect } from '.';
import { useConditionalSnackBar } from 'context/snackbar';
import { Constants } from 'crono-fe-common/constants/constants';
import useSearchAccountsForExport from 'hooks/services/account/useSearchAccountsForExport';
import { getError } from 'crono-fe-common/utils';
import { useFilters } from 'pages/home/context/filters';
import { useJuneAnalytics } from 'context/june';

interface IProps extends BasePropsExportDataModal {
  setExportInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  downloadCSV: (name: string, data: any, columns: any) => void;
}

const companyColumnsToSelect: IColumnsToSelect[] = [
  { label: 'Company name', value: 'name' },
  { label: 'Owner', value: 'owner' },

  { label: 'Industry', value: 'industry' },
  { label: 'Last Activity', value: 'lastActivityDate' },

  { label: 'Employees', value: 'numberOfEmployees' },
  { label: 'Created Date', value: 'createdDate' },

  { label: 'Phone number', value: 'phone' },
  { label: 'Linkedin', value: 'linkedin' },

  { label: 'Website', value: 'website' },
];

const ExportDataModalCompanies = ({
  onClose,
  total,
  exportInProgress,
  setExportInProgress,
  downloadCSV,
}: IProps) => {
  const analytics = useJuneAnalytics();
  const { searchAccountParameters } = useFilters();
  const {
    data: accountsForExport,
    mutate: searchAccountsForExport,
    error: accountsForExportError,
  } = useSearchAccountsForExport();
  const [columnsSelectedForAccounts, setColumnsSelectedForAccounts] =
    useState<AccountSearchColumns>({
      name: true,
      industry: true,
      numberOfEmployees: true,
      phone: true,
      owner: true,
      lastActivityDate: true,
      createdDate: true,
      linkedin: true,
      website: true,
    });

  useEffect(() => {
    if (accountsForExport?.data?.data?.length && exportInProgress) {
      const columns = [
        'id',
        ...Object.keys(columnsSelectedForAccounts).filter(
          (key) =>
            columnsSelectedForAccounts[key as keyof AccountSearchColumns],
        ),
      ];
      const data = accountsForExport.data.data.map((account, index) => {
        const accountWithOwner: any = account;
        accountWithOwner.id = index + 1;
        return accountWithOwner;
      });

      downloadCSV(Constants.defaultExportAccountsCSVName, data, columns);

      if (analytics) {
        analytics.track('export-companies', {
          count: accountsForExport.data.data.length,
        });
      }

      onClose();
    }
  }, [accountsForExport]);

  const handleSubmit = useCallback(
    (columns: AccountSearchColumns) => {
      setExportInProgress(true);
      searchAccountsForExport({
        ...searchAccountParameters,
        limit: Constants.defaultExportDataLimit,
        columns,
      });
    },
    [setExportInProgress, searchAccountParameters, searchAccountsForExport],
  );

  useConditionalSnackBar([
    {
      condition: !!accountsForExportError,
      message: getError(accountsForExportError) ?? 'Error exporting companies',
      severity: 'error',
    },
  ]);

  return (
    <ExportDataModal
      columnsSelected={columnsSelectedForAccounts}
      setColumnsSelected={setColumnsSelectedForAccounts}
      exportDataName="companies"
      onClose={onClose}
      onSubmit={handleSubmit}
      optionsToSelect={companyColumnsToSelect}
      exportInProgress={exportInProgress}
      total={total}
    />
  );
};

export default ExportDataModalCompanies;

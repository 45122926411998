export enum CronoErrorCode {
  BadRequest = 'BadRequest',
  EntityNotFound = 'EntityNotFound',
  EntityNotConsistent = 'EntityNotConsistent',
  EntityNotCreated = 'EntityNotCreated',
  EntityNotUpdated = 'EntityNotUpdated',
  CrmConflictError = 'CrmConflictError',
  CronoConflictError = 'CronoConflictError',
  InternalError = 'InternalError',
  LogFailed = 'LogFailed',
  SalesforceTokenExpired = 'SalesforceTokenExpired',
  SalesforceRefreshTokenExpired = 'SalesforceRefreshTokenExpired',
  SalesforceRefreshTokenMissing = 'SalesforceRefreshTokenMissing',
  SalesforeResponseNotValid = 'SalesforeResponseNotValid',
  IntegrationNotValidError = 'IntegrationNotValidError',
  IntegrationApiError = 'IntegrationApiError',
  GoogleTokenExpired = 'GoogleTokenExpired',
  GoogleRefreshTokenExpired = 'GoogleRefreshTokenExpired',
  GoogleRefreshTokenMissing = 'GoogleRefreshTokenMissing',
  GoogleApiFailed = 'GoogleApiFailed',
  GoogleApiPermissionMissing = 'GoogleApiPermissionMissing',
  HubspotTokenExpired = 'HubspotTokenExpired',
  HubspotRefreshTokenExpired = 'HubspotRefreshTokenExpired',
  HubspotRefreshTokenMissing = 'HubspotRefreshTokenMissing',
  HubspotWebhookTooOld = 'HubspotWebhookTooOld',
  HubspotWebhookInvalidSignature = 'HubspotWebhookInvalidSignature',
  HubspotWebhookInvalidHeaders = 'HubspotWebhookInvalidHeaders',
  ScrapeNotEnabled = 'ScrapeNotEnabled',
  HunterGetError = 'HunterGetError',
  ScrapeRequestsFinished = 'ScrapeRequestsFinished',
  ScrapeNotFoundError = 'ScrapeNotFoundError',
  ScrapeInvalidUrl = 'ScrapeInvalidUrl',
  ScrapeNotCompleted = 'ScrapeNotCompleted',
  ProspeoGetError = 'ProspeoGetError',
  DatagmaGetError = 'DatagmaGetError',
  SubscriptionInactive = 'SubscriptionInactive',
  SubscriptionExpired = 'SubscriptionExpired',
  SubscriptionMaxUsersReached = 'SubscriptionMaxUsersReached',
  SubscriptionInvalidIntegrationType = 'SubscriptionInvalidIntegrationType',
  ImportError = 'ImportError',
  MaxActiveAccountsNumberExceeded = 'MaxActiveAccountsNumberExceeded',
  PipedriveTokenExpired = 'PipedriveTokenExpired',
  PipedriveRefreshTokenExpired = 'PipedriveRefreshTokenExpired',
  PipedriveRefreshTokenMissing = 'PipedriveRefreshTokenMissing',
  PipedriveResponseNotValid = 'PipedriveResponseNotValid',
  AssistantNotEnabled = 'AssistantNotEnabled',
  GPTError = 'GPTError',
  AssistantRequestsFinished = 'AssistantRequestsFinished',
  JuneLogError = 'JuneLogError',
  AutomationFailedEmailSend = 'AutomationFailedEmailSend',
  AutomationFailedAddressMissing = 'AutomationFailedAddressMissing',
  AutomationFailedTemplateMissing = 'AutomationFailedTemplateMissing',
  AutomationFailedEmailCreation = 'AutomationFailedEmailCreation',
  AutomationFailedEmailSettingsMissing = 'AutomationFailedEmailSettingsMissing',
  AutomationFailedTemplateApplicationError = 'AutomationFailedTemplateApplicationError',
  AutomationFailedUnsupportedTaskTypeError = 'AutomationFailedUnsupportedTaskTypeError',
  AutomationFailedLinkedinStatusUnknown = 'AutomationFailedLinkedinStatusUnknown',
  AutomationFailedLinkedinSendMessage = 'AutomationFailedLinkedinSendMessage',
  AutomationFailedLinkedinInvitationLimitReached = 'AutomationFailedLinkedinInvitationLimitReached',
  AutomationFailedLinkedinInvitationMaxCharacters = 'AutomationFailedLinkedinInvitationMaxCharacters',
  AutomationFailedLinkedinInvitationRecentlyWithdrawn = 'AutomationFailedLinkedinInvitationRecentlyWithdrawn',
  AutomationFailedLinkedinUrlMissing = 'AutomationFailedLinkedinUrlMissing',
  AutomationFailedLinkedinInvitationPending = 'AutomationFailedLinkedinInvitationPending',
  AutomationFailedEmailNotValid = 'AutomationFailedEmailNotValid',
  AutomationFailedSalesNavNotLogged = 'AutomationFailedSalesNavNotLogged',
  AutomationFailedSalesNavNotConnected = 'AutomationFailedSalesNavNotConnected',
  AutomationFailedMessageToNotConnected = 'AutomationFailedMessageToNotConnected',
  TaskErrorEmailBounced = 'TaskErrorEmailBounced',
  UploadToS3Error = 'UploadToS3Error',
  GetFileFromS3Error = 'GetFileFromS3Error',
  UploadAttachmentError = 'UploadAttachmentError',
  DeleteFileFromS3Error = 'DeleteFileFromS3Error',
  ProgressSyncError = 'ProgressSyncError',
  OutlookTokenExpired = 'OutlookTokenExpired',
  OutlookRefreshTokenExpired = 'OutlookRefreshTokenExpired',
  OutlookRefreshTokenMissing = 'OutlookRefreshTokenMissing',
  OutlookApiFailed = 'OutlookApiFailed',
  OutlookApiPermissionMissing = 'OutlookApiPermissionMissing',
  AircallTokenMissing = 'AircallTokenMissing',
  AircallTokenExpired = 'AircallTokenExpired',
  MigrationError = 'MigrationError',
  MigrationNotActiveError = 'MigrationNotActiveError',
  GenericError = 'GenericError',
}

import { QueryClient } from 'react-query';
import { Request } from 'crono-fe-common/types/request';
import client from 'utils/clients/client';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { Analytics } from '@june-so/analytics-next';
import { htmlToText } from 'crono-fe-common/utils';
import {
  createLogLinkedinRequest,
  invalidateLogLinkedinQueries,
} from 'hooks/services/event/useLogLinkedin';
import {
  LinkedinType,
  LogLinkedinInsert,
} from 'crono-fe-common/types/logLinkedinInsert';
import { TaskPatch } from 'crono-fe-common/types/DTO/taskPatch';
import { CronoErrorCode } from 'crono-fe-common/types/enums/cronoErrorCode';
import CronoExtensionRequests from 'services/CronoExtensionRequests';
import {
  getMissingTemplateVariables,
  prospectName,
  formatHtmlContent,
  replaceAllTemplateVariables,
} from 'utils/fe-utils';
import { User } from 'crono-fe-common/types/user';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import { LinkedinConnectionStatus } from 'crono-fe-common/types/crono-extension/linkedin';
import {
  createPatchTaskRequest,
  invalidatePatchTaskQueries,
} from 'hooks/services/task/usePatchTask';
import { ENDPOINTS } from 'config/endpoints';
import { Response } from 'crono-fe-common/types/response';
import { LinkedinAutomaticTasksQuantity } from 'crono-fe-common/types/DTO/linkedinAutomaticTasksQuantity';
import { createGetTemplateAttachmentsRequest } from 'hooks/services/templates/useGetTemplateAttachmentsMutate';
import { CronoAttachment } from 'crono-fe-common/types/cronoAttachment';
import { createGetTemplateAttachmentsFileRequest } from 'hooks/services/templates/useGetTemplateAttachmentsFileMutate';
import { CronoAttachmentWithContent } from 'crono-fe-common/types/cronoAttachmentWithContent';
import { UpdateProspect } from 'crono-fe-common/types/DTO/updateProspect';
import {
  createEditProspectRequest,
  invalidateEditProspectQueries,
} from 'hooks/services/prospect/useEditProspect';
import { EventEmitter } from 'eventemitter3';
import CronoNotification from './CronoNotification';
import { createGetTemplateVariablesRequest } from 'hooks/services/variables/useGetTemplateVariables';
import { ITemplateVariables } from 'crono-fe-common/types/templateVariables';

class CronoAutomaticTaskExecutor {
  private queryClient: QueryClient | null = null;
  private analytics: Analytics | null = null;
  private user: User | null = null;

  private stopped = true;

  private lastNextTaskReturnedNull = false;
  private nextTaskDateFromBackend: Date | null = null;
  private currentDelay: 'short' | 'long' = 'short';
  public readonly nextTaskEventEmitter = new EventEmitter<'next'>();

  setQueryClient(queryClient: QueryClient) {
    this.queryClient = queryClient;
  }

  setAnalytics(analytics: Analytics) {
    this.analytics = analytics;
  }

  private delayTimeout: NodeJS.Timeout | null = null;
  private delayResolve: ((value: unknown) => void) | null = null;

  public async start(user: User) {
    this.user = user;

    const disableDueToUserNotReady =
      !user ||
      user.candidate ||
      user.firstIntegration === false ||
      user.company?.expired;
    if (!this.stopped || disableDueToUserNotReady) {
      return;
    }
    this.stopped = false;
    CronoNotification.notificationEmitter.on(
      'CronoAutomaticTaskStopDelay',
      () => {
        this.stopDelay();
      },
    );

    try {
      const check = await this.getTaskLinkedinAutomaticCheck();
      this.nextTaskDateFromBackend = check.next;
    } catch (e) {
      console.error(e);
    }

    while (!this.stopped) {
      try {
        await this.delay();

        const check = await this.getTaskLinkedinAutomaticCheck();
        this.nextTaskDateFromBackend = check.next;

        const tooSoon =
          check.last && new Date().getTime() - check.last.getTime() < 60_000;
        if (tooSoon || this.stopped) {
          continue;
        }

        const task = await this.nextAutomaticTaskToExecute();
        if (task) {
          await this.executeTask(task);
        }

        const check2 = await this.getTaskLinkedinAutomaticCheck();
        this.nextTaskDateFromBackend = check2.next;
      } catch (e) {
        this.analytics?.track('automator-error', {
          message: e instanceof Error ? e.message : `unknown loop error: ${e}`,
        });
        console.error(e);
      }
    }
    this.nextTaskEventEmitter.emit('next', { next: null });
  }

  private async delay() {
    let delayType: 'short' | 'long' = 'short';

    const cachedQuantity = this.queryClient?.getQueryData<
      Response<LinkedinAutomaticTasksQuantity>
    >([ENDPOINTS.task.linkedin.quantity]);
    if (cachedQuantity) {
      if (cachedQuantity.data?.data?.inPipeline === 0) {
        delayType = 'long';
      }
    } else if (this.lastNextTaskReturnedNull) {
      delayType = 'long';
    }
    this.currentDelay = delayType;

    return new Promise((resolve) => {
      const baseDelayMillis = delayType === 'short' ? 80_000 : 10 * 60 * 1000;
      const delay = baseDelayMillis + Math.random() * 40_000;

      if (this.nextTaskDateFromBackend) {
        const nextTime = Math.max(
          this.nextTaskDateFromBackend.getTime(),
          new Date().getTime() + delay,
        );
        this.nextTaskEventEmitter.emit('next', { next: new Date(nextTime) });
      } else {
        this.nextTaskEventEmitter.emit('next', { next: null });
      }

      this.delayResolve = resolve;
      this.delayTimeout = setTimeout(() => resolve(null), delay);
    });
  }
  //This is used to stop the long delay earlier when new tasks are added to the automator
  public stopDelay() {
    //We want the delay to be stopped only if it was long, to prevent user to fire too many messages removing the short delay between the tasks
    if (
      this.currentDelay === 'long' &&
      this.delayTimeout &&
      this.delayResolve
    ) {
      clearTimeout(this.delayTimeout);
      this.delayResolve(null);
    }
  }

  public stop() {
    this.stopped = true;
    this.nextTaskEventEmitter.emit('next', { next: null });
    CronoNotification.notificationEmitter.off('CronoAutomaticTaskStopDelay');
  }

  private async executeTask(task: TaskTodo) {
    try {
      if (!task.prospect.linkedin) {
        await this.patchTask({
          id: task.id,
          automationError: CronoErrorCode.AutomationFailedLinkedinUrlMissing,
        });
        return;
      }

      const linkedinStatus = await CronoExtensionRequests.getLinkedinStatus({
        url: task.prospect.linkedin,
        user: prospectName(task.prospect),
        company: task.account?.name,
      });

      if (!linkedinStatus || linkedinStatus.status === 'Unknown') {
        await this.patchTask({
          id: task.id,
          automationError: CronoErrorCode.AutomationFailedLinkedinStatusUnknown,
        });
        return;
      }

      if (task.prospect.linkedinStatus !== linkedinStatus.status) {
        await this.patchProspect({
          prospectId: task.prospect.objectId,
          linkedinStatus: linkedinStatus.status,
        });
      }

      if (
        linkedinStatus.status === 'Connected' &&
        (task.subtype == null ||
          task.subtype === TaskTodoSubtype.LinkedinMessage)
      ) {
        await this.executeMessageTask(task);
      } else if (task.subtype === TaskTodoSubtype.LinkedinInvitation) {
        await this.executeInvitationTask(task, linkedinStatus.status);
      } else if (
        linkedinStatus.status === 'InvitationSent' &&
        (task.subtype == null ||
          task.subtype === TaskTodoSubtype.LinkedinMessage)
      ) {
        await this.patchTask({
          id: task.id,
          automationError:
            CronoErrorCode.AutomationFailedLinkedinInvitationPending,
        });
      } else if (
        linkedinStatus.status === 'NotConnected' &&
        (task.subtype == null ||
          task.subtype === TaskTodoSubtype.LinkedinMessage)
      ) {
        await this.patchTask({
          id: task.id,
          automationError: CronoErrorCode.AutomationFailedMessageToNotConnected,
        });
      } else {
        await this.patchTask({
          id: task.id,
          automationError: CronoErrorCode.GenericError,
        });
      }
    } catch (e) {
      await this.patchTask({
        id: task.id,
        automationError: CronoErrorCode.GenericError,
      });
    }
  }

  private async executeMessageTask(task: TaskTodo) {
    const template = task.template;
    if (!template) {
      await this.patchTask({
        id: task.id,
        automationError: CronoErrorCode.AutomationFailedTemplateMissing,
      });
      return;
    }
    const formattedTemplate = formatHtmlContent(template.content);
    const templateText = htmlToText(formattedTemplate);
    if (!templateText) {
      await this.patchTask({
        id: task.id,
        automationError:
          CronoErrorCode.AutomationFailedTemplateApplicationError,
      });
      return;
    }
    const templateVariables = await this.getTemplateVariables();
    const text = replaceAllTemplateVariables(
      templateText,
      task.prospect,
      task.account ?? null,
      this.user,
      templateVariables,
    );

    const missingVariables: string[] = getMissingTemplateVariables(text);
    if (missingVariables.length > 0) {
      await this.patchTask({
        id: task.id,
        automationError:
          CronoErrorCode.AutomationFailedTemplateApplicationError,
      });
      return;
    }

    //Load templates if any
    const attachments = await this.loadAttachments(template.id);
    let attachmentsContent: CronoAttachmentWithContent[] = [];
    let result = false;

    //Use the correct send method depending on the attachment status
    if (attachments.length > 0) {
      attachmentsContent = await this.getAttachmentsContent(
        attachments.map((a) => a.id),
      );
      attachmentsContent = attachmentsContent.filter((a) => a.fileContent);
      result = await CronoExtensionRequests.sendLinkedinMessageWithAttachments({
        text: text ?? '',
        url: task.prospect.linkedin,
        attachmentUrlData: attachmentsContent.map((a) => a.fileContent!),
        fileName: attachmentsContent.map((a) => a.name),
      });
    } else {
      result = await CronoExtensionRequests.sendLinkedinMessage({
        message: text ?? '',
        user: prospectName(task.prospect),
        url: task.prospect.linkedin,
        company: task.account?.name ?? null,
      });
    }

    if (!result) {
      await this.patchTask({
        id: task.id,
        automationError: CronoErrorCode.AutomationFailedLinkedinSendMessage,
      });
      return;
    }

    if (task.sequenceInstanceId) {
      this.analytics?.track('sequence-task', {})?.then();
      this.analytics?.track('send-linkedin', {})?.then();
    }
    this.analytics?.track('send-linkedin-automatic-message', {})?.then();

    await this.logLinkedin({
      taskTodoId: task.id,
      prospectId: task.prospect.objectId,
      accountId: task.prospect.accountId,
      linkedinType: LinkedinType.Message,
      templateId: template.id,
      content: text,
      sequenceStepTemplateId: task.sequenceStepTemplateId,
    });
  }

  private async executeInvitationTask(
    task: TaskTodo,
    status: LinkedinConnectionStatus,
  ) {
    if (status === 'Connected' || status === 'InvitationSent') {
      await this.patchTask({
        id: task.id,
        completed: true,
      });
      return;
    }

    let message = '';
    const template = task.template;

    if (template) {
      const templateText = htmlToText(template.content);
      if (!templateText) {
        await this.patchTask({
          id: task.id,
          automationError:
            CronoErrorCode.AutomationFailedTemplateApplicationError,
        });
        return;
      }
      const templateVariables = await this.getTemplateVariables();
      message = replaceAllTemplateVariables(
        templateText,
        task.prospect,
        task.account ?? null,
        this.user,
        templateVariables,
      );
      //Check if all template variables have been replaced
      const missingVariables: string[] = getMissingTemplateVariables(message);
      if (missingVariables.length > 0) {
        await this.patchTask({
          id: task.id,
          automationError:
            CronoErrorCode.AutomationFailedTemplateApplicationError,
        });
        return;
      }
    }

    const invitationLimits =
      await CronoExtensionRequests.getLinkedinInvitationsLimit();
    if (
      invitationLimits?.maxMessageLength &&
      message.length > invitationLimits.maxMessageLength
    ) {
      await this.patchTask({
        id: task.id,
        automationError:
          CronoErrorCode.AutomationFailedLinkedinInvitationMaxCharacters,
      });
      return;
    }

    const result = await CronoExtensionRequests.sendLinkedinInvitation({
      user: prospectName(task.prospect),
      url: task.prospect.linkedin,
      company: task.account?.name ?? null,
      message,
    });
    if (!result || !result.invitationSent) {
      await this.patchTask({
        id: task.id,
        automationError: result?.invitationsLimitReached
          ? CronoErrorCode.AutomationFailedLinkedinInvitationLimitReached
          : result?.messageTooLong
            ? CronoErrorCode.AutomationFailedLinkedinInvitationMaxCharacters
            : result?.connectionRecentlyWithdrawn
              ? CronoErrorCode.AutomationFailedLinkedinInvitationRecentlyWithdrawn
              : CronoErrorCode.GenericError,
      });
      return;
    }

    if (task.sequenceInstanceId) {
      this.analytics?.track('sequence-task', {})?.then();
      this.analytics?.track('send-invitation', {})?.then();
    }
    this.analytics?.track('send-linkedin-automatic-invitation', {})?.then();

    await this.logLinkedin({
      taskTodoId: task.id,
      prospectId: task.prospect.objectId,
      accountId: task.prospect.accountId,
      linkedinType: LinkedinType.Invitation,
      templateId: template?.id ?? null,
      content: message,
      sequenceStepTemplateId: task.sequenceStepTemplateId,
    });

    await this.patchProspect({
      prospectId: task.prospect.objectId,
      linkedinStatus: 'InvitationSent',
    });
  }

  // requests

  private async getTaskLinkedinAutomaticCheck(): Promise<{
    last: Date | null;
    next: Date | null;
  }> {
    const request: Request = {
      url: ENDPOINTS.task.linkedin.automatic.check,
      config: {
        method: 'get',
      },
    };

    const response = await client(request);
    const { last, next } = response?.data?.data ?? {};

    return {
      last: last ? new Date(last) : null,
      next: next ? new Date(next) : null,
    };
  }

  private async nextAutomaticTaskToExecute(): Promise<TaskTodo | null> {
    const request: Request = {
      url: ENDPOINTS.task.search.linkedin.next,
      config: {
        method: 'post',
      },
    };

    const response = await client(request);
    const task = response?.data?.data ?? null;
    this.lastNextTaskReturnedNull = task === null;
    return task;
  }

  private async logLinkedin(logLinkedin: LogLinkedinInsert) {
    const request = createLogLinkedinRequest(logLinkedin);
    await client(request);
    if (this.queryClient) {
      await invalidateLogLinkedinQueries(this.queryClient, logLinkedin);
    }
  }

  private async patchTask(taskPatch: TaskPatch) {
    const request = createPatchTaskRequest(taskPatch);
    await client(request);
    if (this.queryClient) {
      await invalidatePatchTaskQueries(this.queryClient);
    }
  }

  private async patchProspect(updateProspect: UpdateProspect) {
    const request = createEditProspectRequest(updateProspect);
    await client(request);
    if (this.queryClient) {
      await invalidateEditProspectQueries(this.queryClient);
    }
  }

  private async loadAttachments(
    templateId: number,
  ): Promise<CronoAttachment[]> {
    const request = createGetTemplateAttachmentsRequest(templateId);
    const res = await client(request);
    return res?.data?.data ?? [];
  }
  private async getAttachmentsContent(
    templateIds: number[],
  ): Promise<CronoAttachmentWithContent[]> {
    const request = createGetTemplateAttachmentsFileRequest(templateIds);
    const res = await client(request);
    return res?.data?.data ?? [];
  }
  private async getTemplateVariables(): Promise<ITemplateVariables | null> {
    const request = createGetTemplateVariablesRequest();
    const res = await client(request);
    if (!res.data.data) {
      return null;
    }
    return res.data.data;
  }
}

const instance = new CronoAutomaticTaskExecutor();
export default instance;

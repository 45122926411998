import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const InMailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: calc(100% - 130px);
  width: 100%;
  justify-content: space-between;
  position: 'relative';
  padding: 16px 8px 0px 18px;

  .prospect-avatar-row {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    margin-right: 16px;
    size: 16px !important;
    font-size: 16px !important;
    height: 32px;
    width: 32px;
  }

  .subject-selector-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 6px;
    gap: 8px;
  }
  .subject-selector-container > svg {
    opacity: 1;
    cursor: pointer;
  }
  // .subject-selector-container:hover > svg {
  //   opacity: 1;
  // }

  .bottom-line {
    border-bottom: 1px solid ${colors.grey5};
  }

  .textarea-container {
    width: 100%;
    position: relative;
    border: 1px solid ${colors.grey4};
    border-radius: 16px;
    overflow: hidden;
    background-color: ${colors.grey6};
    min-height: 256px;
    max-height: 650px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .file-preview {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .file {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 30px;
    background: ${colors.callScheduledLightHover};
    border-radius: 8px;
    padding: 8px;
  }
  .delete-attachments-icon {
    cursor: pointer;
  }
  .header-textarea {
    height: 50px;
    width: 100%;
    background: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12px;
  }

  .divider {
    border-top: 1px solid ${colors.grey3};
    opacity: 0.5;
    margin-block: 12px;
  }

  .textarea {
    width: 100%;
    flex: 1;
    resize: none;
    border: 0px;
    padding: 12px;
    font-family: inherit;
    font-size: 14px;
    margin-bottom: -6px;
    &:focus {
      outline: none;
    }
    background-color: ${colors.grey6};
  }
  textarea {
    background-color: ${colors.grey6};
  }

  .send-invitation-button {
    height: 40px !important;
    margin: 0px !important;
  }

  .all-screen-linkedin-view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
  .informative-message-linkedin-view {
    width: 420px;
    border-radius: 16px;
    padding: 16px;
    gap: 16px;
    background: ${colors.primaryLight};
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    .informative-icon {
      background: ${colors.primaryLightHover};
      padding: 6px 16px;
      border-radius: 24px;
    }
  }
  .linkedin-invitations-remaining-banner {
    border-radius: 9999px;
    padding: 12px 15px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
  }
  .some-invitations-left {
    background: ${colors.prospectingLight};
    color: ${colors.intenseBlue};
  }
  .no-invitations-left {
    background: ${colors.inactiveLight};
    color: ${colors.inactive};
  }
  .template-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    border-radius: 8px;
    padding: 4px;
    cursor: pointer;
    min-height: 32px;

    .template-button-icon {
      height: 16px;
      width: 16px;
    }
  }
  .template-button-container:hover {
    background-color: ${colors.grey444};
  }
  .templates-button,
  .insights-button {
    margin-inline: 4px;
  }

  .templates-button {
    background-color: #d4bfff !important;
    gap: 0px;
    padding-right: 10px;
    padding-left: 6px;
  }
  .insights-button {
    background-color: #ffd748 !important;
    padding-right: 10px;
    padding-left: 6px;
  }
  .templates-button:hover,
  .insights-button:hover {
    filter: brightness(0.8);
  }
  .ab-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.purpleLight};
    width: 40px;
    height: 25px;
    border-radius: 12px;
    padding: 4px 8px;
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .icon-wrapper {
    padding: 4px;
  }
  .small-divider {
    height: 16px;
    width: 0px;
    border-left: 1px solid ${colors.grey444};
  }

  .timezone-icon {
    flex-shrink: 0;
  }
`;

import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const CallModalWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  overflow-y: auto;
  padding: 16px 18px 0px 18px;

  .prospect-avatar-row {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    margin-right: 16px;
    size: 16px !important;
    font-size: 16px !important;
    height: 32px;
    width: 32px;
  }
  .vertical-divider {
    border: 1px solid ${colors.grey4};
    height: 40px;
    margin-left: 6px;
  }

  .right-side {
    display: flex;
    justify-content: end;
    margin-right: 2px;
    align-items: center;
    margin-left: 50px;
  }

  .left-side {
    display: flex;
  }

  .modal-body {
    width: 100%;
    padding: 0;
    margin: 0;
    display: inline-flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
  }

  .message-channel-container {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    height: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .left-side-actions {
    padding: 10px;
    flex: 2;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }

  .quick-actions {
    gap: 8px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }

  .quick-actions-empty {
    gap: 8px;
    height: 92px;
  }

  .quick-actions > h2 {
    font-weight: 500;
    color: ${colors.primary};
    margin: 0;
    margin-bottom: 1rem;
  }

  .dialer {
    gap: 20px;
  }

  .add-note-text-area {
    width: 100%;
    background-color: ${colors.grey6};
    color: ${colors.dialerBlue};
    border-radius: 1rem;
    outline: none;
  }

  .call-input {
    color: ${colors.white};
    font-size: 1.2em;
    padding: 15px;
    width: auto;
    border-radius: 5px;
  }
  .retrieve-call-button,
  .call-phone-button,
  .small-call-phone-button {
    text-transform: unset !important;
    border-radius: 30px;
    color: ${colors.white};
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    height: 40px;
  }
  .small-call-phone-button {
    width: 40px;
    background: ${colors.callScheduled};
  }
  .call-phone-button {
    min-width: 96px;
    background: ${colors.callScheduled};
  }
  .retrieve-call-button {
    min-width: 144px;
    padding: 7px 7px 7px 16px !important;
    color: ${colors.mainDark};
    border: 1px solid ${colors.grey444};
    background: ${colors.white};
    :hover {
      background: ${colors.grey6};
    }
  }
  .phone-icon {
    width: 16px;
    height: 16px;
  }
  .aircall-logo-icon {
    width: 24px;
    height: 24px;
    border-radius: 12px;
  }
  .call-log-icon-round {
    border: 1px solid ${colors.grey444};
    border-radius: 999px;
    width: 30px;
    height: 30px;
    padding: 5px;
  }
  .redirect-icon {
    display: flex;
    align-items: center;
  }
  .redirect-icon > button {
    border-radius: 999px;
    min-width: auto;
  }
  .redirect-icon:hover > button {
    background: ${colors.grey6};
  }
  .in-charge-label,
  .completed-label {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    padding: 2px 4px;
    gap: 10px;
    border-radius: 8px;
  }
  .in-charge-label {
    color: ${colors.blueHover};
    background: ${colors.blueLight};
  }
  .completed-label {
    color: ${colors.customerHover};
    background: ${colors.customerLight};
  }
  .editable-div-call-view,
  .editable-div-call-view-disabled {
    border-radius: 8px;
    padding: 2px 0px 2px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    min-height: 26px;
    height: 36px;
  }
  .editable-div-call-view > span > svg,
  .editable-div-call-view-disabled > span > svg {
    opacity: 0;
  }
  .editable-div-call-view:hover > span > svg {
    opacity: 1;
  }
  .editable-div-call-view > div > span,
  .editable-div-call-view-disabled > div > span {
    opacity: 0;
  }
  .editable-div-call-view:hover,
  .editable-div-call-view-disabled:hover {
    background: ${colors.grey6};
  }
  .editable-div-call-view > div > div > input:focus ~ .editable-div-call-view {
    background: ${colors.inactive};
  }
  .editable-div-call-view:hover > div > span {
    opacity: 1;
  }
  .editable-div-call-view > div > .edit-editable-div-icon,
  .editable-div-call-view-disabled > div > .edit-editable-div-icon {
    width: 0px;
  }
  .editable-div-call-view:hover > div > .edit-editable-div-icon {
    width: 24px;
  }
  .editable-div-call-view > .edit-editable-div-icon,
  .editable-div-call-view-disabled > .edit-editable-div-icon {
    width: 0px;
  }
  .editable-div-call-view:hover > .edit-editable-div-icon {
    width: 24px;
  }
  .editable-div-call-view > div > button,
  .editable-div-call-view-disabled > div > button {
    opacity: 0;
  }
  .editable-div-call-view:hover > div > button {
    opacity: 1;
  }

  @media (max-width: 900px) {
    .modal-body {
      display: flex;
      flex-direction: column;
    }
  }

  .badge {
    min-width: 9rem;
    width: fit-content;
    padding: 8px 10px;
  }
  .status-not-selected-wrongNumber {
    background: #ffe2e2;
    color: #fd6778;
  }
  .status-wrongNumber {
    background: #fd6778;
  }
  .status-not-selected-checkbox-wrongNumber {
    color: #fd6778;
  }
  .status-not-selected-wrongNumber:hover {
    background: #fdd0d0;
  }
  .status-not-selected-notAnswered {
    background: #fef3d2;
    color: #e2ad13;
  }
  .status-notAnswered {
    background: #f9bb06;
  }
  .status-not-selected-checkbox-notAnswered {
    color: #e2ad13;
  }
  .status-not-selected-notAnswered:hover {
    background: #f8e6b1;
  }
  .status-not-selected-voicemail {
    background: #eaf1fb;
    color: #3b58db;
  }
  .status-voicemail {
    background: #3b58db;
  }
  .status-not-selected-checkbox-voicemail {
    color: #3b58db;
  }
  .status-not-selected-voicemail:hover {
    background: #d5e0f0;
  }
  .status-not-selected-gatekeeper {
    background: #e9f8f8;
    color: #1a9d6e;
  }

  .status-gatekeeper {
    background: #24ba84;
  }
  .status-not-selected-checkbox-gatekeeper {
    color: #1a9d6e;
  }
  .status-not-selected-gatekeeper:hover {
    background: #ceeded;
  }

  .status-not-selected-connected {
    background: #f2eaff;
    color: #8846dc;
  }
  .status-connected {
    background: #a35aff;
  }
  .status-not-selected-checkbox-connected {
    color: #8846dc;
  }
  .status-not-selected-connected:hover {
    background: #e4d8f6;
  }

  .template-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    border-radius: 8px;
    padding: 4px;
    cursor: pointer;
    min-height: 40px;

    .template-button-icon {
      height: 20px;
      width: 20px;
    }
  }

  /* .insights-button {
    margin-inline: 4px;
  } */

  .insights-button {
    background-color: #ffd748 !important;
    padding-right: 10px;
    padding-left: 12px;
  }
  .insights-button:hover {
    filter: brightness(0.8);
  }
  .textarea-container {
    width: 100%;
    position: relative;
    border: 1px solid ${colors.grey4};
    border-radius: 16px;
    overflow: hidden;
    background-color: ${colors.grey6};
    min-height: 256px;
    max-height: 650px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .header-textarea {
    height: 50px;
    width: 100%;
    background: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12px;
  }
  .textarea {
    width: 100%;
    flex: 1;
    resize: none;
    border: 0px;
    padding: 12px;
    font-family: inherit;
    font-size: 14px;
    margin-bottom: -6px;
    &:focus {
      outline: none;
    }
    background-color: ${colors.grey6};
  }
  .template-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    border-radius: 8px;
    padding: 4px;
    cursor: pointer;
    min-height: 32px;

    .template-button-icon {
      height: 16px;
      width: 16px;
    }
  }
  .template-button-container:hover {
    background-color: ${colors.grey444};
  }
  .templates-button,
  .insights-button {
    margin-inline: 4px;
  }

  .templates-button {
    background-color: #d4bfff !important;
    gap: 0px;
    padding-right: 10px;
    padding-left: 6px;
  }
  .insights-button {
    background-color: #ffd748 !important;
    padding-right: 10px;
    padding-left: 6px;
  }
  .templates-button:hover,
  .insights-button:hover {
    filter: brightness(0.8);
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .icon-wrapper {
    padding: 4px;
  }
  .small-divider {
    height: 16px;
    width: 0px;
    border-left: 1px solid ${colors.grey444};
  }

  .timezone-icon {
    flex-shrink: 0;
  }
`;

export const DialerWrapper = styled.div`
  border: 2px solid ${colors.iconBlack};
  border-radius: 0.5rem;
  background-color: ${colors.dialerBackground};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
`;

export const DialerButton = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${colors.dialerBlue};
  border-radius: 50%;
  color: ${colors.white};
  text-align: center;
  padding: 0.5rem;
  font-weight: bold;
  font-size: 1.5rem;
  vertical-align: middle;
`;

type QuickActionButtonProps = {
  selected: boolean;
};

export const QuickActionButton = styled.div<QuickActionButtonProps>`
  background-color: ${(props) =>
    props.selected ? colors.primary : colors.dialerBackground};
  border: 1px solid ${colors.primary};
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: ${(props) => (props.selected ? colors.white : colors.dialerBlue)};
  font-weight: 600;
  width: 10.5rem;
  text-align: center;
  cursor: pointer;
`;

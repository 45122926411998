import React from 'react';
import IProps from './interface';
import { colors } from 'crono-fe-common/theme';

const IconTimeZone = ({
  color = colors.grey11,
  className,
  onClick,
  style,
}: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...style}
      onClick={onClick}
    >
      <path
        d="M2 7.99986H4.78133C5.82267 7.99986 6.66667 7.15586 6.66667 6.11453V6.11453C6.66667 5.61453 6.468 5.13453 6.11467 4.7812L4.478 3.14453"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1941 12.292L11.0094 10.5153C10.7947 10.1933 10.4341 10 10.0474 10V10C9.6094 10 9.2094 9.75267 9.0134 9.36067L8.92473 9.18333C8.76207 8.858 8.76207 8.47467 8.92473 8.14933L9.68007 6.63867C9.8754 6.24733 10.2761 6 10.7141 6H13.6527"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="8"
        cy="8"
        r="6"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 11.3433C3.5 11.3433 3.76472 10.7998 4.58699 10.7998C5.40926 10.7998 6.5 11.7606 6.5 13.4998"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconTimeZone;

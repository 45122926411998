import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import React, { useEffect, useMemo, useState } from 'react';
import { ModifyTagWrapper } from './style';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import { IconButton, TextField, Typography } from '@mui/material';
import useDeleteExternalPropertyOption from 'hooks/services/externalProperty/useDeleteExternalPropertyOption';
import useCreateExternalPropertyOption from 'hooks/services/externalProperty/useCreateExternalPropertyOption';
import {
  getError,
  getExternalPropertyLabels,
  getExternalPropertyOptions,
} from 'crono-fe-common/utils';
import { useConditionalSnackBar } from 'context/snackbar';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import usePatchExternalProperty from 'hooks/services/externalProperty/usePatchExternalProperty';
import ExternalPropertyEditable from 'crono-fe-common/components/ExternalPropertyEditable';
import usePatchExternalPropertyOption from 'hooks/services/externalProperty/usePatchExternalPropertyOption';
import { useConfirmModal } from 'context/confirmModal';

interface IProps {
  close: () => void;
  tag: ExternalProperty;
  indexTag: number;
}

const ModifyTag = ({ close, tag, indexTag }: IProps) => {
  const {
    mutate: deleteOption,
    isSuccess: optionDeleted,
    error: errorDeleteOption,
  } = useDeleteExternalPropertyOption();

  const tagsToShow = useMemo(() => {
    return getExternalPropertyLabels(tag);
  }, [tag]);

  const { openModal } = useConfirmModal();

  const handleRemoveOption = (index: number) => {
    openModal({
      cancelFunction: () => {},
      confirmFunction: () => {
        deleteOption({
          externalPropertyId: tag.id,
          option: getExternalPropertyOptions(tag)[index],
        });
      },
      title: 'Delete tag option',
      text: 'Are you sure you want to delete this tag option?',
    });
  };

  const {
    mutate: createOption,
    isSuccess: optionCreated,
    variables: variablesCreated,
    error: errorCreateOption,
  } = useCreateExternalPropertyOption();

  const [newValue, setNewValue] = useState<string>('');

  useEffect(() => {
    if (optionCreated) {
      setNewValue('');
    }
  }, [optionCreated]);

  const handleAdd = () => {
    if (newValue.trim() === '') return;
    createOption({
      externalPropertyId: tag.id,
      label: newValue.trim(),
    });
  };

  const [propertyName, setPropertyName] = useState<string>(
    tag.publicName ?? '',
  );

  const [showActionButtons, setShowActionButtons] = useState<boolean>(false);
  const {
    mutate: patchExternalProperty,
    isSuccess: externalPropertyPatched,
    error: errorPatchingExternalProperty,
    variables: variablesPatchingExternalProperty,
  } = usePatchExternalProperty();

  const handleSubmitNewName = () => {
    if (propertyName === '') return;
    const newPublicName = propertyName.trim();
    if (
      (variablesPatchingExternalProperty === undefined &&
        newPublicName !== tag.publicName) ||
      newPublicName !== variablesPatchingExternalProperty?.publicName
    ) {
      patchExternalProperty({
        externalPropertyId: tag.id,
        publicName: newPublicName,
        tableType: tag.tableType,
        type: tag.valueType,
      });
    }
  };

  const {
    mutate: patchExternalPropertyOption,
    variables: patchingExternalPropertyOptionVariables,
    isSuccess: externalPropertyOptionPatched,
    error: errorPatchingExternalPropertyOption,
  } = usePatchExternalPropertyOption();

  const handleChangePropertyOption = (newValue: string, index: number) => {
    if (newValue === '') return;
    const option = getExternalPropertyOptions(tag)[index];
    const newValueTrimmed = newValue.trim();
    if (
      (patchingExternalPropertyOptionVariables === undefined &&
        option !== newValueTrimmed) ||
      newValueTrimmed !== patchingExternalPropertyOptionVariables?.option
    ) {
      patchExternalPropertyOption({
        externalPropertyId: tag.id,
        option:
          patchingExternalPropertyOptionVariables === undefined
            ? option
            : patchingExternalPropertyOptionVariables?.option,
        newLabel: newValueTrimmed,
      });
    }
  };

  useConditionalSnackBar([
    {
      condition: !!errorCreateOption,
      message: getError(errorCreateOption) ?? 'Error creating option',
      severity: 'error',
    },
    {
      condition: !!errorDeleteOption,
      message: getError(errorDeleteOption) ?? 'Error deleting option',
      severity: 'error',
    },
    {
      condition: !!optionDeleted,
      message: 'Tag option correctly deleted',
      severity: 'success',
    },
    {
      condition: !!errorPatchingExternalProperty,
      message:
        getError(errorPatchingExternalProperty) ?? 'Error patching property',
      severity: 'error',
    },
    {
      condition: !!externalPropertyPatched,
      message: 'Property name correctly updated',
      severity: 'success',
    },
    {
      condition: !!errorPatchingExternalPropertyOption,
      message:
        getError(errorPatchingExternalPropertyOption) ??
        'Error patching tag option',
      severity: 'error',
    },
    {
      condition: !!externalPropertyOptionPatched,
      message: 'Tag option correctly updated',
      severity: 'success',
    },
  ]);
  return (
    <BlurBackgroundDivFixed>
      <ModifyTagWrapper>
        <CloseTabButton close={close} />
        <div className="modify-tag-container">
          <Typography fontSize={24} fontWeight={700}>
            Edit property:
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              fontSize={16}
              fontWeight={600}
              lineHeight={'24px'}
              style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}
            >
              Name:
            </Typography>
            <div className="insert-value-text-field">
              <TextField
                placeholder="Type tag name..."
                variant="standard"
                fullWidth
                value={propertyName}
                onChange={(e) => {
                  setPropertyName(e.target.value);
                  setShowActionButtons(true);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmitNewName();
                  }
                }}
                onBlur={(e) => {
                  handleSubmitNewName();
                }}
                helperText={
                  tag.isSync &&
                  '*If you change the name in Crono, it will remain unchanged in your CRM '
                }
                error={propertyName === ''}
              />
              {showActionButtons && (
                <div
                  className="manage-value-buttons-container"
                  style={{
                    display:
                      propertyName !== '' && propertyName !== null
                        ? 'flex'
                        : 'none',
                  }}
                >
                  <IconButton
                    className="manage-value-button"
                    style={{ background: colors.inactiveLight }}
                    onClick={() => setPropertyName('')}
                  >
                    <CloseMIcon color={colors.inactive} />
                  </IconButton>
                  <IconButton
                    className="manage-value-button"
                    style={{ background: colors.callScheduledLight }}
                    onClick={handleSubmitNewName}
                  >
                    <CheckMarkIcon color={colors.callScheduled} />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
          {!tag.isSync && (
            <>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  lineHeight={'24px'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 8,
                  }}
                >
                  Values:
                </Typography>
                <div className="insert-value-text-field">
                  <TextField
                    placeholder="Type new value name..."
                    variant="standard"
                    fullWidth
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleAdd();
                      }
                    }}
                    helperText={'*Tag values should be not empty'}
                  />
                  <div
                    className="manage-value-buttons-container"
                    style={{
                      display:
                        newValue !== '' && newValue !== null ? 'flex' : 'none',
                    }}
                  >
                    <IconButton
                      className="manage-value-button"
                      style={{ background: colors.inactiveLight }}
                      onClick={() => setNewValue('')}
                    >
                      <CloseMIcon color={colors.inactive} />
                    </IconButton>
                    <IconButton
                      className="manage-value-button"
                      style={{ background: colors.callScheduledLight }}
                      onClick={handleAdd}
                    >
                      <CheckMarkIcon color={colors.callScheduled} />
                    </IconButton>
                  </div>
                </div>
              </div>

              <div className="options-container">
                {tagsToShow.map((option, index) => (
                  <ExternalPropertyEditable
                    key={`Option-Tag-${index}-${option}`}
                    value={option}
                    index={indexTag}
                    indexOption={index}
                    onClick={() => {
                      handleRemoveOption(index);
                    }}
                    handleSetValue={(value) => {
                      if (value !== option)
                        handleChangePropertyOption(value, index);
                    }}
                  />
                ))}
              </div>
            </>
          )}
          {/* <FlexDiv width="fit-content" style={{ alignSelf: "flex-end" }}>
            <CancelButton onClick={() => close()}>Close</CancelButton>
          </FlexDiv> */}
        </div>
      </ModifyTagWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default ModifyTag;

import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const InsertContactWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${colors.white};
  width: 600px;
  max-height: 95%;
  border-radius: 16px;
  padding-block: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .close-button {
    align-self: flex-end;
    cursor: pointer;
    z-index: 1001;
    height: 24px;
    width: 24px;
  }
  .insert-form {
    display: flex;
    flex-direction: column;
    padding: 0px 48px 24px 48px;
    gap: 16px;
    overflow: scroll;
  }
  .form-fields-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .prospect-info-fields {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 12px;
    gap: 4px;
  }
  .icons-wrapper {
    display: flex;
    align-items: center;
    margin-left: 4px;
    gap: 2px;
  }
  .edit-icon {
    display: flex;
    align-items: center;
    padding-bottom: 2px;
  }
  .company-options {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
  }
  .company-label-field-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
  }
  .company-logo {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .editable-div {
    border-radius: 8px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 26px !important;
    height: 34px !important;
    flex: 1;
  }
  .editable-div > span > svg {
    opacity: 0;
  }
  .editable-div:hover > span > svg {
    opacity: 1;
  }
  .editable-div > div > span {
    opacity: 0;
  }
  .editable-div > div > div > input:focus ~ .editable-div {
    background: ${colors.inactive};
  }
  .editable-div:hover {
    background: ${colors.grey6};
  }
  .editable-div:hover > div > span {
    opacity: 1;
  }
  .info-icon {
    min-width: 34px;
    min-height: 34px;
    padding: 5px;
    border-radius: 999px;
    border: 2px solid ${colors.grey4};
    margin-right: 4px;
  }
  .divider {
    border-top: 1px solid ${colors.grey4};
  }
  .information-label {
    max-width: 150px !important;
    width: fit-content;
    min-width: fit-content;
    color: ${colors.grey11};
    margin-right: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .information-editable-container {
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    height: 30px !important;

    &--big {
      height: 64px !important;
      margin: 0;
    }
  }
  .select-radius {
    border-radius: 8px;
  }
  .select-padding {
    padding: 13px;
  }
  .select-wrapper {
    width: 100%;
  }
  .select-strategy {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 8px;
  }
  .selected-strategy {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__info {
      display: flex;
      flex-direction: column;
    }

    &__title {
      margin-bottom: 2px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: ${colors.black};
    }

    &__steps {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: ${colors.grey11};
    }

    &__icon-container {
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: ${colors.white};
      border: 1px solid ${colors.grey444};
      cursor: pointer;

      transition-property: border, background-color;
      transition-duration: 200ms;

      &:hover {
        background-color: ${colors.grey444};
        border: 0;
      }
    }
  }
  .contact-info-fields-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .external-properties-tag {
    max-width: 50%;
  }
  .additional-information-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .field-input {
    width: 100%;
    border-radius: 8px;
    padding: 4px 0px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiInput-input {
      padding: 0px;
    }
    & .MuiSelect-select {
      padding: 0px 26px 0px 4px;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .field-input:hover {
    background: ${colors.grey6};
  }
  .text-field {
    width: 100%;
    padding-inline: 0px;
  }
  .green-sequence-button {
    background-color: ${colors.primaryLight};

    &--selected {
      background-color: ${colors.grey6};
      -webkit-text-fill-color: inherit !important;
    }
  }
  .green-sequence-button-disabled {
    filter: grayscale(1);
  }
  .icon-container > svg > path {
    fill: ${colors.white};
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    border-radius: 999px;
    background: ${colors.primary};
    transition: background-color 200ms;

    &--hovered {
      background: ${colors.darkGreen};
      transition: background-color 200ms;
    }
  }
`;

import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const LinkedinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: calc(100% - 130px);
  width: 100%;

  .prospect-avatar-row {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    margin-right: 16px;
    size: 16px !important;
    font-size: 16px !important;
    height: 32px;
    width: 32px;
  }

  .delete-attachments-icon {
    cursor: pointer;
  }

  .textarea-container {
    width: 100%;
    border: 1px solid ${colors.grey4};
    border-radius: 16px;
    overflow: hidden;
    background-color: ${colors.grey6};
    padding: 0;
    position: relative;
  }

  .header-textarea {
    height: 50px;
    width: 100%;
    background: ${colors.white};
    display: flex;
    align-items: center;
    padding-right: 12px;
  }
  .conversation-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-inline: 12px;
    padding-top: 6px;
  }

  .message-avatar-reply,
  .message-avatar {
    font-size: 16px;
    margin-right: 10px;
    margin-top: 4px;
  }

  .message-avatar {
    background: ${colors.nurtureBlue};
  }

  .message-text {
    overflow-wrap: break-word;
    width: 100%;
  }

  .divider {
    border-top: 1px solid ${colors.grey3};
    opacity: 0.5;
    margin-block: 12px;
  }

  .textarea {
    width: 100%;
    height: 100%;
    resize: none;
    border: 0px;
    padding: 12px;
    font-family: inherit;
    font-size: 14px;
    margin-bottom: -6px;
    background-color: ${colors.grey6};
    border-bottom: 1px solid ${colors.grey444};

    &:focus {
      outline: none;
    }
  }
  textarea {
    background-color: ${colors.grey6};
  }
  .send-invitation-button {
    height: 40px;
    margin: 0px;

    &--margin {
      margin: 10px 0px 0px 10px;
    }
  }

  .all-screen-linkedin-view {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
  .informative-message-linkedin-view {
    width: 420px;
    border-radius: 16px;
    padding: 16px;
    gap: 16px;
    background: ${colors.primaryLight};
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    .informative-icon {
      background: ${colors.primaryLightHover};
      padding: 6px 16px;
      border-radius: 24px;
    }
  }
  .linkedin-invitations-remaining-banner {
    border-radius: 9999px;
    padding: 12px 15px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
  }
  .some-invitations-left {
    background: ${colors.prospectingLight};
    color: ${colors.intenseBlue};
  }
  .no-invitations-left {
    background: ${colors.inactiveLight};
    color: ${colors.inactive};
  }
  .convert-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    margin-right: 8px;
    height: 32px;

    &:hover {
      background-color: ${colors.grey444};
    }

    svg {
      height: 16px;
    }
  }
  .file-preview {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .file {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 30px;
    background: ${colors.callScheduledLightHover};
    border-radius: 8px;
    padding: 8px;
  }
  .template-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    border-radius: 8px;
    padding: 4px;
    cursor: pointer;
    min-height: 32px;

    .template-button-icon {
      height: 16px;
      width: 16px;
    }
  }
  .template-button-container:hover {
    background-color: ${colors.grey444};
  }
  .templates-button,
  .insights-button {
    margin-inline: 4px;
  }

  .templates-button {
    background-color: #d4bfff !important;
    gap: 0px;
    padding-right: 10px;
    padding-left: 6px;
  }
  .insights-button {
    background-color: #ffd748 !important;
    padding-right: 10px;
    padding-left: 6px;
  }
  .templates-button:hover,
  .insights-button:hover {
    filter: brightness(0.8);
  }

  .ab-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.purpleLight};
    width: 40px;
    height: 25px;
    border-radius: 12px;
    padding: 4px 8px;
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .icon-wrapper {
    padding: 4px;
  }
  .small-divider {
    height: 16px;
    width: 0px;
    border-left: 1px solid ${colors.grey444};
  }

  .timezone-icon {
    flex-shrink: 0;
  }
`;

export const LinkedinModalWrapper = styled.div`
  z-index: 6000;
  background: ${colors.white};
  width: 100%;
  height: 100%;
  padding: 0px;

  .MuiGrid-item {
    width: 100%;
  }

  h2 {
    margin-bottom: 2px;
  }

  .error {
    color: ${colors.error};
    font-weight: 500;
    font-size: 1.25rem;
  }

  .prospect-container {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    width: 100%;
    margin-bottom: 10px;
  }

  .prospect-role {
    font-weight: 500;
    font-size: 16px;
  }

  .modal-header {
    height: 100px;
    background-color: white;
    border-radius: 20px;
    padding: 20px 40px 10px 0px;
    margin-bottom: 10px;
  }

  .modal-header h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 600;
  }

  .modal-body {
    max-height: calc(100% - 100px);
    overflow-y: auto;
    background-color: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px 20px 20px 0px;
  }

  .close-modal-icon {
    color: ${colors.primary};
    font-size: 40px;
    cursor: pointer;
  }

  .linkedin-icon {
    color: ${colors.linkedin};
    font-size: 22px;
    cursor: pointer;
    margin-right: 10px;
  }

  .layout-grid {
    width: 100%;
    height: 100%;
    min-height: 600px;
  }

  .error-p {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }

  @media screen and (max-width: 768px) {
    .modal-box {
      width: 100% !important;
    }
  }

  .prospect-name {
    font-weight: 500;
    font-size: 16px;
  }

  .account-name {
    font-weight: 400;
    color: ${colors.primary};
  }

  .modal-button {
    margin-left: 40px;
  }

  .message-container {
    width: 100%;
    margin-bottom: 10px;
    min-height: 40px;
  }

  .message-header {
    cursor: pointer;
  }

  .message-header-selected {
    cursor: pointer;
    border-radius: 8px 8px 0px 0px;
    background-color: ${colors.primaryDisabled};
    border-bottom: solid 0.5px ${colors.black};
  }

  .reply-container {
    margin-bottom: 10px;
    width: 95%;
  }

  .reply-button {
    width: 100px;
    border-radius: 999px;
  }

  .message-sender {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }

  .message-subject {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 400;
  }

  .message-date {
    margin-right: 20px;
    color: ${colors.grey11};
  }

  .message-body {
    width: 100%;
    margin-left: 30px;
    margin-top: 20px;
    align-items: flex-start;
    border-left: 2px solid ${colors.grey4Hover};
    padding-left: 10px;
  }

  .no-thread {
    white-space: pre-line;
  }
`;

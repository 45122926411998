import React, { useState } from 'react';
import { Button, ClickAwayListener } from '@mui/material';
import CalendarRescheduleIcon from 'crono-fe-common/icons/Icon-Calendar-Reschedule';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import ThreeDotsIcon from 'crono-fe-common/icons/Icon-Three-dots';
import { CustomOptionsTooltip } from 'pages/settings/Strategy';
import { SequenceOptionsWrapper } from 'pages/settings/Strategy/sequenceOptions/style';

interface IProps {
  onReschedule: () => void;
  onSkip: () => void;
  isSequenceTask: boolean;
}

const ThreeDotsIconTaskCard = ({
  onReschedule,
  onSkip,
  isSequenceTask,
}: IProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  return (
    <div>
      <ClickAwayListener onClickAway={() => setIsOpened(false)}>
        <div>
          <CustomOptionsTooltip
            enterDelay={400}
            enterNextDelay={400}
            width={200}
            title={
              <SequenceOptionsWrapper>
                <Button
                  className="option-button"
                  color="primary"
                  fullWidth
                  endIcon={<CalendarRescheduleIcon />}
                  onClick={() => {
                    onReschedule();
                    setIsOpened(false);
                  }}
                >
                  Reschedule task
                </Button>
                <Button
                  className="option-button"
                  color="primary"
                  fullWidth
                  endIcon={<CloseMIcon />}
                  onClick={() => {
                    onSkip();
                    setIsOpened(false);
                  }}
                >
                  {isSequenceTask ? 'Skip' : 'Delete'} task
                </Button>
              </SequenceOptionsWrapper>
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={() => setIsOpened(false)}
            open={isOpened}
            PopperProps={{
              disablePortal: true,
              sx: {
                zIndex: '0 !important',
              },
            }}
          >
            <div>
              <ThreeDotsIcon onClick={() => setIsOpened((prev) => !prev)} />
            </div>
          </CustomOptionsTooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ThreeDotsIconTaskCard;

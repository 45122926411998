import { ENDPOINTS } from 'config/endpoints';
import { useMutation, useQuery } from 'react-query';
import CronoError from 'crono-fe-common/types/error';
import client from 'utils/clients/client';
import { Request } from 'crono-fe-common/types/request';
import { Response } from 'crono-fe-common/types/response';
import { Import } from 'crono-fe-common/types/import';
import { TableType } from 'crono-fe-common/types/externalProperty';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';

export type IGetImportMutation = {
  Offset: number;
  Limit: number;
  TableType?: TableType;
  StatusType?: ImportStatusType;
};

export default function useGetImportsMutation() {
  const getImportsMutation = ({
    Offset,
    Limit,
    TableType,
    StatusType,
  }: IGetImportMutation) => {
    const queryParams = new URLSearchParams({
      Limit: Limit.toString(),
      Offset: Offset.toString(),
      ...(TableType ? { TableType: TableType.toString() } : undefined),
      ...(StatusType ? { StatusType: StatusType.toString() } : undefined),
    }).toString();
    const request: Request = {
      url: `${ENDPOINTS.import.main}?${queryParams}`,
      config: {
        method: 'get',
      },
    };
    return client(request);
  };

  return useMutation<Response<Import[]>, CronoError, IGetImportMutation>(
    getImportsMutation,
    {},
  );
}

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NotificationBoxWrapper } from './style';
import { CircularProgress, ClickAwayListener, Typography } from '@mui/material';
import NotificationItem from './notificationItem';
import useLiveFeed from 'hooks/services/notification/useLiveFeed';
import { Constants } from 'crono-fe-common/constants/constants';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { colors } from 'crono-fe-common/theme';
import FeedPlaceholderLinkedinMessage from '../../../assets/images/No_feed_linkedin.png';
import FeedPlaceholderClickedEmail from '../../../assets/images/No_feed_click_email.png';
import FeedPlaceholderEmailOpened from '../../../assets/images/No_feed_opened_email.png';
import { ReactComponent as NoLiveFeed } from '../../../assets/images/No_live_feed.svg';
import { TuneRounded } from '@mui/icons-material';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { FilterModalContainer } from 'pages/accountTab/filter/style';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import FilterNotifications, {
  PickedNotificationFilters,
  showFilterOptions,
} from './filter/filterNotifications';
import { createPortal } from 'react-dom';
import NotificationType from 'crono-fe-common/types/enums/notificationType';
import { Account } from 'crono-fe-common/types/account';
import CronoNotification from 'services/CronoNotification';
import { CustomOptionsTooltip } from 'pages/settings/Strategy';
import IconTopArrow from 'crono-fe-common/icons/Icon-Top-Arrow';
import IconBottomArrow from 'crono-fe-common/icons/Icon-Bottom-Arrow';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { ShowFilterOptionsWrapper } from 'context/strategyDetail/strategyContacts/style';
import CronoCheckbox from 'crono-fe-common/components/CronoCheckbox';
import { useConditionalSnackBar } from 'context/snackbar';

const NotificationBoxTooltip = () => {
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [company, setCompany] = useState<Account | undefined | null>();
  const [notificationTypes, setNotificationTypes] = useState<
    NotificationType[] | null
  >();
  const [unread, setUnread] = useState<boolean>(false);
  const {
    refetch: callNotifications,
    data: notifications,
    isLoading: loadingNotifications,
    isRefetching: refetchingNotifications,
    fetchNextPage: fetchNextNotifications,
    isFetchingNextPage: isFetchingNextNotifications,
    isFetching: isFetchingNotifications,
  } = useLiveFeed({
    Offset: 0,
    Limit: Constants.defaultLimit,
    AccountId: company?.objectId,
    Types: notificationTypes,
    Read: unread ? false : null,
  });
  const newNotifications = useMemo(() => {
    if (!notifications) return 0;
    return notifications?.pages
      .flatMap((page) => page.data.data)
      .filter((notification) => !notification.read).length;
  }, [notifications]);
  useEffect(() => {
    callNotifications();
    refNotificationsList.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [company, notificationTypes, unread]);
  const canRefetchNotifications = useRef(true);
  useEffect(() => {
    let refetchTimeout: NodeJS.Timeout | null = null;
    CronoNotification.notificationEmitter.on('LiveFeedRefetch', () => {
      //We refetch only each 5 seconds to avoid too much calls to the BE
      if (!canRefetchNotifications.current) return;
      canRefetchNotifications.current = false;
      callNotifications();
      refetchTimeout = setTimeout(() => {
        canRefetchNotifications.current = true;
      }, 5000);
    });
    return () => {
      CronoNotification.notificationEmitter.off('LiveFeedRefetch');
      refetchTimeout && clearTimeout(refetchTimeout);
    };
  }, []);

  // Filters logic ----
  const changeFilters = (filters: PickedNotificationFilters) => {
    changeAllFilterTypes(filters.type ?? notificationTypes ?? []);
    setCompany(filters.company);
    setUnread(filters.unread ?? false);
  };

  const appliedFilters = useMemo(() => {
    const filters = { company, notificationTypes, unread };
    if (notificationTypes?.length === showFilterOptions.length) {
      filters.notificationTypes = null;
    }
    return Object.values(filters)
      .flat()
      .filter(
        (value) => value !== undefined && value !== null && value !== false,
      ).length;
  }, [company, notificationTypes, unread]);

  const rowPlaceholder = (
    <div style={{ width: '100%', padding: '16px 24px' }}>
      <img
        src={FeedPlaceholderLinkedinMessage}
        alt="Empty feed placeholder"
        style={{
          opacity: 0.49,
          width: '100%',
          padding: '5px',
        }}
      />
      <img
        src={FeedPlaceholderClickedEmail}
        alt="Empty feed placeholder"
        style={{
          opacity: 0.29,
          width: '100%',
          padding: '5px',
        }}
      />
      <img
        src={FeedPlaceholderEmailOpened}
        alt="Empty feed placeholder"
        style={{
          opacity: 0.09,
          width: '100%',
          padding: '5px',
        }}
      />
    </div>
  );

  // Filters type logic -----
  const [filterTooltipToggle, setFilterTooltipToggle] =
    useState<boolean>(false);
  const [selectedShowFilters, setSelectedShowFilters] = useState<
    NotificationType[]
  >(notificationTypes ?? Object.values(NotificationType));

  const changeAllFilterTypes = (value: NotificationType[]) => {
    setNotificationTypes(value);
    setSelectedShowFilters(value);
  };

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleShowFilters = (filter: NotificationType) => {
    setSelectedShowFilters((prev) => {
      if (prev.includes(filter)) {
        if (prev.length === 1) {
          setErrorMessage('At least one filter must be selected');
          return prev;
        }
        // remove the previous filter if it was already selected
        return [...prev].filter((op) => op !== filter);
      } else {
        // add the filter
        return [...prev, filter];
      }
    });
  };

  //Apply the filters when the tooltip is closed
  useEffect(() => {
    if (!filterTooltipToggle) {
      setNotificationTypes(selectedShowFilters);
    }
  }, [filterTooltipToggle]);
  console.log('NotoficaitonPAges', notifications?.pages);
  const showFilter = (
    <ClickAwayListener onClickAway={() => setFilterTooltipToggle(false)}>
      <ShowFilterOptionsWrapper>
        {showFilterOptions.map((option) => {
          return (
            <div
              key={option.filter}
              className="show-filter-option"
              onClick={() => handleShowFilters(option.filter)}
            >
              <label>
                <CronoCheckbox
                  checked={selectedShowFilters.includes(option.filter)}
                  sx={{
                    padding: 'inherit',
                    margin: 'inherit',
                    '&:hover': {
                      backgroundColor: colors.grey4,
                    },
                    '&.Mui-checked': {
                      '&:hover': {
                        opacity: 0.7,
                      },
                    },
                    marginRight: 1,
                  }}
                />
                {/* <Checkbox
                  icon={<IconUnchecked />}
                  checkedIcon={<CheckedIcon />}
                  indeterminateIcon={<IndeterminateIcon />}
                  
                /> */}
              </label>
              <Typography fontSize={'12px'} fontWeight={500}>
                {option.text}
              </Typography>
            </div>
          );
        })}
      </ShowFilterOptionsWrapper>
    </ClickAwayListener>
  );

  const filtersCondition = useMemo(() => {
    return (
      selectedShowFilters?.length === 0 ||
      selectedShowFilters.length === 1 ||
      selectedShowFilters.length === showFilterOptions.length
    );
  }, [selectedShowFilters]);
  const [showBringTopButtom, setShowBringTopButtom] = useState(false);
  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 30;
    if (e.target.scrollTop > 50) {
      setShowBringTopButtom(true);
    } else {
      setShowBringTopButtom(false);
    }
    if (bottom && !isFetchingNextNotifications && !isFetchingNotifications) {
      fetchNextNotifications();
    }
  };
  const refNotificationsList = useRef<HTMLDivElement>(null);
  const onClickBringTop = () => {
    refNotificationsList.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useConditionalSnackBar([
    {
      condition: !!errorMessage,
      message: errorMessage ?? 'Error',
      severity: 'error',
    },
  ]);

  const areThereNotifications: boolean = useMemo(() => {
    return (
      (notifications?.pages &&
        (notifications?.pages.at(0)?.data.data.length ?? 0) > 0) ??
      false
    );
  }, [notifications]);

  return (
    <>
      {filtersVisible &&
        createPortal(
          <BlurBackgroundDivFixed>
            <FilterModalContainer
              style={{ height: 'fit-content', minHeight: '410px' }}
            >
              <div className="add-main-container">
                <div className="modal-header">
                  <CloseTabButton close={() => setFiltersVisible(false)} />
                  <Typography
                    fontSize={'24px'}
                    fontWeight={700}
                    lineHeight={'30px'}
                  >
                    Live feed filters:
                  </Typography>
                </div>
                <FilterNotifications
                  // accountId={account?.objectId ?? null}
                  close={() => setFiltersVisible(false)}
                  handleSubmit={changeFilters}
                  pickedFilters={{
                    company: company,
                    type: notificationTypes,
                    unread: unread,
                  }}
                  changeAllFilterTypes={changeAllFilterTypes}
                />
              </div>
            </FilterModalContainer>
          </BlurBackgroundDivFixed>,
          document.body,
        )}
      <NotificationBoxWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            overflow: 'hidden',
            gap: 4,
          }}
        >
          <Typography
            fontSize={'14px'}
            fontWeight={600}
            lineHeight={'22px'}
            style={{ display: 'flex', columnGap: 8, paddingLeft: '24px' }}
          >
            Live feed{' '}
            {newNotifications ? (
              <span className="number-of">{newNotifications}</span>
            ) : null}
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              flex: 1,
              overflow: 'hidden',
              justifyContent: 'flex-end',
            }}
          >
            <CustomOptionsTooltip
              open={filterTooltipToggle}
              title={showFilter}
              maxHeight={'255px'}
              width={'100%'}
            >
              <div
                className="show-filter-container"
                style={{
                  backgroundColor: filtersCondition ? colors.white : '',
                  flex: 1,
                  overflow: 'hidden',
                  maxWidth: 130,
                }}
                onClick={() => setFilterTooltipToggle(!filterTooltipToggle)}
              >
                <Typography
                  fontSize={'14px'}
                  style={{ flex: 1 }}
                  noWrap
                  fontWeight={filtersCondition ? 400 : 500}
                >
                  {selectedShowFilters?.length === 0
                    ? 'Select an event'
                    : selectedShowFilters?.length === showFilterOptions.length
                      ? 'All events'
                      : selectedShowFilters?.length === 1
                        ? showFilterOptions.filter(
                            (option) =>
                              option.filter === selectedShowFilters[0],
                          )[0].text
                        : `${selectedShowFilters.length} selected`}
                </Typography>

                {filtersCondition ? (
                  filterTooltipToggle ? (
                    <IconTopArrow
                      className="tag-arrow"
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <IconBottomArrow
                      className="tag-arrow"
                      style={{ cursor: 'pointer' }}
                    />
                  )
                ) : (
                  <CloseMIcon
                    className="remove-text-icon"
                    style={{ cursor: 'pointer' }}
                    color={colors.grey11}
                    onClick={(ev: any) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      changeAllFilterTypes(
                        showFilterOptions.map((op) => op.filter),
                      );
                    }}
                  />
                )}
              </div>
            </CustomOptionsTooltip>
            <div
              className="filter-button"
              onClick={() => setFiltersVisible(true)}
            >
              <TuneRounded />
              {appliedFilters ? (
                <span className="number-of">{appliedFilters}</span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="notification-list">
          {loadingNotifications ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : areThereNotifications && notifications?.pages ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '4px',
                overflow: 'hidden scroll',
                position: 'relative',
              }}
              onScroll={handleScroll}
              ref={refNotificationsList}
            >
              {notifications.pages.flatMap((notificationsPage) => {
                return notificationsPage.data.data.map(
                  (notification, index) => (
                    <div key={index}>
                      <NotificationItem notification={notification} />
                      <div className="divider" />
                    </div>
                  ),
                );
              })}
              {isFetchingNextNotifications && (
                <FlexDiv>
                  <CircularProgress />
                </FlexDiv>
              )}
              {showBringTopButtom && (
                <div className="return-to-top-button" onClick={onClickBringTop}>
                  <IconTopArrow />
                </div>
              )}
            </div>
          ) : (
            <FlexDiv
              direction="column"
              position="relative"
              justifyContent="start"
              style={{
                overflow: 'hidden',
              }}
            >
              {rowPlaceholder}
              <FlexDiv
                direction="column"
                position="absolute"
                gap="30px"
                style={{
                  marginTop: '35px',
                }}
              >
                <div className="img-wrapper-xl">
                  <NoLiveFeed />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <Typography
                    fontSize={'16px'}
                    fontWeight={600}
                    lineHeight={'24px'}
                    textAlign={'center'}
                  >
                    Check your live feed here
                  </Typography>
                  <Typography
                    fontSize={'14px'}
                    fontWeight={400}
                    lineHeight={'24px'}
                    maxWidth={'290px'}
                    textAlign={'center'}
                    color={colors.grey11}
                  >
                    Make sure to costumize your suggestion preferences to
                    leverage data and receive useful tips to optimize your work
                  </Typography>
                </div>
              </FlexDiv>
            </FlexDiv>
          )}
        </div>
      </NotificationBoxWrapper>
    </>
  );
};

export default NotificationBoxTooltip;

import { useJuneAnalytics } from 'context/june';
import { useSelectedAccount } from 'pages/home/context/account';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import {
  adjustDateToTimezone,
  getError,
  htmlToText,
} from 'crono-fe-common/utils';
import { LinkedinWrapper } from './styles';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  styled,
  TextareaAutosize,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import LinkedinNotLogged from './LinkedinNotLogged';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import { useFormik } from 'formik';
import { LinkedinInputs, linkedinInvitationValidationSchema } from './types';
import { useAuth } from 'context/auth';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import { Template } from 'crono-fe-common/types/template';
import moment from 'moment';
import { colors } from 'crono-fe-common/theme';
import { useConditionalSnackBar } from 'context/snackbar';
import useLogLinkedin, {
  invalidateLogLinkedinQueries,
} from 'hooks/services/event/useLogLinkedin';
import {
  LinkedinType,
  LogLinkedinInsert,
} from 'crono-fe-common/types/logLinkedinInsert';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import TaskInvitationPending from './taskInvitationPending';
import { useConfirmModal } from 'context/confirmModal';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import {
  useLinkedinDownloadFile,
  useLinkedinGetConversation,
  useLinkedinGetInvitationsLimit,
  useLinkedinGetStatus,
  useLinkedinSendInvitation,
  useLinkedinSendMessage,
  useLinkedinSendMessageWithAttachment,
  useLinkedinSendVoiceMessage,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import {
  LinkedinConnectionStatus,
  LinkedinMessage,
} from 'crono-fe-common/types/crono-extension/linkedin';
import {
  calculateTemplateLength,
  getMissingTemplateVariables,
  prospectName,
  replaceAllTemplateVariables,
  stringAvatarProspect,
  transformToValidUrl,
} from 'utils/fe-utils';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import usePatchTask from 'hooks/services/task/usePatchTask';
import useGetProspect from 'hooks/services/prospect/useGetProspect';
import { useWindow } from 'context/sideTab';
import { Constants } from 'crono-fe-common/constants/constants';
import { useHideTaskConstruction } from '../hideTaskConstructionContext';
import IconInformationS from 'crono-fe-common/icons/Icon-Information-Small';
import AddTemplateIconS from 'crono-fe-common/icons/Icon-Add-Template-S';
import IconStarsInsights from 'crono-fe-common/icons/Icon-Stars-Insights';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import LinkedinInvalidUrl from 'pages/accountTab/linkedinView/LinkedinInvalidUrl';
import { Prospect } from 'crono-fe-common/types/prospect';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';
import useEventsCheckLinkedinPublicIds from 'hooks/services/event/useEventsCheckLinkedinPublicIds';
import LinkedinMessagesUtils from 'crono-fe-common/utils/LinkedinMessagesUtils';
import { useQueryClient } from 'react-query';
import { ReactComponent as FileAttachmentAttachment } from 'assets/images/Icon_FileAttachment.svg';
import { ReactComponent as DownloadAttachment } from 'assets/images/Icon_DownloadAttachment.svg';
import { ReactComponent as VoiceMessagePlay } from 'assets/images/Icon_VoiceMessagePlay.svg';
import { ReactComponent as VoiceMessagePause } from 'assets/images/Icon_VoiceMessagePause.svg';
import ActivitiesBoxInsideActivityView from '../activitiesInsideActivityView';
import CronoVoiceVisualizer from './cronoVoiceVisualizer';
import { ReactComponent as KeyboardIcon } from 'crono-fe-common/icons/Icon-Keyboard.svg';
import { ReactComponent as AttachmentIcon } from 'crono-fe-common/icons/Attachment.svg';
import { FileContent, useFilePicker } from 'use-file-picker';
import { FeConstants } from 'constants/FeConstants';
import { CronoAttachment } from 'crono-fe-common/types/cronoAttachment';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import MicrophoneIcon from 'crono-fe-common/icons/Icon-Microphone';
import { isFileContent } from 'pages/settings/Template/model';
import useGetTemplateAttachmentsMutate from 'hooks/services/templates/useGetTemplateAttachmentsMutate';
import useGetTemplateAttachmentsFileMutate from 'hooks/services/templates/useGetTemplateAttachmentsFileMutate';
import { CronoAttachmentWithContent } from 'crono-fe-common/types/cronoAttachmentWithContent';
import { LinkedinTabLabel } from '..';
import { RenderFeedback } from './linkedinFeedback';
import { useAttachmentsFilePicker } from 'hooks/useAttachmentsFilePicker';
import InsightsCard, {
  RewriteSubmittedContext,
} from '../emailView/insightsCard';
import TemplatesTooltipCard from '../emailView/TemplatesTooltipCard';

export const TemplateCardsContainerTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
      placement="left"
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    minWidth: 400,
    maxHeight: 600,
    border: `1px solid #D4BFFF`,
    borderRadius: 16,
    marginBlock: 16,
    padding: 0,
    paddingBottom: 12,
    WebkitBoxShadow: '0px 4px 8px 4px rgba(0, 0, 0, 0.20)',
    MozBoxShadow: '0px 4px 8px 4px rgba(0, 0, 0, 0.20)',
    OBoxShadow: '0px 4px 8px 4px rgba(0, 0, 0, 0.20)',
    BoxShadow: '0px 4px 8px 4px rgba(0, 0, 0, 0.20)',
    overflow: 'hidden',
  },
}));
import { ReactComponent as IconOpenAI } from 'assets/images/Icon_OpenAI.svg';
import IcebreakersButton from './icebreakersButton';
import useGetTemplateVariables from 'hooks/services/variables/useGetTemplateVariables';
import IconTimeZone from 'crono-fe-common/icons/Icon-Time-Zone';

interface IProps {
  task?: TaskTodo | null;
  selectedProspectId?: string | null;
  callOnSuccess?: (
    keepTemplatesOpened?: boolean,
    executeTheSame?: boolean,
  ) => void;
  suggestionId?: number | null;
  handleCloseModal: () => void;
  defaultOpenTemplateTab?: boolean;
  visibility: 'none' | 'visible';
  close: () => void;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  setLinkedinTabLabel: React.Dispatch<React.SetStateAction<LinkedinTabLabel>>;
}

export type CursorPosition = {
  selectionStart: number;
  selectionEnd: number;
};

export const TooltipIcebreakers = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: "white",
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    top: 86,
    left: 18,
    width: 400,
    minWidth: 400,
    borderRadius: 16,
    padding: 0,
    zIndex: 16000,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

const LinkedinView = ({
  task,
  selectedProspectId,
  callOnSuccess,
  suggestionId,
  handleCloseModal,
  defaultOpenTemplateTab,
  visibility,
  close,
  setTabValue,
  setLinkedinTabLabel,
}: IProps) => {
  const { user } = useAuth();
  const analytics = useJuneAnalytics();

  const { openWindow } = useWindow();

  const { account } = useSelectedAccount();

  //Prospect on which the linkedin communication is performed
  const { data: prospectFromDb, isLoading: gettingProspect } =
    useGetProspect(selectedProspectId);
  const prospect = useMemo(() => {
    if (
      prospectFromDb?.data?.data &&
      account?.objectId === prospectFromDb.data.data.accountId
    ) {
      return prospectFromDb.data.data;
    }
    return null;
  }, [prospectFromDb, account?.objectId]);

  const { setHideProgressTask } = useHideTaskConstruction();

  useEffect(() => {
    if (prospect) {
      setHideProgressTask(false);
    }
    //The dependency of task is to allow the screen to be shut down even in case of two tasks directed to the same prospect and of the same type (the prospect would be already loaded
    //and the useEffect would not be fired)
  }, [prospect, task]);

  const [getLinkedinStatusCalled, setGetLinkedinStatusCalled] = useState(false);
  // This call tells us the status of the prospect, if it is connected or not, invitation pending and also his profileId used for the conversation
  const {
    call: getLinkedinStatus,
    result: linkedinStatus,
    error: linkedinStatusError,
    isLoading: isLinkedinStatusLoading,
    resetData: resetLinkedinStatus,
  } = useLinkedinGetStatus();

  // Call to obtain the conversation with the prospect
  const {
    call: getLinkedinConversation,
    result: linkedinConversation,
    // isLoading: isLinkedinConversationLoading,
    resetData: resetLinkedinConversation,
    error: linkedinConversationError,
  } = useLinkedinGetConversation();

  useCheckLinkedinMessages(prospect, linkedinConversation?.conversation);

  //Since the process to obtain all the information has different flows depending on the status, I use this state to show the loading
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);

  //When I start the process of getting the linkedin status I set the loading page to true
  useEffect(() => {
    if (isLinkedinStatusLoading) {
      setIsLoadingPage(true);
    }
  }, [isLinkedinStatusLoading]);

  //When I get a response from the conversation API the process of loading is finished
  useEffect(() => {
    if (
      linkedinConversation ||
      linkedinConversationError ||
      linkedinStatusError
    ) {
      setIsLoadingPage(false);
    }
  }, [linkedinConversationError, linkedinConversation, linkedinStatusError]);

  //ENTRY POINT: this is where all the calls start from. When the prospect is loaded I get his status if he has linkedin, otherwise I terminate
  //the loading process (since nothing can be done, other parts of the code will take care of this)
  useEffect(() => {
    if (!prospect) return;
    //New prospect, so I reset status and conversation (to delete the conversation with the previous prospect, we had this problem in the past)
    resetLinkedinConversation();
    resetLinkedinStatus();
    setInsightsTabState(initialInsightTabState);
    if (prospect?.linkedin && prospect?.linkedin !== '') {
      getLinkedinStatus({
        url: prospect.linkedin,
        user: prospectName(prospect),
        company: account?.name ?? null,
      }).then();
      setGetLinkedinStatusCalled(true);
    }
  }, [prospect]);

  //This memo is used to always know the state of the current prospect. The profileId is used to allow all the components/useEffects to refresh
  //when a new prospect is analyzed, even if they have the same status
  const prospectLinkedinStatus: {
    prospectId: string;
    profileId: string | null;
    status: LinkedinConnectionStatus;
  } | null = useMemo(() => {
    if (!(prospect?.linkedin && prospect?.linkedin !== '') || !linkedinStatus)
      return null;
    return {
      profileId: linkedinStatus?.profileId ?? null,
      status: linkedinStatus?.status ?? 'Unknown',
      prospectId: prospect.objectId,
    };
  }, [/*prospect,*/ linkedinStatus]);

  const [prospectPatched, setProspectPatched] = useState(false);
  const { mutate: updateProspect } = useEditProspect();

  useEffect(() => {
    setProspectPatched(false);
  }, [prospect?.objectId]);

  useEffect(() => {
    if (
      !prospect ||
      !prospectLinkedinStatus ||
      prospect.objectId !== prospectLinkedinStatus.prospectId
    ) {
      return;
    }

    if (
      !prospectPatched &&
      prospect.linkedinStatus !== prospectLinkedinStatus.status
    ) {
      updateProspect({
        prospectId: prospect.objectId,
        linkedinStatus: prospectLinkedinStatus.status,
        invitationAccepted: prospect.linkedinStatus === 'InvitationSent',
      });
      setProspectPatched(true);
    }
  }, [prospect, prospectLinkedinStatus, updateProspect, prospectPatched]);

  const [tooltipVisibility, setTooltipVisibility] = useState<boolean>(true);
  const [showTemplateTab, setShowTemplateTab] = useState<boolean>(
    defaultOpenTemplateTab ?? false,
  );
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<Template | null>(null);

  useEffect(() => {
    if (!prospectLinkedinStatus) return;
    // If the users are connected, I load the conversation
    if (
      prospectLinkedinStatus.profileId &&
      prospectLinkedinStatus.status === 'Connected'
    ) {
      getLinkedinConversation({ profileId: prospectLinkedinStatus.profileId });
    } else {
      resetLinkedinConversation();
      //Nothing more to wait for
      setIsLoadingPage(false);
    }
  }, [prospectLinkedinStatus /*, isLinkedinStatusLoading*/]);

  //Task template or default invitation template
  useEffect(() => {
    if (!prospectLinkedinStatus) return;
    if (task) {
      //If the task has a template associated I set it only if it is the correct type.
      //If it is a task and there is no invitation template, I don't use the default one
      if (
        (prospectLinkedinStatus.status === 'Connected' &&
          task.template?.type === TemplateType.LINKEDIN) ||
        task.template?.type === TemplateType.SCRIPT ||
        (prospectLinkedinStatus.status === 'NotConnected' &&
          task.template?.type === TemplateType.INVITATION)
      ) {
        task.template.sequenceStepTemplateId = task.sequenceStepTemplateId;
        setSelectedTemplate(task.template);
      } else {
        setSelectedTemplate(null);
      }
    } else {
      setSelectedTemplate(null);
    }
  }, [prospectLinkedinStatus, task]);

  //Ref to scroll into view after loading the conversation (to show the last message as the users expect)
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };

  useLayoutEffect(() => {
    if (linkedinConversation && !isLoadingPage) scrollToBottom();
  }, [linkedinConversation, isLoadingPage]);

  //Show the message of too many characters for invitation
  const [longMessageError, setLongMessageError] = useState<boolean>(false);

  const { result: sendInvitationResponse, call: sendLinkedinInvitation } =
    useLinkedinSendInvitation();

  const { result: sendMessageResponse, call: sendLinkedinMessage } =
    useLinkedinSendMessage();
  const {
    result: sendMessageWithAttachmentResponse,
    call: sendLinkedinMessageWithAttachments,
  } = useLinkedinSendMessageWithAttachment();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    mutate: createLogLinkedin,
    error: logLinkedinError,
    isSuccess: isLogLinkedinSuccess,
  } = useLogLinkedin();

  //State to make the user wait while the invitation/message is sent (because then we also have to consider the log)
  //The circularProgress after the send/schedule has to be shown using this state
  const [sending, setSending] = useState<boolean>(false);

  //Log invitation + june tracks
  useEffect(() => {
    if (!sendInvitationResponse) return;
    let t: NodeJS.Timeout;
    if (sendInvitationResponse?.invitationSent) {
      if (account && prospect) {
        //If I have passed through the message but not connected screen I do not have to pass the taskId
        const taskIdToSend = (!showConnectWindow && task?.id) || null;
        const logLinkedin: LogLinkedinInsert = {
          taskTodoId: taskIdToSend,
          suggestionId: suggestionId ?? null,
          prospectId: prospect?.objectId,
          accountId: account?.objectId,
          content: formik.values.message ?? '',
          linkedinType: LinkedinType.Invitation,
          templateId: selectedTemplate?.id ?? null,
          sequenceStepTemplateId:
            selectedTemplate?.sequenceStepTemplateId ?? null,
        };
        createLogLinkedin(logLinkedin);
        //If the action performed is part of a sequence, I log it into the analytics
        if (task?.id) {
          if (analytics) {
            analytics.track('sequence-task', {});
          }
        }
      }
      if (analytics) {
        analytics.track('send-invitation', {});
      }
    } else {
      //Linkedin has a maximum amount of invitations that can be sent per week, if it is reached we show a custom error
      if (sendInvitationResponse?.invitationsLimitReached === true) {
        setErrorMessage(
          "You've reached your maximum number of Linkedin invitations",
        );
      } else if (sendInvitationResponse?.connectionRecentlyWithdrawn === true) {
        setErrorMessage(
          'Invitation failed. You can’t send this invitation as it was recently withdrawn',
        );
      } else {
        setErrorMessage('Linkedin error');
      }
      setSending(false);
      t = setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [sendInvitationResponse]);

  const logLinkedinMessage = (content: string) => {
    if (account && prospect) {
      //If I have passed through the message but not connected screen I do not have to pass the taskId
      const taskIdToSend = (!showConnectWindow && task?.id) || null;
      const logLinkedin: LogLinkedinInsert = {
        taskTodoId: taskIdToSend,
        suggestionId: suggestionId ?? null,
        prospectId: prospect?.objectId,
        accountId: account?.objectId,
        content: content ?? '',
        linkedinType: LinkedinType.Message,
        templateId: selectedTemplate?.id ?? null,
        sequenceStepTemplateId:
          selectedTemplate?.sequenceStepTemplateId ?? null,
      };
      createLogLinkedin(logLinkedin);
      //If the action performed is part of a sequence, I log it into the analytics
      if (task?.id) {
        if (analytics) {
          analytics.track('sequence-task', {});
        }
      }
    }
    if (analytics) {
      analytics.track('send-linkedin', {});
    }
  };

  //Log message + june tracks
  useEffect(() => {
    if (!sendMessageResponse) return;
    let t: NodeJS.Timeout;
    if (sendMessageResponse?.messageSent) {
      logLinkedinMessage(formik.values.message);
    } else {
      setErrorMessage('Linkedin error');
      t = setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      setSending(false);
    }
    return () => {
      clearTimeout(t);
    };
  }, [sendMessageResponse]);

  useEffect(() => {
    if (!sendMessageWithAttachmentResponse) return;
    let t: NodeJS.Timeout;
    if (sendMessageWithAttachmentResponse?.messageSent) {
      logLinkedinMessage(formik.values.message);
    } else {
      setErrorMessage('Linkedin error');
      t = setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      setSending(false);
    }
    return () => {
      clearTimeout(t);
    };
  }, [sendMessageWithAttachmentResponse]);

  useEffect(() => {
    //If success I switch to the company tab
    //If the action performed went well, I call the callOnSuccess function (for example for the suggestion)
    if (isLogLinkedinSuccess) {
      //If I come from the message not connected screen I have to reschedule the task
      if (showConnectWindow && task?.id) {
        let currentDate = new Date();
        currentDate = moment(currentDate).businessAdd(5).toDate();
        //The callOnSuccess is called after the patching
        patchTask({
          id: task.id,
          activityDate: currentDate,
        });
        if (analytics) {
          analytics.track('patch-task', {
            type: 'reschedule',
          });
        }
        return;
      } else if (callOnSuccess) {
        callOnSuccess(showTemplateTab);
      }
      //I close also the linkedinTab
      handleCloseModal();
    }
  }, [isLogLinkedinSuccess]);

  const { mutateAsync: getTemplates } = useGetTemplateAttachmentsFileMutate();

  const [rewriteSubmittedContext, setRewriteSubmittedContext] =
    useState<RewriteSubmittedContext | null>(null);

  const formik = useFormik<LinkedinInputs>({
    initialValues: {
      message: '',
    },
    validationSchema: linkedinInvitationValidationSchema,

    onSubmit: async (values) => {
      if (
        prospectLinkedinStatus?.status === 'NotConnected' &&
        isMaxCharOverLimit()
      ) {
        setLongMessageError(true);
        // turn off after 3 second
        setTimeout(() => {
          setLongMessageError(false);
        }, 3000);
      } else if (user && prospect?.linkedin) {
        const text = values.message;

        if (rewriteSubmittedContext && analytics) {
          analytics.track('sent-with-rewrite', {
            ...rewriteSubmittedContext,
            actualMessage: text,
          });
          setRewriteSubmittedContext(null);
        }

        if (prospectLinkedinStatus?.status === 'NotConnected') {
          sendLinkedinInvitation({
            message: text,
            user: prospectName(prospect),
            url: prospect.linkedin,
            company: account?.name ?? null,
          }).then();
          setSending(true);
        } else {
          setSending(true);
          // If already connected -> message
          if (files.length > 0) {
            const attachmentsToLoad = files.filter(
              (file) => !isFileContent(file),
            ) as CronoAttachment[];
            let templates: CronoAttachmentWithContent[] = [];
            //If we have templates to load we load them, otherwise we skip to save the await time
            if (attachmentsToLoad.length > 0) {
              const templatesResponse = await getTemplates(
                attachmentsToLoad.map((attachment) => attachment.id),
              );
              if (!templatesResponse.data?.isSuccess) {
                setErrorOnLoadAttachments('Error loading attachments');
                setSending(false);
                setTimeout(() => {
                  setErrorOnLoadAttachments(null);
                }, 3000);
                return;
              }
              templates = templatesResponse.data?.data ?? [];
            }
            sendLinkedinMessageWithAttachments({
              publicId:
                LinkedinUrlUtils.getIdFromLinkedinProfileUrl(
                  prospect.linkedin,
                ) ?? undefined,
              url: prospect.linkedin,
              text: text ?? '',
              fileName: files.map((file) => file.name),
              attachmentUrlData: files
                .filter((file) => isFileContent(file))
                .map((file) => (file as any as FileContent).content)
                .concat(
                  templates
                    .filter((template) => template.fileContent)
                    .map((template) => template.fileContent!) ?? [],
                ),
            });
          } else if (text !== '') {
            sendLinkedinMessage({
              message: text ?? '',
              user: prospectName(prospect),
              url: prospect.linkedin,
              company: account?.name ?? null,
            }).then();
            setSending(true);
          }
        }
      }
    },
  });
  //Check if the linkedin message is over the limit (used for the invitations only)
  const isMaxCharOverLimit = () => {
    return formik.values.message.length > maxLinkedinInvitationChars;
  };

  const [maxLinkedinInvitationChars, setMaxLinkedinInvitationChars] =
    useState<number>(Constants.linkedinInvitationMaxChars);

  const { call: getLinkedinInvitationLimits } =
    useLinkedinGetInvitationsLimit();

  const [invitationRemaining, setInvitationRemaining] = useState<number | null>(
    null,
  );

  useEffect(() => {
    getLinkedinInvitationLimits()
      .then((linkedinInvitationLimits) => {
        // console.log("Inv", linkedinInvitationLimits);
        setMaxLinkedinInvitationChars(
          linkedinInvitationLimits?.maxMessageLength ??
            Constants.linkedinInvitationMaxChars,
        );
        setInvitationRemaining(
          linkedinInvitationLimits?.invitationsRemaining ?? null,
        );
      })
      .catch((err) => {
        console.log('Error', err);
      });
  }, []);

  //If true show the task not executable due to invitation pending view, that set this state to false when closed
  const [
    showTaskNotExecutableDueIntivation,
    setShowTaskNotExecutableDueIntivation,
  ] = useState<boolean>(true);

  //When the task changes I reset the states related to the task
  useEffect(() => {
    if (task) {
      setShowTaskNotExecutableDueIntivation(true);
      setShowConnectWindow(false);
    }
  }, [task]);

  const { mutateAsync: loadAttachments } = useGetTemplateAttachmentsMutate();
  const { data: templateVariables } = useGetTemplateVariables();

  useEffect(() => {
    //To delete the template during the loading, since it may be releated to the previous contact
    if (!prospectLinkedinStatus || !selectedTemplate || isLoadingPage) {
      formik.setFieldValue('message', '');
      setFiles([]);
      return;
    }
    let content = htmlToText(selectedTemplate?.content || '');

    content = replaceAllTemplateVariables(
      content ?? '',
      prospect ?? null,
      account ?? null,
      user ?? null,
      templateVariables?.data?.data ?? null,
    );

    formik.setFieldValue('message', content);
    loadAttachments(selectedTemplate.id).then((response) => {
      let newFiles = files;
      newFiles = files.filter((file) => isFileContent(file));
      setFiles(newFiles.concat(response.data?.data ?? []));
    });
  }, [selectedTemplate, prospectLinkedinStatus, isLoadingPage]);

  const [showIcebreakersTab, setShowIcebreakersTab] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState<CursorPosition>({
    selectionStart: 0,
    selectionEnd: 0,
  });

  const handleSelectIcebreaker = (icebreaker: string) => {
    const previousValue = formik.values.message ?? '';

    // Insert the icebreaker at the cursor position:
    const modifiedMessage =
      previousValue.substring(0, cursorPosition.selectionStart) +
      icebreaker +
      previousValue.substring(cursorPosition.selectionEnd);

    formik.setFieldValue('message', modifiedMessage);

    setShowIcebreakersTab(false);
  };

  const {
    mutate: patchTask,
    error: errorPatchingTask,
    isSuccess: taskPatched,
    variables: variablesPatchTask,
    isLoading: patchingTask,
  } = usePatchTask();

  const { openModal: openConfirmModal } = useConfirmModal();

  //Check if any of the variables from template is missing, show confirm modal in case
  const handleClickSubmit = () => {
    const missingVariables: string[] = getMissingTemplateVariables(
      formik.values.message ?? '',
    );
    if (missingVariables.length > 0) {
      openConfirmModal({
        title: 'Variable value missing',
        text: `${
          missingVariables.length === 1 ? 'Variable' : 'Variables'
        } ${missingVariables.map((variable) => variable).join(', ')} ${
          missingVariables.length === 1 ? 'is' : 'are'
        } missing from the message, are you sure you want to continue?`,
        confirmText: `Send anyway`,
        cancelText: 'Go back',
        cancelFunction: () => null,
        confirmFunction: () => {
          formik.handleSubmit();
        },
      });
    } else {
      formik.handleSubmit();
    }
  };

  //Function called when the next button is pressed if the task is invitation but the
  //prospect is already connected
  const handleGoNextTask = () => {
    if (task?.id) {
      patchTask({
        id: task.id,
        completed: true,
      });
      if (analytics) {
        analytics.track('sequence-task', {
          type: 'completed',
        });
        analytics.track('patch-task', {
          type: 'completed',
        });
      }
    }
  };
  //After patching I call the callOnSuccess function
  useEffect(() => {
    if (taskPatched && callOnSuccess) {
      setSending(false);
      //If the patch comes from a change of subtype it means that we want to execute the same again
      callOnSuccess(false, !!variablesPatchTask?.subtype);
      //I close also the linkedinTab
      handleCloseModal();
    }
  }, [taskPatched]);

  //State to pass from the view when there is a message task but not already connected
  //to the send invitation
  const [showConnectWindow, setShowConnectWindow] = useState<boolean>(false);

  const { setSelectedProspectId } = useSelectedAccount();

  const showTextareaContainer = () => {
    // will be hidden when:
    // Contact url is broken -> BrokenContactLinkedinUrl
    // loading linkedin status -> isLinkedinStatusLoading
    // Profile error or no linkedinStatus -> LinkedinNotLogged
    // invitation sent but not accepted yet -> PendingLinkedinInvitation
    return !(
      !prospect?.linkedin ||
      isLinkedinStatusLoading ||
      linkedinStatusError === 'profileError' ||
      !linkedinStatus ||
      prospectLinkedinStatus?.status === 'InvitationSent'
    );
  };

  // -----Voice notes logic-------
  const { call: sendVoiceMessage, result: sendVoiceMessageResponse } =
    useLinkedinSendVoiceMessage();
  const [isVoiceNoteRecorded, setIsVoiceNoteRecorded] = useState(false);
  const [isRecordingInProgress, setIsRecordingInProgress] = useState(false);
  //This has to change for the manual tasks
  const [isVoiceNote, setIsVoiceNote] = useState<boolean>(false);

  //We have to keep the label in the tab updated with the status of the task
  useEffect(() => {
    if (isVoiceNote) {
      setLinkedinTabLabel('Voicenote');
    } else if (
      task?.subtype === TaskTodoSubtype.LinkedinInvitation ||
      (!task && prospectLinkedinStatus?.status === 'NotConnected') ||
      showConnectWindow
    ) {
      setLinkedinTabLabel('Invitation');
    } else {
      setLinkedinTabLabel('Message');
    }
  }, [isVoiceNote, task, prospectLinkedinStatus, showConnectWindow]);

  //Initial value of the voice note based on the task
  useEffect(() => {
    setIsVoiceNote(
      prospectLinkedinStatus?.status !== 'NotConnected' &&
        task?.subtype === TaskTodoSubtype.LinkedinVoiceNote,
    );
  }, [prospectLinkedinStatus, task]);
  //Remove attachments when moving to voice note
  useEffect(() => {
    if (isVoiceNote) {
      setFiles([]);
    }
  }, [isVoiceNote]);

  const onRecordingStart = () => {
    setIsRecordingInProgress(true);
  };

  const onRecordingEnd = () => {
    setIsRecordingInProgress(false);
  };

  // Log voice note
  useEffect(() => {
    if (!sendVoiceMessageResponse) return;
    let t: NodeJS.Timeout;
    if (sendVoiceMessageResponse?.messageSent) {
      if (analytics) {
        analytics.track('send-linkedin-voicenote');
      }
      logLinkedinMessage('LinkedIn Voice Note');
    } else {
      setErrorMessage('Linkedin error');
      t = setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      setSending(false);
    }
    return () => {
      clearTimeout(t);
    };
  }, [sendVoiceMessageResponse]);

  const convertTaskToLinkedinMessage = () => {
    if (task) {
      patchTask({
        id: task.id,
        subtype: TaskTodoSubtype.LinkedinMessage,
      });
      if (analytics) {
        analytics.track('patch-task', {
          type: 'subtype',
        });
      }
    } else {
      setIsVoiceNote(false);
    }
  };

  const convertTaskToLinkedinVoiceNote = () => {
    if (task) {
      patchTask({
        id: task.id,
        subtype: TaskTodoSubtype.LinkedinVoiceNote,
      });
      if (analytics) {
        analytics.track('patch-task', {
          type: 'subtype',
        });
      }
    } else {
      setIsVoiceNote(true);
    }
  };

  //Attachments
  const [openFileSelector, { filesContent, errorMessage: errorUploadFile }] =
    useAttachmentsFilePicker();

  const [errorOnLoadAttachments, setErrorOnLoadAttachments] = useState<
    string | null
  >(null);

  const [files, setFiles] = useState<(FileContent | CronoAttachment)[]>([]);

  const setAttachments = (files: FileContent[]) => {
    if (files && files.length > 0) {
      setFiles((prev) => prev.concat(files));
    }
  };

  useEffect(() => {
    setAttachments(filesContent);
  }, [filesContent]);

  const handleAttachments = (files: File[]) => {
    if (files.length > 0) {
      const fileContents: FileContent[] = [];
      let filesRead = 0;

      files.forEach((file: any) => {
        const reader = new FileReader();
        reader.onload = () => {
          // it was not working with ...file
          fileContents.push({
            name: file.name,
            size: file.size,
            type: file.type,
            slice: file.slice,
            text: file.text,
            stream: file.stream,
            arrayBuffer: file.arrayBuffer,
            lastModified: file.lastModified,
            content: reader.result as string,
          });
          filesRead++;

          // Check if all files have been read
          if (filesRead === files.length) {
            setAttachments(fileContents);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const { dataTransfer } = event;

    if (dataTransfer && dataTransfer.files && dataTransfer.files.length > 0) {
      const files = Array.from(dataTransfer.files) as File[];
      handleAttachments(files);
    }
  };

  const removeAttachment = (index: number) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  useConditionalSnackBar([
    {
      condition: !!errorMessage,
      message: errorMessage ?? 'Linkedin error',
      severity: 'error',
    },
    {
      condition: !!logLinkedinError,
      message: getError(logLinkedinError) ?? 'Linkedin error',
      severity: 'error',
    },
    {
      condition: longMessageError,
      message: `Invitation message can have a maximum of ${maxLinkedinInvitationChars} characters`,
      severity: 'error',
    },
    {
      condition: !!isLogLinkedinSuccess,
      message: 'Linkedin logged successfully',
      severity: 'success',
    },
    {
      condition: !!errorPatchingTask,
      message: getError(errorPatchingTask) ?? 'Error while updating task',
      severity: 'error',
    },
    {
      condition: !!errorUploadFile,
      message: errorUploadFile ?? 'Error uploading file',
      severity: 'error',
    },
    {
      condition: !!errorOnLoadAttachments,
      message:
        errorOnLoadAttachments ?? 'Error on loading template attachments',
      severity: 'error',
    },
  ]);
  // Insights logic --------------
  const initialInsightTabState = {
    isOpened: false,
    rewriteEnabled: false,
  };

  const [insightsTabState, setInsightsTabState] = useState(
    initialInsightTabState,
  );

  const toggleInsightTab = () => {
    setInsightsTabState((prev) => {
      if (!prev.isOpened) {
        if (analytics) {
          analytics.track('open-insights', {});
        }
      }

      return {
        isOpened: !prev.isOpened,
        // we set to initial state (false) on insights close to keep all logic persistant
        rewriteEnabled: false,
      };
    });
  };

  const onRewriteWithAIClick = () => {
    setInsightsTabState((prev) => ({
      isOpened: !(prev.isOpened && prev.rewriteEnabled),
      rewriteEnabled: !(prev.isOpened && prev.rewriteEnabled),
    }));
  };

  const [selectedText, setSelectedText] = useState<string | undefined>(
    undefined,
  );
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [selection, setSelection] = useState({ start: 0, end: 0 });

  const handleSelect = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      const start = textArea.selectionStart;
      const end = textArea.selectionEnd;

      if (start === end) {
        setSelection({ start: 0, end: textArea.value.length });
        setSelectedText(textArea.value);
      } else {
        setSelection({ start, end });
        setSelectedText(textArea.value.substring(start, end));
      }
    }
  };

  //HACK to trigger handleSelect function when needed
  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea && insightsTabState.isOpened) {
      textArea.setSelectionRange(0, 0);
      handleSelect();
    }
  }, [insightsTabState.isOpened, selectedTemplate]);

  const showSelection = () => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.focus();
      textArea.setSelectionRange(selection.start, selection.end);
    }
  };

  const replaceSelectedText = (text: string) => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.focus();
      const textBeforeSelection = formik.values.message.slice(
        0,
        selection.start,
      );
      const textAfterSelection = formik.values.message.slice(selection.end);

      const newText = textBeforeSelection + text + textAfterSelection;

      formik.setFieldValue('message', newText);
    }
  };

  const currentDate = useMemo(() => {
    if (!prospect?.timeZone) return;
    return adjustDateToTimezone(new Date(), prospect.timeZone);
  }, [prospect, prospect?.timeZone]);

  return (
    <>
      {/*Insight shown on the left side of the tab */}
      {insightsTabState.isOpened && (
        <InsightsCard
          close={() => setInsightsTabState(initialInsightTabState)}
          prospect={prospect}
          account={account}
          rewriteEnabled={insightsTabState.rewriteEnabled}
          wholeMessage={formik.values.message}
          partToRewrite={selectedText}
          showSelection={showSelection}
          replaceSelectedText={replaceSelectedText}
          setRewriteSubmittedContext={setRewriteSubmittedContext}
        />
      )}
      {/*Templates shown on the left side of the tab */}
      {/* {showTemplateTab && (
        <TemplatesCard
          accountId={account?.objectId}
          prospectId={prospect?.objectId}
          selectedTemplateId={selectedTemplate?.id ?? null}
          setSelectedTemplate={(template: Template) =>
            setSelectedTemplate(template)
          }
          hideShared={false}
          hideAll={false}
          selectedType={
            prospectLinkedinStatus?.status === 'NotConnected'
              ? TemplateType.INVITATION
              : isVoiceNote
                ? TemplateType.SCRIPT
                : TemplateType.LINKEDIN
          }
          showAnalytics={true}
          close={() => setShowTemplateTab(false)}
          account={account}
          prospect={prospect}
          visibility={visibility}
          sequenceStepTemplateId={task?.sequenceStepTemplateId}
        />
      )} */}
      <LinkedinWrapper
        id="linkedin-view-component"
        style={{
          justifyContent: 'space-between',
          position: 'relative',
          display: visibility === 'visible' ? undefined : 'none',
          height: showTextareaContainer() ? undefined : '100%',
          paddingLeft: 18,
          paddingTop: 16,
          paddingRight: 8,
        }}
      >
        {/*If I'm loading the prospect I show the circular progress. This also prevents non complete status to be shown to the user (
          like a message without the variables replaced) */}
        {gettingProspect ? (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        ) : //If prospectLinkedinStatus is decided (so no loading) and the status is unknown I show the no result page
        prospectLinkedinStatus !== null &&
          prospectLinkedinStatus?.status === 'Unknown' &&
          !isLoadingPage ? (
          <RenderFeedback
            type="brokenLinkedinFeedback"
            actionMainButton={() => {
              setSelectedProspectId(selectedProspectId ?? null);
              openWindow({
                windowType: 'account',
                selectedProspectId: selectedProspectId ?? undefined,
                tab: 'prospect',
              });
            }}
          />
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                paddingBottom: 8,
                width: '100%',
                height: 'fit-content',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom:
                  prospectLinkedinStatus?.status === 'Connected'
                    ? `1px solid ${colors.grey44}`
                    : undefined,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  marginRight: 12,
                  overflow: 'hidden',
                  maxWidth: '70%',
                  alignItems: 'center',
                  flex: 1,
                }}
              >
                <Avatar
                  key={prospect?.objectId}
                  className="prospect-avatar-row"
                >
                  {stringAvatarProspect(prospect)}
                </Avatar>

                <div
                  style={{
                    marginRight: 12,
                    overflow: 'hidden',
                    flex: 1,
                    width: 'fit-content',
                    minWidth: 0,
                    maxWidth: 'fit-content',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 4,
                      width: '100%',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontSize={14}
                      lineHeight={'18px'}
                      fontWeight={600}
                      noWrap
                    >
                      {prospect?.name}
                    </Typography>

                    <Tooltip
                      title={
                        !prospect?.linkedin
                          ? 'No Linkedin profile URL found'
                          : ''
                      }
                      placement="top"
                      arrow
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          style={{ width: 20, height: 20, padding: 2 }}
                          disabled={!prospect?.linkedin}
                          onClick={() => {
                            if (!prospect?.linkedin) return;
                            const url = transformToValidUrl(prospect?.linkedin);
                            if (url) window.open(url, '_blank');
                          }}
                        >
                          <LinkedinIcon
                            color={
                              !prospect?.linkedin ? colors.grey3 : colors.blue
                            }
                          />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 4,
                      width: '100%',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontSize={12}
                      color={colors.grey11}
                      lineHeight={'16px'}
                      noWrap
                    >
                      {prospect?.title}
                    </Typography>
                    {prospect?.timeZone && (
                      <>
                        <div className="small-divider" />
                        <IconTimeZone className="timezone-icon" />
                        <Typography
                          fontSize={12}
                          lineHeight={'16px'}
                          color={colors.grey11}
                        >
                          {moment.parseZone(currentDate).format('HH:mm')} local
                          time
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                {invitationRemaining !== null &&
                  prospectLinkedinStatus?.status === 'NotConnected' && (
                    <div
                      className={`linkedin-invitations-remaining-banner ${
                        invitationRemaining > 0
                          ? 'some-invitations-left'
                          : 'no-invitations-left'
                      }`}
                    >
                      <IconInformationS
                        color={
                          invitationRemaining > 0
                            ? colors.intenseBlue
                            : colors.inactive
                        }
                      />
                      <Typography>
                        <span style={{ fontWeight: 600, lineHeight: '22px' }}>
                          {invitationRemaining}
                        </span>{' '}
                        personalized invitations left
                      </Typography>
                    </div>
                  )}
                <ActivitiesBoxInsideActivityView
                  setTabValue={setTabValue}
                  selectedProspectId={
                    prospect?.objectId ??
                    task?.prospectId ??
                    task?.prospect.objectId ??
                    null
                  }
                />
              </div>
            </div>
            {/*Screens for special tasks situation, such as incompatibility between the expected action to perform and the linkedin status of the prospect */}
            {
              //If we have a linkedin task to execute but the invitation is pending
              task &&
              prospectLinkedinStatus?.status === 'InvitationSent' &&
              (task.subtype === TaskTodoSubtype.LinkedinMessage ||
                !task.subtype) &&
              showTaskNotExecutableDueIntivation ? (
                <div className="all-screen-linkedin-view">
                  <TaskInvitationPending
                    task={task}
                    close={() => setShowTaskNotExecutableDueIntivation(false)}
                    callOnSuccess={callOnSuccess}
                  />
                </div>
              ) : task &&
                task.subtype === TaskTodoSubtype.LinkedinInvitation &&
                prospectLinkedinStatus?.status === 'Connected' ? (
                <RenderFeedback
                  type="alreadyConnectedFeedback"
                  actionMainButton={handleGoNextTask}
                  loadingMainButton={patchingTask}
                />
              ) : //Task invitation and invitation sent but not accepted
              task &&
                task.subtype === TaskTodoSubtype.LinkedinInvitation &&
                prospectLinkedinStatus?.status === 'InvitationSent' ? (
                <RenderFeedback
                  type="completePendingInvitationFeedback"
                  actionMainButton={handleGoNextTask}
                  loadingMainButton={patchingTask}
                /> //The task is a linkedinMessage but the contact is not connected
              ) : task &&
                (task.subtype === TaskTodoSubtype.LinkedinMessage ||
                  //This checks if there is a task and no subtype is present we consider it a message
                  !task.subtype) &&
                prospectLinkedinStatus?.status === 'NotConnected' &&
                !showConnectWindow ? (
                <RenderFeedback
                  type="cantPerformTaskFeedback"
                  actionMainButton={() => setShowConnectWindow(true)}
                />
              ) : (
                <div
                  style={{
                    width: '100%',
                    position: 'relative',
                    height:
                      prospectLinkedinStatus?.status === 'Connected' ||
                      showTemplateTab
                        ? 'calc(100% - 42px)'
                        : '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {/*Message, depending on the status */}
                  {!prospect?.linkedin ? (
                    <RenderFeedback
                      type="brokenLinkedinFeedback"
                      actionMainButton={() => {
                        setSelectedProspectId(selectedProspectId ?? null);
                        openWindow({
                          windowType: 'account',
                          selectedProspectId: selectedProspectId ?? undefined,
                          tab: 'prospect',
                        });
                      }}
                    />
                  ) : isLinkedinStatusLoading ? (
                    <></>
                  ) : linkedinStatusError === 'missing username or profile' ? (
                    <LinkedinInvalidUrl handleCloseModal={handleCloseModal} />
                  ) : getLinkedinStatusCalled &&
                    (linkedinStatusError === 'profileError' ||
                      !linkedinStatus) ? (
                    <LinkedinNotLogged close={close} />
                  ) : prospectLinkedinStatus?.status === 'NotConnected' ? (
                    <RenderFeedback type="notLinkedinConnectedFeedback" />
                  ) : prospectLinkedinStatus?.status === 'InvitationSent' ? (
                    <RenderFeedback type="connectionRequestPendingFeedback" />
                  ) : !isLoadingPage &&
                    (!linkedinConversation?.conversation ||
                      !linkedinConversation?.conversation.length) ? (
                    <RenderFeedback type="noPreviousMessageFeedback" />
                  ) : null}

                  {/*Conversation */}
                  {isLoadingPage ? (
                    <FlexDiv>
                      <CircularProgress />
                    </FlexDiv>
                  ) : linkedinConversation?.conversation &&
                    linkedinConversation?.conversation.length > 0 ? (
                    <LinkedinConversation
                      conversation={linkedinConversation?.conversation}
                      messagesEndRef={messagesEndRef}
                    />
                  ) : null}
                  {/*Hidden in voiceNote to give the best experience to the user. They are removed also by the useEffect, this is a security check */}
                  {files && files.length > 0 && !isVoiceNote ? (
                    <div className="file-preview">
                      {files.map((file, index) => (
                        <div className={`file`} key={index}>
                          <CloseMIcon
                            className="delete-attachments-icon"
                            onClick={() => {
                              removeAttachment(index);
                            }}
                          />
                          {file.name}
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {/*Textarea */}
                  {showTextareaContainer() ? (
                    <div
                      style={{
                        height: isVoiceNoteRecorded ? 120 : 245,
                        minHeight: isVoiceNoteRecorded ? 120 : 245,
                      }}
                      className="textarea-container"
                    >
                      <div
                        className="header-textarea"
                        style={{
                          justifyContent: isVoiceNote
                            ? 'space-between'
                            : 'flex-end',
                        }}
                      >
                        {isVoiceNote && (
                          <CronoVoiceVisualizer
                            setIsAudioRecorded={setIsVoiceNoteRecorded}
                            onSend={async (recording, durationSeconds) => {
                              setSending(true);
                              await sendVoiceMessage({
                                url:
                                  task?.prospect.linkedin ?? prospect?.linkedin,
                                voiceMessageUrlData: recording,
                                //The API requires milliseconds
                                duration: durationSeconds * 1000,
                              });
                            }}
                            onStartRecording={onRecordingStart}
                            onClear={onRecordingEnd}
                            isSending={sending}
                          />
                        )}

                        {isVoiceNote &&
                          !isRecordingInProgress &&
                          !isVoiceNoteRecorded && (
                            <Tooltip
                              arrow
                              placement="top"
                              title={'Switch task to LinkedIn message'}
                              enterDelay={800}
                              enterNextDelay={800}
                            >
                              <div
                                className={'convert-button'}
                                onClick={convertTaskToLinkedinMessage}
                              >
                                <KeyboardIcon />
                                <Typography fontSize={'14px'}>ABC</Typography>
                              </div>
                            </Tooltip>
                          )}

                        {(task?.subtype === TaskTodoSubtype.LinkedinMessage ||
                          //If no subtype is considered linkedinMessage
                          (!task && !isVoiceNote) ||
                          (task && !task.subtype)) &&
                          prospectLinkedinStatus?.status === 'Connected' && (
                            <Tooltip
                              arrow
                              placement="top"
                              title={'Switch task to LinkedIn Voice Note'}
                              enterDelay={800}
                              enterNextDelay={800}
                            >
                              <div
                                className={'convert-button'}
                                onClick={convertTaskToLinkedinVoiceNote}
                              >
                                <MicrophoneIcon color={colors.black} />
                              </div>
                            </Tooltip>
                          )}

                        {!isRecordingInProgress && !isVoiceNoteRecorded && (
                          <>
                            {prospectLinkedinStatus?.status === 'Connected' &&
                              !isVoiceNote && (
                                <div
                                  className="template-button-container"
                                  onClick={openFileSelector}
                                  style={{
                                    padding: '7px',
                                    marginRight: '8px',
                                  }}
                                >
                                  <AttachmentIcon
                                    style={{ width: '18px', height: '18px' }}
                                  />
                                </div>
                              )}

                            <TemplateCardsContainerTooltip
                              title={
                                <TemplatesTooltipCard
                                  accountId={account?.objectId}
                                  prospectId={prospect?.objectId}
                                  selectedTemplateId={
                                    selectedTemplate?.id ?? null
                                  }
                                  setSelectedTemplate={(template: Template) =>
                                    setSelectedTemplate(template)
                                  }
                                  hideShared={false}
                                  hideAll={false}
                                  selectedType={
                                    prospectLinkedinStatus?.status ===
                                    'NotConnected'
                                      ? TemplateType.INVITATION
                                      : isVoiceNote
                                        ? TemplateType.SCRIPT
                                        : TemplateType.LINKEDIN
                                  }
                                  showAnalytics={true}
                                  close={() => {
                                    setShowTemplateTab(false);
                                    setTooltipVisibility(true);
                                  }}
                                  account={account}
                                  prospect={prospect}
                                  visibility={visibility}
                                  sequenceStepTemplateId={
                                    task?.sequenceStepTemplateId
                                  }
                                />
                              }
                              onClose={() => setShowTemplateTab(false)}
                              open={showTemplateTab}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                            >
                              <div
                                className="template-button-container templates-button"
                                onClick={() => {
                                  setShowTemplateTab((prev) => !prev);
                                }}
                              >
                                <span className="flex-center icon-wrapper">
                                  <AddTemplateIconS
                                    className={'template-button-icon'}
                                    color={colors.black}
                                  />
                                </span>
                                <Typography fontSize={'12px'}>
                                  Templates
                                </Typography>
                                {task?.template?.sequenceStepTemplateId ? (
                                  <Typography
                                    fontWeight={500}
                                    fontSize={'12px'}
                                    color={colors.purpleHover}
                                    className="ab-tag"
                                  >
                                    A/B
                                  </Typography>
                                ) : null}
                              </div>
                            </TemplateCardsContainerTooltip>
                          </>
                        )}

                        <div
                          className="template-button-container insights-button"
                          onClick={toggleInsightTab}
                        >
                          <span className="flex-center icon-wrapper">
                            <IconStarsInsights
                              className={'template-button-icon'}
                              color={colors.black}
                              viewBox="0 0 16 16"
                            />
                          </span>
                          <Typography fontSize={'12px'}>Insights</Typography>
                        </div>
                      </div>
                      {!isVoiceNoteRecorded && (
                        <form onSubmit={formik.handleSubmit}>
                          <TextareaAutosize
                            ref={textAreaRef}
                            id="textarea"
                            minRows={5}
                            maxRows={5}
                            className="textarea dark-scrollbar"
                            value={formik.values.message}
                            onDrop={
                              isVoiceNote ||
                              linkedinStatus?.status === 'NotConnected'
                                ? undefined
                                : task?.subtype ===
                                      TaskTodoSubtype.LinkedinMessage ||
                                    !task?.subtype
                                  ? handleDrop
                                  : undefined
                            }
                            placeholder={
                              isVoiceNote
                                ? 'Insert a text for script...'
                                : 'Insert a message...'
                            }
                            onSelect={handleSelect}
                            onChange={(ev) => {
                              formik.setFieldValue('message', ev.target.value);
                              setCursorPosition({
                                selectionStart: ev.target.selectionStart,
                                selectionEnd: ev.target.selectionEnd,
                              });
                            }}
                            onClick={(ev) => {
                              const target = ev.target as HTMLInputElement;
                              setCursorPosition({
                                selectionStart: target.selectionStart ?? 0,
                                selectionEnd: target.selectionEnd ?? 0,
                              });
                            }}
                            onKeyDown={(ev) => {
                              const target = ev.target as HTMLInputElement;
                              setCursorPosition({
                                selectionStart: target.selectionStart ?? 0,
                                selectionEnd: target.selectionEnd ?? 0,
                              });
                            }}
                            disabled={
                              prospectLinkedinStatus?.status ===
                                'InvitationSent' || isRecordingInProgress
                            }
                          />
                          {!isVoiceNote &&
                            prospect?.linkedin &&
                            (sending || isLoadingPage ? (
                              <div className="send-invitation-button send-invitation-button--margin">
                                <CircularProgress />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                  columnGap: 8,
                                  padding: 8,
                                  marginTop: 4,
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 8,
                                  }}
                                >
                                  <MainButton
                                    className="send-invitation-button"
                                    disabled={
                                      prospectLinkedinStatus?.status ===
                                        'InvitationSent' ||
                                      (prospectLinkedinStatus?.status ===
                                        'NotConnected' &&
                                        calculateTemplateLength(
                                          formik.values.message,
                                          false,
                                        ) > maxLinkedinInvitationChars)
                                    }
                                    onClick={handleClickSubmit}
                                  >
                                    {prospectLinkedinStatus?.status ===
                                    'NotConnected'
                                      ? 'Connect'
                                      : 'Send'}
                                  </MainButton>
                                  {prospectLinkedinStatus?.status ===
                                    'NotConnected' && (
                                    <Typography
                                      fontSize={12}
                                      fontWeight={500}
                                      lineHeight={'18px'}
                                      color={
                                        (calculateTemplateLength(
                                          formik.values.message,
                                          false,
                                        ) ?? 0) > maxLinkedinInvitationChars
                                          ? colors.inactive
                                          : colors.grey11
                                      }
                                    >
                                      {calculateTemplateLength(
                                        formik.values.message,
                                        false,
                                      ) ?? 0}
                                      /{maxLinkedinInvitationChars}
                                    </Typography>
                                  )}
                                </div>
                                {prospectLinkedinStatus?.status ===
                                  'Connected' && user ? (
                                  <button
                                    type={'button'}
                                    className={'rewrite-with-ai-button'}
                                    onClick={onRewriteWithAIClick}
                                  >
                                    <IconOpenAI />
                                    <p>Rewrite with AI</p>
                                  </button>
                                ) : (
                                  <IcebreakersButton
                                    handleGenerateIcebreakers={() =>
                                      setShowIcebreakersTab(true)
                                    }
                                    handleSelectIcebreaker={
                                      handleSelectIcebreaker
                                    }
                                    prospect={prospect}
                                    publicId={linkedinStatus?.publicId ?? null}
                                    showIcebreakersTab={showIcebreakersTab}
                                    setShowIcebreakersTab={
                                      setShowIcebreakersTab
                                    }
                                  />
                                )}
                              </div>
                            ))}
                        </form>
                      )}
                    </div>
                  ) : null}
                </div>
              )
            }
          </>
        )}
      </LinkedinWrapper>
    </>
  );
};

function useCheckLinkedinMessages(
  prospect: Prospect | null,
  messages: LinkedinMessage[] | undefined,
) {
  const publicId = useMemo(
    () => LinkedinUrlUtils.getIdFromLinkedinProfileUrl(prospect?.linkedin),
    [prospect],
  );
  const { data: response } = useEventsCheckLinkedinPublicIds(
    publicId ? [publicId] : undefined,
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!messages || !response?.data?.data || !prospect?.owned) {
      return;
    }

    LinkedinMessagesUtils.markLogLinkedinAsAnswered(
      response.data.data,
      messages,
    ).then((patched) => {
      if (patched) {
        invalidateLogLinkedinQueries(queryClient).then();
      }
    });
  }, [messages, response, queryClient]);
}

function LinkedinConversation({
  conversation,
  messagesEndRef,
}: {
  conversation: LinkedinMessage[];
  messagesEndRef: React.MutableRefObject<HTMLDivElement | null>;
}) {
  const sortedConversation = useMemo(
    () => conversation.sort((a, b) => a.timestamp - b.timestamp).slice(-10),
    [conversation],
  );
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTo({
        top: divRef.current.offsetHeight,
        behavior: 'smooth',
      });
    }
  }, [divRef]);

  return (
    <div className="conversation-container" ref={divRef}>
      {sortedConversation.map((message, index) => {
        //In order to show the separator of the date
        let breakDate;
        if (index === 0) {
          breakDate = (
            <Typography variant="body1" style={{ marginBottom: 16 }}>
              {new Date(message.timestamp).toLocaleDateString()}
            </Typography>
          );
        } else if (
          new Date(message.timestamp).getDay() !==
          new Date(
            sortedConversation[index - 1].timestamp ||
              new Date(message.timestamp).getDay(),
          ).getDay()
        ) {
          breakDate = (
            <div style={{ marginBottom: 12 }}>
              <div className="divider" />
              <Typography fontSize={10} fontWeight={500} color={colors.grey11}>
                {moment(message.timestamp).format('LL')}
              </Typography>
            </div>
          );
        }

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              whiteSpace: 'pre-line',
              marginBottom: 12,
              width: '100%',
            }}
            key={index}
          >
            {breakDate}
            <div style={{ display: 'flex', width: '100%' }}>
              <Avatar
                className={
                  message.sent ? 'message-avatar' : 'message-avatar-reply'
                }
                sx={{
                  height: '30px',
                  width: '30px',
                }}
                key={`avatar-key-${message.user}`}
                alt={message.user}
                src={message.senderPictureUrlData}
              >
                {message.senderPictureUrlData
                  ? null
                  : message.user
                      .split(' ')
                      .map((word) => word[0])
                      .join('')}
              </Avatar>

              <div style={{ width: 'calc(100% - 40px)' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 4,
                    width: '100%',
                  }}
                >
                  <Typography fontSize={14} fontWeight={500}>
                    {message.user}
                  </Typography>
                  <Typography
                    fontSize={11}
                    fontWeight={500}
                    color={colors.grey11}
                  >
                    {moment(message.timestamp).format('LT')}
                  </Typography>
                </div>

                <Typography
                  fontSize={12}
                  fontWeight={400}
                  className="message-text"
                >
                  {message.message}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                  }}
                >
                  {(message.attachments ?? []).map((attachment) => (
                    <LinkedinAttachment
                      key={attachment.url}
                      attachment={attachment}
                    />
                  ))}
                </Box>

                {message.voiceMessage ? (
                  <LinkedinVoiceMessagePlayer
                    voiceMessage={message.voiceMessage}
                  />
                ) : null}
              </div>
            </div>
          </div>
        );
      })}

      <div id="messagesEndRef" ref={messagesEndRef} />
    </div>
  );
}

function LinkedinAttachment({
  attachment,
}: {
  attachment: NonNullable<LinkedinMessage['attachments']>[number];
}) {
  return (
    <Stack
      sx={{
        backgroundColor: colors.primaryLight,
        '&:hover': { backgroundColor: colors.primaryLightHover },
        borderRadius: 1,
        maxWidth: 300,
      }}
      direction="row"
      alignItems="center"
      padding={1}
      spacing={1.5}
    >
      <DownloadAttachment style={{ flexShrink: 0 }} />

      <Stack sx={{ flex: 1 }} direction="column" justifyContent="center">
        <Typography
          fontSize={14}
          fontWeight={500}
          style={{
            minWidth: 180,
            maxWidth: 180,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {attachment.name}
        </Typography>
        <Typography fontSize={12} color={colors.grey11}>
          {humanFileSize(attachment.byteSize)}
        </Typography>
      </Stack>

      <a
        href={attachment.url}
        target="_blank"
        style={{ textDecoration: 'none', color: colors.grey11 }}
        title="Click to download file"
        rel="noreferrer"
      >
        <FileAttachmentAttachment style={{ flexShrink: 0 }} />
      </a>
    </Stack>
  );
}

function humanFileSize(size: number): string {
  // https://stackoverflow.com/a/20732091
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    // @ts-ignore
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
}

function LinkedinVoiceMessagePlayer({
  voiceMessage,
}: {
  voiceMessage: NonNullable<LinkedinMessage['voiceMessage']>;
}) {
  const { call: callDownloadAttachment } = useLinkedinDownloadFile();
  const [state, setState] = useState<
    'idle' | 'downloading' | 'error' | 'playing' | 'paused'
  >('idle');
  const [audioUrlData, setAudioUrlData] = useState<string | undefined>();
  const [currentPlayTimeSeconds, setCurrentPlayTimeSeconds] =
    useState<number>(0);
  const playerRef = useRef<HTMLAudioElement>(null);

  const [hover, setHover] = useState(false);

  function playAudioFile() {
    if (!playerRef.current) {
      return;
    }

    // pause other players
    document.querySelectorAll('audio').forEach((player) => {
      if (!player.paused) {
        player.pause();
      }
    });

    setState('playing');
    setCurrentPlayTimeSeconds(0);
    playerRef.current.play().then();
  }

  function downloadAudioFile() {
    setState('downloading');
    callDownloadAttachment({ url: voiceMessage.url }).then((data) => {
      if (data) {
        setAudioUrlData(
          data?.urlData?.replace('application/octet-stream', 'audio/mp4'),
        );
        // audio will play by 'onLoadedData'
      } else {
        setState('error');
      }
    });
  }

  function handleClick() {
    if (!playerRef.current || state === 'downloading' || state === 'error') {
      return;
    }

    if (state === 'idle') {
      if (audioUrlData) {
        playAudioFile();
      } else {
        downloadAudioFile();
      }
    } else if (state === 'playing') {
      setState('paused');
      playerRef.current.pause();
    } else if (state === 'paused') {
      setState('playing');
      playerRef.current.play().then();
    }
  }

  const totalTimeSeconds = voiceMessage.durationMillis / 1000;
  const timeToShow =
    state === 'downloading'
      ? 0
      : state === 'playing' || state === 'paused'
        ? currentPlayTimeSeconds
        : totalTimeSeconds;

  return (
    <>
      <Stack
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={handleClick}
        sx={{
          backgroundColor:
            state === 'playing' || state === 'downloading'
              ? colors.primary
              : colors.primaryLight,
          '&:hover': {
            backgroundColor:
              state === 'playing' || state === 'downloading'
                ? colors.darkGreen
                : colors.primaryLightHover,
          },
          borderRadius: 1,
          maxWidth: 150,
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={1}
        spacing={1.5}
      >
        {state === 'idle' || state === 'paused' ? (
          <VoiceMessagePlay
            style={{
              color: hover ? colors.darkGreen : colors.primary,
              cursor: 'pointer',
            }}
          />
        ) : (
          <VoiceMessagePause
            style={{
              color: hover ? colors.darkGreen : colors.primary,
              cursor: 'pointer',
            }}
          />
        )}

        <Typography
          fontWeight={500}
          color={
            state === 'playing' || state === 'downloading'
              ? 'white'
              : colors.grey11
          }
          style={{ cursor: 'pointer' }}
        >
          {Math.floor(timeToShow / 60).toFixed(0)}:
          {Math.floor(timeToShow % 60)
            .toFixed(0)
            .padStart(2, '0')}
        </Typography>
      </Stack>

      <audio
        ref={playerRef}
        src={audioUrlData}
        onLoadedData={() => {
          if (state === 'downloading') {
            playAudioFile();
          }
        }}
        onTimeUpdate={(e) => {
          setCurrentPlayTimeSeconds(e.currentTarget.currentTime);
        }}
        onEnded={() => {
          setState('idle');
        }}
        onPause={() => {
          setState('paused');
        }}
      />
    </>
  );
}

export default LinkedinView;

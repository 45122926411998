import { Tooltip, Typography } from '@mui/material';
import { VariablesPageWrapper } from './style';
import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import {
  ITemplateSnippet,
  ITemplateVariables,
} from 'crono-fe-common/types/templateVariables';
import useGetTemplateVariables from 'hooks/services/variables/useGetTemplateVariables';
import usePatchTemplateVariables from 'hooks/services/variables/usePatchTemplateVariables';
import {
  MainPrimarySmallButton,
  AddInputButton,
} from 'crono-fe-common/components/CronoButton';
import IconPlusSmall from 'crono-fe-common/icons/Icon-Plus-Small';
import { colors } from 'crono-fe-common/theme';
import IconPlus from 'crono-fe-common/icons/Icon-Plus';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import { useAuth } from 'context/auth';
import SnippetModal from './SnippetModal';
import usePostSnippetVariable, {
  IPostSnippetVariable,
} from 'hooks/services/variables/usePostSnippetVariable';
import EditPencilIcon from 'crono-fe-common/icons/Icon-Edit-pencil';
import usePatchSnippetVariable, {
  IPatchSnippetVariable,
} from 'hooks/services/variables/usePatchSnippetVariable';
import useDeleteSnippetVariable from 'hooks/services/variables/useDeleteSnippetVariable';
import useDeleteTemplateVariables from 'hooks/services/variables/useDeleteTemplateVariables';
import React from 'react';
import { useJuneAnalytics } from 'context/june';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';

const getTextWidth = (text: string, font: string): number => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return 0;
  context.font = font;
  return context.measureText(text).width;
};

const autoResizeTextarea = (textarea: HTMLTextAreaElement | null) => {
  if (!textarea) return;

  const handleInput = () => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  handleInput();

  textarea.addEventListener('input', handleInput);

  return () => {
    textarea.removeEventListener('input', handleInput);
  };
};

const VariablesPage = () => {
  const analytics = useJuneAnalytics();
  const { highestRole } = useAuth();
  const [productName, setProductName] = useState<string>('');
  const [productDescription, setProductDescription] = useState<string>('');
  const [productReferences, setProductReferences] = useState<string[]>([]);
  const [productBenefits, setProductBenefits] = useState<string[]>([]);
  const [productIndustries, setProductIndustries] = useState<string[]>([]);
  const [snippets, setSnippets] = useState<ITemplateSnippet[]>([]);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [snippetToEdit, setSnippetToEdit] = useState<ITemplateSnippet | null>(
    null,
  );
  //for snackbar
  const [savedSuccessMessage, setSavedSuccessMessage] = useState<string>('');
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string>('');
  const [emptyFieldErrorMessage, setEmptyFieldErrorMessage] =
    useState<string>('');

  //hooks
  const { data: templateVariables } = useGetTemplateVariables();
  const { mutate: patchTemplateVariables } = usePatchTemplateVariables();
  const { mutate: deleteTemplateVariables } = useDeleteTemplateVariables();
  const { mutate: postSnippetVariable } = usePostSnippetVariable();
  const { mutate: patchSnippetVariable } = usePatchSnippetVariable();
  const { mutate: deleteSnippetVariable } = useDeleteSnippetVariable();

  const newReferenceInputRef = useRef<HTMLInputElement | null>(null);
  const newBenefitFieldRef = useRef<HTMLTextAreaElement | null>(null);
  const newIndustryFieldRef = useRef<HTMLTextAreaElement | null>(null);

  const benefitRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
  const industryRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
  const snippetRefs = useRef<(HTMLTextAreaElement | null)[]>([]);

  const setBenefitRef = useCallback(
    (element: HTMLTextAreaElement | null, index: number) => {
      benefitRefs.current[index] = element;
    },
    [],
  );

  const setIndustryRef = useCallback(
    (element: HTMLTextAreaElement | null, index: number) => {
      industryRefs.current[index] = element;
    },
    [],
  );

  const setSnippetRef = useCallback(
    (element: HTMLTextAreaElement | null, index: number) => {
      snippetRefs.current[index] = element;
    },
    [],
  );

  useEffect(() => {
    benefitRefs.current.forEach((textarea) => {
      autoResizeTextarea(textarea);
    });

    industryRefs.current.forEach((textarea) => {
      autoResizeTextarea(textarea);
    });

    snippetRefs.current.forEach((textarea) => {
      autoResizeTextarea(textarea);
    });
  }, [productBenefits, productIndustries, snippetRefs]);

  useEffect(() => {
    if (!templateVariables?.data?.data) return;

    const templateVariablesData: ITemplateVariables =
      templateVariables.data.data;

    setProductName(templateVariablesData.productName || '');
    setProductDescription(templateVariablesData.productDescription || '');
    setProductReferences(templateVariablesData.productReferences);
    setProductBenefits(templateVariablesData.productBenefits);
    setProductIndustries(templateVariablesData.productIndustries);
    setSnippets(templateVariablesData.templateSnippets);

    setTimeout(() => {
      Object.values(snippetRefs.current).forEach((textarea) => {
        autoResizeTextarea(textarea);
      });
    }, 0);
  }, [templateVariables]);

  const typingTimer = useRef<NodeJS.Timeout | null>(null);
  const typingDelay = useMemo(() => 1000, []);

  //Quick fix to disable initial patch due to implementation
  const enableSaveOnBacked = useRef<boolean>(false);

  const handleInputChange = <T extends string | string[]>(
    setter: React.Dispatch<React.SetStateAction<T>>,
    index?: number,
  ) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      enableSaveOnBacked.current = true;
      setter((prev) => {
        if (Array.isArray(prev)) {
          const updatedArray = [...(prev as string[])];
          if (typeof index === 'number') {
            updatedArray[index] = value;
          }
          return updatedArray as T;
        } else {
          return value as T;
        }
      });
    };
  };

  useEffect(() => {
    if (!enableSaveOnBacked.current) return;
    if (typingTimer.current) {
      clearTimeout(typingTimer.current);
    }

    typingTimer.current = setTimeout(() => {
      patchTemplateVariables(
        {
          productName: productName,
          productDescription: productDescription,
          productReferences: productReferences,
          productBenefits: productBenefits,
          productIndustries: productIndustries,
        },
        {
          onSuccess: () => {
            setSavedSuccessMessage('Changes saved successfully');
            setTimeout(() => setSavedSuccessMessage(''), 2000);

            if (analytics) {
              analytics.track('template-variables-patch', {});
            }
          },
        },
      );
    }, typingDelay);
  }, [
    productName,
    productDescription,
    productReferences,
    productBenefits,
    productIndustries,
  ]);

  const handleAddItem = (
    itemType: 'benefits' | 'references' | 'industries',
  ) => {
    const stateRef =
      itemType === 'benefits'
        ? productBenefits
        : itemType === 'references'
          ? productReferences
          : productIndustries;

    const setState =
      itemType === 'benefits'
        ? setProductBenefits
        : itemType === 'references'
          ? setProductReferences
          : setProductIndustries;

    const newInputRef =
      itemType === 'benefits'
        ? newBenefitFieldRef
        : itemType === 'references'
          ? newReferenceInputRef
          : newIndustryFieldRef;

    const errorMessage: string =
      itemType === 'benefits'
        ? 'Fill all benefits fields before adding new ones'
        : itemType === 'references'
          ? 'Fill all references fields before adding new ones'
          : 'Fill all challenges fields before adding new ones';

    if (stateRef.some((item) => item.trim() === '')) {
      setEmptyFieldErrorMessage(errorMessage);
      setTimeout(() => {
        setDeleteErrorMessage('');
      }, 1000);
      return;
    }

    setState((prevState) => {
      const updatedArray = [...prevState, ''];
      return updatedArray;
    });

    setTimeout(() => newInputRef.current?.focus(), 0);
  };

  const handleDeleteItem = (
    itemType: 'benefits' | 'references' | 'industries',
    index: number,
  ) => {
    let deleteParams = {};

    switch (itemType) {
      case 'benefits':
        deleteParams = { productBenefits: true };
        break;

      case 'references':
        deleteParams = { productReferences: true };
        break;

      case 'industries':
        deleteParams = { productIndustries: true };
        break;
    }

    deleteTemplateVariables(deleteParams, {
      onSuccess: () => {
        if (analytics) {
          analytics.track('template-variables-delete', {});
        }
      },
      onError: (error) => {
        setDeleteErrorMessage(getError(error) || '');
        setTimeout(() => {
          setDeleteErrorMessage('');
        }, 1000);
      },
    });
  };

  const handleCloseModal = useCallback(() => {
    setModalOpened(false);
    setSnippetToEdit(null);
  }, []);

  const handleOpenModal = useCallback(() => {
    setModalOpened(true);
  }, []);

  const handleSnippetCreate = useCallback(
    (label: string, content: string) => {
      const snippetToCreate: IPostSnippetVariable = {
        label,
        content,
      };

      postSnippetVariable(snippetToCreate, {
        onSuccess: () => {
          handleCloseModal();

          setTimeout(() => {
            Object.values(snippetRefs.current).forEach((textarea) => {
              autoResizeTextarea(textarea);
            });
          }, 0);

          if (analytics) {
            analytics.track('template-snippet-create', {});
          }
        },
      });
    },
    [postSnippetVariable, analytics],
  );

  const handleSnippetUpdate = useCallback(
    (label: string, content: string, id: number) => {
      const snippetToUpdate: IPatchSnippetVariable = {
        id,
        label,
        content,
      };

      patchSnippetVariable(snippetToUpdate, {
        onSuccess: () => {
          handleCloseModal();

          setTimeout(() => {
            Object.values(snippetRefs.current).forEach((textarea) => {
              autoResizeTextarea(textarea);
            });
          }, 0);

          if (analytics) {
            analytics.track('template-snippet-patch', {});
          }
        },
      });
    },
    [patchSnippetVariable, analytics],
  );

  const handleSnippetDelete = useCallback(
    (templateSnippetId: number) => {
      deleteSnippetVariable(templateSnippetId, {
        onSuccess: () => {
          if (analytics) {
            analytics.track('template-snippet-delete', {});
          }
        },
        onError: (error) => {
          setDeleteErrorMessage(getError(error) || '');
          setTimeout(() => {
            setDeleteErrorMessage('');
          }, 1000);
        },
      });
    },
    [deleteSnippetVariable, analytics],
  );

  useConditionalSnackBar([
    {
      condition: !!savedSuccessMessage,
      message: savedSuccessMessage,
      severity: 'success',
    },
    {
      condition: !!deleteErrorMessage,
      message: deleteErrorMessage,
      severity: 'error',
    },
    {
      condition: !!emptyFieldErrorMessage,
      message: emptyFieldErrorMessage,
      severity: 'error',
    },
  ]);

  return (
    // highestRole === Role.MANAGER
    // user can only create manager can also edit
    // this is handled on backend but should be implemented on frotnedn as qwell
    <VariablesPageWrapper>
      {modalOpened && (
        <SnippetModal
          onClose={handleCloseModal}
          onCreate={handleSnippetCreate}
          onUpdate={handleSnippetUpdate}
          snippet={snippetToEdit}
        />
      )}

      <div className="top">
        <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
          Template variables
        </Typography>
      </div>

      <div className="section">
        <Typography fontSize={16} fontWeight={600} lineHeight={'24px'}>
          Product
        </Typography>

        <label htmlFor="" className="label">
          <p className="label__title">Product name</p>

          <input
            type="text"
            className="input"
            placeholder="Your product name"
            value={productName}
            onChange={handleInputChange(setProductName)}
          />
        </label>

        <label htmlFor="" className="label">
          <p className="label__title">Product description</p>

          <input
            type="text"
            className="input"
            placeholder="Your product description"
            value={productDescription}
            onChange={handleInputChange(setProductDescription)}
          />
        </label>

        <label className="label">
          <p className="label__title">Customer references</p>

          <div className="references-container">
            {productReferences.map((reference, i) => {
              const textWidth: number = getTextWidth(reference, '14px Poppins');
              let inputWidth: number = textWidth + 24;

              if (!reference.length) {
                inputWidth = 163;
              }

              return (
                <React.Fragment key={`reference-${i}`}>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="input input--shrinked"
                      placeholder="Add reference here"
                      value={productReferences[i]}
                      onChange={handleInputChange(setProductReferences, i)}
                      ref={
                        i === productReferences.length - 1
                          ? newReferenceInputRef
                          : null
                      }
                      style={{ width: `${inputWidth}px` }}
                    />

                    {i === productReferences.length - 1 && (
                      <Tooltip title={'Delete variable'} placement="top" arrow>
                        <div
                          className="icon-trash"
                          onClick={() => handleDeleteItem('references', i)}
                        >
                          <TrashCanDeleteIcon color={colors.inactiveHover} />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </React.Fragment>
              );
            })}

            <AddInputButton onClick={() => handleAddItem('references')}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '10px',
                }}
              >
                <IconPlus color={colors.darkGreen} />
              </div>
              Add reference
            </AddInputButton>
          </div>
        </label>

        <div>
          {!productBenefits.length ? (
            <label className="label">
              <p className="label__title">Product benefit</p>
            </label>
          ) : (
            <div className="section" style={{ marginBottom: '8px' }}>
              {productBenefits.map((_, i) => (
                <label key={`benefit-${i}`} className="label">
                  <p className="label__title">Product benefit {i + 1}</p>

                  <div className="input-wrapper">
                    <textarea
                      className="input input--textarea"
                      placeholder="Add your product benefit"
                      value={productBenefits[i]}
                      onChange={handleInputChange(setProductBenefits, i)}
                      ref={(el) => setBenefitRef(el, i)}
                      rows={1}
                    />

                    {i === productBenefits.length - 1 && (
                      <Tooltip title={'Delete variable'} placement="top" arrow>
                        <div
                          className="icon-trash"
                          onClick={() => handleDeleteItem('benefits', i)}
                        >
                          <TrashCanDeleteIcon color={colors.inactiveHover} />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </label>
              ))}
            </div>
          )}

          <AddInputButton onClick={() => handleAddItem('benefits')}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              <IconPlus color={colors.darkGreen} />
            </div>
            Add benefit
          </AddInputButton>
        </div>
      </div>

      <div className="section">
        <Typography fontSize={16} fontWeight={600} lineHeight={'24px'}>
          Industry
        </Typography>

        <div>
          {!productIndustries.length ? (
            <label className="label">
              <p className="label__title">Industry challange</p>
            </label>
          ) : (
            <div className="section" style={{ marginBottom: '8px' }}>
              {productIndustries.map((_, i) => (
                <label key={`challange-${i}`} className="label">
                  <p className="label__title">Industry challange {i + 1}</p>

                  <div className="input-wrapper">
                    <textarea
                      className="input input--textarea"
                      placeholder="Add industry challange"
                      value={productIndustries[i]}
                      onChange={handleInputChange(setProductIndustries, i)}
                      ref={(el) => setIndustryRef(el, i)}
                      rows={1}
                    />

                    {i === productIndustries.length - 1 && (
                      <Tooltip title={'Delete variable'} placement="top" arrow>
                        <div
                          className="icon-trash"
                          onClick={() => handleDeleteItem('industries', i)}
                        >
                          <TrashCanDeleteIcon color={colors.inactiveHover} />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </label>
              ))}
            </div>
          )}

          <AddInputButton onClick={() => handleAddItem('industries')}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              <IconPlus color={colors.darkGreen} />
            </div>
            Add challenge
          </AddInputButton>
        </div>
      </div>

      <div className="section">
        <div className="top">
          <Typography fontSize={16} fontWeight={600} lineHeight={'24px'}>
            Snippets
          </Typography>
          <MainPrimarySmallButton height="40px" onClick={handleOpenModal}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '4px',
              }}
            >
              <IconPlusSmall color={colors.white} />
            </div>
            Create snippets
          </MainPrimarySmallButton>
        </div>
        {!!snippets.length &&
          snippets.map((snippet) => {
            const { id, label, content } = snippet;

            return (
              <div key={id} className="snippet">
                <div
                  className="snippet__top"
                  onClick={() => {
                    // if (highestRole !== Role.MANAGER) return
                    setSnippetToEdit(snippet);
                    handleOpenModal();
                  }}
                >
                  <p className="snippet__title">{label}</p>

                  <div className="edit-pencil">
                    <EditPencilIcon color={colors.darkGreen} />
                  </div>
                </div>

                <div className="input-wrapper">
                  <textarea
                    className="input input--textarea"
                    disabled
                    value={content}
                    rows={1}
                    ref={(el) => setSnippetRef(el, id)}
                  />

                  <Tooltip title={'Delete snippet'} placement="top" arrow>
                    <div
                      className="icon-trash"
                      onClick={() => handleSnippetDelete(id)}
                    >
                      <TrashCanDeleteIcon color={colors.inactiveHover} />
                    </div>
                  </Tooltip>
                </div>
              </div>
            );
          })}
      </div>
    </VariablesPageWrapper>
  );
};

export default VariablesPage;

import React, { useEffect, useMemo, useState } from 'react';
import { AddContactFormWrapper } from './style';
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { colors } from 'crono-fe-common/theme';
import useAccounts from 'hooks/services/account/useAccounts';
import Role from 'crono-fe-common/types/enums/role';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import {
  CancelButton,
  MainButton,
  TabSelectionButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import {
  ProspectInsert,
  ProspectInsertCodeDTO,
} from 'crono-fe-common/types/DTO/prospectInsert';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import FindEmailPreconditionButton from 'pages/taskBoard/suggestionsBox/suggestionCards/findEmailPreconditionButton';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import FindPhonePreconditionButton from 'pages/taskBoard/suggestionsBox/suggestionCards/findPhonePreconditionButton';
import VerificationStatusType, {
  EmailScrapeResult,
} from 'crono-fe-common/types/enums/verificationStatusType';
import useFindVerify from 'hooks/services/scrape/useFindVerify';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import { useConditionalSnackBar } from 'context/snackbar';
import MobilePhoneIcon from 'crono-fe-common/icons/Icon-Mobile-Phone';
import { TagCreation } from 'crono-fe-common/types/DTO/accountInsert';
import InputExternalPropertyNotTag from 'pages/accountTab/externalPropertyNotTag/inputExternalPropertyNotTag';
import {
  emailInformation,
  renderContactInformationIcon,
} from 'pages/accountTab/contactView/iconWithValidityBadge';
import { getNewOptionsWhenSelecting } from 'crono-fe-common/utils';

interface IProps {
  showSubmit: boolean;
  close?: () => void;
  handleSubmit: (values: ProspectInsert) => void;
  createLoading?: boolean;
}

const AddContactForm = ({
  showSubmit,
  close,
  handleSubmit,
  createLoading,
}: IProps) => {
  const { user, highestRole } = useAuth();

  const [createAsLead, setCreateAsLead] = useState<boolean>(
    user?.company?.integrationType === IntegrationType.SALESFORCE
      ? user?.userPreferences?.createAsLead !== null ||
        user?.userPreferences?.createAsLead !== undefined
        ? true
        : user?.userPreferences?.createAsLead
      : true,
  );

  const [errorMandatoryFields, setErrorMandatoryFields] = useState<
    string | null
  >(null);

  //To clear the error after 5 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (errorMandatoryFields) {
      timeout = setTimeout(() => {
        setErrorMandatoryFields(null);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [errorMandatoryFields]);

  const { mutateAsync: verifyEmail, isLoading: isVerifying } = useFindVerify();
  const formik = useFormik<ProspectInsertCodeDTO>({
    initialValues: {
      firstName: '',
      lastName: '',
      accountId: null,
      title: null,
      email: null,
      phone: null,
      mobilePhone: null,
      company: '',
      linkedin: null,
      linkedinLeadId: null,
      createAsLead: false,
      strategyId: null,
      externalValues: [],
    },
    onSubmit: async (values) => {
      const externalValuesToAdd: { [key: number]: string } = {};
      formik.values.externalValues.forEach((val) => {
        externalValuesToAdd[val.externalPropertyId] = val.value;
      });
      //Check if the mandatory fields are filled
      const mandatoryToFill: string[] = [];
      externalProperties?.data?.data.forEach((property) => {
        if (
          property.tableType !== 'Account' &&
          property.isMandatory &&
          !externalValuesToAdd[property.id]
        ) {
          mandatoryToFill.push(
            property.publicName ?? property.externalName ?? 'Unnamed property',
          );
        }
      });
      if (mandatoryToFill.length > 0) {
        setErrorMandatoryFields(mandatoryToFill.join(', '));
        return;
      }
      if (disableAdd) return;
      if (values.email && !values.emailStatus) {
        try {
          const { data } = await verifyEmail({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            linkedinUrl: values.linkedin,
          });
          if (data?.isSuccess && data?.data?.status) {
            values.emailStatus = data?.data?.status;
          } else {
            values.emailStatus = VerificationStatusType.UNKNOWN;
          }
        } catch (_) {
          values.emailStatus = VerificationStatusType.UNKNOWN;
        }
      }
      const prospectInsert: ProspectInsert = {
        firstName: values.firstName,
        externalValues: externalValuesToAdd,
        lastName: values.lastName,
        createAsLead: createAsLead,
        linkedin: values.linkedin,
        title: values.title,
        email: values.email,
        emailStatus: values.emailStatus,
        phone: values.phone,
        mobilePhone: values.mobilePhone,
        ...(selectedAccount
          ? {
              accountId: selectedAccount.objectId,
              company: selectedAccount.name,
            }
          : searchCompanyText
            ? {
                company: searchCompanyText,
              }
            : {
                //This should never be reached due to checks
                company: '',
              }),
      };
      // console.log("Pro", prospectInsert);
      handleSubmit(prospectInsert);
    },
  });

  //If the company is not selected (so we want to create a new one) the new name will be inside this
  const [searchCompanyText, setSearchCompanyText] = useState<string>('');
  const { data: accounts } = useAccounts({
    name: searchCompanyText,
    limit: 25,
    status: null,
    IsManager: highestRole === Role.MANAGER,
  });

  const mappedAccount = useMemo(() => {
    if (!accounts || !accounts.data) return [];
    return accounts.data?.data.map((acc) => {
      return acc;
    });
  }, [accounts]);

  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  //Here I have the acconut to insert, with the accountId
  const selectedAccount = useMemo(() => {
    const acc = mappedAccount.find((el) => el.objectId === selectedCompany);
    return acc;
  }, [selectedCompany, mappedAccount]);

  const [enableCreateAsLead, setEnableCreateAsLead] = useState(true);

  const [mailScraped, setMailScraped] = useState<EmailScrapeResult | null>(
    null,
  );

  useEffect(() => {
    if (mailScraped) {
      formik.setFieldValue('email', mailScraped.email);
      formik.setFieldValue('emailStatus', mailScraped.verificationStatus);
    }
  }, [mailScraped]);

  const [phoneScraped, setPhoneScraped] = useState<string | null>(null);

  useEffect(() => {
    if (phoneScraped) {
      formik.setFieldValue('phone', phoneScraped);
    }
  }, [phoneScraped]);

  const { data: externalProperties } = useGetExternalProperty(
    createAsLead && user?.integrationType === IntegrationType.SALESFORCE
      ? 'Lead'
      : 'Prospect',
    null,
    true,
  );

  const externalPropertyTags: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter((property) => property.isTag) ?? []
    );
  }, [externalProperties]);

  const externalPropertyNotTags: ExternalProperty[] = useMemo(() => {
    return (
      externalProperties?.data?.data?.filter((property) => !property.isTag) ??
      []
    );
  }, [externalProperties]);

  //Initialize the externalValues that has default value
  useEffect(() => {
    if (!externalProperties) return;
    const newExternalProperties: TagCreation[] = [];
    externalProperties.data?.data.forEach((property) => {
      if (property.defaultValue) {
        newExternalProperties.push({
          externalPropertyId: property.id,
          value: property.defaultValue,
          isTag: property.isTag,
        });
      }
    });
    formik.setFieldValue('externalValues', newExternalProperties);
  }, [externalProperties]);

  useEffect(() => {
    if (selectedAccount && !selectedAccount.isSynced) {
      setEnableCreateAsLead(false);
      setCreateAsLead(true);
    }
  }, [selectedAccount]);

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const externalPropertyId = externalProperty.id;
    const newValues = [...formik.values.externalValues];

    const newValueOption = getNewOptionsWhenSelecting({
      currentExternalValues: newValues,
      externalPropertyId,
      option: option,
      valueType: externalProperty.valueType,
    });
    //This is going to be used only when the option is not null, so it is safe to use the !
    const newExternalValue = {
      externalPropertyId,
      value: newValueOption!,
      isTag: true,
    };
    const oldValue = newValues.filter(
      (val) => val.externalPropertyId === externalPropertyId,
    );
    if (oldValue.length > 0 || option === null) {
      newValues.splice(newValues.indexOf(oldValue[0]), 1);
      if (oldValue[0].value !== option) {
        newValues.push(newExternalValue);
      }
      formik.setFieldValue('externalValues', newValues);
    } else {
      newValues.push(newExternalValue);
      formik.setFieldValue('externalValues', newValues);
    }
  };

  const handleSetExternalValueNotTag = (
    externalPropertyId: number,
    value: string,
  ) => {
    const newValues = [...formik.values.externalValues];
    const newExternalValue = {
      externalPropertyId,
      value: value,
      isTag: false,
    };
    const oldValue = newValues.filter(
      (val) => val.externalPropertyId === externalPropertyId,
    );
    if (oldValue.length > 0) {
      newValues.splice(newValues.indexOf(oldValue[0]), 1);

      newValues.push(newExternalValue);

      formik.setFieldValue('externalValues', newValues);
    } else {
      newValues.push(newExternalValue);
      formik.setFieldValue('externalValues', newValues);
    }
  };

  const disableAdd = useMemo(() => {
    return !selectedAccount && (!searchCompanyText || searchCompanyText === '');
  }, [selectedAccount, searchCompanyText]);

  const verifyAndSetEmail = async (email: string | null) => {
    if (!email) return;
    try {
      const { data } = await verifyEmail({
        email: email,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        linkedinUrl: formik.values.linkedin,
      });
      if (data?.isSuccess && data?.data?.status) {
        formik.values.emailStatus = data?.data?.status;
      } else {
        formik.values.emailStatus = VerificationStatusType.UNKNOWN;
      }
    } catch (_) {
      formik.values.emailStatus = VerificationStatusType.UNKNOWN;
    }
  };

  useConditionalSnackBar([
    {
      condition: !!errorMandatoryFields,
      message: `The empty fields ${errorMandatoryFields} are mandatory. Add a value to continue`,
      severity: 'error',
    },
  ]);

  return (
    <AddContactFormWrapper>
      <form
        className="form-add-container"
        onSubmit={formik.handleSubmit}
        onKeyDown={(ev) => {
          if (ev.code === 'Enter') {
            ev.preventDefault();
            ev.stopPropagation();
          }
        }}
      >
        {user?.company?.integrationType === IntegrationType.SALESFORCE && (
          <div>
            <TabSelectionButton
              selected={!createAsLead}
              sx={{ position: 'relative', marginLeft: 0 }}
              size="large"
              onClick={() => setCreateAsLead(false)}
              disableElevation
              disabled={!enableCreateAsLead}
            >
              Create as contact
            </TabSelectionButton>
            <TabSelectionButton
              selected={createAsLead}
              size="large"
              sx={{ position: 'relative', margin: '6px', right: 10 }}
              onClick={() => setCreateAsLead(true)}
              disableElevation
            >
              Create as lead
            </TabSelectionButton>
          </div>
        )}
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Company*
          </Typography>
          <Autocomplete
            id="companySelect"
            className="add-new-auto-complete"
            // value={{
            //   id: selectedCompany || "-1",
            //   name:
            //     mappedAccount.find((el) => el.objectId === selectedCompany)
            //       ?.name || searchCompanyText,
            // }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            onChange={(e, value) => {
              if (typeof value !== 'string') {
                if (value === null) setSelectedCompany(null);
                else setSelectedCompany(value.id);
              }
            }}
            options={mappedAccount.map((acc) => {
              return { id: acc.objectId, name: acc.name };
            })}
            freeSolo
            getOptionLabel={(option) =>
              (typeof option !== 'string' && option.name) || ''
            }
            PaperComponent={({ children }) => (
              <div className="autocomplete-paper">{children}</div>
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                value={option.id || undefined}
                className="autocomplete-option"
              >
                {option.name}
              </li>
            )}
            disablePortal
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="standard"
                placeholder="Type company name..."
                value={searchCompanyText}
                onChange={(e: any) => setSearchCompanyText(e.target.value)}
                sx={{
                  '& .MuiInput-underline:before': {
                    borderBottomColor: colors.grey444,
                  },
                }}
              />
            )}
          />
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            First Name
          </Typography>
          <div style={{ display: 'flex' }}>
            <TextField
              placeholder="Type first name..."
              variant="standard"
              autoComplete="off"
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: colors.grey444,
                },
              }}
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              fullWidth
            />
          </div>
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Last Name
          </Typography>
          <div style={{ display: 'flex' }}>
            <TextField
              placeholder="Type last name..."
              variant="standard"
              autoComplete="off"
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: colors.grey444,
                },
              }}
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              fullWidth
            />
          </div>
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Title
          </Typography>
          <div style={{ display: 'flex' }}>
            <TextField
              placeholder="Type title..."
              variant="standard"
              autoComplete="off"
              sx={{
                '& .MuiInput-underline:before': {
                  borderBottomColor: colors.grey444,
                },
              }}
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              fullWidth
            />
          </div>
        </div>
        <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Contact details
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            className="editable-div"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              {renderContactInformationIcon(
                emailInformation,
                {
                  email: formik.values.email,
                  emailStatus: formik.values.emailStatus,
                },
                null,
              )}
              <TextField
                name="email"
                variant="standard"
                autoComplete="off"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                placeholder="Insert email"
                onChange={(ev) => {
                  formik.handleChange(ev);
                  formik.setFieldValue('emailStatus', undefined);
                }}
                onBlur={(ev) => {
                  verifyAndSetEmail(ev.target.value);
                }}
                value={formik.values.email}
              />
              {/*I show the mail finder only if the company selected is one of the Crono's  */}
              {(!formik.values.email || formik.values.email === '') &&
                selectedAccount && (
                  <FindEmailPreconditionButton
                    account={selectedAccount}
                    setEmail={setMailScraped}
                    prospectInputs={formik.values}
                    hasLabel={true}
                    iconColor={colors.white}
                    // todo add when add company insert
                    // handleAddAccountInformation={handleAddAccountInformation}
                  />
                )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            className="editable-div"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <LinkedinIcon className="info-icon" />
              <TextField
                name="linkedin"
                variant="standard"
                autoComplete="off"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                placeholder="Insert LinkedIn URL"
                onChange={formik.handleChange}
                value={formik.values.linkedin}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            className="editable-div"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <CallIcon className="info-icon" />
              <TextField
                name="phone"
                variant="standard"
                autoComplete="off"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                placeholder="Insert phone number"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
              {(!formik.values.phone || formik.values.phone === '') &&
                selectedAccount && (
                  <FindPhonePreconditionButton
                    account={selectedAccount}
                    setPhone={setPhoneScraped}
                    prospectInputs={formik.values}
                    hasLabel={true}
                    iconColor={colors.white}
                    // todo add when add company insert
                    // handleAddAccountInformation={handleAddAccountInformation}
                  />
                )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            className="editable-div"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <MobilePhoneIcon className="info-icon" />
              <TextField
                name="mobilePhone"
                variant="standard"
                autoComplete="off"
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                placeholder="Insert mobile number"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
            </div>
          </div>
        </div>
        {externalPropertyTags?.filter((tag) => tag.isEditable).length !== 0 && (
          <div className="form-box">
            <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
              Add tags:
            </Typography>
            <div className="tags-container">
              <TagsBoxShowAll
                type={createAsLead ? 'Lead' : 'Prospect'}
                currentSituationTags={
                  formik.values?.externalValues
                    .filter((prop) => prop.isTag)
                    .map((prop) => ({
                      externalPropertyId: prop.externalPropertyId,
                      option: prop.value,
                    })) ?? []
                }
                onlyInsert={true}
                handleClickTag={handleClickTag}
                showMandatoryAsStar={true}
              />
            </div>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {externalPropertyNotTags.map((property, index) => {
            return (
              <InputExternalPropertyNotTag
                key={property.id}
                externalProperty={property}
                owned={true}
                externalValues={formik.values.externalValues}
                handleSetValue={handleSetExternalValueNotTag}
              />
            );
          })}
        </div>
        {(showSubmit === undefined ? true : showSubmit) && (
          <FlexDiv
            width="fit-content"
            height="fit-content"
            style={{ alignSelf: 'flex-end' }}
          >
            <CancelButton
              onClick={() => {
                if (close) close();
              }}
            >
              Cancel
            </CancelButton>
            {isVerifying || createLoading ? (
              <FlexDiv>
                <CircularProgress />
              </FlexDiv>
            ) : (
              <MainButton
                type="submit"
                disabled={disableAdd}
                style={{ background: disableAdd ? colors.grey11 : undefined }}
              >
                Add
              </MainButton>
            )}
          </FlexDiv>
        )}
      </form>
    </AddContactFormWrapper>
  );
};

export default AddContactForm;

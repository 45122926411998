import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SingleStatusComponentWrapper } from './style';
import IconMCompany from 'crono-fe-common/icons/Icon-M-Company';
import IconMContact from 'crono-fe-common/icons/Icon-M-Contact';
import { CircularProgress, Tooltip, Typography } from '@mui/material';
import useGetExternalPropertyStatus from 'hooks/services/externalProperty/useGetExternalPropertyStatus';
import ResyncButton from 'crono-fe-common/components/resyncButton';
import { colors } from 'crono-fe-common/theme';
import { useAuth } from 'context/auth';
import IconInformationS from 'crono-fe-common/icons/Icon-Information-Small';
import { MainPrimarySmallButton } from 'crono-fe-common/components/CronoButton';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd';
import { AxiosError } from 'axios';
import { useConditionalSnackBar } from 'context/snackbar';
import { CronoErrorCode } from 'crono-fe-common/types/enums/cronoErrorCode';
import {
  getColorsStatus,
  getError,
  getExternalPropertyLabels,
} from 'crono-fe-common/utils';
import { useConfirmModal } from 'context/confirmModal';
import { useJuneAnalytics } from 'context/june';
import { ReactComponent as IntegrationS } from 'crono-fe-common/icons/Integrations-S.svg';
import { ReactComponent as Unlink } from 'crono-fe-common/icons/Unlink-S.svg';
import {
  DefaultCompanyStatus,
  DefaultContactStatus,
  DefaultDefaultCompanyStatus,
  DefaultDefaultContactStatus,
  StatusPropertyType,
} from '../../../../crono-fe-common/types/model';
import usePatchExternalPropertyOption from 'hooks/services/externalProperty/usePatchExternalPropertyOption';
import useDeleteExternalPropertyOption from 'hooks/services/externalProperty/useDeleteExternalPropertyOption';
import usePatchExternalProperty from 'hooks/services/externalProperty/usePatchExternalProperty';
import StatusSettingsCard from './StatusSettingsCard';
import AddStatusContainer from './addStatus/container';
import useCreateExternalPropertyOption from 'hooks/services/externalProperty/useCreateExternalPropertyOption';
import useSyncExternalProperty from 'hooks/services/externalProperty/useSyncExternalProperty';
import EditIcon from 'crono-fe-common/icons/Icon-Edit';
import LinkStatusToExternalProperty from './pickCRMProperty';
import { StatusComponentOptions } from 'crono-fe-common/types/StatusOptions';
import { FeConstants } from 'constants/FeConstants';
import useMigrateExternalPropertyStatus from 'hooks/services/externalProperty/useMigrateExternalPropertyStatus';
import { ExternalPropertyStatusMigrate } from 'crono-fe-common/types/DTO/externalPropertyStatusMigrate';
import useGetApplyStatusMigration from 'hooks/services/applyStatusMigration/useGetApllyStatusMigration';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';

interface IProps {
  type: StatusComponentOptions;
}

const SingleStatusComponent = ({ type }: IProps) => {
  const { user } = useAuth();
  const { data: externalPropertyStatus } = useGetExternalPropertyStatus(
    type === 'account' ? 'Account' : 'Prospect',
  );

  const externalPropertyStatusId = externalPropertyStatus?.data?.data?.id;

  const isExternalPropertyStatus = !!externalPropertyStatus?.data?.data;
  const isExternalPropertySynced = !!externalPropertyStatus?.data?.data?.isSync;
  const statuses: StatusPropertyType[] = useMemo(() => {
    return !isExternalPropertyStatus
      ? type === 'account'
        ? DefaultCompanyStatus
        : DefaultContactStatus
      : externalPropertyStatus.data?.data?.options
        ? externalPropertyStatus.data.data.options.map((option, index) => {
            return {
              id: index,
              option: option,
              label:
                getExternalPropertyLabels(
                  externalPropertyStatus.data!.data!,
                ).at(index) ?? option,
            };
          })
        : [];
  }, [isExternalPropertyStatus, type, externalPropertyStatus]);

  const totalNumberOfStatuses = statuses?.length ?? 0;

  const handleChangeTypeOfStatus = useCallback(() => {
    //Go back to custom state, starting from the default one
    if (isExternalPropertySynced) {
      const defaultStatuses =
        type === 'account' ? DefaultCompanyStatus : DefaultContactStatus;

      migrateExternalPropertyStatus({
        tableType: type === 'account' ? 'Account' : 'Prospect',
        customStatusLabels: defaultStatuses.map((status) => status.label),
        customStatusOptions: defaultStatuses.map((status) => status.option),
        defaultStatus:
          type === 'account'
            ? DefaultDefaultCompanyStatus.option
            : DefaultDefaultContactStatus.option,
      });
    } else {
      setShowCRMPropertiesPicker(true);
    }
  }, [isExternalPropertySynced]);

  //  Sync CRM logic ----------------------------

  const {
    mutate: syncExternalProperty,
    isLoading: synchingExternalProperty,
    error: errorSyncExternalProperty,
  } = useSyncExternalProperty();

  const analytics = useJuneAnalytics();

  const handleResyncStatus = () => {
    if (!externalPropertyStatusId) return;
    syncExternalProperty({ externalPropertyIds: [externalPropertyStatusId] });
    if (analytics) {
      analytics.track('sync-status', {
        type,
      });
    }
  };

  const [showCRMPropertiesPicker, setShowCRMPropertiesPicker] = useState(false);

  const handleShowCRMPropertiesPicker = () => {
    setShowCRMPropertiesPicker(true);
  };

  // Edit/delete status standalone ---------------------
  const {
    mutate: patchExternalPropertyOption,
    error: patchExternalPropertyOptionError,
  } = usePatchExternalPropertyOption();
  const [localStatuses, setLocalStatuses] = useState<
    StatusPropertyType[] | undefined
  >(undefined);
  const [statusFocused, setStatusFocused] = useState<number | null>(null);

  useEffect(() => {
    if (statuses) {
      setLocalStatuses([...statuses]);
    }
  }, [statuses]);

  const handleChangeName = useCallback(
    (
      name: string | null,
      index: number,
      onAccept: (reset?: boolean) => void,
      onRefuse: () => void,
    ) => {
      if (!name || name === '') {
        setStatusFocused(null);
        onRefuse();
        return;
      }
      const nameTrimmed = name.trim();
      const statuses = [...(localStatuses ?? [])];
      const currentOption = statuses[index].option;
      const currentName = statuses[index].label ?? '';
      //Check for similar name. The API will not return this since we keep the option and change only the label
      const indexSimilar = localStatuses?.findIndex(
        (status) => status.label === nameTrimmed,
      );
      //If we find a similar name and it is not the same status we are editing, show an error
      if ((indexSimilar ?? -1) > -1 && indexSimilar !== index) {
        openConfirmModal({
          title: 'A status with the same name already exist',
          text: 'Two statuses cannot have the same name. Please choose a different name.',
          cancelText: 'Cancel',
          cancelFunction: () => {
            onRefuse();
          },
          styles: {
            cancelButton: {
              width: '120px',
            },
          },
        });
        return;
      }
      if (nameTrimmed === currentName) {
        setStatusFocused(null);
        onAccept();
        return;
      }
      //If we have to migrate to custom status
      if (!externalPropertyStatusId) {
        //If we are not using the same name (which means that if the user enters the textfield and exit it without changing we are not asking to create a custom property)
        const newLabels = statuses.map((status) => status.label);
        newLabels.splice(index, 1, nameTrimmed);
        if (!isExternalPropertyStatus) {
          handleConfirmToPassToCustom(
            {
              tableType: type === 'account' ? 'Account' : 'Prospect',
              customStatusLabels: newLabels,
              customStatusOptions: statuses.map((status) => status.option),
              defaultStatus: defaultValue,
            },
            () => {
              statuses[index].label = currentName;
              setLocalStatuses(statuses);
              onAccept(true);
              setStatusFocused(null);
            },
          );
        }
        onAccept();
        setStatusFocused(null);

        return;
      }

      // Update locally and make patch request
      patchExternalPropertyOption({
        externalPropertyId: externalPropertyStatusId,
        newLabel: nameTrimmed,
        option: currentOption,
      });
      if (analytics) {
        analytics.track('patch-status', {
          field: 'name',
          type,
        });
      }
      statuses[index].label = nameTrimmed;

      onAccept();
      setLocalStatuses(statuses);
      //Once done remove the focus from the selected status
      setStatusFocused(null);
    },
    [localStatuses],
  );

  const {
    mutate: migrateExternalPropertyStatus,
    isSuccess: isSuccessMigrateExternalPropertyStatus,
  } = useMigrateExternalPropertyStatus();

  useEffect(() => {
    if (isSuccessMigrateExternalPropertyStatus) {
      //Close the window to create a new status
      if (showAddStatus) {
        setShowAddStatus(false);
      }
    }
  }, [isSuccessMigrateExternalPropertyStatus]);

  //Function called to ask for confirmation to pass to custom statuses when changing the default one
  const handleConfirmToPassToCustom = (
    dataToMigrate: ExternalPropertyStatusMigrate,
    onCancel?: () => void,
  ) => {
    openConfirmModal({
      title: 'Are you sure you want to create a custom Status?',
      text: 'Switching to custom statuses for contacts or companies might require several minutes',
      confirmText: 'Yes',
      cancelText: 'No',
      confirmFunction: () => {
        migrateExternalPropertyStatus(dataToMigrate);
        if (analytics) {
          analytics.track('migrate-status', {
            type,
          });
        }
      },
      cancelFunction: () => {
        onCancel && onCancel();
      },
    });
  };

  const handleShowAddStatus = () => {
    setShowAddStatus(true);
  };

  const { mutateAsync: deleteStatusAsync, error: deleteStatusError } =
    useDeleteExternalPropertyOption();

  const { openModal: openConfirmModal } = useConfirmModal();

  const handleDeleteStatus = async (index: number) => {
    if (!externalPropertyStatusId) {
      if (!isExternalPropertyStatus) {
        const optionToDelete = localStatuses?.[index].option;
        handleConfirmToPassToCustom({
          tableType: type === 'account' ? 'Account' : 'Prospect',
          defaultStatus:
            optionToDelete === defaultValue
              ? localStatuses?.[index === 0 ? 1 : 0].option
              : defaultValue,
          customStatusLabels: localStatuses
            ?.filter((_, indexStatus) => indexStatus !== index)
            .map((status) => status.label),
          customStatusOptions: localStatuses
            ?.filter((_, indexStatus) => indexStatus !== index)
            .map((status) => status.option),
        });
      }
      //This should not be possible, but here just in case
      return;
    }
    const statuses = [...(localStatuses ?? [])];
    if (statuses.length <= FeConstants.MINIMUM_STATUSES) {
      return;
    }
    const status = statuses[index];
    try {
      await deleteStatusAsync({
        externalPropertyId: externalPropertyStatusId,
        option: status.option,
        checkPresence: true,
      });
      if (analytics) {
        analytics.track('delete-status-option', {
          type,
        });
      }
      statuses.splice(index, 1);
      //Set the default to be the first one if we deleted the default one
      if (status.option === defaultValue) {
        handleChangeDefaultStatus(statuses[0].option);
      }
    } catch (error: unknown) {
      // Not the best way to handle this error, but the modal figma design requires it to be like this
      if (error instanceof AxiosError) {
        const title =
          error?.response?.data.errors![0]!.code ===
          CronoErrorCode.CronoConflictError
            ? `Change status of existing ${
                type === 'account' ? 'companies' : 'contacts'
              } first`
            : 'Error deleting the status';
        const message =
          error?.response?.data.errors![0]!.code ===
          CronoErrorCode.CronoConflictError
            ? `You have ${
                type === 'account' ? 'companies' : 'contacts'
              } with this status. Please assign another status to ${
                type === 'account' ? 'companies' : 'contacts'
              } before deleting this status`
            : error?.response?.data.errors![0]!.message;
        openConfirmModal({
          title: title,
          text: message ?? 'Error deleting the status',
          cancelText: 'Cancel',
          cancelFunction: () => {},
          styles: {
            cancelButton: {
              width: '120px',
            },
          },
        });
      }
    } finally {
      setLocalStatuses(statuses);
    }
  };

  //Add new status ---------------------
  const [showAddStatus, setShowAddStatus] = useState(false);

  const {
    mutate: createExternalPropertyOption,
    isLoading: creatingExternalPropertyOption,
    isSuccess: createdExternalPropertyOption,
    error: createExternalPropertyOptionError,
  } = useCreateExternalPropertyOption();

  const handleCreateNewStatus = (newLabel: string) => {
    if (!externalPropertyStatusId) {
      if (!isExternalPropertyStatus) {
        handleConfirmToPassToCustom({
          tableType: type === 'account' ? 'Account' : 'Prospect',
          defaultStatus: defaultValue,
          customStatusLabels: [
            ...(localStatuses?.map((status) => status.label) ?? []),
            newLabel,
          ],
          customStatusOptions: [
            ...(localStatuses?.map((status) => status.option) ?? []),
            crypto.randomUUID(),
          ],
        });
      }
      //This should not be possible, but here just in case
      return;
    }
    createExternalPropertyOption({
      externalPropertyId: externalPropertyStatusId,
      label: newLabel.trim(),
    });
    if (analytics) {
      analytics.track('create-status', {
        type,
      });
    }
  };

  useEffect(() => {
    if (createdExternalPropertyOption) {
      setShowAddStatus(false);
    }
  }, [createdExternalPropertyOption]);

  // Drag and drop logic ----------------------------
  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return { result, removed };
  };

  const { mutate: patchExternalProperty, isLoading: patchingExternalProperty } =
    usePatchExternalProperty();

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const { result: items, removed } = reorder(
      localStatuses ?? [],
      result.source.index,
      result.destination.index,
    );
    if (!externalPropertyStatusId) {
      if (!isExternalPropertyStatus) {
        handleConfirmToPassToCustom({
          tableType: type === 'account' ? 'Account' : 'Prospect',
          defaultStatus: defaultValue,
          customStatusLabels: items.map((status) => status.label),
          customStatusOptions: items.map((status) => status.option),
        });
      }
      //This should not be possible, but here just in case
      return;
    }
    setLocalStatuses(items);

    patchExternalProperty({
      externalPropertyId: externalPropertyStatusId,
      tableType: type === 'account' ? 'Account' : 'Prospect',
      type: 'List',
      options: items.map((item) => item.option),
      labels: items.map((item) => item.label),
    });
    if (analytics) {
      analytics.track('patch-status', {
        field: 'order',
        type,
      });
    }
  };

  const defaultValue = useMemo(() => {
    if (!externalPropertyStatus?.data?.data?.defaultValue)
      return type === 'account'
        ? DefaultDefaultCompanyStatus.option
        : DefaultDefaultContactStatus.option;
    return externalPropertyStatus?.data?.data?.defaultValue;
  }, [
    externalPropertyStatus?.data?.data?.defaultValue,
    externalPropertyStatus,
  ]);

  const handleChangeDefaultStatus = (option: string) => {
    if (!externalPropertyStatusId) {
      if (!isExternalPropertyStatus) {
        handleConfirmToPassToCustom({
          tableType: type === 'account' ? 'Account' : 'Prospect',
          defaultStatus: option,
          customStatusLabels: localStatuses?.map((status) => status.label),
          customStatusOptions: localStatuses?.map((status) => status.option),
        });
      }
      //This should not be possible, but here just in case
      return;
    }
    patchExternalProperty({
      externalPropertyId: externalPropertyStatusId,
      defaultValue: option,
      tableType: type === 'account' ? 'Account' : 'Prospect',
      type: 'List',
    });

    if (analytics) {
      analytics.track('patch-status', {
        field: 'default',
        type,
      });
    }
  };

  const { data: currentStatusMigration } = useGetApplyStatusMigration(
    type === 'account' ? 'Account' : 'Prospect',
  );

  const isChangingStatus =
    currentStatusMigration?.data?.data &&
    (currentStatusMigration?.data?.data.status === ImportStatusType.OnGoing ||
      currentStatusMigration?.data?.data.status === ImportStatusType.Started);

  useConditionalSnackBar([
    {
      condition: !!patchExternalPropertyOptionError,
      message:
        getError(patchExternalPropertyOptionError) ??
        'Error updating the status',
      severity: 'error',
    },
    {
      condition: !!errorSyncExternalProperty,
      message: getError(errorSyncExternalProperty) ?? 'Error syncing with CRM',
      severity: 'error',
    },
    {
      condition: !!createExternalPropertyOptionError,
      message:
        getError(createExternalPropertyOptionError) ?? 'Error creating status',
      severity: 'error',
    },
  ]);

  return (
    <>
      {showAddStatus && (
        <AddStatusContainer
          close={() => setShowAddStatus(false)}
          createLoading={creatingExternalPropertyOption}
          handleCreateNewStatus={handleCreateNewStatus}
          existingStatuses={localStatuses?.map((status) => status.label)}
        />
      )}
      {showCRMPropertiesPicker && (
        <LinkStatusToExternalProperty
          type={type}
          close={() => setShowCRMPropertiesPicker(false)}
        />
      )}
      <SingleStatusComponentWrapper>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              height: 40,
            }}
          >
            <SingleStatusIcon type={type} />
            <Typography fontSize={16} lineHeight={'24px'} fontWeight={500}>
              {type === 'account'
                ? 'Company'
                : user?.company?.integrationType === IntegrationType.SALESFORCE
                  ? 'Lead/contact'
                  : 'Contact'}{' '}
              status
            </Typography>
          </div>
          {isChangingStatus ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 12,
                paddingRight: 12,
              }}
            >
              <Typography
                fontSize={12}
                lineHeight={'16px'}
                fontWeight={500}
                color={colors.intenseBlue}
              >
                Changes in progress
              </Typography>
              <span className="loader changing-loader" />
            </div>
          ) : (
            isExternalPropertySynced &&
            user?.company?.integrationType &&
            (synchingExternalProperty ? (
              <CircularProgress />
            ) : (
              <div className="resync-crm-status" onClick={handleResyncStatus}>
                <ResyncButton CRM={user?.company?.integrationType} title={''} />
                <Typography
                  fontSize={'16px'}
                  fontWeight={500}
                  color={colors.intenseBlue}
                >
                  Sync with CRM
                </Typography>
              </div>
            ))
          )}
        </div>
        <div className="status-list-container">
          <div className="status-list-container-header">
            {!isExternalPropertySynced ? (
              <>
                <Tooltip
                  title={
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      The selected default status will be assigned automatically
                      to all new {type === 'account' ? 'companies' : 'contacts'}
                    </span>
                  }
                  arrow
                  enterDelay={400}
                  enterNextDelay={400}
                  placement="top"
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 4,
                      cursor: 'default',
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      lineHeight={'14px'}
                      width={50}
                    >
                      Default Status
                    </Typography>
                    <IconInformationS />
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    localStatuses?.length === FeConstants.MAX_STATUSES
                      ? `You can have up to ${FeConstants.MAX_STATUSES} statuses`
                      : null
                  }
                  arrow
                  enterDelay={400}
                  enterNextDelay={400}
                  placement="top"
                >
                  <div>
                    <MainPrimarySmallButton
                      startIcon={<PlusSmallIcon color={colors.white} />}
                      onClick={handleShowAddStatus}
                      disabled={
                        localStatuses?.length === FeConstants.MAX_STATUSES
                      }
                    >
                      Add Status
                    </MainPrimarySmallButton>
                  </div>
                </Tooltip>
              </>
            ) : (
              <>
                <div
                  className="external-property-name"
                  onClick={handleShowCRMPropertiesPicker}
                >
                  <Typography
                    fontSize={14}
                    lineHeight={'18px'}
                    fontWeight={500}
                    color={colors.primary}
                  >
                    {externalPropertyStatus?.data?.data?.externalName}
                  </Typography>
                  <EditIcon color={colors.primary} />
                </div>
                <Tooltip
                  title="All statuses synchronized with your CRM"
                  enterDelay={400}
                  enterNextDelay={400}
                  arrow
                >
                  <div className="linked-with-crm-tooltip">
                    <Typography
                      fontSize={12}
                      lineHeight={'14px'}
                      fontWeight={500}
                      color={colors.mainDark}
                    >
                      Linked with CRM
                    </Typography>
                    <IconInformationS color={colors.mainDark} />
                  </div>
                </Tooltip>
              </>
            )}
          </div>
          {statuses && statuses.length ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
                overflow: 'hidden',
              }}
            >
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={'droppable-id'}>
                  {(provided) => {
                    return (
                      <div
                        className="stages-container"
                        style={{
                          padding: isExternalPropertySynced
                            ? '4px 4px'
                            : '2px 10px 2px 4px',
                        }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {localStatuses?.map((status, index) => {
                          const colorsStatus = getColorsStatus(
                            index,
                            totalNumberOfStatuses ?? 0,
                          );
                          return (
                            <Draggable
                              key={status.id + (status.option ?? '')}
                              draggableId={status.id + ''}
                              isDragDisabled={isExternalPropertySynced}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <StatusSettingsCard
                                    syncedWithCRM={isExternalPropertySynced}
                                    handleChangeName={handleChangeName}
                                    type={type}
                                    status={status}
                                    colorsStatus={colorsStatus}
                                    provided={provided}
                                    snapshot={snapshot}
                                    statusFocused={statusFocused}
                                    setStatusFocused={setStatusFocused}
                                    index={index}
                                    handleDeleteStatus={handleDeleteStatus}
                                    defaultValue={defaultValue}
                                    handleChangeDefaultStatus={
                                      handleChangeDefaultStatus
                                    }
                                    disableDelete={
                                      statuses.length <=
                                      FeConstants.MINIMUM_STATUSES
                                    }
                                  />
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <Typography>No statuses found</Typography>
          )}
        </div>
        {/* Currently we do not allow Salesforce subscriptions to link to CRM  prospect EP*/}
        {user?.integrationType !== IntegrationType.CRONO &&
          (user?.integrationType !== IntegrationType.SALESFORCE ||
            type === 'account') && (
            <div className="end-of-page-message">
              <Typography fontSize={14} fontWeight={500} lineHeight={'18px'}>
                {isExternalPropertySynced
                  ? 'If you want to unlink Contact status the CRM, consider that all assigned statuses will be changed to custom'
                  : 'If you want linked statuses with CRM, select the column in the CRM that you would like to use as status'}
              </Typography>
              <div
                className="text-button-link"
                onClick={handleChangeTypeOfStatus}
              >
                {isExternalPropertySynced ? <Unlink /> : <IntegrationS />}{' '}
                <Typography
                  fontSize={14}
                  lineHeight={'18px'}
                  fontWeight={500}
                  color={colors.primary}
                >
                  {isExternalPropertySynced
                    ? 'Create custom statuses'
                    : 'Integrate statuses from CRM'}
                </Typography>
              </div>
            </div>
          )}
      </SingleStatusComponentWrapper>
    </>
  );
};

interface IPropsIcon {
  type: StatusComponentOptions;
}

const SingleStatusIcon = ({ type, ...rest }: IPropsIcon & any) => {
  switch (type) {
    case 'account':
      return <IconMCompany {...rest} />;
    case 'prospect':
      return <IconMContact {...rest} />;
    default:
      return null;
  }
};

export default SingleStatusComponent;

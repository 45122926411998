import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { colors } from 'crono-fe-common/theme';

export const CronoStatusWrapper = styled.div`
  max-width: 130px;
  height: fit-content;
  border-radius: 9999px;
  text-align: center;
  padding: 8px 16px;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
`;

export const StatusTagButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  width: fit-content;
  max-width: 180px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid ${colors.grey3};
  white-space: nowrap;
  overflow: hidden;
  .status-settings-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 4px;
  }
`;

export const StatusTooltipPickerWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  padding: 12px 8px;
  .status-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
  }
`;

export const SingleStatusOptionComponentWrapper = styled.div`
  cursor: pointer;
  .status-option-picker-not-selected,
  .status-option-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px 8px 16px;
    font-size: 12px;
    min-width: 130px;
    width: fit-content;
    border-radius: 20px;
    width: 130px;
  }
`;

import ExternalPropertyFilter from '../externalPropertyFilter';
import { ProspectStatus } from '../prospect';
import { SequenceStatusTypeDTO } from '../enums/prospectSequenceStatus';
import {
  DefaultCompanyStatusOptions,
  DefaultContactStatusOptions,
} from '../model';

export type PhoneStatuses = 'Valid' | 'Invalid';
export type EmailStatuses = PhoneStatuses | 'AcceptAll' | 'Unknown';

export const phoneStatuses: PhoneStatuses[] = ['Valid', 'Invalid'];
export const emailStatuses: EmailStatuses[] = [
  'Valid',
  'AcceptAll',
  'Invalid',
  'Unknown',
];

export interface ProspectSearch {
  limit: number;
  offset: number;
  name: string | null;
  onlyOwned?: boolean | null;
  status: ProspectStatus[] | null;
  actualStatus?: DefaultContactStatusOptions[] | null;
  accountStatus?: DefaultCompanyStatusOptions[] | null;
  externalProperties?: ExternalPropertyFilter[] | null;
  //Its important to list this after the externalProperties in order to show in the filter resume first the contact ones
  accountExternalProperties?: ExternalPropertyFilter[] | null;
  industry?: string;
  country?: string | null;
  numberOfEmployeesMin?: number | null;
  numberOfEmployeesMax?: number | null;
  verificationStatuses?: EmailStatuses[] | null;
  isPhoneValid?: boolean | null;
  userId?: number | null;
  currentSolution?: string;
  inSequence?: boolean | null;
  title?: string;
  strategyId?: number | null;
  sequenceStatus?: SequenceStatusTypeDTO | null;
  objectIds?: string[] | null;
  accountId?: string | null;
  createdDateMin?: string | null;
  createdDateMax?: string | null;
  lastActivityDateMin?: string | null;
  lastActivityDateMax?: string | null;
  sort: ProspectSearchSort;
  importId?: number | null;
  hasLinkedin?: boolean | null;
  hasEmail?: boolean | null;
  hasPhone?: boolean | null;
  hasMobilePhone?: boolean | null;
  fromContact?: boolean | null;

  // these get removed before sending to BE
  selected?: boolean | null;
  importName?: string | null;
}

export type ProspectSearchSort =
  | 'Name'
  | 'NameDesc'
  | 'CompanyName'
  | 'CompanyNameDesc'
  | 'LastActivity'
  | 'LastActivityDesc'
  | 'CreatedDate'
  | 'CreatedDateDesc'
  | 'Progress'
  | 'ProgressDesc'
  | 'Tasks'
  | 'TasksDesc'
  | 'Owner'
  | 'OwnerDesc';

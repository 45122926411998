import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import ClockIconTwelveFifteen from 'crono-fe-common/icons/Icon-Clock-12-15';
import { colors } from 'crono-fe-common/theme';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import moment from 'moment';
import React from 'react';

interface IProps {
  task: TaskTodo;
}

const TimeBadge = ({ task }: IProps) => {
  const isPast = moment(task.activityDate).isBefore(new Date());
  return (
    <TimeBadgeWrapper className={isPast ? 'past' : ''}>
      <ClockIconTwelveFifteen
        color={isPast ? colors.inactive : colors.grey11}
        className="icon-time"
      />
      <Typography
        fontSize={10}
        lineHeight={'12px'}
        fontWeight={500}
        color={isPast ? colors.inactive : colors.grey11}
      >
        {moment(task.activityDate).format('HH:mm')}
      </Typography>
    </TimeBadgeWrapper>
  );
};

export default TimeBadge;

const TimeBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 4px 2px 2px;
  gap: 4px;
  border-radius: 8px;
  background: ${colors.badgeBackground};
  height: 16px;
  .icon-time {
    height: 12px;
    width: 12px;
    flex-shrink: 0;
  }
  &.past {
    background: ${colors.inactiveLight};
  }
`;

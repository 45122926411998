import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StrategyOverviewContent, StrategyOverviewTab } from './types';
import { StrategyOverviewWrapper } from './style';
import { CloseButtonDarker } from 'crono-fe-common/components/CronoButton';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import {
  TextField,
  FormControl,
  ClickAwayListener,
  Typography,
  CircularProgress,
} from '@mui/material';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import ThreeDotsIcon from 'crono-fe-common/icons/Icon-Three-dots';
import { CustomOptionsTooltip } from 'pages/settings/Strategy';
import SequenceOptions from 'pages/settings/Strategy/sequenceOptions';
import { useSequenceContext } from './context/sequence';
import { useJuneAnalytics } from 'context/june';
import usePatchSequence from 'hooks/services/sequence/usePatchSequence';
import useDeleteStrategy from 'hooks/services/sequence/useDeleteStrategy';
import useDuplicateStrategy from 'hooks/services/sequence/useDuplicateStrategy';
import useStopStrategy from 'hooks/services/sequence/useStopStrategy';
import { useConfirmModal } from 'context/confirmModal';
import { TagInsert } from 'crono-fe-common/types/tagInsert';
import useApplyChangesToSequenceInstances from 'hooks/services/sequence/useApplyChangesToSequenceInstances';
import useGetSequence from 'hooks/services/sequence/useGetSequence';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError, getNewOptionsWhenSelecting } from 'crono-fe-common/utils';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import AccountTabTab from 'pages/accountTab/tab';
import { AccountTabsContainer } from 'pages/accountTab/tab/style';
import StrategyDetailModal from './strategyDetailModal';
import StrategyContactsModal from 'context/strategyDetail/strategyContacts/strategyContactsModal';
import { SequenceFilterStatusType } from 'crono-fe-common/types/DTO/strategyDetailSearch';
import { useWindow } from 'context/sideTab';
import StrategyPersonalise from './strategyPersonalise';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import IconStarsInsights from 'crono-fe-common/icons/Icon-Stars-Insights';
import { useSafeStrategyOverviewContext } from './index';
import { FeConstants } from 'constants/FeConstants';
import { ReactComponent as IconOpenAI } from 'assets/images/Icon_OpenAI.svg';
import { useAuth } from 'context/auth';

interface IProps {
  sequenceId: number;
  setStrategyDetailContent: (
    value: React.SetStateAction<StrategyOverviewContent | null>,
  ) => void;
  initialTab: StrategyOverviewTab;
  initialFilter?: SequenceFilterStatusType;
  onApplySequenceChanges?: () => void;
  handleRedirectToHome: () => void;
}

const StrategyOverview = ({
  sequenceId,
  setStrategyDetailContent,
  initialTab,
  initialFilter,
  onApplySequenceChanges,
  handleRedirectToHome,
}: IProps) => {
  const { user } = useAuth();
  const analytics = useJuneAnalytics();

  const { sequence, setSequence, isNewSequenceView } = useSequenceContext();

  //IsRefetching has to be used since opening a strategy -> performing a task -> reopening the same strategy would lead to
  //react-query to use the old data while waiting for the new ones, but since we just take the first data coming from the BE
  //and then not align anymore with the BE (to handle everything locally) we need to make sure that the data is not refetching
  const { data: originalSequence, isRefetching: refetchingOriginalSequence } =
    useGetSequence(sequenceId);
  useEffect(() => {
    if (
      !refetchingOriginalSequence &&
      originalSequence &&
      originalSequence.data &&
      //Update only if the originalSequence is a new one
      (sequence.id === -1 || sequence.id !== originalSequence.data.data.id)
    ) {
      setSequence(originalSequence.data.data);
    }
  }, [originalSequence, refetchingOriginalSequence]);

  const {
    mutate: patchSequence,
    error: errorPatchSequence,
    isSuccess: sequencePatched,
  } = usePatchSequence();

  //True if the sequence has changed during the session, to apply changes to the active sequenceInstances
  const changed = useRef(false);
  //To show only the first time the banner to save the sequence
  const [autoSaveShowed, setAutoSaveShowed] = useState(false);

  const { openModal: openConfirmModal } = useConfirmModal();

  const [optionSequenceToogle, setOptionSequenceToogle] = useState<
    number | null
  >(null);

  const { windowContent, closeWindow } = useWindow();
  const {
    insightsContext: {
      insightsButtonRef,
      prospect,
      setShowInsightTab,
      setRewriteEnabledMode,
      rewriteEnabledMode,
      resetInsightsContext,
    },
  } = useSafeStrategyOverviewContext();

  const close = () => {
    if (windowContent) {
      closeWindow();
    }
    setStrategyDetailContent(null);
    resetInsightsContext();
  };

  const [selectedTab, setSelectedTab] =
    useState<StrategyOverviewTab>(initialTab);

  const handleChangeTab = (tab: StrategyOverviewTab) => {
    if (selectedTab === tab) {
      return;
    }

    resetInsightsContext();

    if (selectedTab === StrategyOverviewTab.Detail) {
      handleMoveAwayFromDetail(() => setSelectedTab(tab));
    } else {
      setSelectedTab(tab);
    }
  };

  const handleChangeName = (ev: any) => {
    if (isNewSequenceView) {
      setSequence({ ...sequence, name: ev.target.value });
    } else {
      //I set the Id so that the sequence also get update with the one coming from the BE

      setSequence({ ...sequence, id: -1, name: ev.target.value });

      patchSequence({
        id: sequence.id,
        strategyId: sequence.strategyId,
        name: ev.target.value,
      });
      if (analytics) {
        analytics.track('patch_sequence', {
          patch: 'name',
        });
      }
    }
  };

  const handleChangeShared = (shared: boolean) => {
    if (isNewSequenceView) {
      setSequence({ ...sequence, shared });
    } else {
      //I change also the id to refresh the sequence API call
      setSequence({ ...sequence, shared, id: -1 });
      patchSequence({
        id: sequence.id,
        strategyId: sequence.strategyId,
        shared,
      });
      if (analytics) {
        analytics.track('patch_sequence', {
          patch: 'shared',
        });
      }
    }
  };

  // -------- Top right corner 3 dots icon logic
  const {
    mutate: duplicateStrategy,
    isSuccess: successDuplicateSequence,
    error: errorDuplicateSequence,
    // isLoading: duplicateLoading
  } = useDuplicateStrategy();

  const {
    mutate: stopStrategy,
    isSuccess: successStoppedStrategy,
    error: errorStopStrategy,
    // isLoading: loadingStopStrategy
  } = useStopStrategy();
  const {
    mutate: deleteStrategy,
    error: errorDeleteStrategy,
    isSuccess: strategyDeleted,
  } = useDeleteStrategy();

  const handleStopStrategy = (
    strategyId: number,
    activeCount?: number | null,
  ) => {
    openConfirmModal({
      title: `Are you sure you want to stop ${activeCount ?? 'all'} active ${activeCount === 1 ? 'contact' : 'contacts'}?`,
      cancelText: 'Cancel',
      confirmText: 'Stop',
      confirmFunction: () => {
        stopStrategy(strategyId);
        if (analytics) {
          analytics.track('strategy-stopped', {
            strategyId: strategyId,
          });
        }
      },
      cancelFunction: () => {
        return;
      },
    });
  };

  const handleDeleteStrategy = (strategyId: number) => {
    openConfirmModal({
      title: 'Are you sure you want to delete this strategy?',
      cancelText: 'Cancel',
      confirmText: 'Delete',
      cancelFunction: () => {
        return;
      },
      confirmFunction: () => {
        deleteStrategy(strategyId);
        if (analytics) {
          analytics.track('strategy-deleted', {
            strategyId: strategyId,
          });
        }
      },
    });
  };

  const [tagSelected, setTagSelected] = useState<TagInsert[]>([]);

  useEffect(() => {
    if (sequence && sequence.tags) {
      const newTags = sequence.tags;
      setTagSelected(newTags);
    } else if (sequence && sequence.tags === null) {
      setTagSelected([]);
    }
  }, [sequence]);

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const newExternalValue = {
      externalPropertyId: externalProperty.id,
      value: getNewOptionsWhenSelecting({
        currentExternalValues: tagSelected,
        externalPropertyId: externalProperty.id,
        option,
        valueType: externalProperty.valueType,
      }),
    };

    if (isNewSequenceView) {
      const externalPropertyId = externalProperty.id;
      const newTags = [...tagSelected];

      const oldTag = tagSelected.find(
        (tag) => tag.externalPropertyId === externalPropertyId,
      );
      //If option is null I have to remove the externalPropertyId selected
      if (oldTag && (oldTag?.value === option || option === null)) {
        newTags.splice(
          newTags.findIndex(
            (tag) => tag.externalPropertyId === externalPropertyId,
          ),
          1,
        );
      } else if (oldTag && newExternalValue.value !== null) {
        newTags.splice(
          newTags.findIndex(
            (tag) => tag.externalPropertyId === externalPropertyId,
          ),
          1,
          newExternalValue as TagInsert, //For ts to understand that the value field is not null
        );
      } else if (newExternalValue.value !== null) {
        newTags.push(newExternalValue as TagInsert); //For ts to understand that the value field is not null
      }

      setSequence({
        ...sequence,
        tags: newTags.map((t, idx) => ({ ...t, id: idx })),
      });
    } else {
      //This way the sequence will be updated with the one coming from the server
      setSequence({ ...sequence, id: -1 });
      patchSequence({
        id: sequence.id,
        strategyId: sequence.strategyId,
        strategyTags: [newExternalValue],
      });
      if (analytics) {
        analytics.track('patch_sequence', {
          patch: 'tags',
        });
      }
    }
  };

  const {
    mutateAsync: applyChangesToSequenceInstances,
    isLoading: applyingChangesToSequenceInstances,
    isSuccess: sequenceInstancesUpdated,
    error: errorUpdatingSequenceInstances,
  } = useApplyChangesToSequenceInstances();

  const creatingNewSequenceIsInProgress = useMemo(() => {
    return (
      isNewSequenceView &&
      (sequence.name !== FeConstants.defaultNewStrategyName ||
        sequence.tags?.length > 0 ||
        sequence.shared)
    );
  }, [sequence, isNewSequenceView]);

  //This function checks if there is any changes to be applied to existing sequences before moving away
  const handleMoveAwayFromDetail = (onDone: (e?: any) => any) => {
    if (creatingNewSequenceIsInProgress) {
      openConfirmModal({
        title: `Are you sure you want to close the strategy`,
        text: `If you close a strategy before creating at least one step all changes will be discarded`,
        confirmText: 'Close',
        cancelText: 'Cancel',
        confirmFunction: onDone,
        cancelFunction: () => {},
        disableClickOnBackground: true,
      });
    } else {
      if (changed.current && (sequence.activeCount ?? 0) > 0) {
        openConfirmModal({
          title: `Apply changes to ${sequence.activeCount} ${
            sequence.activeCount === 1 ? 'strategy' : 'strategies'
          }?`,
          text: `Do you want to apply these changes also to already active strategies?`,
          confirmText: 'Apply',
          cancelText: "Don't apply",
          confirmFunction: async () => {
            await applyChangesToSequenceInstances(sequence.strategyId);
            if (onApplySequenceChanges) {
              onApplySequenceChanges();
            }
            openConfirmModal(null);
            changed.current = false;
            onDone();
          },
          cancelFunction: () => {
            changed.current = false;
            onDone();
          },
          disableClickOnBackground: true,
        });
      } else {
        onDone();
      }
    }
  };

  const handleClickOnBackground = () => {
    if (changed.current) {
      handleMoveAwayFromDetail(close);
    } else {
      close();
    }
  };

  const [showAutoSavePopup, setShowAutoSavePopup] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (autoSaveShowed) {
      setShowAutoSavePopup(true);
      timeout = setTimeout(() => {
        setShowAutoSavePopup(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [autoSaveShowed]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (sequencePatched) {
      if (!autoSaveShowed) {
        setAutoSaveShowed(true);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [sequencePatched]);

  useConditionalSnackBar([
    {
      condition: !!errorDeleteStrategy,
      message: getError(errorDeleteStrategy) ?? 'Error deleting strategy',
      severity: 'error',
    },
    {
      condition: !!strategyDeleted,
      message: 'Strategy deleted',
      severity: 'success',
    },
    {
      condition: !!errorDuplicateSequence,
      message: getError(errorDuplicateSequence) ?? 'Error duplication strategy',
      severity: 'error',
    },
    {
      condition: !!successDuplicateSequence,
      message: 'Strategy duplicated successfully',
      severity: 'success',
    },
    {
      condition: !!errorStopStrategy,
      message: getError(errorStopStrategy) ?? 'Error pausing strategy',
      severity: 'error',
    },
    {
      condition: !!successStoppedStrategy,
      message: 'Strategy paused',
      severity: 'success',
    },
    {
      condition: !!errorUpdatingSequenceInstances,
      message:
        getError(errorUpdatingSequenceInstances) ??
        'Error updating the strategy instances',
      severity: 'error',
    },
    {
      condition: !!sequenceInstancesUpdated,
      message: 'Strategy instances updated',
      severity: 'success',
    },
    {
      condition: !!errorPatchSequence,
      message: getError(errorPatchSequence) ?? 'Error updating the strategy',
      severity: 'error',
    },
    {
      condition: showAutoSavePopup,
      message: 'Changes saved successfully',
      severity: 'success',
    },
  ]);

  const onRewriteWithAIClick = () => {
    setShowInsightTab((prevShowInsightTab) => {
      setRewriteEnabledMode((prev) =>
        prevShowInsightTab && prev ? null : 'sequenceInstance',
      );

      return !(prevShowInsightTab && rewriteEnabledMode);
    });
  };

  return (
    <>
      <StrategyOverviewWrapper
        id="strategy-overview-wrapper"
        style={{
          ...(selectedTab === StrategyOverviewTab.Personalise
            ? { width: '73%' }
            : {}),
        }}
      >
        {applyingChangesToSequenceInstances && (
          <BlurBackgroundDivFixed
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </BlurBackgroundDivFixed>
        )}
        <div className="sequence-detail-header">
          <div className="sequence-detail-header-title">
            <div className="close-button-wrapper">
              <CloseButtonDarker
                size="large"
                onClick={() => handleMoveAwayFromDetail(close)}
                className="close-detail-button"
              >
                <CloseMIcon color={colors.grey11} />
              </CloseButtonDarker>
            </div>
            <div className="header-tags-container">
              <div style={{ display: 'flex', minWidth: '100%' }}>
                {sequence?.name && (
                  <TextField
                    placeholder="Add a strategy name"
                    defaultValue={sequence?.name || ''}
                    key={sequence.id}
                    onKeyDown={(ev: any) => {
                      if (ev.key === 'Enter' && ev.target.value.trim() !== '') {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChangeName(ev);
                        ev.target.blur();
                      }
                    }}
                    fullWidth
                    onBlur={(ev: any) => {
                      if (ev.target.value.trim() !== '') {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChangeName(ev);
                      }
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: 'title',
                      },
                    }}
                  />
                )}
                <div className="header-buttons-container">
                  <FormControl
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      lineHeight={'18px'}
                      color={colors.grey11}
                    >
                      Private
                    </Typography>
                    <CronoSwitch
                      checked={!sequence.shared}
                      onChange={() => {
                        handleChangeShared(!sequence.shared);
                      }}
                    />
                  </FormControl>
                  {selectedTab === StrategyOverviewTab.Detail &&
                    !isNewSequenceView && (
                      <>
                        <ClickAwayListener
                          onClickAway={() => setOptionSequenceToogle(null)}
                        >
                          <div>
                            <CustomOptionsTooltip
                              placement="bottom"
                              title={
                                <SequenceOptions
                                  addContacts={handleRedirectToHome}
                                  stopStrategy={() =>
                                    handleStopStrategy(
                                      sequence.strategyId,
                                      sequence.activeCount,
                                    )
                                  }
                                  duplicateStrategy={() =>
                                    duplicateStrategy(sequence.strategyId)
                                  }
                                  deleteStrategy={() =>
                                    handleDeleteStrategy(sequence.strategyId)
                                  }
                                  enableResume={true}
                                  sequenceId={sequence.strategyId}
                                />
                              }
                              maxHeight={260}
                              width={240}
                              left={-24}
                              borderRadius={'16px'}
                              open={optionSequenceToogle === sequence.id}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              sx={{
                                zIndex: '5001 !important',
                              }}
                            >
                              <div
                                className="strategy-options"
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  setOptionSequenceToogle(
                                    (prev: number | null) => {
                                      if (prev === null) {
                                        return sequence.id;
                                      } else {
                                        return null;
                                      }
                                    },
                                  );
                                }}
                              >
                                <ThreeDotsIcon />
                              </div>
                            </CustomOptionsTooltip>
                          </div>
                        </ClickAwayListener>
                      </>
                    )}
                </div>
              </div>
              <TagsBoxShowAll
                type="AccountAndProspect"
                currentSituationTags={tagSelected.map((tagSelected) => {
                  return {
                    externalPropertyId: tagSelected.externalPropertyId,
                    option: tagSelected.value,
                  };
                })}
                handleClickTag={handleClickTag}
                showRemovable={false}
                whiteBackground={true}
              />
            </div>
          </div>
          <div className="tabs-header-container">
            <AccountTabsContainer>
              <AccountTabTab
                selected={selectedTab === StrategyOverviewTab.Detail}
                label={'Structure'}
                onClick={() => {
                  handleChangeTab(StrategyOverviewTab.Detail);
                }}
              />
              <div
                className="tabs-divider"
                style={{
                  visibility:
                    selectedTab === StrategyOverviewTab.Detail
                      ? 'visible'
                      : 'hidden',
                }}
              />
              <AccountTabTab
                selected={selectedTab === StrategyOverviewTab.Personalise}
                label={
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                      lineHeight: '20px',
                    }}
                  >
                    Personalize <div className="new-feature-badge">New</div>
                  </span>
                }
                onClick={() => {
                  handleChangeTab(StrategyOverviewTab.Personalise);
                }}
              />
              <div
                className="tabs-divider"
                style={{
                  visibility:
                    selectedTab === StrategyOverviewTab.Personalise
                      ? 'visible'
                      : 'hidden',
                }}
              />
              <AccountTabTab
                selected={selectedTab === StrategyOverviewTab.Metrics}
                label={'Metrics'}
                onClick={() => {
                  handleChangeTab(StrategyOverviewTab.Metrics);
                }}
              />
            </AccountTabsContainer>
            {selectedTab === StrategyOverviewTab.Personalise && prospect && (
              <>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    gap: '4px',
                  }}
                >
                  <button
                    type={'button'}
                    className={'rewrite-with-ai-button'}
                    style={{
                      height: '32px',
                      borderRadius: '8px',
                      padding: '7px 10px 7px 8px',
                    }}
                    onClick={onRewriteWithAIClick}
                  >
                    <IconOpenAI width={20} height={20} />
                    <p style={{ fontSize: 12 }}>Rewrite all with AI</p>
                    <div className="new-feature-badge">New</div>
                  </button>
                  <div
                    ref={insightsButtonRef}
                    className="template-button-container insights-button"
                    onClick={() => {
                      setShowInsightTab((prev) => {
                        if (prev) setRewriteEnabledMode(null);
                        return !prev;
                      });
                    }}
                  >
                    <span className="flex-center icon-wrapper">
                      <IconStarsInsights
                        className={'template-button-icon'}
                        color={colors.black}
                        viewBox="0 0 16 16"
                      />
                    </span>
                    <Typography style={{ fontSize: 12, fontWeight: 500 }}>
                      Insights
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {selectedTab === StrategyOverviewTab.Detail ? (
          <div
            style={{
              flex: 1,
              overflow: 'hidden',
              padding: '24px 0px 24px 24px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <StrategyDetailModal
              changed={changed}
              autoSaveShowed={autoSaveShowed}
              setAutoSaveShowd={setAutoSaveShowed}
              handleClickOnBackground={handleClickOnBackground}
            />
          </div>
        ) : selectedTab === StrategyOverviewTab.Metrics ? (
          <div
            style={{
              flex: 1,
              overflow: 'hidden',
              display: 'flex',
            }}
          >
            <StrategyContactsModal
              sequenceId={sequenceId}
              initialFilter={initialFilter ?? SequenceFilterStatusType.ALL}
              handleClickOnBackground={handleClickOnBackground}
            />
          </div>
        ) : selectedTab === StrategyOverviewTab.Personalise ? (
          <>
            <div
              style={{
                flex: 1,
                overflow: 'hidden',
                padding: '24px 0px 24px 24px',
                display: 'flex',
              }}
            >
              <StrategyPersonalise sequenceId={sequenceId} />
            </div>
          </>
        ) : (
          <></>
        )}
      </StrategyOverviewWrapper>
    </>
  );
};

export default StrategyOverview;

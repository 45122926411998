import { CallModalWrapper } from './styles';
import React, { useEffect, useMemo, useState } from 'react';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { MainButton } from 'crono-fe-common/components/CronoButton';
import {
  Avatar,
  Button,
  Checkbox,
  IconButton,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import {
  adjustDateToTimezone,
  getError,
  htmlToText,
} from 'crono-fe-common/utils';
import useLogCall from 'hooks/services/event/useLogCall';
import { CallFeedback, LogCall } from 'crono-fe-common/types/logCall';
import { useConditionalSnackBar } from 'context/snackbar';
import { useJuneAnalytics } from 'context/june';
import { useSelectedAccount } from 'pages/home/context/account';
import { ProspectCardWrapper } from '../contactView/style';
import CallIcon from 'crono-fe-common/icons/Icon-Call';
import { ReactComponent as EditIcon } from 'crono-fe-common/icons/Edit.svg';
import {
  ContentCopy,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import { useConfirmModal } from 'context/confirmModal';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import {
  convertSecondstoTime,
  replaceAllTemplateVariables,
  stringAvatarProspect,
  transformToValidUrl,
} from 'utils/fe-utils';
import useGetProspect from 'hooks/services/prospect/useGetProspect';
import FindPhonePreconditionButton from 'pages/taskBoard/suggestionsBox/suggestionCards/findPhonePreconditionButton';
import { useHideTaskConstruction } from '../hideTaskConstructionContext';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import IconAircallWhite from 'crono-fe-common/icons/Icon-Aircall-White';
import useCallExternal from 'hooks/services/call/useCallExternal';
import { ExternalCallLog } from 'crono-fe-common/types/externalCallLog';
import moment from 'moment';
import CallLogIcon from 'crono-fe-common/icons/Icon-Call-Log';
import { ReactComponent as RedirectIcon } from 'crono-fe-common/icons/Redirect.svg';
import MobilePhoneIcon from 'crono-fe-common/icons/Icon-Mobile-Phone';
import { PhoneOption } from 'context/sideTab/types';
import ActivitiesBoxInsideActivityView from '../activitiesInsideActivityView';
import { useAuth } from 'context/auth';
import { isComplete } from 'crono-fe-common/types/sequenceInstance';
import IconStarsInsights from 'crono-fe-common/icons/Icon-Stars-Insights';
import InsightsCard from '../emailView/insightsCard';
import CallLogModal from './callLogModal';
import { TemplateCardsContainerTooltip } from '../linkedinView';
import TemplatesTooltipCard from '../emailView/TemplatesTooltipCard';
import { Template } from 'crono-fe-common/types/template';
import AddTemplateIconS from 'crono-fe-common/icons/Icon-Add-Template-S';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import useGetTemplateVariables from 'hooks/services/variables/useGetTemplateVariables';
import IconTimeZone from 'crono-fe-common/icons/Icon-Time-Zone';

interface IProps {
  task?: TaskTodo | null;
  profileId?: string;
  selectedThreadId?: string | null;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenHint?: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateLinkedinStatus?: React.Dispatch<React.SetStateAction<string | null>>;
  setUpdateAction?: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: () => void;
  selectedProspectId?: string | null;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  callOnSuccess?: () => void;
  suggestionId?: number | null;
  handleCloseModal: () => void;
  visibility: 'none' | 'visible';
  initialPhoneSelected?: PhoneOption;
}

const CallView = ({
  task,
  selectedProspectId,
  setTabValue,
  callOnSuccess,
  suggestionId,
  handleCloseModal,
  visibility,
  initialPhoneSelected,
}: IProps) => {
  const analytics = useJuneAnalytics();
  const { user } = useAuth();
  const { account } = useSelectedAccount();

  const { data: prospectFromDb } = useGetProspect(selectedProspectId);
  const prospect = useMemo(() => {
    if (
      prospectFromDb?.data?.data &&
      account?.objectId === prospectFromDb.data.data.accountId
    ) {
      return prospectFromDb.data.data;
    }
    return null;
  }, [prospectFromDb, account?.objectId]);

  const [note, setNote] = useState<string>('');
  const [selectedCallFeedback, setSelectedCallFeedback] =
    useState<CallFeedback | null>(null);
  const [logCallError, setLogCallError] = useState<string | null>(null);

  const selectCallFeedback = (feedBack: CallFeedback) => {
    setSelectedCallFeedback(feedBack);
  };

  const {
    mutate: createLogCall,
    isSuccess: logCallSuccess,
    error: createLogCallError,
    isLoading: isLoggingCall,
  } = useLogCall();

  const { mutate: updateProspect, error: errorUpdateProspect } =
    useEditProspect();

  useEffect(() => {
    //If the log goes well, I bring the user to the company view and reset the cache
    //I also call the funciton callOnSuccess if it exists
    if (logCallSuccess) {
      if (callOnSuccess) callOnSuccess();
      handleCloseModal();
    }
  }, [logCallSuccess]);

  const logCall = (feedback: CallFeedback, sequenceStop: boolean) => {
    if (account && prospect) {
      const callToLog: LogCall = {
        accountId: account?.objectId,
        prospectId: prospect?.objectId,
        taskTodoId: task?.id ?? null,
        suggestionId: suggestionId ?? null,
        note: note,
        telephone: phoneNumber ?? '',
        callFeedback: feedback,
        stopSequence: sequenceStop,
      };
      //If the action performed is part of a sequence, I log it into the analytics
      if (task?.id) {
        if (analytics) {
          analytics.track('sequence-task', {});
        }
      }
      if (analytics) {
        analytics.track('log-call', {});
      }
      createLogCall(callToLog);
    }
  };

  const { openModal: openConfirmModal } = useConfirmModal();

  const ultimateLogCall = (feedback: CallFeedback) => {
    //If the feedback is a connection, I ask to the user if he wants to keep the prospect in sequence
    //if he is inside one
    // console.log(feedback, prospect?.sequenceInstance);

    if (
      feedback === CallFeedback.CONNECTED &&
      prospect?.sequenceInstance &&
      prospect?.sequenceInstance.active === true &&
      !isComplete(prospect.sequenceInstance)
    ) {
      openConfirmModal({
        title: 'Contact in strategy',
        text: 'The contact is in a strategy, do you want to keep it in the strategy?',
        confirmText: 'Remove it',
        cancelText: 'Keep in strategy',
        confirmFunction: () => {
          if (feedback) logCall(feedback, true);
        },
        cancelFunction: () => {
          if (feedback) logCall(feedback, false);
        },
      });
    } else {
      logCall(feedback, false);
    }
  };

  const [openLogModal, setOpenLogModal] = useState<boolean>(false);

  const handleLogClick = () => {
    const feedback = selectedCallFeedback;

    if (!feedback) {
      setLogCallError('Please select a call feedback');
      // clear error after 3 seconds
      setTimeout(() => {
        setLogCallError(null);
      }, 3000);

      return;
    }

    //If we are in a dialer campaign in progress we ask for confirmation
    if (task?.inChargeStatus === 'InCharge') {
      openConfirmModal({
        title: 'Are you sure you want to log a call during a dialer campaign?',
        text: 'This call is still in progress. Logged call will be saved as another one. The dailer campaign will continue to run',
        confirmText: 'Log',
        cancelText: 'Cancel',
        confirmFunction: () => {
          ultimateLogCall(feedback!);
        },
        cancelFunction: () => {},
      });
      return;
    }
    ultimateLogCall(feedback!);
  };

  const [phoneSelected, setPhoneSelected] = useState<PhoneOption>('phone');

  useEffect(() => {
    if (prospect) {
      if (prospect.phone && !prospect.mobilePhone) {
        setPhoneSelected('phone');
      } else if (!prospect.phone && prospect.mobilePhone) {
        setPhoneSelected('mobilePhone');
      } else if (initialPhoneSelected) {
        setPhoneSelected(initialPhoneSelected);
      }
    }
  }, [prospect?.objectId]);

  useEffect(() => {
    if (phoneSelected === 'phone') {
      setPhoneNumber(prospect?.phone || '');
    } else {
      setPhoneNumber(prospect?.mobilePhone || '');
    }
  }, [phoneSelected, prospect]);

  const [showRetrieveAfterCall, setShowRetrieveAfterCall] =
    useState<boolean>(false);

  const handleShowRetrieveAfterCall = () => {
    if (!user?.aircallSettings) return;
    //If the user has aircall settings, after I call I show the button to retrieve the call
    setShowRetrieveAfterCall(true);
  };

  useEffect(() => {
    setExternalLog(null);
    setShowRetrieveAfterCall(false);
    setNote('');
    setSelectedCallFeedback(null);
  }, [prospect?.objectId]);

  const [phoneNumber, setPhoneNumber] = useState<string | null>('');

  //Function to handle the change of information of a prospect
  //This is called when the enter is pressed
  const handleChange = (
    prospectId: string | null | undefined,
    event: any,
    phoneSelected: PhoneOption,
  ) => {
    if (!prospectId) return;
    const { value } = event.target;
    //I update the prospect with the new information
    updateProspect({
      prospectId: prospectId,
      ...(phoneSelected === 'phone'
        ? { phone: value }
        : { mobilePhone: value }),
    });
  };

  const handleChangeFeedback = (feedback: CallFeedback) => {
    selectCallFeedback(feedback);
  };

  const handleChangeNote = (note: string) => {
    setNote(note);
  };

  const { openModal } = useConfirmModal();

  const handleCall = (number: string | null) => {
    if (task?.inChargeStatus === 'InCharge') {
      openModal({
        title: 'Do you want to call contact during a dialer campaign?',
        text: 'This call is still in progress. Logged call will be saved as another one. The dailer campaign will continue to run',
        confirmText: 'Call',
        cancelText: 'Cancel',
        confirmFunction: () => {
          window.location.replace(`tel:${number ?? ''}`);
          handleShowRetrieveAfterCall();
        },
        cancelFunction: () => {},
      });
    } else {
      window.location.replace(`tel:${number ?? ''}`);
      handleShowRetrieveAfterCall();
    }
  };

  const { setHideProgressTask } = useHideTaskConstruction();

  //When the prospect is loaded, I remove the panel to hide the changes to the page
  useEffect(() => {
    if (prospect) {
      setHideProgressTask(false);
    }
    //The dependency of task is to allow the screen to be shut down even in case of two tasks directed to the same prospect and of the same type (the prospect would be already loaded
    //and the useEffect would not be fired)
  }, [prospect, task]);

  const [showError, setShowError] = useState<string | null>(null);

  useEffect(() => {
    let t: NodeJS.Timeout;
    if (showError) {
      t = setTimeout(() => {
        setShowError(null);
      }, 3000);
    }
    return () => clearTimeout(t);
  }, [showError]);

  const {
    mutateAsync: retrieveExternalCall,
    error: errorRetrievingExternalCall,
  } = useCallExternal();

  const [externalLog, setExternalLog] = useState<ExternalCallLog | null>(null);

  const retrieveCall = async (
    number: string | null,
    selectedPhone: PhoneOption,
  ) => {
    if (number) {
      const externalLogResult = await retrieveExternalCall(number);
      if (!externalLogResult.data?.data) {
        setShowError('No call found for this number');
        setExternalLog(null);
        return;
      }
      //Update clearPhone or clearMobilePhone on the backend
      if (selectedPhone === 'phone') {
        if (
          prospect?.objectId &&
          (!prospect?.clearPhone ||
            prospect.clearPhone !== externalLogResult.data.data.telephone)
        ) {
          updateProspect({
            prospectId: prospect?.objectId,
            clearPhone: externalLogResult.data.data.telephone,
          });
        }
      } else {
        if (
          prospect?.objectId &&
          (!prospect?.clearMobilePhone ||
            prospect.clearMobilePhone !== externalLogResult.data.data.telephone)
        ) {
          updateProspect({
            prospectId: prospect?.objectId,
            clearMobilePhone: externalLogResult.data.data.telephone,
          });
        }
      }
      setExternalLog(externalLogResult.data.data);
      //If there is no answeredAt, set as default the status of not answered
      if (!externalLogResult.data.data.answeredAt) {
        setSelectedCallFeedback(CallFeedback.NOT_ANSWERED);
      }
      if (analytics) {
        analytics.track('retrieve-call', {});
      }
    } else {
      setExternalLog(null);
    }
  };

  //Automatically get the call log if completed
  useEffect(() => {
    if (!user?.aircallSettings) return;
    if (
      phoneNumber &&
      task?.inChargeStatus === 'Completed' &&
      //To avoid to call with the wrong number coming from previous states
      (phoneNumber === task.prospect.phone ||
        phoneNumber === task.prospect.mobilePhone) &&
      !externalLog
    ) {
      retrieveCall(phoneNumber, phoneSelected);
    }
  }, [task?.inChargeStatus, phoneNumber, externalLog, phoneSelected]);

  useConditionalSnackBar([
    {
      condition: !!logCallSuccess,
      message: 'Call log successfully',
      severity: 'success',
    },
    {
      condition: !!logCallError,
      message: logCallError ?? '',
      severity: 'error',
    },
    {
      condition: !!createLogCallError,
      message: getError(createLogCallError) ?? 'Error while log a call',
      severity: 'error',
    },
    {
      condition: !!errorUpdateProspect,
      message: getError(errorUpdateProspect) ?? 'Error update contact',
      severity: 'error',
    },
    {
      condition: !!errorRetrievingExternalCall,
      message: getError(errorRetrievingExternalCall) ?? 'Error retrieving call',
      severity: 'error',
    },
    {
      condition: !!showError,
      message: showError ?? '',
      severity: 'error',
    },
  ]);

  const phoneRef = React.useRef<HTMLInputElement>(null);
  const mobilePhoneRef = React.useRef<HTMLInputElement>(null);

  const [showInsightTab, setShowInsightTab] = useState<boolean>(false);

  const [showTemplateTab, setShowTemplateTab] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<Template | null>(null);

  const [templateContent, setTemplateContent] = useState<string>('');
  const { data: templateVariables } = useGetTemplateVariables();

  useEffect(() => {
    if (task && task.template?.type === TemplateType.SCRIPT) {
      setSelectedTemplate(task.template);
    } else {
      setSelectedTemplate(null);
    }
  }, [task]);

  useEffect(() => {
    if (selectedTemplate) {
      let content = htmlToText(selectedTemplate?.content || '');

      content = replaceAllTemplateVariables(
        content ?? '',
        prospect ?? null,
        account ?? null,
        user ?? null,
        templateVariables?.data?.data ?? null,
      );
      setTemplateContent(content);
    } else {
      setTemplateContent('');
    }
  }, [selectedTemplate]);

  const currentDate = useMemo(() => {
    if (!prospect?.timeZone) return;
    return adjustDateToTimezone(new Date(), prospect.timeZone);
  }, [prospect, prospect?.timeZone]);

  if (!prospect) return <></>;
  return (
    <>
      {showInsightTab && (
        <InsightsCard
          close={() => setShowInsightTab(false)}
          prospect={prospect}
          account={account}
        />
      )}
      {openLogModal && (
        <CallLogModal
          close={() => setOpenLogModal(false)}
          selectedCallFeedback={selectedCallFeedback}
          handleChangeNote={handleChangeNote}
          handleChangeFeedback={handleChangeFeedback}
          note={note}
          isLoggingCall={isLoggingCall}
          handleLogClick={handleLogClick}
        />
      )}
      <CallModalWrapper
        id="call-view-component"
        style={{
          display: visibility === 'visible' ? undefined : 'none',
        }}
      >
        <FlexDiv direction="column" className="modal-body" height="fit-content">
          {/*Prospect information */}
          <FlexDiv width="100%" direction="column">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                {prospect && (
                  <Avatar
                    key={prospect.objectId}
                    className="prospect-avatar-row"
                  >
                    {stringAvatarProspect(prospect)}
                  </Avatar>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 4,
                      width: '100%',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontSize={14}
                      lineHeight={'18px'}
                      fontWeight={600}
                      noWrap
                    >
                      {prospect?.name}
                    </Typography>
                    <Tooltip
                      title={
                        !prospect?.linkedin
                          ? 'No Linkedin profile URL found'
                          : ''
                      }
                      placement="top"
                      arrow
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          style={{ width: 20, height: 20, padding: 2 }}
                          disabled={!prospect?.linkedin}
                          onClick={() => {
                            if (!prospect?.linkedin) return;
                            const url = transformToValidUrl(prospect?.linkedin);
                            if (url) window.open(url, '_blank');
                          }}
                        >
                          <LinkedinIcon
                            color={
                              !prospect?.linkedin ? colors.grey3 : colors.blue
                            }
                          />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 4,
                      width: '100%',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontSize={12}
                      color={colors.grey11}
                      lineHeight={'16px'}
                      noWrap
                    >
                      {prospect?.title}
                    </Typography>
                    {prospect?.timeZone && (
                      <>
                        <div className="small-divider" />
                        <IconTimeZone className="timezone-icon" />
                        <Typography
                          fontSize={12}
                          lineHeight={'16px'}
                          color={colors.grey11}
                        >
                          {moment.parseZone(currentDate).format('HH:mm')} local
                          time
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <ActivitiesBoxInsideActivityView
                  setTabValue={setTabValue}
                  selectedProspectId={
                    prospect.objectId ??
                    task?.prospectId ??
                    task?.prospect.objectId
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                height: 'fit-content',
                width: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 8,
              }}
            >
              <Typography
                fontSize={'16px'}
                fontWeight={700}
                lineHeight={'24px'}
              >
                Call Overview
              </Typography>
              {task?.inChargeStatus &&
                task.inChargeStatus !== 'NotInCharge' && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: 4,
                    }}
                  >
                    <span
                      className={
                        task.inChargeStatus === 'InCharge'
                          ? 'in-charge-label'
                          : 'completed-label'
                      }
                    >
                      {task.inChargeStatus === 'InCharge'
                        ? 'Dialer'
                        : 'Completed'}
                    </span>
                  </div>
                )}
            </div>
            <ProspectCardWrapper
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <div
                  className={
                    phoneSelected === 'phone'
                      ? 'editable-div-call-view'
                      : 'editable-div-call-view-disabled'
                  }
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                  onClick={() => {
                    setPhoneSelected('phone');
                  }}
                >
                  <span>
                    <Checkbox
                      icon={
                        <RadioButtonUnchecked sx={{ color: colors.mainDark }} />
                      }
                      checkedIcon={
                        <RadioButtonChecked sx={{ color: colors.mainDark }} />
                      }
                      checked={phoneSelected === 'phone'}
                      sx={{ opacity: '1 !important' }}
                    />
                  </span>
                  <div
                    className={`phone-mobile-field ${
                      phoneSelected === 'mobilePhone' ? 'field-disabled' : ''
                    }`}
                  >
                    <CallIcon
                      className="info-icon"
                      style={{ marginRight: '8px' }}
                    />
                    <TextField
                      inputRef={phoneRef}
                      variant="standard"
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      fullWidth
                      placeholder="Insert phone number"
                      // onInput={(ev) =>
                      //   handleChange(prospect.objectId, ev)
                      // }
                      onChange={(ev) => {
                        setPhoneNumber(ev.target.value);
                      }}
                      value={
                        phoneSelected === 'phone'
                          ? phoneNumber
                          : prospect.phone || ''
                      }
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChange(prospect?.objectId, ev, 'phone');
                        }
                      }}
                      onBlur={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChange(prospect?.objectId, ev, 'phone');
                      }}
                    />
                  </div>
                  {phoneSelected === 'phone' && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 4,
                      }}
                    >
                      <span>
                        <EditIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            phoneRef.current?.focus();
                          }}
                        />
                      </span>
                      {phoneNumber === '' || !phoneNumber ? (
                        <FindPhonePreconditionButton
                          account={account}
                          prospect={prospect}
                          setPhone={setPhoneNumber}
                          hasLabel={true}
                          iconColor={colors.white}
                          makeBEPatchProspect={true}
                        />
                      ) : (
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(phoneNumber || '');
                          }}
                        >
                          <ContentCopy />
                        </IconButton>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={
                    phoneSelected === 'mobilePhone'
                      ? 'editable-div-call-view'
                      : 'editable-div-call-view-disabled'
                  }
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                  onClick={() => {
                    setPhoneSelected('mobilePhone');
                  }}
                >
                  <span>
                    <Checkbox
                      icon={
                        <RadioButtonUnchecked sx={{ color: colors.mainDark }} />
                      }
                      checkedIcon={
                        <RadioButtonChecked sx={{ color: colors.mainDark }} />
                      }
                      checked={phoneSelected === 'mobilePhone'}
                      sx={{ opacity: '1 !important' }}
                      onClick={() => {
                        setPhoneSelected('mobilePhone');
                      }}
                    />
                  </span>
                  <div
                    className={`phone-mobile-field ${
                      phoneSelected === 'phone' ? 'field-disabled' : ''
                    }`}
                  >
                    <MobilePhoneIcon
                      className="info-icon"
                      style={{ marginRight: '8px' }}
                    />
                    <TextField
                      inputRef={mobilePhoneRef}
                      variant="standard"
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      fullWidth
                      placeholder="Insert mobile phone number"
                      onChange={(ev) => {
                        setPhoneNumber(ev.target.value);
                      }}
                      value={
                        phoneSelected === 'mobilePhone'
                          ? phoneNumber
                          : prospect.mobilePhone || ''
                      }
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleChange(prospect?.objectId, ev, 'mobilePhone');
                        }
                      }}
                      onBlur={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        handleChange(prospect?.objectId, ev, 'mobilePhone');
                      }}
                    />
                  </div>
                  {phoneSelected === 'mobilePhone' && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 4,
                      }}
                    >
                      <span>
                        <EditIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            mobilePhoneRef.current?.focus();
                          }}
                        />
                      </span>
                      {/*For the moment the finder is only for the phone */}
                      {/* {phoneNumber === "" || !phoneNumber ? (
                    <FindPhonePreconditionButton
                      isIcon={true}
                      account={account}
                      prospect={prospect}
                      setPhone={setPhoneNumber}
                    />
                  ) : ( */}
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(phoneNumber || '');
                        }}
                      >
                        <ContentCopy />
                      </IconButton>
                      {/* )} */}
                    </div>
                  )}
                </div>
              </div>
              {task?.inChargeStatus &&
                task.inChargeStatus !== 'NotInCharge' && (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        fontSize={12}
                        fontWeight={500}
                        lineHeight={'16px'}
                        color={colors.grey11}
                        whiteSpace={'nowrap'}
                      >
                        Aircall Dialer Campaign:
                      </Typography>
                      <Typography fontSize={14} lineHeight={'24px'}>
                        {task?.inChargeStatus === 'InCharge'
                          ? 'In progress'
                          : externalLog?.startedAt
                            ? moment(externalLog?.startedAt).format(
                                'll ・ HH:mm',
                              )
                            : 'Completed'}
                      </Typography>
                    </div>
                    <div className="vertical-divider" />
                  </>
                )}
              {externalLog ? (
                <div
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    gap: 12,
                    alignItems: 'center',
                  }}
                >
                  {/*We use answeredAt to know if the call was answered or not */}
                  {externalLog.answeredAt ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '75px',
                          }}
                        >
                          <CallLogIcon className="call-log-icon-round" />
                          {convertSecondstoTime(externalLog?.duration ?? 0)}
                        </span>
                      </div>
                      <div className="redirect-icon">
                        <Button
                          disabled={!externalLog.directLink}
                          onClick={() => {
                            if (externalLog.directLink) {
                              window.open(externalLog.directLink, '_blank');
                            }
                          }}
                        >
                          <RedirectIcon />
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <IconAircallWhite
                        color={colors.grey33}
                        logoColor={colors.white}
                        className="aircall-logo-icon"
                      />
                      <Typography
                        fontSize={14}
                        lineHeight={'18px'}
                        fontWeight={500}
                        whiteSpace={'nowrap'}
                        color={colors.grey1}
                      >
                        Not answered
                      </Typography>
                    </>
                  )}
                </div>
              ) : task?.inChargeStatus === 'Completed' ? (
                <Button
                  variant="contained"
                  className="retrieve-call-button"
                  endIcon={
                    <IconAircallWhite
                      color={colors.white}
                      className="aircall-logo-icon"
                    />
                  }
                  disableElevation
                  onClick={() => retrieveCall(phoneNumber, phoneSelected)}
                >
                  Retrieve call
                </Button>
              ) : showRetrieveAfterCall ? (
                <>
                  <IconButton
                    className="small-call-phone-button"
                    onClick={() => handleCall(phoneNumber)}
                    disabled={!phoneNumber}
                  >
                    <CallIcon
                      color={!phoneNumber ? colors.grey2 : colors.white}
                      className="phone-icon"
                    />
                  </IconButton>
                  <Button
                    variant="contained"
                    className="retrieve-call-button"
                    endIcon={
                      <IconAircallWhite
                        color={colors.callScheduled}
                        logoColor={colors.white}
                        className="aircall-logo-icon"
                      />
                    }
                    disableElevation
                    onClick={() => retrieveCall(phoneNumber, phoneSelected)}
                  >
                    Retrieve call
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  className="call-phone-button"
                  startIcon={
                    <CallIcon
                      color={!phoneNumber ? colors.grey2 : colors.white}
                      className="phone-icon"
                    />
                  }
                  disableElevation
                  onClick={() => handleCall(phoneNumber)}
                  disabled={!phoneNumber}
                >
                  Call
                </Button>
              )}
            </ProspectCardWrapper>
          </FlexDiv>
          <FlexDiv style={{ gap: 20 }} direction="column">
            <Typography
              fontSize={'16px'}
              fontWeight={700}
              lineHeight={'24px'}
              alignSelf={'flex-start'}
            >
              Call script
            </Typography>
            <div className="textarea-container">
              <div className="header-textarea">
                <TemplateCardsContainerTooltip
                  title={
                    <TemplatesTooltipCard
                      accountId={account?.objectId}
                      prospectId={prospect?.objectId}
                      selectedTemplateId={selectedTemplate?.id ?? null}
                      setSelectedTemplate={(template: Template) =>
                        setSelectedTemplate(template)
                      }
                      hideShared={false}
                      hideAll={false}
                      selectedType={TemplateType.SCRIPT}
                      showAnalytics={true}
                      close={() => setShowTemplateTab(false)}
                      account={account}
                      prospect={prospect}
                      visibility={visibility}
                      sequenceStepTemplateId={task?.sequenceStepTemplateId}
                    />
                  }
                  onClose={() => setShowTemplateTab(false)}
                  open={showTemplateTab}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                >
                  <div
                    className="template-button-container templates-button"
                    onClick={() => {
                      setShowTemplateTab((prev) => !prev);
                    }}
                  >
                    <span className="flex-center icon-wrapper">
                      <AddTemplateIconS
                        className={'template-button-icon'}
                        color={colors.black}
                      />
                    </span>
                    <Typography fontSize={'12px'}>Templates</Typography>
                    {task?.template?.sequenceStepTemplateId ? (
                      <Typography
                        fontWeight={500}
                        fontSize={'12px'}
                        color={colors.purpleHover}
                        className="ab-tag"
                      >
                        A/B
                      </Typography>
                    ) : null}
                  </div>
                </TemplateCardsContainerTooltip>
                <div
                  className="template-button-container insights-button"
                  onClick={() => {
                    setShowInsightTab((prev) => {
                      if (!prev) {
                        if (analytics) {
                          analytics.track('open-insights', {});
                        }
                      }
                      return !prev;
                    });
                  }}
                >
                  <span className="flex-center icon-wrapper">
                    <IconStarsInsights
                      className={'template-button-icon'}
                      color={colors.black}
                      viewBox="0 0 16 16"
                    />
                  </span>
                  <Typography fontSize={'12px'}>Insights</Typography>
                </div>
              </div>

              <TextareaAutosize
                id="textarea"
                minRows={4}
                className="textarea"
                value={templateContent}
                placeholder="Insert a message..."
                onChange={(ev) => {
                  setTemplateContent(ev.target.value);
                }}
              />
            </div>

            <MainButton
              onClick={() => setOpenLogModal(true)}
              style={{
                alignSelf: 'flex-start',
                margin: 0,
                width: '120px',
                height: '48px',
              }}
            >
              Log Call
            </MainButton>
          </FlexDiv>
        </FlexDiv>
      </CallModalWrapper>
    </>
  );
};

export default CallView;

import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

type AccountTabProps = {
  padding?: string;
  borderRadius?: string;
  margin?: string;
  overflow?: string;
  boxShadow?: string;
};

export const AccountTabWrapper = styled.div<AccountTabProps>`
  margin: ${(props) => (props.margin ? props.margin : '12px 0px 0px 0px')};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-self: flex-end;
  padding: ${(props) => props.padding ?? '18px'};
  border-radius: ${(props) => props.borderRadius ?? ''};
  overflow: ${(props) => props.overflow ?? 'hidden'};

  -webkit-box-shadow: ${(props) =>
    props.boxShadow ?? '5px 6px 14px -2px #757575'};
  -moz-box-shadow: ${(props) => props.boxShadow ?? '5px 6px 14px -2px #757575'};
  -o-box-shadow: ${(props) => props.boxShadow ?? '5px 6px 14px -2px #757575'};
  box-shadow: ${(props) => props.boxShadow ?? '5px 6px 14px -2px #757575'};

  .deals-container,
  .contacts-container {
    display: flex;
    gap: 12px;
    flex-direction: column;
    flex: 1;
    padding-bottom: 12px;
  }

  .deals-container {
    padding: 0px 24px 12px;
    overflow: auto;
  }

  .switch-container {
    display: flex;
    align-items: center;
  }

  .layout-grid {
    width: 100%;
    flex: 1;
    display: flex;
    overflow: hidden;
    padding: 16px 24px;
    background: ${colors.white};
  }

  .layout-grid-item {
    width: 50%;
    height: 100%;
    margin: 6px;
  }
  .grid-item-tasks-suggestions,
  .grid-item {
    display: flex;
    flex-direction: column;
    padding: 18px 2px 0px 6px;
    height: 100%;
    overflow: auto;
  }
  .grid-item-tasks-suggestions {
    overflow: hidden;
  }
  .container {
    border: 1px solid #dddddd;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .half-item {
    // 8px cause of the margin between the 2 halves
    height: calc(50% - 8px);
    // overflow: auto;
  }

  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 9999px;
  }
  .divider {
    border-top: 1px solid ${colors.grey3};
    opacity: 0.5;
    margin-left: -12px;
    margin-right: -12px;
    margin-top: 6px;
  }
  .remove-button {
    text-transform: unset !important;
    width: 100%;
    border-radius: 0;
  }
  .tabs-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 18px;
    border-bottom: 1px solid ${colors.grey444};
    background-color: ${colors.grey6};
  }

  .activities-list {
    height: calc(100% - 40px);
    overflow: auto;
  }
  .activities-list-tooltip {
    height: calc(100% - 40px);
    overflow: auto;
    padding: 4px;
  }
  .box-header-tooltip,
  .box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px 10px 12px;
  }
  .box-header-tooltip {
    padding: 8px 16px;
  }

  .prospect-activities-list {
    height: 100%;
    overflow: auto;
    padding: 5px;
  }
  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
    min-width: 40px;
    height: 40px;
    gap: 4px;
    padding-inline: 8px;
    cursor: pointer;
  }
  .filter-button:hover {
    background-color: ${colors.blueLightHover};
  }
  .number-of {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    height: 24px;
    width: 24px;
    background: ${colors.grey55};
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-button {
    background: ${colors.black};
    padding: 8px;
  }
  .add-task-button {
    background: ${colors.grey4};
    padding: 10px;
  }
  .add-task-button:hover {
    background: ${colors.grey6};
  }
  .delete-deal-button {
    background: ${colors.inactiveLight};
    padding: 10px;
  }
  .delete-deal-button:hover {
    background: ${colors.inactiveLightHover};
  }
  .activity {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 8px;
    padding-inline: 12px;
    padding-block: 6px;
  }

  .activity:hover {
    background: ${colors.grey6};
  }
  .activity-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .activity-icon {
    width: 30px;
    min-width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 999px;
    margin-right: 8px;
    align-self: center;
  }
  .activity-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .call-log-icon:hover > span > svg > path,
  .call-log-icon:hover {
    color: #0a9b94 !important;
    stroke: #0a9b94;
  }
  .call-log-unavailable {
    color: red !important;
  }
  .call-log-unavailable:hover {
    color: blue !important;
  }
  .call-icon {
    background: ${colors.callScheduledLight};
  }
  .email-icon {
    background: ${colors.inactiveLight};
  }
  .linkedin-icon {
    background: ${colors.blueLight};
  }
  .inmail-icon {
    background: ${colors.purpleLight};
  }
  .linkedin-inmail {
    margin-top: 1px;
    margin-left: 3px;
  }
  .meeting-icon {
    background: ${colors.nurtureLight};
  }
  .note-icon {
    background: ${colors.purpleLight};
  }
  .crm-info-icon,
  .info-icon {
    min-width: 32px;
    min-height: 32px;
    padding: 7px;
    border-radius: 999px;
    border: 1px solid ${colors.grey4};
    margin-right: 4px;
  }
  .crm-info-icon {
    padding: 5px;
  }
  .mobile-phone-info-icon {
    padding: 4px;
  }
  .mobile-phone-info-icon:hover {
    background: ${colors.callScheduled};
  }
  .phone-info-icon:hover {
    background: ${colors.callScheduled};
  }
  .email-info-icon:hover {
    background: ${colors.inactive};
  }
  .linkedin-info-icon:hover {
    background: ${colors.blueHover};
  }
  .phone-info-icon:hover > path,
  .email-info-icon:hover > path {
    stroke: ${colors.white};
  }
  .mobile-phone-info-icon:hover > path {
    fill: ${colors.white};
  }
  .mobile-phone-info-icon:hover > #path_4 {
    stroke: ${colors.callScheduled};
  }
  .linkedin-info-icon:hover > path {
    fill: ${colors.white};
  }
  .icon-badge-container {
    display: flex;
    align-items: center;
  }
  .icon-badge-container:hover > #email-badge > span {
    border: 1px solid ${colors.white};
  }
  .salesforce-icon:hover {
    background: ${colors.salesforceBlue};
  }
  .pipedrive-icon:hover {
    background: ${colors.pipedriveColor};
  }
  .hubspot-icon:hover {
    background: ${colors.hubspotOrange};
  }
  .salesforce-icon:hover > path,
  .pipedrive-icon:hover > path,
  .hubspot-icon:hover > path {
    fill: ${colors.white};
  }

  .account-info-icon {
    min-width: 24px;
    min-height: 24px;
    margin-inline: 6px;
  }
  .opportunity-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
  }
  .opportunity-badge {
    background-color: ${colors.intenseBlue};
    color: ${colors.white};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 4px;
    height: 15px;
  }
  .activity-date,
  .activity-date-tooltip {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .activity-date {
    gap: 9px;
    justify-content: space-around;
  }
  .activity-date-tooltip {
    gap: 0px;
    justify-content: center;
  }

  .account-avatar {
    background: ${colors.primaryLight} !important;
    color: ${colors.primary} !important;
    font-size: 20px !important;
    border: 1px solid ${colors.grey4Hover};
  }
  .white {
    background: ${colors.white} !important;
  }

  .top-half {
    display: flex;
    flex-direction: column;
    padding-inline: 16px;
    margin-bottom: -15px !important;
  }

  .bottom-half {
    display: flex;
    flex-direction: column;
    height: calc(100% - 250px);
    overflow: hidden auto;
    padding-top: 8px;
    padding-inline: 16px;
  }

  .field-input {
    width: 100%;
    border-radius: 8px;
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiInput-input {
      padding: 0px;
    }
    & .MuiSelect-select {
      padding: 0px 26px 0px 4px;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & .MuiAutocomplete-inputRoot {
      padding: 0px;
    }
  }
  .no-padding {
    padding: 0px;
  }

  .information-editable-container {
    display: flex;
    align-items: center;
    margin: 3px 0px;
  }
  .information-label-opportunity,
  .information-label {
    width: 100%;
    color: ${colors.grey11};
    margin-right: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .information-label-opportunity {
    min-width: max-content;
  }

  .external-properties-tag {
    max-width: 50%;
  }

  .field-input {
    width: 100%;
    border-radius: 8px;
    padding: 4px 0px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiInput-input {
      padding: 0px;
    }
    & .MuiSelect-select {
      padding: 0px 26px 0px 4px;
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .list {
  }

  .field-input:hover {
    background: ${colors.grey6};
  }

  .information-value {
    color: ${colors.black};
    overflow: hidden;
  }
  .tasks-list-container {
    // padding-left: 18px;
    height: calc(100% - 4px);
  }
  .tasks-list-container-tooltip {
    height: calc(100% - 4px);
  }
  .tasks-list-tooltip,
  .tasks-list {
    margin-top: 6px;
    height: calc(100% - 60px);
    overflow: auto;
    row-gap: 8px;
    display: flex;
    flex-direction: column;
    padding-right: 4px;
  }
  .tasks-list-tooltip {
    height: calc(100% - 20px);
    margin-left: 8px;
  }
  .suggestion-container {
    height: calc(100% - 200px);
  }

  .editable-div {
    border-radius: 8px;
    padding: 2px 0px 2px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 26px;
    height: 36px;
  }
  .editable-div > span > svg {
    opacity: 0;
  }
  .editable-div:hover > span > svg {
    opacity: 1;
  }
  .editable-div > div > span {
    opacity: 0;
  }
  .editable-div > div > #email-badge {
    opacity: 1;
  }
  .editable-div > div > div > input:focus ~ .editable-div {
    background: ${colors.inactive};
  }
  .editable-div:hover {
    background: ${colors.grey6};
  }
  .editable-div:hover > div > span {
    opacity: 1;
  }
  .editable-div > div > .edit-editable-div-icon {
    width: 0px;
  }
  .editable-div:hover > div > .edit-editable-div-icon {
    width: 24px;
  }
  .editable-div > .edit-editable-div-icon {
    width: 0px;
  }
  .editable-div:hover > .edit-editable-div-icon {
    width: 24px;
  }

  .account-name-div {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }

  .placeholder {
    opacity: 0.5;
  }
  .edited-field-badge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    font-size: 0.8rem;
    border-radius: 16px;
    color: ${colors.primaryDark};
    background-color: ${colors.backgroundSaveGreen};
    padding: 4px 6px;
  }
  .deal-action-icon-container,
  .prospect-activity-icon-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid ${colors.grey444};
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    background-color: ${colors.white};
  }
  .deal-action-icon-container:hover,
  .prospect-activity-icon-container:hover {
    background-color: ${colors.blueLightHover};
  }
  .no-border {
    border: none;
  }
  .no-activities-disclaimer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 12px;
  }
  .links-icon-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .links-icon:hover path {
    stroke: ${colors.black};
  }
  .no-results-top-placeholder {
    min-width: 150px;
    min-height: 20px;
    background-color: ${colors.placeholderGrey};
    border-radius: 16px;
  }
  .blur-backdrop-effect {
    flex-direction: column;
    gap: 10px;
    position: absolute;
    max-width: 400px;
    max-height: 400px;
    border-radius: 50%;
    backdrop-filter: blur(2px);
    margin-top: 40px;
  }
  .img-wrapper-tooltip {
    min-width: 100px;
  }
  .img-wrapper {
    min-height: 100px;
    min-width: 100px;
  }
  .img-wrapper-xl {
    min-height: 210px;
    min-width: 210px;
  }
  .owner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: ${colors.white};
    padding: 8px 24px 8px 8px;
    gap: 10px;
  }
  .deals-header-container {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .note-list-tooltip {
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    row-gap: 2px;
    overflow: auto;
    padding: 0px 10px;
  }
  .note-card-tooltip {
    border-bottom: 2px solid ${colors.grey4};
    padding: 14px 6px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .go-to-button {
    display: flex;
    align-items: center;
    gap: 2px;
    color: ${colors.primary};
  }
  .go-to-button > svg > path {
    stroke: ${colors.primary};
  }
  .go-to-button:hover {
    color: ${colors.darkGreenHover};
    cursor: pointer;
  }
  .go-to-button:hover > svg > path {
    stroke: ${colors.darkGreenHover};
  }
`;

export const LinkUrlWrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 16px 6px;

  .link-headers {
    display: flex;
    justify-content: center;
    position: sticky;
    width: 100%;
    color: ${colors.black};
    gap: 175px;
  }
  .links-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${colors.black};
    gap: 20px;
    padding-left: 14px;
  }
  .link-row {
    display: flex;
    align-items: center;
  }
  .link-row:hover {
    color: ${colors.darkGreen};
    cursor: pointer;
  }
  .link-row:hover path {
    stroke: ${colors.darkGreen};
  }
`;

export const HideProgressWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000000000;
  height: 100%;
  width: 50%;
  background-color: ${colors.white};
  padding: 18px;
  display: flex;
  flex-direction: column;
  .loader {
    width: 52px;
    height: 52px;
    position: absolute;
    bottom: 5px;
    right: 0px;
    animation: flippx 2s infinite linear;
  }
  .loader:before {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    transform-origin: -4px 50%;
    animation: spin 1s infinite linear;
  }
  .loader:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  @keyframes flippx {
    0%,
    49% {
      transform: scaleX(1);
    }
    50%,
    100% {
      transform: scaleX(-1);
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

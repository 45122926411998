import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FC, useEffect } from 'react';
// import { Account } from "crono-fe-common/types/account";
import { LinkedinModalWrapper } from 'pages/accountTab/linkedinView/styles';

import { Prospect } from 'crono-fe-common/types/prospect';

import { Typography } from '@mui/material';
import moment from 'moment';
import { useAuth } from 'context/auth';
import { User } from 'crono-fe-common/types/user';
import { Task } from 'crono-fe-common/types/task';
import { prospectName } from 'utils/fe-utils';

interface LinkedinModalProps {
  prospect: Prospect | null;
  activity?: Task;
}

const renderUserFrom = (activity: Task) => {
  if (activity?.userId) {
    if (activity.user) {
      return activity.user;
    }
    return 'Inactive user';
  }
  return 'Unknown user';
};

const LinkedinModal: FC<LinkedinModalProps> = ({ prospect, activity }) => {
  const { user } = useAuth();

  const renderMessage = (activity: Task, user: User, prospect: Prospect) => {
    return (
      <FlexDiv
        className="message-container"
        justifyContent="center"
        direction="column"
      >
        <FlexDiv className="message-header" justifyContent="space-between">
          <div className="message-sender">
            <Typography fontSize={14} fontWeight={500}>
              from: {renderUserFrom(activity)}
            </Typography>
            <Typography fontSize={12} fontWeight={400}>
              to: {prospectName(prospect)}
            </Typography>
          </div>
          <Typography className="message-date" fontWeight={500} fontSize={12}>
            {activity?.createdDate &&
              moment(activity.createdDate).format('LLL')}
          </Typography>
        </FlexDiv>
        <FlexDiv
          className="message-body no-thread"
          justifyContent="space-between"
          direction="column"
        >
          {activity?.description}
        </FlexDiv>
      </FlexDiv>
    );
  };

  return (
    <LinkedinModalWrapper>
      <FlexDiv
        direction="column"
        className="modal-header"
        justifyContent="start"
        alignItems="start"
      >
        <FlexDiv justifyContent="space-between" alignItems="center">
          <h2>
            {`${activity?.subject} ${activity?.linkedinLog?.linkedinType ?? ''}`}
          </h2>
        </FlexDiv>
      </FlexDiv>
      <div className="modal-body">
        {activity?.description &&
          user &&
          prospect &&
          renderMessage(activity, user, prospect)}
      </div>
    </LinkedinModalWrapper>
  );
};

export default LinkedinModal;

import styled from '@emotion/styled';
import { colors } from 'crono-fe-common/theme';

export const TemplatesSettingsWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  width: 100%;
  display: flex;
  // justify-content: center;
  .template-settings-container {
    background: ${colors.white};
    border-radius: 16px;
    padding: 18px 0px 18px 0px;
    width: calc(100% - 250px);
    height: 100%;
  }
  .tab-button-template-creation {
    width: 160px;
  }
  .tag-container {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
  }
  .text-field {
    width: 180px;
    .MuiInput-underline:after {
      border-bottom: 2px solid ${colors.grey11};
    }
  }
  .templates-list {
    height: calc(100% - 160px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    padding: 0px 13px 0px 18px;
  }
  .template-item {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid ${colors.grey4};
    padding: 16px;
    cursor: pointer;
  }
  .template-item:hover {
    background-color: ${colors.grey55}aa;
  }
  .tags-container-template-creation {
    display: flex;
    width: 100%;
    gap: 8px;
    justify-content: space-between;
  }
  .edit-icon-template-settings {
    background: ${colors.callScheduledLight};
    border-radius: 999px;
  }
  .edit-icon-template-settings:hover {
    background: ${colors.primaryLightHover};
    border-radius: 999px;
  }
  .remove-icon-template-settings {
    background-color: ${colors.inactiveLight};
    border-radius: 999px;
  }
  .remove-icon-template-settings:hover {
    background-color: ${colors.inactiveLightHover};
  }

  .template-rate {
    width: 60px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .usage-rate {
    color: ${colors.blueHover};
  }
  .open-rate {
    color: ${colors.purpleHover};
  }
  .reply-rate {
    color: ${colors.primaryDark};
  }
  .tag-filter-container {
    height: 100%;
    border: 1px solid ${colors.grey4};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 7px 7px 7px 14px;
    width: 90px;
    cursor: pointer;
  }
  .tag-filter-container:hover {
    border: 1px solid ${colors.black};
  }
  .tags-selected {
    background: ${colors.grey4};
    width: auto;
  }
  .template-type-column {
    align-items: start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  .tabs-container {
    padding: 20px 14px 0px 14px;
    height: 100%;
    width: 230px;
    background: ${colors.white};
    border-radius: 16px;
  }
  .tab-content-container {
    height: 100%;
    // width: calc(100% - 230px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
  }
  .template-type-tab {
    text-transform: unset;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    min-height: unset !important;
    border-radius: 16px;
    padding: 15px 20px;
    text-align: start;
  }
  .template-type-tab > svg {
    min-width: 24px;
  }
`;

export const TemplateManagementWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${colors.black}66;
  z-index: 1000;

  .tox-toolbar__group:last-child {
    margin-left: auto;
    border-left: none;
  }
  .main-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 750px;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    background: ${colors.white};
    border-radius: 16px;
    padding: 18px 0px 0px 18px;
  }
  .template-management-container {
    padding: 0px 40px 0px 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .close-button {
    cursor: pointer;
  }
  .tab-template-button {
    width: 115px;
  }
  .generate-button {
    border-radius: 999px !important;
    width: 140px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .save-test-button {
    border-radius: 999px !important;
    width: 110px;
    height: 48px;
    margin: 6px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .variables-container {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 18px;
  }
  .variable-button {
    border: 1px solid ${colors.grey4};
    text-transform: unset;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
  .mail-form {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    padding-bottom: 20px;
  }

  .tags-container-template-creation {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
  }
  .file-preview {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .file {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 30px;
    background: ${colors.callScheduledLightHover};
    border-radius: 8px;
    padding: 8px;
  }
  .delete-attachments-icon {
    cursor: pointer;
  }
  .remove-text-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .add-variables-button {
    text-transform: unset;
    min-width: fit-content;
    white-space: nowrap;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  #text {
    padding-top: 2px;
  }
  .step-type-option-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    cursor: pointer;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 120px;
  margin: 6px;
  background: ${colors.white} !important;
  font-size: 16px;
  line-height: 24px;
  padding-inline: 20px;
`;

import {
  CancelButton,
  GreyButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { useConditionalSnackBar } from 'context/snackbar';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Template, TemplateVariables } from 'crono-fe-common/types/template';
import {
  initialValues,
  isFileContent,
  TemplateInputs,
  validationSchema,
} from './model';
import useCreateTemplate from 'hooks/services/templates/useCreateTemplate';
import useEditTemplate from 'hooks/services/templates/useEditTemplate';
import TemplateType from 'crono-fe-common/types/enums/templateType';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  MenuItem,
  Select,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { useAuth } from 'context/auth';
import { useJuneAnalytics } from 'context/june';
import GenerateTemplateModal from './modal/GenerateTemplateModal';
import { LoaderContainer, TemplateManagementWrapper } from './style';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import { ReactComponent as GenerateIcon } from 'crono-fe-common/icons/Generate.svg';
import { TemplateStep } from 'crono-fe-common/types/enums/templateStep';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { TagInsert } from 'crono-fe-common/types/tagInsert';
import { Tag } from 'crono-fe-common/types/tag';
import { getError, getNewOptionsWhenSelecting } from 'crono-fe-common/utils';
import { TagPatch } from 'crono-fe-common/types/tagPatch';
import { FileContent } from 'use-file-picker';
import useAddTemplateAttachment from 'hooks/services/templates/useAddTemplateAttachment';
import useGetTemplateAttachments from 'hooks/services/templates/useGetTemplateAttachments';
import useDeleteTemplateAttachment from 'hooks/services/templates/useDeleteTemplateAttachment';
import useSendPreviewEmail, {
  SendPreviewEmailDto,
} from 'hooks/services/email/useSendPreviewEmail';
import { CronoAttachment } from 'crono-fe-common/types/cronoAttachment';
import { MockAccount, MockProspect } from './mockData';
import useEmailIntegrationGuard from 'hooks/guards/useEmailIntegrationGuard';
import { ReactComponent as VariablesIcon } from 'crono-fe-common/icons/Variables.svg';
import VariablesSelector from './variablesSelector';
import CloseTabButton from 'crono-fe-common/components/CronoButton/closeTabButton';
import { useConfirmModal } from 'context/confirmModal';
import { Constants } from 'crono-fe-common/constants/constants';
import {
  calculateTemplateLength,
  formatHtmlContent,
  replaceAllTemplateVariables,
  removeTags,
} from 'utils/fe-utils';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import { useLinkedinGetInvitationsLimit } from 'crono-fe-common/hooks/crono-extension/gateway';
import { useCustomerly } from 'react-live-chat-customerly';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import EmailIcon from 'crono-fe-common/icons/Icon-Email';
import LinkedinIconMessage from 'crono-fe-common/icons/Icon-Linkedin-Message';
import LinkedinIconInMailCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-New';
import LinkedinIconInvoice from 'crono-fe-common/icons/Icon-Linkedin-Invoice';
import LinkedinInvitationIcon from 'crono-fe-common/icons/Icon-Linkedin-Invitation';
import { CustomOptionsTooltip } from '../Strategy';
import { StepTypeOptionsWrapper } from 'context/strategyDetail/stepDetailComponent/style';
import ReactQuill from 'react-quill';
import CronoEditor from 'components/CronoEditor/CronoEditor';
import { useAttachmentsFilePicker } from 'hooks/useAttachmentsFilePicker';
import { BlurBackgroundDivFixed } from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import useGetTemplateVariables from 'hooks/services/variables/useGetTemplateVariables';

const templateOption = {
  Email: 'Email',
  Linkedin: 'Linkedin Message',
  Invitation: 'Linkedin Invitation',
  Script: 'Script',
  InMail: 'Linkedin InMail',
};
interface TypeOption {
  type: TemplateType;
  icon: JSX.Element;
  text: string;
}

const typeOptions: TypeOption[] = [
  {
    type: TemplateType.EMAIL,
    icon: <EmailIcon />,
    text: 'Email',
  },
  {
    type: TemplateType.INVITATION,
    icon: <LinkedinInvitationIcon />,
    text: 'Linkedin Invitation',
  },
  {
    type: TemplateType.LINKEDIN,
    icon: <LinkedinIconMessage />,
    text: 'Linkedin Message',
  },
  {
    type: TemplateType.IN_MAIL,
    icon: <LinkedinIconInMailCompass />,
    text: 'Linkedin InMail',
  },
  {
    type: TemplateType.SCRIPT,
    icon: <LinkedinIconInvoice />,
    text: 'Script',
  },
];

export const CustomTooltipVariables = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      arrow={props.arrow ? props.arrow : false}
      {...props}
      classes={{ popper: className }}
    />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    maxWidth: 350,
    border: `1px solid ${colors.grey4}`,
    borderRadius: 8,
    padding: 0,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

interface IProps {
  selectedTemplate: Template | null;
  actualType?: TemplateType | null;
  close: () => void;
  onSuccessSameType?: (templateId: Template) => void;
  style?: any;
}

const TemplateManagement = ({
  selectedTemplate,
  close,
  actualType,
  onSuccessSameType,
  style,
}: IProps) => {
  const { user } = useAuth();
  const analytics = useJuneAnalytics();

  const initialTags = useRef<Tag[]>(selectedTemplate?.templateTags || []);

  const [openGenerateTemplate, setOpenGenerateTemplate] = useState(false);

  //If a template is passed (so we are in creation) I set that language, otherwise I use the one in the user preferences
  const [selectedLanguage, setSelectedLanguage] = useState(
    selectedTemplate?.language ??
      (user?.userPreferences?.templateLanguage &&
      Constants.templateLanguages.includes(
        user.userPreferences.templateLanguage,
      )
        ? user.userPreferences.templateLanguage === 'all'
          ? 'en'
          : user.userPreferences.templateLanguage
        : Constants.templateLanguages[0]),
  );
  const [step, setStep] = useState<TemplateStep>(TemplateStep.FIRST_TOUCH);
  const [selectedType, setSelectedType] = useState(
    selectedTemplate?.type ?? actualType ?? TemplateType.EMAIL,
  );

  //Remove attachments if moving to a type of template that does not allow them
  useEffect(() => {
    if (
      selectedType === TemplateType.INVITATION ||
      selectedType === TemplateType.SCRIPT
    ) {
      formik.setFieldValue('attachments', []);
    }
  }, [selectedType]);

  const [shared, setShared] = useState(selectedTemplate?.shared ?? true);

  const {
    isSuccess: isSuccessCreate,
    data: dataCreate,
    variables: variablesCreate,
    error: errorCreateTemplate,
    mutate: createTemplate,
  } = useCreateTemplate();
  const {
    isSuccess: isSuccessEdit,
    error: errorEditTemplate,
    mutateAsync: editTemplate,
  } = useEditTemplate();

  useEffect(() => {
    if (isSuccessCreate || isSuccessEdit) {
      //If the template created is the same type of the one we are creating, I have to call the function passed (for example to assign immediately the template to the strategy)
      if (variablesCreate?.type === actualType && onSuccessSameType) {
        //Get the new Id returned and pass it to the function
        const newId = dataCreate?.data.data;
        if (newId) onSuccessSameType(newId);
      }
      close();
    }
  }, [isSuccessEdit, isSuccessCreate]);

  const { data: externalPropertyTags } = useGetExternalProperty(null, true);
  const [tagSelected, setTagSelected] = useState<TagInsert[]>([]);

  //Customerly for events for workflows
  const { event } = useCustomerly();

  useEffect(() => {
    if (isSuccessCreate) {
      event('create_template');
    }
  }, [isSuccessCreate]);

  const formik = useFormik<TemplateInputs>({
    initialValues: {
      ...initialValues,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let event = 'create-template';

      if (selectedTemplate) {
        //Attachments are not passed when edit template
        const { attachments, content, ...rest } = values;

        //If we are editing and the type is different, we create a new template
        if (selectedTemplate.type !== selectedType) {
          event = 'create-template-from-changing-type-of-existing';
          const newTags: TagInsert[] = tagSelected;
          const { attachments, content, ...rest } = values;
          const oldAttachments = attachments.filter(
            (attachment) => !isFileContent(attachment),
          ) as CronoAttachment[];

          const newAttachments = attachments.filter((attachment) =>
            isFileContent(attachment),
          ) as FileContent[];
          createTemplate({
            ...rest,
            content: formatHtmlContent(content),
            attachments: newAttachments,
            attachmentIds: oldAttachments.map((attachment) => attachment.id),
            type: selectedType,
            shared: shared,
            templateTags: newTags,
            //If the title is the same we put the type at the end to differenciate from the original
            title:
              rest.title === selectedTemplate.title
                ? rest.title + ` - ${selectedType}`
                : rest.title,
          });
        } else {
          event = 'edit-template';
          const newTags: TagPatch[] = [];
          //I have to set to null the tags that are not selected anymore
          externalPropertyTags?.data?.data.forEach((externalProperty) => {
            const newChoosen = tagSelected.find(
              (tag) => tag.externalPropertyId === externalProperty.id,
            );
            const old = initialTags.current.find(
              (tag) => tag.externalPropertyId === externalProperty.id,
            );
            if (!newChoosen && old) {
              newTags.push({
                externalPropertyId: externalProperty.id,
                value: null,
              });
            } else {
              newTags.push({
                externalPropertyId: externalProperty.id,
                value: newChoosen?.value ?? old?.value ?? null,
              });
            }
          });

          const attachmentsToDelete =
            templateAttachmentsSaved?.data?.data.filter(
              (ia) =>
                !attachments.some((a) => !isFileContent(a) && a.id === ia.id),
            ) ?? [];
          const promises = [];
          for (const attachment of attachmentsToDelete) {
            promises.push(
              deleteAttachment({
                templateId: selectedTemplate.id,
                attachmentId: attachment.id,
              }),
            );
          }
          await Promise.all(promises);

          const attachmentsToAdd = attachments.filter((a) =>
            isFileContent(a),
          ) as FileContent[];
          await addAttachmentToTemplate({
            templateId: selectedTemplate.id,
            attachments: attachmentsToAdd,
          });
          await editTemplate({
            ...rest,
            content: formatHtmlContent(content),
            type: selectedType,
            shared: shared,
            id: selectedTemplate.id,
            templateTags: newTags,
          });
        }
      } else {
        const newTags: TagInsert[] = tagSelected;
        const { attachments, content, ...rest } = values;
        const oldAttachments = attachments.filter(
          (attachment) => !isFileContent(attachment),
        ) as CronoAttachment[];

        const newAttachments = attachments.filter((attachment) =>
          isFileContent(attachment),
        ) as FileContent[];
        createTemplate({
          ...rest,
          content: formatHtmlContent(content),
          attachments: newAttachments,
          attachmentIds: oldAttachments.map((attachment) => attachment.id),
          type: selectedType,
          shared: shared,
          templateTags: newTags,
        });
      }

      if (analytics) {
        analytics.track(event, {});
      }
    },
  });

  //When the language in the state changes (from the generate window for example) I update also the formik
  useEffect(() => {
    formik.setFieldValue('language', selectedLanguage);
  }, [selectedLanguage]);

  //When the step in the state changes (from the generate window for example) I update also the formik
  useEffect(() => {
    formik.setFieldValue('step', step);
  }, [step]);

  const {
    data: templateAttachmentsSaved,
    error: errorDeleteTemplateAttachment,
  } = useGetTemplateAttachments(selectedTemplate?.id ?? null);

  useEffect(() => {
    if (!selectedTemplate) return;
    formik.setFieldValue('title', selectedTemplate?.title || '');
    formik.setFieldValue('subject', selectedTemplate?.subject || '');
    formik.setFieldValue('content', selectedTemplate?.content || '');
    formik.setFieldValue('shared', selectedTemplate?.shared || false);
    formik.setFieldValue('type', selectedTemplate?.type || TemplateType.EMAIL);
    formik.setFieldValue(
      'step',
      selectedTemplate?.step || TemplateStep.FIRST_TOUCH,
    );
  }, [selectedTemplate]);

  useEffect(() => {
    if (templateAttachmentsSaved && selectedTemplate) {
      formik.setFieldValue('attachments', templateAttachmentsSaved?.data?.data);
    }
  }, [templateAttachmentsSaved]);

  useEffect(() => {
    if (!selectedTemplate || !externalPropertyTags) return;
    const newTags = selectedTemplate?.templateTags ?? [];
    setTagSelected(newTags);
  }, [selectedTemplate, externalPropertyTags]);

  const editorRef = useRef<ReactQuill | null>(null);

  const handleChangeType = (type: TemplateType) => {
    setSelectedType(type);
    if (type !== TemplateType.EMAIL) {
      const newContent = removeTags(formik.values.content, [
        'strong',
        'em',
        'u',
        'ol',
        'li',
        'a',
        'img',
      ]);
      //If we go outside the email type we have to clear all possible styles
      formik.setFieldValue('content', newContent);
    }
  };

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const externalPropertyId = externalProperty.id;
    const newFilters = [...tagSelected];

    const newValueOption = getNewOptionsWhenSelecting({
      currentExternalValues: newFilters,
      externalPropertyId,
      option: option,
      valueType: externalProperty.valueType,
    });
    const newExternalValue: TagInsert = {
      externalPropertyId: externalPropertyId,
      value: newValueOption!,
    };
    const oldTag = tagSelected.find(
      (tag) => tag.externalPropertyId === externalPropertyId,
    );
    //If option is null I have to remove the externalPropertyId selected
    if (oldTag && (oldTag?.value === option || option === null)) {
      newFilters.splice(
        newFilters.findIndex(
          (tag) => tag.externalPropertyId === externalPropertyId,
        ),
        1,
      );
    } else if (oldTag) {
      newFilters.splice(
        newFilters.findIndex(
          (tag) => tag.externalPropertyId === externalPropertyId,
        ),
        1,
        newExternalValue,
      );
    } else {
      newFilters.push(newExternalValue);
    }

    setTagSelected(newFilters);
  };

  const handleOpenGenerate = () => {
    setOpenGenerateTemplate(true);
  };

  const handleChangeLanguage = (language: string) => {
    setSelectedLanguage(language);
    formik.setFieldValue('language', language);
  };

  const [openFileSelector, { filesContent, errorMessage: errorUploadFile }] =
    useAttachmentsFilePicker();

  const {
    mutateAsync: addAttachmentToTemplate,
    error: errorAddTemplateAttachment,
  } = useAddTemplateAttachment();

  const handleAttachments = (files: FileContent[]) => {
    if (files.length > 0) {
      formik.setFieldValue(
        'attachments',
        formik.values.attachments.concat(files),
      );
    }
  };

  useEffect(() => {
    handleAttachments(filesContent);
  }, [filesContent]);

  const { mutateAsync: deleteAttachment } = useDeleteTemplateAttachment();

  const removeAttachment = (index: number) => {
    const newFiles = formik.values.attachments.filter((file, i) => i !== index);
    formik.setFieldValue('attachments', newFiles);
  };

  const {
    mutate: sendPreviewEmail,
    isSuccess: previewMailSent,
    isLoading: previewMailSending,
    error: previewMailError,
  } = useSendPreviewEmail();

  useConditionalSnackBar([
    {
      condition: !!errorCreateTemplate,
      message: getError(errorCreateTemplate) ?? 'Error during create template',
      severity: 'error',
    },
    {
      condition: !!isSuccessCreate,
      message: 'Succesfully created template',
      severity: 'success',
    },
    {
      condition: !!errorEditTemplate,
      message: getError(errorEditTemplate) ?? 'Error during update template',
      severity: 'error',
    },
    {
      condition: !!isSuccessEdit,
      message: 'Succesfully updated template',
      severity: 'success',
    },
    {
      condition: !!errorAddTemplateAttachment,
      message:
        getError(errorAddTemplateAttachment) ?? 'Error on saving attachments',
      severity: 'error',
    },
    {
      condition: !!errorDeleteTemplateAttachment,
      message:
        getError(errorDeleteTemplateAttachment) ??
        'Error on deleting attachments',
      severity: 'error',
    },
    {
      condition: !!previewMailSent,
      message: 'Test email was sent successfully',
      severity: 'success',
    },
    {
      condition: !!previewMailError,
      message: getError(previewMailError) ?? 'Error sending preview mail',
      severity: 'error',
    },
    {
      condition: !!errorUploadFile,
      message: errorUploadFile ?? 'Error uploading file',
      severity: 'error',
    },
  ]);

  const {
    isSuccess: emailIntegrated,
    verifyIntegration,
    data: emailData,
  } = useEmailIntegrationGuard();

  useEffect(() => {
    if (user?.otherSettings?.hasEmail && selectedType === TemplateType.EMAIL) {
      verifyIntegration();
    }
  }, [selectedType]);

  const { data: templateVariables } = useGetTemplateVariables();

  const handleSendPreview = () => {
    let message = formik.values.content;
    if (user && emailIntegrated && emailData?.data?.data?.email) {
      let subject = formik.values.subject || '';

      subject = replaceAllTemplateVariables(
        subject,
        MockProspect ?? null,
        MockAccount ?? null,
        user ?? null,
        templateVariables?.data?.data ?? null,
      );
      message = replaceAllTemplateVariables(
        message,
        MockProspect ?? null,
        MockAccount ?? null,
        user ?? null,
        templateVariables?.data?.data ?? null,
      );

      message += user?.emailSettings?.signature
        ? '<br/>' + user?.emailSettings?.signature
        : '';

      message = formatHtmlContent(message);

      const sendEmailDto: SendPreviewEmailDto = {
        emailInfo: {
          subject: subject,
          contentHtml: message,
        },
        attachments: formik.values.attachments.filter((file) =>
          isFileContent(file),
        ) as FileContent[],
        attachmentIds: (
          formik.values.attachments.filter(
            (file) => !isFileContent(file),
          ) as CronoAttachment[]
        ).map((attachmentTemplate) => attachmentTemplate.id),
      };
      sendPreviewEmail(sendEmailDto);
      // console.log(
      //   sendEmailDto.emailInfo.contentHtml,
      //   JSON.stringify(sendEmailDto)
      // );

      if (analytics) {
        analytics.track('send-email-preview', {});
      }
    }
  };

  const [variablesTooltipSubject, setVariablesTooltipSubject] =
    useState<boolean>(false);

  const addVariablesSubject = (variable: TemplateVariables | string) => {
    formik.setFieldValue('subject', formik.values.subject + variable);
    setVariablesTooltipSubject(false);
  };

  const { openModal: openConfirmModal } = useConfirmModal();

  const handleClickSubmit = () => {
    if (selectedType === TemplateType.EMAIL && !formik.values.subject) {
      openConfirmModal({
        title: 'Email subject is empty',
        text: 'You are saving a template of type email without a subject, are you sure you want to continue?',
        confirmText: 'Save',
        cancelText: 'Go back',
        cancelFunction: () => null,
        confirmFunction: () => {
          formik.handleSubmit();
        },
      });
    } else if (
      selectedType === TemplateType.IN_MAIL &&
      !formik.values.subject
    ) {
      openConfirmModal({
        title: 'InMail subject is empty',
        text: 'You are saving a template of type InMail without a subject, are you sure you want to continue?',
        confirmText: 'Save',
        cancelText: 'Go back',
        cancelFunction: () => null,
        confirmFunction: () => {
          formik.handleSubmit();
        },
      });
    } else {
      formik.handleSubmit();
    }
  };

  const [maxLinkedinInvitationChars, setMaxLinkedinInvitationChars] =
    useState<number>(Constants.linkedinInvitationMaxChars);

  const { call: getLinkedinInvitationLimits } =
    useLinkedinGetInvitationsLimit();

  useEffect(() => {
    getLinkedinInvitationLimits()
      .then((linkedinInvitationLimits) => {
        setMaxLinkedinInvitationChars(
          linkedinInvitationLimits?.maxMessageLength ??
            Constants.linkedinInvitationMaxChars,
        );
      })
      .catch((err) => {
        console.log('Error', err);
      });
  }, []);

  // Template selection logic -----------------

  const [openTypeTooltip, setOpenTypeTooltip] = useState<boolean>(false);

  const renderPossibleTypes = () => {
    return typeOptions
      .filter((option) => option.type !== selectedType)
      .map((option) => {
        return (
          <div key={option.type}>
            <Button
              className="option-button"
              color="primary"
              fullWidth
              endIcon={option.icon}
              onClick={() => {
                handleChangeType(option.type);
                setOpenTypeTooltip(false);
              }}
            >
              {option.text}
            </Button>
          </div>
        );
      });
  };

  const renderStepType = (type: TemplateType) => {
    return (
      <ClickAwayListener onClickAway={() => setOpenTypeTooltip(false)}>
        <div>
          <CustomOptionsTooltip
            maxHeight={160}
            width={220}
            left={1}
            title={
              <StepTypeOptionsWrapper>
                {renderPossibleTypes()}
              </StepTypeOptionsWrapper>
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={openTypeTooltip}
            onClose={() => setOpenTypeTooltip(false)}
            sx={{
              zIndex: '5001 !important',
            }}
          >
            <div
              className="step-type-option-container"
              onClick={() => setOpenTypeTooltip((prev) => !prev)}
            >
              <div className="step-type-option">
                <Typography
                  fontSize={'24px'}
                  fontWeight={700}
                  lineHeight="32px"
                  width="fit-content"
                >
                  {templateOption[type]}
                </Typography>
              </div>
              {openTypeTooltip ? <TopArrowIcon /> : <BottomArrowIcon />}
            </div>
          </CustomOptionsTooltip>
        </div>
      </ClickAwayListener>
    );
  };

  const [showVariablesPicker, setShowVariablesPicker] =
    useState<boolean>(false);

  const handleContentChange = (text: string) => {
    formik.setFieldValue('content', text);
  };

  const isAttachmentsSupported =
    selectedType === TemplateType.LINKEDIN ||
    selectedType === TemplateType.IN_MAIL ||
    selectedType === TemplateType.EMAIL;

  return (
    <BlurBackgroundDivFixed style={style}>
      <TemplateManagementWrapper>
        {openGenerateTemplate && (
          <GenerateTemplateModal
            open={openGenerateTemplate}
            setOpen={setOpenGenerateTemplate}
            step={step}
            setStep={setStep}
            language={selectedLanguage}
            setLanguage={setSelectedLanguage}
            templateType={selectedType}
            setTemplateType={setSelectedType}
            templateFormik={formik}
          />
        )}
        <div className="main-container">
          <CloseTabButton style={{ paddingRight: '18px' }} close={close} />
          <div className="template-management-container">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {/* <CronoSelect
              value={selectedType}
              onChange={(ev) => {
                handleChangeType(ev.target.value as TemplateType);
              }}
              style={{
                width: "fit-content",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "32px"
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  borderRadius: 2
                },
                ".MuiOutlinedInput-input": {
                  paddingLeft: 0
                }
              }}
            >
              {Object.values(TemplateType).map((template) => {
                return (
                  <CronoMenuItem value={template}>
                    {templateOption[template]}
                  </CronoMenuItem>
                );
              })}
            </CronoSelect> */}
              {renderStepType(selectedType)}

              <GreyButton
                className="generate-button"
                startIcon={<GenerateIcon />}
                onClick={handleOpenGenerate}
              >
                Generate
              </GreyButton>
            </div>
            <form onSubmit={formik.handleSubmit} className="mail-form">
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
              >
                <TextField
                  className="form-field"
                  variant="standard"
                  fullWidth
                  id="title"
                  name="title"
                  label="Template name"
                  color="secondary"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />

                {(selectedType === TemplateType.EMAIL ||
                  selectedType === TemplateType.IN_MAIL) && (
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField
                      className="form-field"
                      variant="standard"
                      fullWidth
                      id="subject"
                      name="subject"
                      label="Subject"
                      color="secondary"
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.subject && Boolean(formik.errors.subject)
                      }
                      helperText={
                        formik.touched.subject && formik.errors.subject
                      }
                    />
                    <ClickAwayListener
                      onClickAway={() => setVariablesTooltipSubject(false)}
                    >
                      <div>
                        <CustomTooltipVariables
                          open={variablesTooltipSubject}
                          onClose={() => setVariablesTooltipSubject(false)}
                          title={
                            <VariablesSelector
                              addVariables={addVariablesSubject}
                            />
                          }
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          sx={{ zIndex: '5001 !important' }}
                        >
                          <Button
                            className="add-variables-button"
                            color="secondary"
                            startIcon={<VariablesIcon />}
                            onClick={() =>
                              setVariablesTooltipSubject((prev) => !prev)
                            }
                          >
                            <span id="text">Subject variables</span>
                          </Button>
                        </CustomTooltipVariables>
                      </div>
                    </ClickAwayListener>
                  </div>
                )}
              </div>
              {formik.values.attachments.length > 0 && (
                <div className="file-preview">
                  {formik.values.attachments.map((attachment, index) => (
                    <div className="file" key={index}>
                      <CloseMIcon
                        className="delete-attachments-icon"
                        onClick={() => removeAttachment(index)}
                      />
                      {attachment.name}
                    </div>
                  ))}
                </div>
              )}
              <CronoEditor
                //Since the attachment icon has to appear and disappear changing type I use the key to make the component rerender
                key={selectedType}
                editorRef={editorRef}
                value={formik.values.content}
                onChange={handleContentChange}
                style={{ height: '245px' }}
                hideStylingButtons={selectedType !== TemplateType.EMAIL}
                onFocus={() => {
                  setVariablesTooltipSubject(false);
                }}
                onAttachmentDrop={
                  isAttachmentsSupported ? handleAttachments : undefined
                }
                setShowVariablesPicker={setShowVariablesPicker}
                showVariablesPicker={showVariablesPicker}
                imageSupport={selectedType === TemplateType.EMAIL}
                customButtons={
                  isAttachmentsSupported
                    ? [
                        {
                          type: 'attachments',
                          showLabel: selectedType !== TemplateType.EMAIL,
                          onClick: openFileSelector,
                        },
                        {
                          type: 'variables',
                          showLabel: true,
                          onClick: () =>
                            setShowVariablesPicker((prev) => !prev),
                        },
                      ]
                    : [
                        {
                          type: 'variables',
                          showLabel: true,
                          onClick: () =>
                            setShowVariablesPicker((prev) => !prev),
                        },
                      ]
                }
              />
              {selectedType === TemplateType.INVITATION && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: -8,
                    }}
                  >
                    {(calculateTemplateLength(formik.values.content, true) ??
                      0) > maxLinkedinInvitationChars ? (
                      <Typography
                        color={colors.inactive}
                        fontSize={12}
                        fontWeight={500}
                        lineHeight={'18px'}
                      >
                        The maximum number of characters for a Linkedin
                        invitation is {maxLinkedinInvitationChars}
                      </Typography>
                    ) : (
                      <div />
                    )}
                    <Typography
                      color={colors.grey11}
                      fontSize={12}
                      fontWeight={500}
                      lineHeight={'18px'}
                    >
                      {calculateTemplateLength(formik.values.content, true) ??
                        0}
                      /{maxLinkedinInvitationChars}
                    </Typography>
                  </div>
                </>
              )}
              {/*  </>*/}
              {/*)}*/}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 6,
                }}
              >
                <Typography fontSize={14} fontWeight={600}>
                  Select language:*
                </Typography>
                <FormControl size="small">
                  <Select
                    labelId="select-small"
                    id="select-small"
                    name="language"
                    value={formik.values.language ?? undefined}
                    onChange={(ev) => handleChangeLanguage(ev.target.value)}
                    sx={{
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': {
                        border: `1px solid ${colors.grey4}`,
                        borderRadius: 2,
                      },
                      padding: '2px',
                    }}
                    MenuProps={{
                      style: { zIndex: 6001 },
                      PaperProps: {
                        sx: {
                          borderRadius: '8px',
                          paddingInline: '6px',
                          border: '1px solid #dadde9',
                          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                          '& .MuiMenuItem-root': {
                            fontSize: '12px',
                            lineHeight: '16px',
                            height: 'fit-content',
                            padding: '8px',
                            cursor: 'pointer',
                            width: '100%',
                            borderRadius: '8px',
                            '&:hover': {
                              background: colors.primaryLight,
                              color: colors.primary,
                            },
                          },
                          '& .Mui-selected': {
                            color: colors.primary,
                            backgroundColor: 'transparent !important',
                          },
                        },
                      },
                    }}
                  >
                    {Constants.templateLanguagesInput.map((language) => (
                      <MenuItem value={language} key={language}>
                        {language}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {externalPropertyTags?.data?.data.length !== 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                  }}
                >
                  <Typography fontSize={14} fontWeight={600}>
                    Add tags:
                  </Typography>
                  <div className="tags-container-template-creation">
                    <TagsBoxShowAll
                      type="AccountAndProspect"
                      currentSituationTags={tagSelected.map((tag) => ({
                        externalPropertyId: tag.externalPropertyId,
                        option: tag.value,
                      }))}
                      handleClickTag={handleClickTag}
                    />
                  </div>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <FormControl
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    lineHeight={'18px'}
                    color={colors.grey11}
                  >
                    Private
                  </Typography>
                  <CronoSwitch
                    checked={!shared}
                    onChange={() => {
                      setShared(!shared);
                    }}
                  />
                </FormControl>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <CancelButton onClick={() => close()}>Cancel</CancelButton>
                  {selectedType === TemplateType.EMAIL &&
                    (previewMailSending ? (
                      <LoaderContainer>
                        <CircularProgress />
                      </LoaderContainer>
                    ) : (
                      <GreyButton
                        className={'save-test-button'} 
                        onClick={handleSendPreview}
                      >
                        Send test
                      </GreyButton>
                    ))}
                  <MainButton
                    onClick={handleClickSubmit}
                    disabled={
                      selectedType === TemplateType.INVITATION &&
                      (calculateTemplateLength(formik.values.content, true) ??
                        0) > maxLinkedinInvitationChars
                    }
                  >
                    {selectedTemplate ? 'Save' : 'Create'}
                  </MainButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </TemplateManagementWrapper>
    </BlurBackgroundDivFixed>
  );
};

export default TemplateManagement;

import React, { useMemo, useState } from 'react';
import {
  Typography,
  Tooltip,
  Select,
  MenuItem,
  ClickAwayListener,
  Button,
} from '@mui/material';
import CallIconDark from 'crono-fe-common/icons/Icon-Call-Dark';
import EmailIconDark from 'crono-fe-common/icons/Icon-Email-Dark';
import LinkedinIconInMailCompassDark from 'crono-fe-common/icons/Icon-Linkedin-Inmail-Only-Compass-Dark';
import { colors } from 'crono-fe-common/theme';
import { SequenceStep } from 'crono-fe-common/types/sequenceStep';
import { ConditionalSequenceStepWrapper } from './style';
import { getDateFromScheduleTime } from 'utils/fe-utils';
import ReplyThreadIcon from 'crono-fe-common/icons/Icon-Reply-Thread';
import AddTemplateIconS from 'crono-fe-common/icons/Icon-Add-Template-S';
import SelectedTemplateIcon from 'crono-fe-common/icons/Icon-Selected-Template';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { Template } from 'crono-fe-common/types/template';
import { SequenceConditionalStep } from 'crono-fe-common/types/DTO/sequenceConditionalStep';
import { SwitchToTask } from 'crono-fe-common/types/enums/switchToConditionalStep';
import CloseSIcon from 'crono-fe-common/icons/Icon-Close-S';
import { ReactComponent as CloseIconDark } from 'assets/images/Icon_Close_Dark.svg';
import LinkedinIconInMailCompass from 'crono-fe-common/icons/Icon-Linkedin-Inmail-New';
import EditableTemplate from '../stepDetailComponent/editableTemplate';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { ReactComponent as PersonalizedSequenceIcon } from 'crono-fe-common/icons/PersonalizedSequenceInstance.svg';
import CronoTimePicker from 'crono-fe-common/components/CronoTimePicker';
import { CustomOptionsTooltip } from 'pages/settings/Strategy';
import { StepTypeOptionsWrapper } from '../stepDetailComponent/style';
import { ReactComponent as TopArrowIcon } from 'crono-fe-common/icons/Top-Arrow.svg';
import { ReactComponent as BottomArrowIcon } from 'crono-fe-common/icons/Bottom-Arrow.svg';
import { useAuth } from 'context/auth';
import { SequenceConditionalStepPatch } from 'crono-fe-common/types/DTO/sequenceConditionalStepPatch';
import classNames from 'classnames';

type ReplyThread = 'newThread' | 'existingThread';

interface IProps {
  conditionalStep: SequenceConditionalStep;
  handleChange: ((type: string, payload: any, index: number) => void) | null;
  index: number;
  openConditionalTemplatePicker: (type: SwitchToTask) => void;
  previousThreadSubject: SequenceStep | TaskTodo | undefined;
  onSuccessEditTemplate: () => void;
  disableEditsButTemplate?: boolean;
  onSaveReturnTemplate?: (subject: string, content: string) => void;
  patchConditionalStep?: (data: SequenceConditionalStepPatch) => void;
  step?: SequenceStep;

  //For template variables substitution
  isSequenceInstance?: boolean;
  prospectId?: string | null;
  accountId?: string | null;
  previewOnly?: boolean;

  allowToPersonalize?: boolean;
}

const ConditionalStepDetailComponent = ({
  conditionalStep,
  handleChange,
  index,
  openConditionalTemplatePicker,
  previousThreadSubject,
  patchConditionalStep,
  step,
  onSuccessEditTemplate,
  disableEditsButTemplate = false,
  onSaveReturnTemplate,
  isSequenceInstance,
  prospectId,
  accountId,
  previewOnly = false,
  allowToPersonalize,
}: IProps) => {
  const [isReplyThreadSubject, setIsReplyThreadSubject] = useState<ReplyThread>(
    conditionalStep.replyToThread ? 'existingThread' : 'newThread',
  );

  const { hasSalesNav } = useAuth();

  const [openTypeTooltip, setOpenTypeTooltip] = useState<boolean>(false);

  interface SwitchTypeOption {
    type: SwitchToTask;
    icon: JSX.Element;
    text: string;
  }

  const handleTypeChange = (newConditionalType: SwitchToTask) => {
    if (patchConditionalStep && step) {
      patchConditionalStep({
        sequenceStepId: step.id,
        type: newConditionalType,
        delay: newConditionalType === SwitchToTask.DELAY ? 1 : undefined,
        templateId: null,
        automatic: null,
        replyToThread: null,
        scheduleTime: null,
      });
    }
  };

  const switchToAnotherTypeOptions = useMemo(() => {
    if (isSequenceInstance) return null;

    const options: SwitchTypeOption[] = [];
    if (conditionalStep.type !== SwitchToTask.IN_MAIL && hasSalesNav) {
      options.push({
        type: SwitchToTask.IN_MAIL,
        icon: <LinkedinIconInMailCompass viewBox="0 2 20 20" />,
        text: 'Change to Linkedin InMail',
      });
    }

    if (conditionalStep.type !== SwitchToTask.SKIP) {
      options.push({
        type: SwitchToTask.SKIP,
        icon: <CloseSIcon className={'type-icon skip'} color={colors.grey11} />,
        text: 'Skip & Jump to the next task',
      });
    }

    if (options.length === 0) {
      return null;
    }

    return options.map((option, indexOption) => {
      return (
        <div key={indexOption}>
          <Button
            className="option-button"
            color="primary"
            fullWidth
            endIcon={option.icon}
            onClick={() => {
              handleTypeChange(option.type);
              setOpenTypeTooltip(false);
            }}
          >
            {option.text}
          </Button>
        </div>
      );
    });
  }, [conditionalStep, hasSalesNav, handleTypeChange, setOpenTypeTooltip]);

  const renderConditionalStepType = (step: SequenceConditionalStep) => {
    let icon = null;
    let type = '';
    switch (step.type) {
      case SwitchToTask.TO_CALL:
        icon = <CallIconDark />;
        type = 'Call';
        break;
      case SwitchToTask.TO_EMAIL:
        icon = <EmailIconDark />;
        type = 'Email';
        break;
      case SwitchToTask.SKIP:
        icon = <CloseIconDark />;
        type = 'Skip & Jump to the next task';
        break;
      case SwitchToTask.IN_MAIL:
        icon = <LinkedinIconInMailCompassDark />;
        type = 'Linkedin InMail';
        break;
    }

    const label = (
      <Typography fontSize={'12px'} fontWeight={500} paddingLeft={'12px'}>
        {type}
      </Typography>
    );

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {icon}
        {switchToAnotherTypeOptions ? (
          <div className="step-type-option-dropdown">
            <ClickAwayListener onClickAway={() => setOpenTypeTooltip(false)}>
              <div style={{ width: '100%' }}>
                <CustomOptionsTooltip
                  maxHeight={220}
                  width={235}
                  left={1}
                  title={
                    <StepTypeOptionsWrapper>
                      {switchToAnotherTypeOptions}
                    </StepTypeOptionsWrapper>
                  }
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  open={openTypeTooltip}
                  onClose={() => setOpenTypeTooltip(false)}
                  borderRadius={'16px'}
                  sx={{
                    zIndex: '10001 !important',
                  }}
                >
                  <div
                    className="step-type-option-container"
                    onClick={() => {
                      setOpenTypeTooltip((prev) => !prev);
                    }}
                  >
                    <div className="step-type-option">{label}</div>
                    {openTypeTooltip ? <TopArrowIcon /> : <BottomArrowIcon />}
                  </div>
                </CustomOptionsTooltip>
              </div>
            </ClickAwayListener>
          </div>
        ) : (
          label
        )}
      </div>
    );
  };

  const isEmail = conditionalStep.type === SwitchToTask.TO_EMAIL;

  const emailSubject = useMemo(
    () =>
      isReplyThreadSubject === 'newThread'
        ? conditionalStep.template?.subject
        : previousThreadSubject && previousThreadSubject.template?.subject,
    [isReplyThreadSubject, conditionalStep.template, previousThreadSubject],
  );

  const emailThreadButton = useMemo(
    () => (
      <div
        className={`flex-center email-thread ${
          disableEditsButTemplate ? 'disable-thread-selector' : ''
        }`}
      >
        <Select
          value={isReplyThreadSubject}
          startAdornment={
            <ReplyThreadIcon
              color={disableEditsButTemplate ? colors.grey11 : colors.mainDark}
            />
          }
          inputProps={{ IconComponent: () => null }}
          disabled={disableEditsButTemplate}
          defaultValue="newThread"
          onChange={(e) => {
            if (handleChange) {
              setIsReplyThreadSubject(e.target.value as ReplyThread);
              handleChange(
                'replyToThread',
                e.target.value === 'existingThread',
                index,
              );
            }
          }}
          sx={{
            fontSize: '14px',
            minWidth: '120px',
            boxShadow: 'none',
            fontWeight: '500',
            gap: '4px',
            padding: '0px',
            '.MuiOutlinedInput-input': {
              padding: '0px',
            },
            '.MuiSelect-select': {
              padding: '0px !important',
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover': {
              color: colors.darkGreen,
              '&& svg path': {
                stroke: disableEditsButTemplate
                  ? colors.grey11
                  : colors.darkGreen,
              },

              '&& fieldset': {
                borderColor: 'transparent',
              },
            },
          }}
          MenuProps={{
            style: { zIndex: 3501 },
            PaperProps: {
              sx: {
                borderRadius: '8px',
                paddingInline: '6px',
                border: '1px solid #dadde9',
                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
                '& .MuiMenuItem-root': {
                  fontWeight: '500',
                  fontSize: '12px',
                  lineHeight: '16px',
                  height: 'fit-content',
                  padding: '8px',
                  cursor: 'pointer',
                  width: '100%',
                  borderRadius: '8px',
                  '&:hover': {
                    background: colors.primaryLight,
                    color: colors.primary,
                  },
                },
                '& .Mui-selected': {
                  color: colors.primary,
                  backgroundColor: 'transparent !important',
                },
              },
            },
          }}
        >
          <MenuItem value={'newThread'}>New email thread</MenuItem>
          <MenuItem value={'existingThread'}>Previous email thread</MenuItem>
        </Select>
      </div>
    ),
    [setIsReplyThreadSubject, handleChange, index, isReplyThreadSubject],
  );

  const stepType = useMemo(() => {
    return (
      conditionalStep.type === SwitchToTask.TO_EMAIL ||
      conditionalStep.type === SwitchToTask.IN_MAIL ||
      conditionalStep.type === SwitchToTask.TO_CALL
    );
  }, [conditionalStep]);

  return (
    <ConditionalSequenceStepWrapper>
      <div
        className="step-row-conditional"
        id={
          conditionalStep
            ? `${conditionalStep.id.toString()}-conditional`
            : undefined
        }
      >
        <div className="step-content-single-step">
          <div
            className={classNames('step-content-top', {
              'step-content-no-border-bottom': stepType,
            })}
            style={{
              borderBottom: conditionalStep.template ? 'none' : '',
            }}
          >
            <div
              className="flex-center step-left-side-wrapper"
              style={{
                minWidth:
                  conditionalStep.type !== SwitchToTask.TO_EMAIL
                    ? '340px'
                    : undefined,
              }}
            >
              {renderConditionalStepType(conditionalStep)}

              <div style={{ display: 'flex', alignItems: 'center' }}>
                {conditionalStep.type === SwitchToTask.TO_EMAIL &&
                !previewOnly ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Tooltip
                        arrow
                        enterDelay={400}
                        enterNextDelay={400}
                        title={
                          disableEditsButTemplate ? (
                            <span
                              style={{ display: 'flex', textAlign: 'center' }}
                            >
                              The change can’t be made for each contact. Please
                              change this for the whole strategy
                            </span>
                          ) : conditionalStep.template ? (
                            'Automate this step'
                          ) : (
                            'Please select a template to automate this step'
                          )
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: disableEditsButTemplate
                              ? 'not-allowed'
                              : 'pointer',
                          }}
                        >
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color={colors.grey11}
                            paddingLeft={'20px'}
                          >
                            Auto
                          </Typography>

                          <CronoSwitch
                            checked={conditionalStep.automatic ?? false}
                            onChange={(ev) => {
                              if (handleChange) {
                                handleChange(
                                  'automatic',
                                  ev.target.checked,
                                  index,
                                );
                              }
                            }}
                            disabled={
                              !conditionalStep.template ||
                              conditionalStep.type !== SwitchToTask.TO_EMAIL ||
                              disableEditsButTemplate
                            }
                          />
                        </div>
                      </Tooltip>

                      {conditionalStep.automatic && (
                        <CronoTimePicker
                          value={getDateFromScheduleTime(
                            conditionalStep.scheduleTime ?? null,
                          )}
                          disabled={
                            !conditionalStep.template ||
                            conditionalStep.type !== SwitchToTask.TO_EMAIL ||
                            !conditionalStep.automatic ||
                            disableEditsButTemplate
                          }
                          handleChange={(newValue) => {
                            if (handleChange) {
                              handleChange('scheduleTime', newValue, index);
                            }
                          }}
                        />
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          {stepType ? (
            <div
              className={classNames('step-content-bottom', {
                'step-content-no-border-top': stepType,
              })}
              // style={previewOnly ? {minHeight: '0px'} : {}}
            >
              <div className="vertical-divider" />

              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                    justifyContent: 'space-between',
                    flex: 1,
                    alignSelf: conditionalStep.template
                      ? 'flex-start'
                      : undefined,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                      paddingInline: '12px',
                    }}
                  >
                    <Tooltip
                      title={
                        disableEditsButTemplate ? (
                          <span
                            style={{ display: 'flex', textAlign: 'center' }}
                          >
                            The change can’t be made for each contact. Please
                            change this for the whole strategy
                          </span>
                        ) : null
                      }
                      enterDelay={400}
                      enterNextDelay={400}
                      arrow
                    >
                      <div
                        className={`flex-center ${
                          disableEditsButTemplate
                            ? 'step-component-template-disable'
                            : conditionalStep.template
                              ? 'selected-template'
                              : 'add-template'
                        }`}
                      >
                        {conditionalStep.template ? (
                          <>
                            <span className="flex-center icon-wrapper">
                              <SelectedTemplateIcon
                                className={'step-component-button'}
                                color={colors.grey11}
                                onClick={() => {
                                  if (!disableEditsButTemplate) {
                                    openConditionalTemplatePicker(
                                      conditionalStep.type,
                                    );
                                  }
                                }}
                              />
                            </span>

                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              noWrap
                              onClick={() => {
                                if (previewOnly) return;

                                if (!disableEditsButTemplate) {
                                  openConditionalTemplatePicker(
                                    conditionalStep.type,
                                  );
                                }
                              }}
                            >
                              {conditionalStep.template.title}
                            </Typography>

                            <span
                              onClick={() => {
                                if (handleChange && !disableEditsButTemplate) {
                                  handleChange(
                                    'template',
                                    { id: 0 } as Template,
                                    index,
                                  );
                                }
                              }}
                              style={
                                !previewOnly
                                  ? { cursor: 'pointer', display: 'flex' }
                                  : { display: 'none' }
                              }
                              className="close-template-option"
                            >
                              <CloseMIcon color={colors.grey11} />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="flex-center icon-wrapper">
                              <AddTemplateIconS
                                className={'step-component-button'}
                                onClick={() => {
                                  if (!disableEditsButTemplate) {
                                    openConditionalTemplatePicker(
                                      conditionalStep.type,
                                    );
                                  }
                                }}
                              />
                            </span>

                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              noWrap
                              onClick={() => {
                                if (!disableEditsButTemplate) {
                                  openConditionalTemplatePicker(
                                    conditionalStep.type,
                                  );
                                }
                              }}
                            >
                              Add template
                            </Typography>
                          </>
                        )}
                      </div>
                    </Tooltip>

                    {conditionalStep.template?.isPersonalized && (
                      <Tooltip
                        title="Template is personalized"
                        enterDelay={400}
                        enterNextDelay={400}
                        arrow
                      >
                        <PersonalizedSequenceIcon />
                      </Tooltip>
                    )}
                  </div>
                </div>

                {/* Template body if existing template */}
                {conditionalStep?.template && (
                  <EditableTemplate
                    allowToPersonalize={allowToPersonalize}
                    id={'editable-template-conditional-right-leaf'}
                    applyIdOnlyOnEditMode={true}
                    previewOnly={previewOnly}
                    key={`${conditionalStep.template.id}-${conditionalStep.id}`}
                    emailThreadButton={isEmail ? emailThreadButton : undefined}
                    subject={
                      isEmail ? emailSubject : conditionalStep.template?.subject
                    }
                    enableSubjectMutation={isReplyThreadSubject === 'newThread'}
                    template={conditionalStep.template}
                    stepId={conditionalStep.sequenceStepId}
                    onSuccess={onSuccessEditTemplate}
                    onSaveReturnTemplate={onSaveReturnTemplate}
                    isSequenceInstance={isSequenceInstance}
                    prospectId={prospectId}
                    accountId={accountId}
                    uniqueIdentifierOfTemplate={conditionalStep.id}
                    isConditional={true}
                  />
                )}
              </>
            </div>
          ) : null}
        </div>
      </div>
    </ConditionalSequenceStepWrapper>
  );
};

export default ConditionalStepDetailComponent;

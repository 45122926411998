import { ClickAwayListener } from '@mui/base';
import {
  CircularProgress,
  Button,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  TextField,
  Typography,
} from '@mui/material';
import {
  MainPrimaryButton,
  MainPrimarySmallButton,
  RedButton,
} from 'crono-fe-common/components/CronoButton';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ProspectAndAccountIds,
  ProspectAndFromContact,
} from 'crono-fe-common/types/DTO/ProspectAndAccountIds';
import { CustomTooltip, IInformationForSelectedScrapeMissing } from '..';
import MultipleSequenceAdd from '../multipleSequenceAdd';
import useDeleteMultipleSequences from 'hooks/services/sequence/useDeleteMultipleSequences';
import { useConditionalSnackBar } from 'context/snackbar';
import {
  TableFooterWrapper,
  SequenceActionTooltipWrapper,
  GreyOwnerBadgeWrapper,
} from '../../footerStyle';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import IconStrategySettings from 'crono-fe-common/icons/Icon-Strategy-Settings';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import useEditProspectMultiple from 'hooks/services/prospect/useEditProspectMultiple';
import { UpdateProspectMultiple } from 'crono-fe-common/types/DTO/updateProspectMultiple';
import {
  getError,
  getNewOptionsWhenSelectingWithStrings,
} from 'crono-fe-common/utils';
import { Constants } from 'crono-fe-common/constants/constants';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import useDisableProspectMultiple from 'hooks/services/prospect/useDisableProspectMultiple';
import { useConfirmModal } from 'context/confirmModal';
import TagsBoxShowAll from 'components/TagsBoxShowAll';
import useGetUsers, {
  UserSubscription,
} from 'hooks/services/subscription/useGetUsers';
import { fullname } from 'utils/fe-utils';
import OwnerIcon from 'crono-fe-common/icons/Icon-Owner';
import PlusSmallIcon from 'crono-fe-common/icons/Icon-Plus-Small';
import useSyncProspects from 'hooks/services/prospect/useSyncProspects';
import { DefaultContactStatusOptions } from 'crono-fe-common/types/model';
import useGetExternalPropertyStatus from 'hooks/services/externalProperty/useGetExternalPropertyStatus';
import CronoStatus from 'components/CronoStatus';
import useLookSync from 'hooks/services/sync/useLookSync';
import { ImportStatusType } from 'crono-fe-common/types/enums/importStatusType';
import { useJuneAnalytics } from 'context/june';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import IconNotOwned from 'crono-fe-common/icons/Icon-Not-Owned';
import EditPencilIconS from 'crono-fe-common/icons/Icon-Edit-Pencil-S';
import useAddMultipleProspectToSequence from 'hooks/services/sequence/useAddMultipleProspectToSequence';
import { Strategy } from 'crono-fe-common/types/strategy';
import CloseSIcon from 'crono-fe-common/icons/Icon-Close-S';
import { StatusTagButtonWrapper } from 'components/CronoStatus/style';
import TagIcon from 'crono-fe-common/icons/Icon-Tag-Settings';
import IconTopArrow from 'crono-fe-common/icons/Icon-Top-Arrow';
import IconBottomArrow from 'crono-fe-common/icons/Icon-Bottom-Arrow';
import IconRectangle from 'crono-fe-common/icons/Icon-Rectangle';
import IconPlay from 'crono-fe-common/icons/Icon-Play';
import useResumeProspectSequenceMultiple from 'hooks/services/sequence/useResumeProspectSequenceMultiple';
import EnrichFooterModal from './enrichFooterModal';
import EnrichIcon from 'crono-fe-common/icons/Icon-Enrich';
import usePostScrapeFindBulk from 'hooks/services/scrape/usePostScrapeFindBulk';
import { DashboardTabType } from '../../index';

interface IProps {
  selected?: ProspectAndAccountIds[];
  clearSelected: () => void;
  selectedToPause?: string[];
  selectedToStart?: string[];
  selectedToAdd?: ProspectAndAccountIds[];
  selectedOwned?: ProspectAndAccountIds[];
  selectedExternal?: ProspectAndFromContact[];
  selectedEmailMissing?: IInformationForSelectedScrapeMissing[];
  selectedPhoneMissing?: IInformationForSelectedScrapeMissing[];
  setSearchingEmailStarted: React.Dispatch<
    React.SetStateAction<string[] | null>
  >;
  setSearchingPhoneStarted: React.Dispatch<
    React.SetStateAction<string[] | null>
  >;
  onExternalAdd?: () => void;
  onCompleteSync?: (ids: ProspectAndFromContact[]) => void;
  tabState: DashboardTabType;
}

export const TooltipFooter = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    arrow={props.arrow ? props.arrow : false}
    {...props}
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    maxWidth: 350,
    border: `1px solid ${colors.grey4}`,
    borderRadius: 16,
    padding: 0,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

const ProspectSelectionBottom = ({
  selected,
  clearSelected,
  selectedToPause,
  //For the moment product team decided not to use this
  selectedToStart,
  selectedToAdd,
  selectedOwned,
  selectedExternal,
  selectedEmailMissing,
  selectedPhoneMissing,
  setSearchingEmailStarted,
  setSearchingPhoneStarted,
  onExternalAdd,
  onCompleteSync,
  tabState,
}: IProps) => {
  const { user } = useAuth();

  const {
    mutate: stopMultipleSequences,
    isLoading: loadingStopMultipleSequences,
    error: errorStoppingMultipleSequences,
    isSuccess: sequencesStopped,
    data: stopMultipleResponse,
  } = useDeleteMultipleSequences();

  useEffect(() => {
    if (sequencesStopped) {
      clearFooter();
      clearSelected();
    }
  }, [sequencesStopped]);

  const analytics = useJuneAnalytics();

  const handleStopMultipleSequences = useCallback(() => {
    stopMultipleSequences(selectedToPause ?? []);
    if (analytics) {
      analytics.track('stop-multiple-sequence', {
        count: selectedToPause?.length ?? 0,
      });
    }
  }, [analytics, selectedToPause, stopMultipleSequences]);

  const { mutate: resumeProspectSequenceMultiple } =
    useResumeProspectSequenceMultiple();

  const handleResumeMultipleSequences = useCallback(() => {
    resumeProspectSequenceMultiple({
      prospectIds: selectedToStart ?? [],
    });
    if (analytics) {
      analytics.track('resume-multiple-strategies', {
        count: selectedToStart?.length ?? 0,
      });
    }
  }, [analytics, selectedToStart]);

  const clearFooter = () => {
    setStatusSelected(null);
    setOpenStrategyPickerTooltip(false);
    setExternalValues(new Array(Constants.numberOfTags).fill(null));
    setOpenTooltipTags(false);
    setStrategyPicked(null);
    setSelectedUser(null);
    setOpenTooltipSequenceAction(false);
    setEnrichEmail(false);
    setEnrichPhone(false);
    setSearchUserName('');
  };

  useEffect(() => {
    if (selected?.length === 0) {
      clearFooter();
    }
  }, [selected]);

  useEffect(() => {
    return () => {
      clearFooter();
    };
  }, []);

  const { data: externalProperties } = useGetExternalProperty(
    tabState === 'Lead' ? 'Lead' : 'Prospect',
    true,
    false,
  );

  const [externalValues, setExternalValues] = useState<(string | null)[]>(
    new Array(Constants.numberOfTags).fill(null),
  );

  const { data: externalPropertyStatus } =
    useGetExternalPropertyStatus('Prospect');

  const isExternalPropertyStatusId =
    externalPropertyStatus?.data?.data?.id ?? null;
  const [strategyPicked, setStrategyPicked] = useState<Strategy | null>(null);

  const {
    mutateAsync: editProspectMultipleAsync,
    error: prospectsUpdateError,
    isLoading: prospectsUpdating,
    isSuccess: prospectsUpdated,
  } = useEditProspectMultiple();

  const {
    mutateAsync: addMultipleProspectToSequenceAsync,
    isLoading: isLoadingAddMultipleProspectToSequence,
    error: errorAddMultipleProspectToSequence,
  } = useAddMultipleProspectToSequence();

  const { mutateAsync: postScrapeFindBulkAsync } = usePostScrapeFindBulk();

  const handleApply = async () => {
    if (changesToProspect) {
      const newExternalValues: {
        [key: number]: string | null;
      } = {};
      externalValues.forEach((value, index) => {
        if (value && externalProperties?.data?.data[index].id) {
          newExternalValues[externalProperties?.data?.data[index].id] = value;
        }
      });
      //In case the status is not the default one of Crono but an externalProperty
      if (isExternalPropertyStatusId !== null && statusSelected !== null) {
        newExternalValues[isExternalPropertyStatusId] = statusSelected;
      }
      const data: UpdateProspectMultiple[] =
        selected?.map((prospect) => ({
          prospectId: prospect.prospectId,
          externalValues: newExternalValues,
          //If case the status is the default one of Crono
          ...(isExternalPropertyStatusId === null
            ? { status: statusSelected as DefaultContactStatusOptions }
            : {}),
          ...(selectedUser ? { userId: selectedUser.id } : {}),
        })) ?? [];

      await editProspectMultipleAsync(data);
    }
    if (enrichEmail || enrichPhone) {
      const arrayToPass: IInformationForSelectedScrapeMissing[] = [];
      if (enrichEmail && enrichPhone) {
        //Pass to the arrayToPass the union of both selectedEmailMissing and selectedPhoneMissing
        const union = [
          ...(selectedEmailMissing ?? []),
          ...(selectedPhoneMissing ?? []),
        ];
        const uniqueProspects = union.filter(
          (v, i, a) => a.findIndex((t) => t.prospectId === v.prospectId) === i,
        );
        arrayToPass.push(...uniqueProspects);
      } else if (enrichEmail) {
        arrayToPass.push(...(selectedEmailMissing ?? []));
      } else if (enrichPhone) {
        arrayToPass.push(...(selectedPhoneMissing ?? []));
      }

      const importId = await postScrapeFindBulkAsync({
        data: arrayToPass,
        findEmail: enrichEmail,
        findPhone: enrichPhone,
      });
      if (analytics) {
        analytics.track('enrich-multiple-prospects', {
          count: arrayToPass.length,
          enrichEmail,
          enrichPhone,
        });
      }
      if (importId.data?.data) {
        if (enrichEmail)
          setSearchingEmailStarted((prev) => [
            ...(prev ?? []),
            ...(selectedEmailMissing?.map((el) => el.prospectId) ?? []),
          ]);
        if (enrichPhone)
          setSearchingPhoneStarted((prev) => [
            ...(prev ?? []),
            ...(selectedPhoneMissing?.map((el) => el.prospectId) ?? []),
          ]);
      }
    }
    //Clear after patching
    clearFooter();
    clearSelected();
  };

  const [statusSelected, setStatusSelected] = useState<string | null>(null);

  const handleChangeStatus = useCallback(
    (status: string) => {
      if (statusSelected === status) {
        setStatusSelected(null);
      } else {
        setStatusSelected(status);
      }
    },
    [setStatusSelected, statusSelected],
  );

  const {
    mutate: disableMultipleProspect,
    isLoading: disablingMultipleProspect,
    isSuccess: disabledMultipleProspect,
    error: disableMultipleProspectError,
  } = useDisableProspectMultiple();

  const { openModal: openConfirmModal } = useConfirmModal();

  const handleDeleteMultipleProspects = () => {
    openConfirmModal({
      title: 'Delete contacts',
      text: 'Are you sure you want to delete the selected contacts?',
      confirmFunction: () => {
        disableMultipleProspect({
          data: selectedOwned ?? [],
        });
      },
      confirmText: 'Delete',
      cancelText: 'Cancel',
      cancelFunction: () => {},
    });
  };

  const { mutateAsync: syncProspectASync, error: errorSyncingProspects } =
    useSyncProspects();

  const { mutateAsync: lookSyncAsync } = useLookSync();

  const [isSyncing, setIsSyncing] = useState<boolean>(false);

  const [errorImportingExternal, setErrorImportingExternal] = useState<
    string | null
  >(null);

  const startLookSync = (id: number, ids: ProspectAndFromContact[]) => {
    const t = setInterval(async () => {
      const res = await lookSyncAsync(id);
      if (res.data?.data.status === ImportStatusType.CompletedWithErrors) {
        clearInterval(t);
        setErrorImportingExternal('Error syncing some contacts');
        setTimeout(() => {
          setErrorImportingExternal(null);
        }, 3000);
        setTimeout(() => {
          if (onCompleteSync) onCompleteSync(ids);
        }, 500);
      } else if (res.data?.data.status === ImportStatusType.Completed) {
        if (onCompleteSync) onCompleteSync(ids);
        clearInterval(t);
      }
    }, 5000);
  };

  const handleSyncAllProspects = async () => {
    const fromContactTrue = selectedExternal?.filter(
      (prospect) => prospect.fromContact,
    );
    const fromContactFalse = selectedExternal?.filter(
      (prospect) => !prospect.fromContact,
    );
    setIsSyncing(true);

    if (fromContactTrue && fromContactTrue.length > 0) {
      const res = await syncProspectASync({
        data: fromContactTrue?.map((prospect) => prospect.objectId),
        isLead: false,
      });
      if (res?.data?.data) {
        startLookSync(res.data.data, fromContactTrue);
      }
    }
    if (fromContactFalse && fromContactFalse.length > 0) {
      const res = await syncProspectASync({
        data: fromContactFalse?.map((prospect) => prospect.objectId),
        isLead: true,
      });
      if (res?.data?.data) {
        startLookSync(res.data.data, fromContactFalse);
      }
    }

    if (onExternalAdd) onExternalAdd();
    setIsSyncing(false);
    clearSelected();
  };

  useEffect(() => {
    if (disabledMultipleProspect) {
      clearFooter();
      clearSelected();
    }
  }, [disabledMultipleProspect]);

  const { data: usersData } = useGetUsers();
  const users = useMemo(() => usersData?.data?.data ?? [], [usersData]);

  const [searchUserName, setSearchUserName] = useState<string | null>(null);

  const filteredUsers = useMemo(() => {
    if (searchUserName) {
      return users?.filter((user) =>
        fullname(user.firstName, user.lastName)
          .toLowerCase()
          .includes(searchUserName.toLowerCase()),
      );
    }
    return users;
  }, [searchUserName]);

  const [openTooltipOwner, setOpenTooltipOwner] = useState<boolean>(false);

  const handleTooltipOwnerClose = useCallback(() => {
    setOpenTooltipOwner(false);
  }, [setOpenTooltipOwner]);
  const handleTooltipOwnerOpen = useCallback(() => {
    setOpenTooltipOwner(true);
  }, [setOpenTooltipOwner]);

  const [openTooltipEnrich, setOpenTooltipEnrich] = useState<boolean>(false);

  const [enrichEmail, setEnrichEmail] = useState<boolean>(false);
  const [enrichPhone, setEnrichPhone] = useState<boolean>(false);

  const handleTooltipEnrichClose = useCallback(() => {
    setOpenTooltipEnrich(false);
  }, [setOpenTooltipEnrich]);

  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (openTooltipOwner && textFieldRef.current) {
      textFieldRef.current?.focus();
    }
  }, [textFieldRef.current, openTooltipOwner]);

  const [selectedUser, setSelectedUser] = useState<UserSubscription | null>(
    null,
  );

  const handleSelectUser = (userId: number) => {
    setSelectedUser(users?.find((user) => user.id === userId) ?? null);
    handleTooltipOwnerClose();
    setSearchUserName('');
  };

  const onEnterOwner = useCallback(() => {
    if (filteredUsers?.length >= 1) {
      setSelectedUser(filteredUsers[0]);
      handleTooltipOwnerClose();
      setSearchUserName('');
    }
  }, [
    filteredUsers,
    setSelectedUser,
    handleTooltipOwnerClose,
    setSearchUserName,
  ]);

  const handleRemoveUser = useCallback(() => {
    setSelectedUser(null);
  }, [setSelectedUser]);

  //These are the changes that allows the apply to multiple prospects to fire
  const changesToProspect = useMemo(() => {
    return (
      externalValues.some((value) => value !== null) ||
      statusSelected ||
      selectedUser
    );
  }, [externalValues, statusSelected, selectedUser]);

  //If this is true something changed and we should show the apply button
  const showApply = useMemo(() => {
    return changesToProspect || enrichEmail || enrichPhone;
  }, [changesToProspect, enrichEmail, enrichPhone]);

  const handleClickTag = (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => {
    const finalOption = getNewOptionsWhenSelectingWithStrings({
      currentValue: externalValues[index],
      option,
      valueType: externalProperty.valueType,
    });
    const newExternalValues = [...externalValues];
    newExternalValues[index] = finalOption;
    setExternalValues(newExternalValues);
  };

  //Strategy picker
  const [openStrategyPickerTooltip, setOpenStrategyPickerTooltip] =
    useState<boolean>(false);

  useConditionalSnackBar([
    {
      condition: !!errorStoppingMultipleSequences,
      message:
        getError(errorStoppingMultipleSequences) ?? 'Error stopping strategies',
      severity: 'error',
    },
    {
      condition: !!sequencesStopped && !!stopMultipleResponse?.data?.data,
      message: `${stopMultipleResponse?.data?.data.ok} strategies correctly stopped`,
      severity: 'success',
    },
    {
      condition: !!prospectsUpdateError,
      message: getError(prospectsUpdateError) ?? 'Error updating contacts',
      severity: 'error',
    },
    {
      condition: !!prospectsUpdated,
      message: 'Contacts correctly updated',
      severity: 'success',
    },
    {
      condition: !!disabledMultipleProspect,
      message: 'Contacts correctly deleted',
      severity: 'success',
    },
    {
      condition: !!disableMultipleProspectError,
      message:
        getError(disableMultipleProspectError) ?? 'Error deleting contacts',
      severity: 'error',
    },
    {
      condition: !!errorSyncingProspects,
      message: getError(errorSyncingProspects) ?? 'Error adding contacts',
      severity: 'error',
    },
    {
      condition: !!errorImportingExternal,
      message: errorImportingExternal ?? 'Error syncing some companies',
      severity: 'error',
    },
    {
      condition: !!errorAddMultipleProspectToSequence,
      message:
        getError(errorAddMultipleProspectToSequence) ??
        'Error adding contacts to strategy',
      severity: 'error',
    },
  ]);

  const [openTooltipTags, setOpenTooltipTags] = useState<boolean>(false);

  //After implemlenting the strategy to be picked and then applied we decided to roll back to the previous implementation that was applying the strategy immediately after selection
  //This is the fastest way to implement it
  useEffect(() => {
    const handleOnStrategyPicked = async () => {
      if (!strategyPicked) return;
      await addMultipleProspectToSequenceAsync({
        prospects:
          selectedToAdd?.map((prospect) => ({
            prospectId: prospect.prospectId,
            accountId: prospect.accountId,
          })) ?? [],
        strategyId: strategyPicked?.id,
      });
      //Clear after patching
      clearFooter();
      clearSelected();
    };
    handleOnStrategyPicked();
  }, [strategyPicked]);

  const [openTooltipSequenceAction, setOpenTooltipSequenceAction] =
    useState(false);

  return selected && selected.length > 0 ? (
    <TableFooterWrapper>
      <Button
        className="selected-button"
        variant="contained"
        color="secondary"
        endIcon={<CloseMIcon color={colors.grey11} />}
        disableElevation
        onClick={() => {
          if (clearSelected) {
            clearSelected();
          }
        }}
      >
        {selected.length} selected
      </Button>
      {((selectedToPause?.length ?? 0) > 0 ||
        (selectedToAdd?.length ?? 0) > 0 ||
        (selectedToStart?.length ?? 0) > 0) && (
        <ClickAwayListener
          onClickAway={() => {
            setOpenTooltipSequenceAction(false);
          }}
        >
          <div>
            <TooltipFooter
              title={
                <SequenceActionTooltipWrapper>
                  {(selectedToAdd?.length ?? 0) > 0 && (
                    <ClickAwayListener
                      onClickAway={() => setOpenStrategyPickerTooltip(false)}
                    >
                      <div>
                        <CustomTooltip
                          title={
                            <MultipleSequenceAdd
                              setOpen={setOpenStrategyPickerTooltip}
                              handlePickStrategy={(
                                strategyPicked: Strategy,
                              ) => {
                                setStrategyPicked(strategyPicked);
                              }}
                              selected={selected}
                              onSuccess={() => {
                                clearSelected();
                              }}
                            />
                          }
                          onClose={() => setOpenStrategyPickerTooltip(false)}
                          open={openStrategyPickerTooltip}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <Button
                            className="sequence-action-tooltip-button"
                            onClick={() =>
                              setOpenStrategyPickerTooltip((prev) => !prev)
                            }
                            startIcon={
                              <div className="div-add-strategy-plus">
                                <PlusSmallIcon
                                  color={colors.primary}
                                  className="add-strategy-plus"
                                />
                              </div>
                            }
                          >
                            Add to new strategy ({selectedToAdd?.length ?? 0}{' '}
                            {selectedToAdd?.length === 1
                              ? 'contact'
                              : 'contacts'}
                            )
                          </Button>
                        </CustomTooltip>
                      </div>
                    </ClickAwayListener>
                  )}
                  {(selectedToPause?.length ?? 0) > 0 &&
                    (loadingStopMultipleSequences ? (
                      <div
                        style={{
                          width: '100%',
                          padding: '2px 0px 2px 8px',
                          minWidth: 90,
                        }}
                      >
                        <CircularProgress size={18} />
                      </div>
                    ) : (
                      <Button
                        className="sequence-action-tooltip-button"
                        onClick={handleStopMultipleSequences}
                        startIcon={
                          <div className="div-stop-strategy-rectangle">
                            <IconRectangle
                              color={colors.orangeDark}
                              className="stop-strategy-rectangle"
                            />
                          </div>
                        }
                      >
                        Stop strategy ({selectedToPause?.length ?? 0})
                      </Button>
                    ))}
                  {(selectedToStart?.length ?? 0) > 0 &&
                    (loadingStopMultipleSequences ? (
                      <div
                        style={{
                          width: '100%',
                          padding: '2px 0px 2px 8px',
                          minWidth: 90,
                        }}
                      >
                        <CircularProgress size={18} />
                      </div>
                    ) : (
                      <Button
                        className="sequence-action-tooltip-button"
                        onClick={handleResumeMultipleSequences}
                        startIcon={
                          <div className="div-resume-strategy-triangle">
                            <IconPlay
                              color={colors.callScheduledHover}
                              className="resume-strategy-triangle"
                            />
                          </div>
                        }
                      >
                        Resume strategy ({selectedToStart?.length ?? 0})
                      </Button>
                    ))}
                </SequenceActionTooltipWrapper>
              }
              onClose={() => setOpenTooltipSequenceAction(false)}
              open={openTooltipSequenceAction}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <MainPrimarySmallButton
                onClick={() => setOpenTooltipSequenceAction((prev) => !prev)}
                startIcon={
                  <IconStrategySettings
                    className="strategy-icon"
                    color={colors.white}
                  />
                }
                style={{
                  padding: '4px 12px',
                }}
                endIcon={
                  openTooltipSequenceAction ? (
                    <IconTopArrow color={colors.white} />
                  ) : (
                    <IconBottomArrow color={colors.white} />
                  )
                }
              >
                Strategy
              </MainPrimarySmallButton>
            </TooltipFooter>
          </div>
        </ClickAwayListener>
      )}
      <div className="divider" />

      {/*Owner picker */}

      <ClickAwayListener onClickAway={handleTooltipOwnerClose}>
        <div>
          <TooltipFooter
            title={
              <SequenceActionTooltipWrapper>
                {filteredUsers?.map((user) => {
                  return (
                    <Button
                      key={user.id}
                      className={`sequence-action-tooltip-button ${
                        user.id === selectedUser?.id ? 'selected-user' : ''
                      }`}
                      onClick={() => handleSelectUser(user.id)}
                    >
                      {fullname(user.firstName, user.lastName)}
                    </Button>
                  );
                })}
              </SequenceActionTooltipWrapper>
            }
            onClose={handleTooltipOwnerClose}
            open={openTooltipOwner}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <GreyOwnerBadgeWrapper
              startIcon={
                selectedUser?.id === user?.id || !selectedUser?.id ? (
                  <OwnerIcon
                    className="sequence-action-icon"
                    color={colors.mainDark}
                  />
                ) : (
                  <IconNotOwned
                    className="sequence-action-icon"
                    color={colors.mainDark}
                  />
                )
              }
              color="secondary"
              // style={{
              //   filter: strategyPicked ? 'grayscale(1)' : 'none',
              //   cursor: strategyPicked ? 'default' : 'pointer',
              //   opacity: strategyPicked ? 0.5 : 1,
              // }}
              onClick={() => {
                if (openTooltipOwner) {
                  handleTooltipOwnerClose();
                } else {
                  handleTooltipOwnerOpen();
                }
              }}
              endIcon={
                <EditPencilIconS
                  color={colors.grey11}
                  className="sequence-action-icon"
                />
              }
            >
              {openTooltipOwner ? (
                <TextField
                  value={searchUserName}
                  inputRef={textFieldRef}
                  placeholder="Type..."
                  onChange={(ev) => {
                    if (setSearchUserName) setSearchUserName(ev.target.value);
                  }}
                  autoComplete="false"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      onEnterOwner();
                    }
                  }}
                  inputProps={{
                    style: {
                      padding: 0,
                      fontSize: 12,
                    },
                  }}
                  sx={{
                    height: '16px',
                    width: '60px',
                  }}
                />
              ) : selectedUser ? (
                <>
                  <Typography
                    fontSize={12}
                    lineHeight={'16px'}
                    fontWeight={400}
                    noWrap
                  >
                    {fullname(selectedUser.firstName, selectedUser.lastName)}
                  </Typography>
                  <CloseSIcon
                    color={colors.grey11}
                    onClick={(ev: any) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      handleRemoveUser();
                    }}
                  />
                </>
              ) : (
                'Owner'
              )}
            </GreyOwnerBadgeWrapper>
          </TooltipFooter>
        </div>
      </ClickAwayListener>

      <ClickAwayListener onClickAway={handleTooltipEnrichClose}>
        <div>
          <TooltipFooter
            title={
              <EnrichFooterModal
                enrichEmail={enrichEmail}
                emailRequiredLength={selectedEmailMissing?.length ?? 0}
                enrichPhone={enrichPhone}
                phoneRequiredLength={selectedPhoneMissing?.length ?? 0}
                setEnrichEmail={setEnrichEmail}
                setEnrichPhone={setEnrichPhone}
              />
            }
            onClose={handleTooltipOwnerClose}
            open={openTooltipEnrich}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <StatusTagButtonWrapper
              onClick={() => setOpenTooltipEnrich((prev) => !prev)}
            >
              <EnrichIcon className="status-settings-icon" />
              <Typography fontSize={12} lineHeight={'16px'} noWrap>
                Enrich{' '}
                {enrichPhone || enrichEmail
                  ? `(${enrichPhone && enrichEmail ? 2 : 1}): ${
                      enrichPhone && enrichEmail
                        ? 'Emails, Phones'
                        : enrichPhone
                          ? 'Phones'
                          : 'Emails'
                    }`
                  : ''}
              </Typography>
              {openTooltipEnrich ? (
                <IconTopArrow className="tag-arrow" />
              ) : (
                <IconBottomArrow className="tag-arrow" />
              )}
            </StatusTagButtonWrapper>
          </TooltipFooter>
        </div>
      </ClickAwayListener>

      <ClickAwayListener onClickAway={() => setOpenTooltipTags(false)}>
        <div>
          <TooltipFooter
            title={
              <div style={{ padding: 8 }}>
                <TagsBoxShowAll
                  type={tabState === 'Lead' ? 'Lead' : 'Prospect'}
                  currentSituation={externalValues}
                  handleClickTag={handleClickTag}
                  customStyle={{
                    maxWidth: 100,
                  }}
                />
              </div>
            }
            open={openTooltipTags}
            onClose={() => setOpenTooltipTags(false)}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <StatusTagButtonWrapper
              onClick={() => setOpenTooltipTags((prev) => !prev)}
            >
              <TagIcon className="status-settings-icon" />
              <Typography fontSize={12} lineHeight={'16px'} noWrap>
                Tags{' '}
                {externalValues.some((value) => value !== null)
                  ? `(${selected.length}): ${externalValues
                      .map((value, index) => {
                        if (value === null) return null;
                        return `${externalProperties?.data?.data[index].publicName}`;
                      })
                      .filter((val) => val)
                      .join(', ')}`
                  : ''}
              </Typography>
              {openTooltipTags ? (
                <IconTopArrow className="tag-arrow" />
              ) : (
                <IconBottomArrow className="tag-arrow" />
              )}
            </StatusTagButtonWrapper>
          </TooltipFooter>
        </div>
      </ClickAwayListener>
      <CronoStatus
        type="prospect"
        currentPickedStatus={statusSelected}
        currentActualStatus={null}
        currentExternalValues={null}
        nullAsTagPicker={true}
        howManyAffected={selected.length}
        handleSetStatus={handleChangeStatus}
        fixedWidth={true}
      />
      {user?.company?.integrationType === IntegrationType.CRONO &&
        (disablingMultipleProspect ? (
          <CircularProgress />
        ) : (
          <RedButton
            disabled={(selectedOwned?.length ?? 0) === 0}
            style={{ height: 28, paddingInline: 12, fontSize: 14 }}
            startIcon={<TrashCanDeleteIcon color={colors.white} />}
            onClick={handleDeleteMultipleProspects}
          >
            Delete{' '}
            {selectedOwned?.length ? '(' + selectedOwned?.length + ')' : ''}
          </RedButton>
        ))}
      {showApply &&
        (prospectsUpdating || isLoadingAddMultipleProspectToSequence ? (
          <CircularProgress style={{ marginLeft: 18 }} />
        ) : (
          <MainPrimaryButton className="apply-button" onClick={handleApply}>
            Apply
          </MainPrimaryButton>
        ))}
      {/* I've added the addSequence call for multiple prospects, here should appear the choose sequence popup */}
      {/* <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <CustomTooltip
            title={
              <MultipleSequenceAdd
                setOpen={setOpen}
                selected={selected}
                onSuccess={() => {
                  clearSelected();
                }}
              />
            }
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <MainButton onClick={handleTooltipOpen} style={{ height: 40 }}>
              Add {selected.length}
            </MainButton>
          </CustomTooltip>
        </div>
      </ClickAwayListener> */}
      {/* {(selectedToPause.length ?? 0) > 0 &&
        (loadingStopMultipleSequences ? (
          <CircularProgress />
        ) : (
          <MainButton
            style={{ height: 40 }}
            onClick={handleStopMultipleSequences}
          >
            Pause {selectedToPause.length}
          </MainButton>
        ))} */}
      {/* <CancelButton
        onClick={() => {
          if (clearSelected) {
            clearSelected();
          }
        }}
        style={{ height: 40 }}
      >
        Cancel
      </CancelButton> */}
    </TableFooterWrapper>
  ) : selectedExternal && selectedExternal.length > 0 ? (
    <TableFooterWrapper>
      <Button
        className="selected-button"
        variant="contained"
        color="secondary"
        endIcon={<CloseMIcon color={colors.grey11} />}
        disableElevation
        onClick={() => {
          if (clearSelected) {
            clearSelected();
          }
        }}
      >
        {selectedExternal.length} selected
      </Button>
      {isSyncing ? (
        <CircularProgress />
      ) : (
        <MainPrimaryButton
          className="apply-button"
          onClick={handleSyncAllProspects}
          startIcon={<PlusSmallIcon color={colors.white} />}
          style={{ marginRight: 0 }}
        >
          Add {selectedExternal.length} to Crono
        </MainPrimaryButton>
      )}
    </TableFooterWrapper>
  ) : (
    <></>
  );
};

export default ProspectSelectionBottom;

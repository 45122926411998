import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Checkbox,
  ClickAwayListener,
  TextField,
  Typography,
} from '@mui/material';
import { colors } from 'crono-fe-common/theme';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { FilterFormWrapper } from './style';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import { getImageFromUrl, stringAvatarAccount } from 'utils/fe-utils';

import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import NotificationType from 'crono-fe-common/types/enums/notificationType';
import useAccounts from 'hooks/services/account/useAccounts';
import Role from 'crono-fe-common/types/enums/role';
import { useAuth } from 'context/auth';
import { Account } from 'crono-fe-common/types/account';
import CronoSwitch from 'crono-fe-common/components/CronoSwitch';

export type PickedNotificationFilters = {
  company?: Account | null;
  type?: NotificationType[] | null;
  unread: boolean | null;
};

export const showFilterOptions: {
  text: string;
  filter: NotificationType;
}[] = [
  {
    text: 'Email opened',
    filter: NotificationType.EMAIL_OPENED,
  },
  {
    text: 'Email replied',
    filter: NotificationType.EMAIL_ANSWERED,
  },
  {
    text: 'Email bounced',
    filter: NotificationType.EMAIL_BOUNCED,
  },
  {
    text: 'Email clicked links',
    filter: NotificationType.EMAIL_LINK_CLICKED,
  },
  {
    text: 'LinkedIn Invitation accepted',
    filter: NotificationType.LINKEDIN_INVITATION_ACCEPTED,
  },
  {
    text: 'LinkedIn Message replied',
    filter: NotificationType.LINKEDIN_ANSWERED,
  },
  {
    text: 'InMail replied',
    filter: NotificationType.LINKEDIN_IN_MAIL_ANSWERED,
  },
];

interface IProps {
  close?: () => void;
  handleSubmit: (filters: PickedNotificationFilters) => void;
  pickedFilters: PickedNotificationFilters;
  changeAllFilterTypes: (value: NotificationType[]) => void;
}

const FilterNotifications = ({
  close,
  handleSubmit,
  pickedFilters,
  changeAllFilterTypes,
}: IProps) => {
  // Duplicated logic but needed for showing previous value and saving future value before apply
  const [filters, setFilters] =
    useState<PickedNotificationFilters>(pickedFilters);

  // ----------------
  const handleApply = (filters: PickedNotificationFilters) => {
    handleSubmit(filters);
    if (close) close();
  };

  const handleClearFilters = () => {
    handleRemoveAccount();
    changeAllFilterTypes(showFilterOptions.map((el) => el.filter));
    setFilters({
      company: null,
      type: null,
      unread: null,
    });
  };

  const hasSelectedFilters = useMemo(
    () => Object.values(filters).every((el) => el === null),
    [filters],
  );

  // CompanySelect -------------------------------
  const { highestRole } = useAuth();
  const [initialAccount, setInitialAccount] = useState<Account | null>(
    pickedFilters.company ?? null,
  );
  const [searchCompanyText, setSearchCompanyText] = useState<string>('');
  const { data: accounts } = useAccounts({
    name: searchCompanyText,
    limit: 25,
    status: null,
    IsManager: highestRole === Role.MANAGER,
  });

  const mappedAccount = useMemo(() => {
    if (!accounts || !accounts.data) return [];
    return accounts.data?.data.map((acc) => {
      return acc;
    });
  }, [accounts]);

  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  //Here I have the acconut to insert, with the accountId
  const selectedAccount = useMemo(() => {
    if (initialAccount) {
      return initialAccount;
    } else {
      const acc = mappedAccount.find((el) => el.objectId === selectedCompany);
      return acc;
    }
  }, [selectedCompany, mappedAccount, initialAccount]);

  const handleRemoveAccount = () => {
    setSelectedCompany(null);
    setSearchCompanyText('');
    setInitialAccount(null);
    setFilters((prev) => {
      return { ...prev, type: null };
    });
  };

  return (
    <FilterFormWrapper>
      <div className="form-add-container">
        {/* <div className="form-box">
          <Typography fontSize={14} fontWeight={600} lineHeight={'22px'}>
            Event type:
          </Typography>
          <CustomOptionsTooltip
            open={filterTooltipToggle}
            title={showFilter}
            maxHeight={'255px'}
            width={'100%'}
          >
            <div
              className="show-filter-container"
              style={{
                backgroundColor: filtersCondition ? colors.white : '',
              }}
              onClick={() => setFilterTooltipToggle(!filterTooltipToggle)}
            >
              <Typography
                fontSize={'14px'}
                style={{ flex: 1 }}
                noWrap
                fontWeight={filtersCondition ? 400 : 500}
              >
                {selectedShowFilters?.length === 0
                  ? 'Select an event'
                  : selectedShowFilters?.length === showFilterOptions.length
                    ? 'All events'
                    : selectedShowFilters?.length === 1
                      ? showFilterOptions.filter(
                          (option) => option.filter === selectedShowFilters[0],
                        )[0].text
                      : `${selectedShowFilters.length} selected`}
              </Typography>

              {filtersCondition ? (
                filterTooltipToggle ? (
                  <TopArrowIcon
                    className="tag-arrow"
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <BottomArrowIcon
                    className="tag-arrow"
                    style={{ cursor: 'pointer' }}
                  />
                )
              ) : (
                <CloseMIcon
                  className="remove-text-icon"
                  style={{ cursor: 'pointer' }}
                  color={colors.grey11}
                  onClick={(ev: any) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setSelectedShowFilters([]);
                  }}
                />
              )}
            </div>
          </CustomOptionsTooltip>
        </div> */}
        <div className="form-box">
          <Typography fontSize={'14px'} fontWeight={600} lineHeight={'22px'}>
            Company:
          </Typography>
          <div style={{ display: 'flex' }}>
            {selectedAccount ? (
              <div className="information-card-manual-task">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 8,
                    width: '100%',
                  }}
                >
                  <Avatar
                    src={
                      selectedAccount.website &&
                      getImageFromUrl(selectedAccount.website, null)
                    }
                    className="account-avatar"
                  >
                    {stringAvatarAccount(selectedAccount.name || '')}
                  </Avatar>
                  <div style={{ width: 'calc(100% - 40px)' }}>
                    <Typography
                      fontWeight={500}
                      fontSize={'14px'}
                      lineHeight={'18px'}
                      noWrap
                    >
                      {selectedAccount.name}
                    </Typography>
                  </div>
                </div>
                <RemoveIcon
                  className="manual-task-remove-icon"
                  color={colors.grey11}
                  onClick={handleRemoveAccount}
                />
              </div>
            ) : (
              <Autocomplete
                id="companySelect"
                fullWidth
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                onChange={(e, value) => {
                  if (value) setSelectedCompany(value.id);
                  else setSelectedCompany(null);
                }}
                options={mappedAccount.map((acc) => {
                  return { id: acc.objectId, name: acc.name };
                })}
                getOptionLabel={(option) => option.name || ''}
                PaperComponent={({ children }) => (
                  <div className="autocomplete-paper">{children}</div>
                )}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    key={option.id}
                    value={option.id || undefined}
                    className="autocomplete-option"
                  >
                    {option.name}
                  </li>
                )}
                disablePortal
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="standard"
                    fullWidth
                    placeholder="Type company name..."
                    value={searchCompanyText}
                    onChange={(e: any) => setSearchCompanyText(e.target.value)}
                  />
                )}
              />
            )}
          </div>
        </div>

        <div
          className="form-box"
          style={{
            rowGap: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: 16,
            }}
          >
            <Typography lineHeight={'16px'} fontWeight={500}>
              Show only unread feeds
            </Typography>
            <CronoSwitch
              checked={filters.unread ?? false}
              onChange={(ev) => {
                setFilters((prev) => {
                  return { ...prev, unread: ev.target.checked };
                });
              }}
            />
          </div>
          <Typography color={colors.grey11}>
            All read feeds will be hidden from this tab
          </Typography>
        </div>

        <FlexDiv
          width="fit-content"
          height="fit-content"
          style={{ alignSelf: 'flex-end' }}
        >
          {hasSelectedFilters ? (
            <CancelButton
              style={{ minWidth: '120px' }}
              onClick={() => {
                if (close) close();
              }}
            >
              Cancel
            </CancelButton>
          ) : (
            <div className="clear-filter" onClick={handleClearFilters}>
              <TrashCanDeleteIcon color={colors.inactive} />
              <Typography fontSize={'16px'} fontWeight={500}>
                Clear
              </Typography>
            </div>
          )}
          <MainButton
            style={{ minWidth: '120px' }}
            onClick={() =>
              handleApply({ ...filters, company: selectedAccount })
            }
          >
            Apply
          </MainButton>
        </FlexDiv>
      </div>
    </FilterFormWrapper>
  );
};

export default FilterNotifications;

import {
  FeConstants,
  StageColors,
  WonAndLostColors,
  stagesColors,
  stagesColorsMap,
} from 'constants/FeConstants';
import { Constants } from 'crono-fe-common/constants/constants';
import { IntegrationsOnboarding } from 'crono-fe-common/types/DTO/integrationsOnboarding';
import { Account, AccountStatus } from 'crono-fe-common/types/account';
import { LinkedinProfile } from 'crono-fe-common/types/crono-extension/linkedin';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import { EmailMessage } from 'crono-fe-common/types/emailMessage';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import Role from 'crono-fe-common/types/enums/role';
import { TaskTodoType } from 'crono-fe-common/types/enums/taskTodoType';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import { ExternalValue } from 'crono-fe-common/types/externalValue';
import { CallFeedback } from 'crono-fe-common/types/logCall';
import { Prospect } from 'crono-fe-common/types/prospect';
import { TemplateVariables } from 'crono-fe-common/types/template';
import { Token } from 'crono-fe-common/types/token';
import { User, getFullName } from 'crono-fe-common/types/user';
import {
  createVariableFormatFromExternalValue,
  getExternalPropertyLabels,
  htmlToText,
  parseExternalPropertyName,
} from 'crono-fe-common/utils';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';

import moment from 'moment';
import { IVariable } from 'pages/settings/Template/model';
import PATH from 'routing/path';
import { FileContent, FileError } from 'use-file-picker/dist/interfaces';
import { CronoErrorCode } from '../crono-fe-common/types/enums/cronoErrorCode';
import { Opportunity } from 'crono-fe-common/types/DTO/opportunityViewResponseDTO';
import { TimePeriodCustom } from 'crono-fe-common/types/timePeriod';

import SubscriptionPlanType from 'crono-fe-common/types/enums/subscriptionPlanType';
import { USER_TOKEN_KEY } from 'config/localStorage';
import { StatusComponentOptions } from 'crono-fe-common/types/StatusOptions';
import useGetExternalPropertyStatus from 'hooks/services/externalProperty/useGetExternalPropertyStatus';
import {
  DefaultCompanyStatus,
  DefaultContactStatus,
  DefaultDefaultCompanyStatus,
  DefaultDefaultContactStatus,
  StatusPropertyType,
} from 'crono-fe-common/types/model';
import { useMemo } from 'react';
import { colors } from '../crono-fe-common/theme';
import { SequenceStep } from 'crono-fe-common/types/sequenceStep';
import { TaskTodoSubtype } from 'crono-fe-common/types/enums/taskTodoSubtype';
import { ImageResources } from 'crono-fe-common/types/enums/imageResources';
import { Options, Plan } from 'pages/signup';
import CronoError from 'crono-fe-common/types/error';
import { ITemplateVariables } from 'crono-fe-common/types/templateVariables';

export function IsHomeRoute(route: string): boolean {
  return route === PATH.HOME;
}

function formatAddress(fullName: string, address: string) {
  return `${fullName} <${address}>`;
}

export const dispartPascalCase = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export function formatEmail(
  user: User,
  userEmail: string,
  toEmails: string[],
  ccEmails: string[],
  ccnEmails: string[],
  subject: string,
  message: string,
  filesContent: FileContent[],
  trackId: string,
) {
  const to = toEmails.join(',');
  const cc = ccEmails.join(',');
  const ccn = ccnEmails.join(',');
  const trackUrl = `${process.env.REACT_APP_BE_URL}/email/track?id=${trackId}&uid=${user.id}`;

  message +=
    '\n<img src="' + trackUrl + '" style="border:0;width:1px;height:1px;" />';

  const subjectBase64 = Buffer.from(subject).toString('base64');

  const content =
    'Content-Type: multipart/mixed; boundary="' +
    FeConstants.emailBoundary +
    '"\r\n' +
    'MIME-Version: 1.0\r\n' +
    'from: ' +
    formatAddress(getFullName(user), userEmail) +
    '\r\n' +
    'to: ' +
    to +
    '\r\n' +
    'cc: ' +
    cc +
    '\r\n' +
    'bcc: ' +
    ccn +
    '\r\n' +
    'subject: ' + // encode utf8
    '=?UTF-8?B?' +
    subjectBase64 +
    '?=' +
    '\n\n' +
    '--' +
    FeConstants.emailBoundary +
    '\r\n' +
    'Content-Type: text/html; charset="UTF-8"\r\n' +
    'MIME-Version: 1.0\r\n' +
    'Content-Transfer-Encoding: 7bit\r\n\r\n' +
    message +
    '\r\n\r\n' +
    addAttachments(filesContent) +
    '--' +
    FeConstants.emailBoundary +
    '--';

  return content;
}

function addAttachments(filesContent: FileContent[]) {
  let toRet = '';

  filesContent.forEach((file) => {
    const filename = file.name;
    const content = file.content;
    const parts = content.split(';');
    if (parts.length === 2) {
      const contentType = parts[0].replace('data:', '');
      const base64 = parts[1].replace('base64,', '');
      toRet +=
        '--' +
        FeConstants.emailBoundary +
        '\r\n' +
        'Content-Type: ' +
        contentType +
        '\r\n' +
        'MIME-Version: 1.0\r\n' +
        'Content-Transfer-Encoding: base64\r\n' +
        'Content-Disposition: attachment; filename=' +
        filename +
        '\r\n\r\n' +
        base64 +
        '\r\n\r\n';
    }
  });

  return toRet;
}

export function getDateFromScheduleTime(scheduleTime: string | null) {
  if (!scheduleTime) {
    scheduleTime = '09:00';
  }

  // time is in format HH:mm+TZ
  // split by + or - to get time and timezone
  const parts = scheduleTime.split(/[-+]/);

  const time = parts[0];

  const date = new Date();
  const timeParts = time.split(':');

  //console.log(timeParts);

  date.setHours(parseInt(timeParts[0]));
  date.setMinutes(parseInt(timeParts[1]));

  return moment(date).toDate();
}

export function isTokenValid(token: Token) {
  return moment.utc(token.validTo).isAfter(moment().utc());
}

export function isTokenRefreshable(token: Token) {
  return moment.utc(token.validTo).isAfter(moment().utc().add(5, 'minutes'));
}

window.Buffer = window.Buffer || require('buffer').Buffer;

export const fullname = (first?: string | null, last?: string | null) => {
  const firstname = first && first != null && first !== 'null' ? first : '';
  const lastname = last && last != null && last !== 'null' ? last : '';
  if (firstname === '' && lastname === '') return '';
  return `${firstname} ${lastname}`;
};

export const prospectName = (prospect: Prospect | null) => {
  if (!prospect) return '';
  let name = fullname(prospect.firstName, prospect.lastName);
  if (name === '') {
    if (prospect.name && prospect.name.trim() !== '') {
      name = prospect.name;
    } else {
      name = prospect.email ?? '';
    }
  }
  if (name.trim() === '') {
    name = 'No name';
  }
  return name;
};

export const prospectNameFromLinkedinProfile = (
  prospect: LinkedinProfile | null,
) => {
  if (!prospect) return '';
  const name = fullname(prospect.firstName, prospect.lastName);
  // if (name === "") {
  //   if (prospect.name && prospect.name.trim() !== "") {
  //     name = prospect.name;
  //   } else {
  //     name = prospect.email ?? "";
  //   }
  // }

  return name;
};

// export const extractLinkedinProfile = (linkedinProfile: string) => {
//   try {
//     const parts = linkedinProfile.split("/in/");
//     // valid profiles are the ones that can be split correctly (length == 2) and first part includes "www.linkedin.com"
//     if (parts.length === 2 && parts[0].includes("www.linkedin.com")) {
//       const profile = parts[parts.length - 1];
//       return decodeURIComponent(profile.replace("/", ""));
//     } else {
//       return "";
//     }
//   } catch (e) {
//     console.log("Error", e);
//     return "";
//   }
// };

// export const extractLinkedinCompany = (linkedinProfile: string) => {
//   try {
//     const parts = linkedinProfile.split(
//       new RegExp(LinkedinUrlUtils.getLinkedinCompanyRegex())
//     );
//     console.log("Parts", parts);
//     // valid companies are the ones that can be split correctly (length == 2) and first part includes "www.linkedin.com"
//     if (parts.length === 2 && parts[0].includes("www.linkedin.com")) {
//       const company = parts[parts.length - 1];
//       return decodeURIComponent(company.replace("/", ""));
//     } else {
//       return "";
//     }
//   } catch (e) {
//     console.log("Error", e);
//     return "";
//   }
// };

export const prospectTitle = (title?: string | null) => {
  const toRet = title && title != null && title !== 'null' ? title : null;
  if (toRet) {
    return `${toRet}`;
  }

  return '';
};

export function openLinkedinAccount(account?: Account | null) {
  if (account?.linkedin && transformToValidUrl(account?.linkedin) != null) {
    window.open(transformToValidUrl(account.linkedin)!, '_blank');
  } else {
    window.open(Constants.linkedinBaseUrl, '_blank');
  }
}

export function openLinkedinProspect(prospect?: Prospect | null) {
  if (prospect?.linkedin && transformToValidUrl(prospect?.linkedin) != null) {
    window.open(transformToValidUrl(prospect.linkedin)!, '_blank');
  } else {
    window.open(Constants.linkedinBaseUrl, '_blank');
  }
}

const createVariableFormat = (externalProperty: ExternalProperty) => {
  return `{{EP_${externalProperty.id}_${parseExternalPropertyName(
    externalProperty.publicName ?? externalProperty.externalName ?? '',
  )}}}`;
};

export const createExternalPropertyVariable = (
  externalProperty: ExternalProperty,
): IVariable => {
  return {
    variable: createVariableFormat(externalProperty),
    text: externalProperty.publicName ?? externalProperty.externalName ?? '',
  };
};

export const isPossibleForVariable = (externalProperty: ExternalProperty) => {
  return (
    externalProperty.valueType !== 'Boolean' &&
    externalProperty.valueType !== 'MultiSelect'
  );
};

export const getExternalPropertyForVariables = (
  externalProperties?: ExternalProperty[] | null,
) => {
  return (
    externalProperties
      ?.filter(
        //For the moment we do not allow user to select externalProperties of these types as variables
        (externalProperty) =>
          externalProperty.isTemplateVariable &&
          isPossibleForVariable(externalProperty),
      )
      .map((externalProperty) => {
        return createExternalPropertyVariable(externalProperty);
      }) ?? []
  );
};

export function replaceTemplateVariable(
  content: string,
  variable: TemplateVariables | string,
  toReplace: string | null,
): string {
  if (!toReplace) return content;
  while (content.indexOf(variable) !== -1) {
    content = content.replace(variable, toReplace);
  }
  return content;
}

export function replaceAllTemplateVariables(
  content: string,
  prospect: Prospect | null,
  account: Account | null,
  user: User | null,
  templateVariables: ITemplateVariables | null,
): string {
  if (templateVariables) {
    content = replaceTemplateVariable(
      content,
      templateVariables.productNameTemplateVariable,
      templateVariables.productName,
    );

    content = replaceTemplateVariable(
      content,
      templateVariables.productDescriptionTemplateVariable,
      templateVariables.productDescription,
    );

    templateVariables.productReferences.forEach((_, i) => {
      content = replaceTemplateVariable(
        content,
        templateVariables.productReferencesTemplateVariables[i],
        templateVariables.productReferences[i],
      );
    });

    templateVariables.productBenefits.forEach((_, i) => {
      content = replaceTemplateVariable(
        content,
        templateVariables.productBenefitsTemplateVariables[i],
        templateVariables.productBenefits[i],
      );
    });

    templateVariables.productIndustries.forEach((_, i) => {
      content = replaceTemplateVariable(
        content,
        templateVariables.productIndustriesTemplateVariables[i],
        templateVariables.productIndustries[i],
      );
    });

    templateVariables.templateSnippets.forEach((_, i) => {
      content = replaceTemplateVariable(
        content,
        templateVariables.templateSnippets[i].templateVariableName,
        templateVariables.templateSnippets[i].content,
      );
    });
  }

  content = replaceTemplateVariable(
    content,
    TemplateVariables.PROSPECT_NAME,
    prospect?.firstName ?? prospect?.name?.split(' ')[0] ?? null,
  );
  content = replaceTemplateVariable(
    content,
    TemplateVariables.PROSPECT_SURNAME,
    prospect?.lastName ??
      prospect?.name?.replace(prospect?.name?.split(' ')[1], '') ??
      null,
  );
  content = replaceTemplateVariable(
    content,
    TemplateVariables.PROSPECT_TITLE,
    prospect?.title ?? null,
  );
  content = replaceTemplateVariable(
    content,
    TemplateVariables.COMPANY_NAME,
    account?.name ?? null,
  );
  content = replaceTemplateVariable(
    content,
    TemplateVariables.USER_NAME,
    user?.firstName ?? null,
  );
  content = replaceTemplateVariable(
    content,
    TemplateVariables.USER_SURNAME,
    user?.lastName ?? null,
  );
  content = replaceTemplateVariable(
    content,
    TemplateVariables.USER_COMPANY_NAME,
    user?.company?.name ?? null,
  );

  const externalValueUsed = [
    ...(account?.externalValues ?? []),
    ...(prospect?.externalValues ?? []),
  ];
  //I use all the externalValues even if they are not isTemplateVariables true, since there may be some things left from previous templates and it doesn't cost much
  externalValueUsed.forEach((externalValue) => {
    if (!externalValue || !externalValue.value) return;
    content = replaceTemplateVariable(
      content,
      createVariableFormatFromExternalValue(externalValue),
      //If the externalProperty is a list, we need to get the label of the selected option
      externalValue.valueType === 'List'
        ? (externalValue.labels ?? externalValue.options ?? []).at(
            (externalValue.options ?? externalValue.labels ?? []).findIndex(
              (option) => option === externalValue.value,
            ),
          ) ?? null
        : externalValue.value,
    );
  });
  return content;
}

const TEMPLATE_VARIABLE_MISSING = '{{';

export function getMissingOtherTemplateVariables(content: string): string[] {
  const missingVariables: string[] = [];
  let index = -1;
  let occurrenc = -1;
  occurrenc = content.indexOf(TEMPLATE_VARIABLE_MISSING, index);
  while (occurrenc !== -1) {
    //Find the end of the variable
    const end = content.indexOf('}}', occurrenc);

    //If we found no end let's go on to the next one, since this is incomplete
    if (end === -1) {
      index = occurrenc + 2;
      occurrenc = content.indexOf(TEMPLATE_VARIABLE_MISSING, index);
      continue;
    }
    const possibleTemplateVariable = content.substring(occurrenc + 2, end + 2);

    //Check if the variable is an actual one or if may be the following situation {{EP_{{EP_1_name}}
    //If we find further beginning of variables, we skip this one
    if (
      possibleTemplateVariable.includes('{{') ||
      possibleTemplateVariable.includes(' ')
    ) {
      index = occurrenc + 2;
      occurrenc = content.indexOf(TEMPLATE_VARIABLE_MISSING, index);
      continue;
    }

    //Add the variable to the list of missing variables if not already present
    const variable = content.substring(occurrenc, end + 2);
    if (!missingVariables.includes(variable)) {
      missingVariables.push(variable);
    }
    index = end + 2;
    occurrenc = content.indexOf(TEMPLATE_VARIABLE_MISSING, index);
  }

  return missingVariables;
}

export const getMissingTemplateVariables = (
  message: string,
  subject?: string,
): string[] => {
  let missingVariables: string[] = [];

  Object.values(TemplateVariables).map((variable) => {
    if (message?.includes(variable) || subject?.includes(variable)) {
      missingVariables.push(variable);
    }
  });
  missingVariables = missingVariables.concat(
    getMissingOtherTemplateVariables(message),
  );
  return missingVariables;
};

export function checkIfValidUrl(url: string): boolean {
  const regex = FeConstants.urlRegex;
  return regex.test(url);
}

export function checkIfValidWebsite(url: string): boolean {
  const regex = FeConstants.websiteRegex;
  return regex.test(url);
}

export function checkIfValidPhone(phone: string): boolean {
  const regex = FeConstants.phoneRegex;
  return regex.test(phone);
}

export function checkIfValidLinkedinProfileUrl(url: string): boolean {
  const parsed = LinkedinUrlUtils.getIdFromLinkedinProfileUrl(url);
  if (!parsed) {
    return false;
  }

  const isLeadId = parsed.length >= 39 && parsed.startsWith('ACwAA');
  return !isLeadId;
}

export function checkIfValidLinkedinCompanyUrl(url: string): boolean {
  const parsed = LinkedinUrlUtils.getIdFromLinkedinCompanyUrl(url);
  return parsed[0] !== null;
}

export function checkIfValidEmail(email: string): boolean {
  const regex = FeConstants.emailRegex;
  return regex.test(email);
}

export function transformToValidUrl(url: string): string | null {
  if (checkIfValidUrl(url)) return url;

  const newUrl = `http://${url}`;
  if (checkIfValidUrl(newUrl)) return newUrl;

  return null;
}

export function cleanUrl(value: string): string {
  if (value.startsWith('https://')) {
    value = value.replace('https://', '');
  }
  if (value.startsWith('http://')) {
    value = value.replace('http://', '');
  }
  if (!value.startsWith('www.')) {
    value = `www.${value}`;
  }

  return value;
}

export function generateProspectCrmLink(
  account: Account | null,
  prospect: Prospect | null,
  user: User | null,
): string | null {
  let toRet: any = null;

  if (!user || !account || !prospect) return '';

  if (!account.isSynced && user.otherSettings?.hasLateSync)
    return 'Not in sync';

  if (user.salesforceSettings !== null) {
    const objectId = prospect.objectId;

    let type = 'Contact';

    if (!account.isSynced) type = 'Lead';

    toRet = `https://${user.salesforceSettings.domain}.lightning.force.com/lightning/r/${type}/${objectId}/view`;
  } else if (user.hubspotSettings !== null) {
    const objectId =
      prospect.objectId.split('_')[prospect.objectId.split('_').length - 1];
    toRet = `https://${user.hubspotSettings.domain}/contacts/${user.hubspotSettings.portalId}/contact/${objectId}`;
  } else if (user.pipedriveSettings !== null) {
    const objectId =
      prospect.objectId.split('_')[prospect.objectId.split('_').length - 1];
    toRet = `${user.pipedriveSettings.domain}/person/${objectId}`;
  }

  return toRet;
}

export function generateAccountCrmLink(
  account: Account | null,
  user: User | null,
): string | null {
  let toRet = 'Not in sync';

  if (!user) return '';

  if (
    !account ||
    account.objectId.toLowerCase().includes('account') ||
    account.objectId.toLowerCase().includes('_A_')
  )
    return toRet;

  if (!account.isSynced && user.otherSettings?.hasLateSync)
    return 'Not in sync';

  if (user.salesforceSettings !== null) {
    const objectId = account.objectId;
    toRet = `https://${user.salesforceSettings.domain}.lightning.force.com/lightning/r/Account/${objectId}/view`;
  } else if (user.hubspotSettings !== null) {
    const objectId =
      account.objectId.split('_')[account.objectId.split('_').length - 1];
    toRet = `https://${user.hubspotSettings.domain}/contacts/${user.hubspotSettings.portalId}/company/${objectId}`;
  } else if (user.pipedriveSettings !== null) {
    const objectId =
      account.objectId.split('_')[account.objectId.split('_').length - 1];
    toRet = `${user.pipedriveSettings.domain}/organization/${objectId}`;
  }

  return toRet;
}

export function generateOpportunityCrmLink(
  opportunity: Opportunity | null,
  user: User | null,
): string | null {
  let toRet: any = null;

  if (!user || !opportunity || opportunity.objectId == null) return '';

  if (user.salesforceSettings !== null) {
    const objectId = opportunity.objectId;
    toRet = `https://${user.salesforceSettings.domain}.lightning.force.com/lightning/r/Opportunity/${objectId}/view`;
  } else if (user.hubspotSettings !== null) {
    const objectId =
      opportunity.objectId.split('_')[
        opportunity.objectId.split('_').length - 1
      ];
    toRet = `https://${user.hubspotSettings.domain}/contacts/${user.hubspotSettings.portalId}/deal/${objectId}`;
  } else if (user.pipedriveSettings !== null) {
    const objectId =
      opportunity.objectId.split('_')[
        opportunity.objectId.split('_').length - 1
      ];
    toRet = `${user.pipedriveSettings.domain}/deal/${objectId}`;
  }

  return toRet;
}

export function statusIsGreatherOrEqualThan(
  status: AccountStatus,
  toCompare: AccountStatus,
): boolean {
  const statusIndex = Object.values(AccountStatus).indexOf(status);
  const toCompareIndex = Object.values(AccountStatus).indexOf(toCompare);
  return statusIndex >= toCompareIndex;
}

export function newDateWithInitialTime() {
  return dateWithInitialTime(new Date());
}

export function dateWithInitialTime(date: Date) {
  const newDate = new Date(date);
  newDate.setHours(3, 0, 0, 0);
  return newDate;
}

export function newDateWithEndTime() {
  return dateWithEndTime(new Date());
}

export function dateWithEndTime(date: Date) {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
}

export function formatTime(time: Date) {
  return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

export function formatDateTime(time: Date) {
  return time.toLocaleString([], {
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function formatDate(time: Date) {
  return time.toLocaleDateString();
}

export function getCallFeedbackTitle(callFeedback: CallFeedback) {
  let title = '';

  for (let i = 0; i < FeConstants.callFeedback.length; i++) {
    const feedback = FeConstants.callFeedback[i];
    if (feedback.type === callFeedback) {
      title = feedback.title;
      break;
    }
  }

  return title;
}

export function htmlToTextPreview(html: string) {
  //remove code brakes and tabs
  html = html.replace(/\n/g, '');
  html = html.replace(/\t/g, '');

  //keep html brakes and tabs
  html = html.replace(/<\/td>/g, '\t');
  html = html.replace(/<\/table>/g, '\n');
  html = html.replace(/<\/tr>/g, '\n');
  html = html.replace(/<\/p>/g, '\n');
  html = html.replace(/<\/div>/g, '\n');
  html = html.replace(/<\/h>/g, '\n');
  html = html.replace(/<br>/g, '\n');
  html = html.replace(/<br( )*\/>/g, '\n');

  //parse html into text
  const dom = new DOMParser().parseFromString(
    '<!doctype html><body>' + html,
    'text/html',
  );
  return dom.body.textContent;
}

export function extractDomainFromWebsite(url: string): string {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url;
  }
  try {
    // test url regex
    if (checkIfValidWebsite(url)) {
      const u = new URL(url);
      return u.host.replace('www.', '');
    } else {
      return '';
    }
  } catch (e) {
    //console.log("error extracting domain: " + url);
    return '';
  }
}

export function printCallFeedback(callFeedback: CallFeedback) {
  switch (callFeedback) {
    case CallFeedback.CONNECTED:
      return 'Connected';
    case CallFeedback.NOT_ANSWERED:
      return 'No Answer';
    case CallFeedback.GATE_KEEPER:
      return 'Gatekeeper';
    case CallFeedback.VOICEMAIL:
      return 'Voicemail';
    case CallFeedback.WRONG_NUMBER:
      return 'Wrong Number';
    default:
      return '';
  }
}

export function modalAccount(account: Account | null) {
  let toRet = '';
  if (account) {
    toRet = ` ${account.name}`;
  }
  return toRet;
}

export function modalProspect(prospect: Prospect | null) {
  let toRet = '';
  if (prospect) {
    toRet = `${prospectName(prospect)}`;
  }
  return toRet;
}

export function cleanPixel(emailMessage: EmailMessage) {
  const content = emailMessage.content;

  const backend = process.env.REACT_APP_BE_URL;
  const regexString = `src="(${backend}/email/track\\?id=[^"]+)(&amp;uid)?(&uid)?=\\d+"`;
  const regex = new RegExp(regexString, 'g');
  // const regexF =
  //   /<img\s+src="(https?:\/\/dev\.api\.crono\.one\/email\/track\?id=[^"]+)(&amp;uid)?(&uid)?=\d+"(?:\s+style="border:\s*0(px)?;\s*width:\s*1px;\s*height:\s*1px;")?\s*\/?>/;
  emailMessage.content = content.replace(regex, '');
  // console.log("Cl", emailMessage.content);
  return emailMessage;
}

export function cleanPixelFromHtml(content: string) {
  const backend = process.env.REACT_APP_BE_URL;

  const regex = new RegExp(
    '<img src="' +
      backend +
      '/email/track\\?id=[^"]*" style="border:0;width:1px;height:1px;" />',
    'g',
  );

  content = content.replace(regex, '');
  return content;
}

export function calculatePercentage(
  n1: number | undefined,
  n2: number | undefined,
): number {
  if (!n1 || !n2) {
    return 0;
  }
  if (n1 === 0 || n2 === 0) {
    return 0;
  }

  return Math.round((n1 / n2) * 10000) / 100;
}

export function printUserRole(role: Role) {
  switch (role) {
    case Role.ADMIN:
      return 'Admin';
    case Role.MANAGER:
      return 'Manager';
    case Role.BASIC:
      return 'User';
    default:
      return 'User';
  }
}

export function randomIntegerValue(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function formatTodayDate() {
  const today = new Date();
  return (
    today.getFullYear() +
    '-' +
    (today.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    today.getDate().toString().padStart(2, '0')
  );
}

export function formatDateForParameter(date: Date) {
  const newDate = new Date(date);
  newDate.setUTCHours(0, 0, 0, 0);
  return newDate.toISOString();
}

export function downloadCSVFile(
  filename: string,
  csv: string,
  contentType = 'text/csv',
) {
  const blob = new Blob([csv], { type: contentType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.click();
}

export function formatJsonToCSV(json: any[]) {
  const fields = Object.keys(json[0]);
  const replacer = (key: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
  const csv = json.map((row: any) =>
    fields
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(','),
  );
  csv.unshift(fields.join(',')); // add header column
  return csv.join('\r\n');
}

export function getTags(
  externalValues: ExternalValue[] | undefined | null,
  filterNull = false,
) {
  if (!externalValues) {
    return [];
  }
  if (!filterNull) return externalValues.filter((ev) => ev.isTag);
  return externalValues
    .filter((ev) => ev.isTag)
    .filter((value) => {
      return value.value != null;
    });
}

export function getNotTags(
  externalValues: ExternalValue[] | undefined | null,
  filterNull = false,
) {
  if (!externalValues) {
    return [];
  }
  if (!filterNull) return externalValues.filter((ev) => !ev.isTag);
  return externalValues
    .filter((ev) => !ev.isTag)
    .filter((value) => value.value);
}

export const stringAvatarProspect = (prospect: Prospect | null) => {
  if (!prospect) return '';

  const text = prospectName(prospect);

  return text
    .toUpperCase()
    .split(' ')
    .map((word) => word[0])
    .join('')
    .substring(0, 2);
};

export const stringAvatarAccount = (name: string) =>
  name.toUpperCase().substring(0, 2);

export const disabled = (account: Account) => {
  if (!account) return true;
  return !account.owned;
};

export interface IChannel {
  name: string;
  icon: JSX.Element;
  iconColored: JSX.Element;
  type: TaskTodoType;
}

export const getPercentages = (rankingChannels: IChannel[]) => {
  //If one is selected, 100 percent to it and 0 to the others,
  //if 2 are selected, 70 to the first that appears in rankingChannels and 30 to the other, if 3 are selected, 50 to the first, 30 to the second and 20 to the third
  let emailPercent = 0;
  let callPercent = 0;
  let linkedinPercent = 0;
  const singleChannelPercentage = 100;
  const doubleChannelPercentageFirst = 70;
  const doubleChannelPercentageSecond = 30;
  const tripleChannelPercentageFirst = 50;
  const tripleChannelPercentageSecond = 30;
  const tripleChannelPercentageThird = 20;
  if (rankingChannels.length === 1) {
    if (rankingChannels[0].type === TaskTodoType.EMAIL) {
      emailPercent = singleChannelPercentage;
    } else if (rankingChannels[0].type === TaskTodoType.CALL) {
      callPercent = singleChannelPercentage;
    } else if (rankingChannels[0].type === TaskTodoType.LINKEDIN) {
      linkedinPercent = singleChannelPercentage;
    }
  } else if (rankingChannels.length === 2) {
    if (rankingChannels[0].type === TaskTodoType.EMAIL) {
      emailPercent = doubleChannelPercentageFirst;
      if (rankingChannels[1].type === TaskTodoType.CALL) {
        callPercent = doubleChannelPercentageSecond;
      } else if (rankingChannels[1].type === TaskTodoType.LINKEDIN) {
        linkedinPercent = doubleChannelPercentageSecond;
      }
    } else if (rankingChannels[0].type === TaskTodoType.CALL) {
      callPercent = doubleChannelPercentageFirst;
      if (rankingChannels[1].type === TaskTodoType.EMAIL) {
        emailPercent = doubleChannelPercentageSecond;
      } else if (rankingChannels[1].type === TaskTodoType.LINKEDIN) {
        linkedinPercent = doubleChannelPercentageSecond;
      }
    } else if (rankingChannels[0].type === TaskTodoType.LINKEDIN) {
      linkedinPercent = doubleChannelPercentageFirst;
      if (rankingChannels[1].type === TaskTodoType.EMAIL) {
        emailPercent = doubleChannelPercentageSecond;
      } else if (rankingChannels[1].type === TaskTodoType.CALL) {
        callPercent = doubleChannelPercentageSecond;
      }
    }
  } else if (rankingChannels.length === 3) {
    if (rankingChannels[0].type === TaskTodoType.EMAIL) {
      emailPercent = tripleChannelPercentageFirst;
      if (rankingChannels[1].type === TaskTodoType.CALL) {
        callPercent = tripleChannelPercentageSecond;
        linkedinPercent = tripleChannelPercentageThird;
      } else if (rankingChannels[1].type === TaskTodoType.LINKEDIN) {
        callPercent = tripleChannelPercentageThird;
        linkedinPercent = tripleChannelPercentageSecond;
      }
    } else if (rankingChannels[0].type === TaskTodoType.CALL) {
      callPercent = tripleChannelPercentageFirst;
      if (rankingChannels[1].type === TaskTodoType.EMAIL) {
        emailPercent = tripleChannelPercentageSecond;
        linkedinPercent = tripleChannelPercentageThird;
      } else if (rankingChannels[1].type === TaskTodoType.LINKEDIN) {
        emailPercent = tripleChannelPercentageThird;
        linkedinPercent = tripleChannelPercentageSecond;
      }
    } else if (rankingChannels[0].type === TaskTodoType.LINKEDIN) {
      linkedinPercent = tripleChannelPercentageFirst;
      if (rankingChannels[1].type === TaskTodoType.EMAIL) {
        emailPercent = tripleChannelPercentageSecond;
        callPercent = tripleChannelPercentageThird;
      } else if (rankingChannels[1].type === TaskTodoType.CALL) {
        emailPercent = tripleChannelPercentageThird;
        callPercent = tripleChannelPercentageSecond;
      }
    }
  }
  return { emailPercent, callPercent, linkedinPercent };
};

export const getNextStepOrder = (tasks: TaskTodo[] | undefined | null) => {
  return tasks
    ? tasks
        .filter((task) => !task.completed)
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        .at(0)?.order ?? 0
    : 0;
};

export const getImageFromUrl = (url: string | null, size: number | null) => {
  if (!url) return FeConstants.defaultImageUrl;
  const domain = extractDomainFromWebsite(url);
  if (!domain) return FeConstants.defaultImageUrl;

  if (!size) size = 64;

  // we use url instead of domain because it retrieves icon in more cases
  return `https://www.google.com/s2/favicons?sz=${size}&domain_url=${url}`;
};

export const rotateArray = (arr: any[], count: number) => {
  const len = arr.length;
  const newArr = [...arr];
  newArr.push(...newArr.splice(0, ((count % len) + len) % len));
  return newArr;
};

export const isDev = () => {
  return (
    process.env.REACT_APP_ENVIRONMENT === 'dev' ||
    process.env.REACT_APP_ENVIRONMENT === 'local'
  );
};

export const isLocal = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'local';
};

// start values is linked to page fetched in searchLeads / searchCompanies
export const calculateResultCount = ({
  count,
  start,
  total,
}: {
  count: number;
  start: number;
  total: number;
}) => {
  // case for when total % start !== 0
  const diff = start + 10 > total ? total : start + 10;
  return `${start + 1} - ${diff} / ${total}`;
};
export function countVariable(
  content: string,
  variable: TemplateVariables,
): [number, number] {
  let count = -1;
  let index = -1;
  let occurrenc = -1;
  do {
    index = occurrenc + 1;
    occurrenc = content.indexOf(variable, index);
    count++;
  } while (occurrenc !== -1);

  return [count, count * variable.length];
}

export function countOtherTemplateVariables(content: string): [number, number] {
  let count = 0;
  let index = -1;
  let totLen = 0;
  let occurrenc = -1;
  occurrenc = content.indexOf(TEMPLATE_VARIABLE_MISSING, index);
  while (occurrenc !== -1) {
    //Find the end of the variable
    const end = content.indexOf('}}', occurrenc);
    //If we found no end let's go on to the next one, since this is incomplete
    if (end === -1) {
      index = occurrenc + 2;
      occurrenc = content.indexOf(TEMPLATE_VARIABLE_MISSING, index);
      continue;
    }
    const possibleTemplateVariable = content.substring(occurrenc + 2, end + 2);

    //Check if the variable is an actual one or if may be the following situation {{EP_{{EP_1_name}}
    //If we find further beginning of variables, we skip this one
    if (
      possibleTemplateVariable.includes('{{') ||
      possibleTemplateVariable.includes(' ')
    ) {
      index = occurrenc + 2;
      occurrenc = content.indexOf(TEMPLATE_VARIABLE_MISSING, index);
      continue;
    }
    //Add the length of the variable to the total length
    totLen += end - occurrenc + 2;
    index = end + 2;
    count++;
    occurrenc = content.indexOf(TEMPLATE_VARIABLE_MISSING, index);
  }

  return [count, totLen];
}

export function countAllVariables(content: string): [number, number] {
  let count = 0;
  let varLength = 0;
  let totCount = 0;
  let totVar = 0;
  Object.values(TemplateVariables).forEach((variable) => {
    [count, varLength] = countVariable(content, variable);
    totCount += count;
    totVar += varLength;
  });
  [count, varLength] = countOtherTemplateVariables(content);
  totCount += count;
  totVar += varLength;

  return [totCount, totVar];
}

export const FIXED_VARIABLE_LENGTH = 20;

//To calculate the length of a template, considering a value of 20 for the variables
export const calculateTemplateLength = (
  content: string,
  creation: boolean,
): number => {
  if (creation) {
    content = content?.replaceAll('<p>&nbsp;</p>', ';');
  } else {
    //To count the new lines as 1 char as linkedin does
    content = content?.replaceAll('\n', ';');
  }
  const text = htmlToText(content || '');
  const [nOfVariables, lengthOfVariables] = countAllVariables(text ?? '');
  return (
    (text?.length || 1) -
    1 -
    lengthOfVariables +
    FIXED_VARIABLE_LENGTH * nOfVariables
  );
};

type StepSituation = 'NotRelevant' | 'ToDo' | 'Completed';

export interface IOnboardingSteps {
  SalesforceAdmin: StepSituation;
  CrmConnected: StepSituation;
  EmailConnected: StepSituation;
  LinkedinConnected: StepSituation;
}

//Calculate the situation regarding the onboarding
export const isOnboardingToDo = (
  user: User,
  onboardingIntegrations: IntegrationsOnboarding | null,
): IOnboardingSteps | null => {
  if (onboardingIntegrations) {
    const stepSituation: IOnboardingSteps = {
      SalesforceAdmin: 'NotRelevant',
      CrmConnected: 'NotRelevant',
      EmailConnected: 'NotRelevant',
      LinkedinConnected: 'NotRelevant',
    };
    //ToDo: check if the user has to do the onboarding
    let integrationToDo = false;
    //SalesforceAdmin
    if (
      user.company?.integrationType === IntegrationType.SALESFORCE &&
      user.userRoles.includes(Role.MANAGER)
    ) {
      stepSituation.SalesforceAdmin = onboardingIntegrations.adminCrmConnected
        ? 'Completed'
        : 'ToDo';
      integrationToDo ||= stepSituation.SalesforceAdmin === 'ToDo';
    }
    //Crm
    if (user.company?.integrationType !== IntegrationType.CRONO) {
      stepSituation.CrmConnected = onboardingIntegrations.crmConnected
        ? 'Completed'
        : 'ToDo';
      integrationToDo ||= stepSituation.CrmConnected === 'ToDo';
    }
    //Email
    if (user.otherSettings?.hasEmail === true) {
      stepSituation.EmailConnected =
        onboardingIntegrations.emailConnected ||
        onboardingIntegrations.emailConnectedOnboarding
          ? 'Completed'
          : 'ToDo';
      integrationToDo ||= stepSituation.EmailConnected === 'ToDo';
    }
    //Linkedin
    if (user.otherSettings?.hasLinkedin === true) {
      stepSituation.LinkedinConnected =
        onboardingIntegrations.linkedinConnected ||
        onboardingIntegrations.linkedinConnectedOnboarding
          ? 'Completed'
          : 'ToDo';
      integrationToDo ||= stepSituation.LinkedinConnected === 'ToDo';
    }
    //If the relevant integrations have been done, return null
    if (integrationToDo) return stepSituation;
    else {
      return null;
    }
  } else {
    return null;
  }
};

function deepQuerySelectorAll(
  selector: any,
  root: any,
): HTMLParagraphElement[] {
  root = root || document;
  const results: HTMLParagraphElement[] = Array.from(
    root.querySelectorAll(selector),
  );
  const pushNestedResults = function (root: any) {
    deepQuerySelectorAll(selector, root).forEach((elem) => {
      if (!results.includes(elem)) {
        results.push(elem);
      }
    });
  };
  if (root.shadowRoot) {
    pushNestedResults(root.shadowRoot);
  }
  for (const elem of root.querySelectorAll('*')) {
    if (elem.shadowRoot) {
      pushNestedResults(elem.shadowRoot);
    }
  }
  return results;
}

export const formatHtmlContent = (content: string): string => {
  // Create a temporary div element to parse the HTML string
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = content ?? '';

  // Find all <p> elements, also nested ones
  const pElements = deepQuerySelectorAll('p', tempDiv);
  // Loop through the <p> elements
  for (let i = 0; i < pElements.length; i++) {
    const pElement = pElements[i];

    // Get the current style attribute value
    const currentStyle = pElement.getAttribute('style');

    // Add or update the style attribute
    let newStyle = currentStyle;
    if (!newStyle?.includes('margin')) {
      newStyle = currentStyle ? currentStyle + ' margin: 0px;' : 'margin: 0px;';
    }
    pElement.setAttribute('style', newStyle);
  }

  const lists = tempDiv.querySelectorAll('ul, ol');

  lists.forEach((item: any) => {
    item.style.margin = '0';
    item.style.paddingLeft = '1.5em';
  });

  // Convert the modified HTML back to a string
  const modifiedHtmlString = tempDiv.innerHTML;
  return modifiedHtmlString;
};

export const getColorsStages = (
  stageOrder: number,
  totalNumberOfStages: number,
) => {
  const numberOfColors = Object.keys(stagesColors).length;
  let totalColors: StageColors[] = [];

  //If the total number of stages is less than the number of colors we have, we take the colors from the map
  if (totalNumberOfStages <= numberOfColors) {
    totalColors = stagesColorsMap[totalNumberOfStages];
  } else {
    //In case we have more than the max number of colors we always take 12, erasing the last 2 colors and all the excessive ones at the end
    let currentTotal = totalNumberOfStages;
    const numbersOfColorsExceptGreenAndRed = numberOfColors - 2;
    while (currentTotal > 0) {
      totalColors = totalColors.concat(stagesColorsMap[numberOfColors]);
      //Remove the last 2 colors if the total is bigger than the number of colors to remove Green and Red
      totalColors.splice(totalColors.length - 2, 2);
      //We deleted 2 colors, so we need to add 2 to the current total
      currentTotal += 2;
      currentTotal -= numbersOfColorsExceptGreenAndRed;
    }
    totalColors.concat(WonAndLostColors);
  }

  return totalColors[stageOrder - 1] ?? stagesColorsMap[numberOfColors][0];
};

export const capitalizeFirstLetter = (string?: string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : '';

export function getTextOfAutomationError(error: CronoErrorCode | undefined) {
  const defaultError =
    'An unexpected error occurred during the automatic submission';
  if (!error) return defaultError;

  switch (error) {
    case CronoErrorCode.AutomationFailedEmailSend:
      return 'Email automation failed';
    case CronoErrorCode.AutomationFailedAddressMissing:
      return 'Email address is missing';
    case CronoErrorCode.AutomationFailedTemplateMissing:
      return 'Template is missing';
    case CronoErrorCode.AutomationFailedEmailCreation:
      return 'Email automation failed';
    case CronoErrorCode.AutomationFailedEmailSettingsMissing:
      return 'Email not properly connected';
    case CronoErrorCode.IntegrationNotValidError:
      return 'Email failed: email provider is not connected';
    case CronoErrorCode.AutomationFailedTemplateApplicationError:
      return 'Variable values are missing';
    case CronoErrorCode.AutomationFailedUnsupportedTaskTypeError:
      return 'Task could not be automated';
    case CronoErrorCode.AutomationFailedLinkedinInvitationLimitReached:
      return 'LinkedIn invitation limit reached';
    case CronoErrorCode.AutomationFailedLinkedinSendMessage:
      return 'LinkedIn message failed';
    case CronoErrorCode.AutomationFailedLinkedinStatusUnknown:
      return 'Error detecting your relationship status';
    case CronoErrorCode.AutomationFailedLinkedinInvitationPending:
      return 'Invitation is still pending';
    case CronoErrorCode.AutomationFailedLinkedinInvitationMaxCharacters:
      return 'Invitation note is too long';
    case CronoErrorCode.AutomationFailedLinkedinUrlMissing:
      return 'LinkedIn URL is missing';
    case CronoErrorCode.AutomationFailedEmailNotValid:
      return 'Email address is invalid';
    case CronoErrorCode.AutomationFailedLinkedinInvitationRecentlyWithdrawn:
      return 'This invitation failed as it was recently withdrawn';
    case CronoErrorCode.AutomationFailedSalesNavNotConnected:
      return 'Only Sales Navigator users can send inmail messages';
    case CronoErrorCode.AutomationFailedSalesNavNotLogged:
      return 'Be sure to be logged into Sales Navigator';
    case CronoErrorCode.AutomationFailedMessageToNotConnected:
      return 'LinkedIn message failed: recipient is not connected';
    default:
      return defaultError;
  }
}

export const convertSecondstoTime = (inputSeconds: number) => {
  const dateObj = new Date(inputSeconds * 1000);
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();

  const timeString =
    (hours.toString().padStart(2, '0') === '00'
      ? ''
      : hours.toString().padStart(2, '0') + ':') +
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0');

  return timeString;
};

export const getCurrentMonth = () => {
  // setSince to last month
  // set date to the first of this month
  const lastMonth = newDateWithInitialTime();
  lastMonth.setDate(1);

  // set date to the last of this month
  const lastDay = newDateWithEndTime();
  lastDay.setDate(1);
  lastDay.setMonth(lastDay.getMonth() + 1);
  lastDay.setDate(lastDay.getDate() - 1);

  return {
    start: lastMonth,
    end: lastDay,
  };
};

export const getFirstDayOfNextMonth = () => {
  const currentDate = new Date();
  // Calculate the first day of the next month
  const nextMonth = new Date(currentDate);
  nextMonth.setMonth(currentDate.getMonth() + 1, 1);
  return nextMonth;
};

export const getCurrentWeek = () => {
  // find last monday and this friday
  const lastMonday = newDateWithInitialTime();
  const thisFriday = newDateWithInitialTime();
  lastMonday.setDate(lastMonday.getDate() - lastMonday.getDay() + 1);
  thisFriday.setDate(thisFriday.getDate() - thisFriday.getDay() + 5);

  return { start: lastMonday, end: thisFriday };
};

export const getCurrentQuarter = () => {
  // setSince to last month
  // set date to the start of this quarter
  const lastQuarter = newDateWithInitialTime();
  lastQuarter.setMonth(Math.floor(lastQuarter.getMonth() / 3) * 3);
  lastQuarter.setDate(1);

  return { start: lastQuarter, end: new Date() };
};

export const getRangeFromTimePeriod = (period: TimePeriodCustom) => {
  let range: { start: Date | null; end: Date | null } = {
    start: null,
    end: null,
  };

  switch (period) {
    case 'custom':
      return range;
    case 'today':
      range = { start: newDateWithInitialTime(), end: newDateWithEndTime() };
      break;
    case 'this-week':
      range = getCurrentWeek();
      break;
    case 'this-month':
      range = getCurrentMonth();
      break;
    case 'this-quarter':
      range = getCurrentQuarter();
      break;
  }

  return range;
};

export const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const sortPlans = (plans: Options[]): Options[] => {
  // First the plan with subscriptionType "Free Trial", "Started", then "Pro" and last "Ultra"
  // If free trial should not be shown, filter happens on the memoized options list (e.g: Expired plans)
  const result: Options[] = [];
  const freeTrial = plans.find(
    (plan) => plan.subscriptionType === SubscriptionPlanType.FREE_TRIAL,
  );
  if (freeTrial) result.push(freeTrial);
  const starter = plans.find(
    (plan) => plan.subscriptionType === SubscriptionPlanType.STARTER,
  );
  if (starter) result.push(starter);
  const pro = plans.find(
    (plan) => plan.subscriptionType === SubscriptionPlanType.PRO,
  );
  if (pro) result.push(pro);
  const ultra = plans.find(
    (plan) => plan.subscriptionType === SubscriptionPlanType.ULTRA,
  );
  if (ultra) result.push(ultra);
  return result;
};

export const getToken = () => {
  return localStorage.getItem(USER_TOKEN_KEY);
};

type Only<T, U> = {
  [P in keyof T]: T[P];
} & {
  [P in keyof U]?: never;
};

export type Either<T, U> = Only<T, U> | Only<U, T>;

export type Either3<T, U, V> =
  | Only<T, U>
  | Only<U, T>
  | Only<T, V>
  | Only<V, T>
  | Only<U, V>
  | Only<V, U>;

export const useGetStatusesOptions = (
  type: StatusComponentOptions,
  enabled = true,
) => {
  const { data: externalPropertyStatus } = useGetExternalPropertyStatus(
    type === 'account' ? 'Account' : 'Prospect',
    enabled,
  );

  const externalPropertyStatusId = externalPropertyStatus?.data?.data?.id;

  const isExternalPropertyStatus = !!externalPropertyStatus?.data?.data;
  // const isExternalPropertySynced = !!externalPropertyStatus?.data?.data?.isSync;
  const statuses: StatusPropertyType[] = useMemo(() => {
    return !isExternalPropertyStatus
      ? type === 'account'
        ? DefaultCompanyStatus
        : DefaultContactStatus
      : externalPropertyStatus.data?.data?.options
        ? externalPropertyStatus.data.data.options.map((option, index) => {
            return {
              id: index,
              option: option,
              label:
                getExternalPropertyLabels(
                  externalPropertyStatus.data!.data!,
                ).at(index) ?? option,
            };
          })
        : [];
  }, [isExternalPropertyStatus, type, externalPropertyStatus]);

  const externalPropertyDefaultStatus =
    externalPropertyStatus?.data?.data?.defaultValue ??
    (type === 'account'
      ? DefaultDefaultCompanyStatus.option
      : DefaultDefaultContactStatus.option);

  return {
    statuses,
    externalPropertyStatusId,
    isExternalPropertyStatus,
    externalPropertyDefaultStatus,
  };
};

export const underlineInputStyles = (isError: boolean | undefined) => ({
  '& .MuiInput-underline:before': {
    borderBottomColor: isError ? colors.inactive : colors.grey444,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: isError ? colors.inactive : colors.primary,
  },
});

export const IndexFirstLinkedin = (
  steps: SequenceStep[] | TaskTodo[] | null | undefined,
) => {
  if (!steps) return null;
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].type === TaskTodoType.LINKEDIN) return i;
  }
  return null;
};

export const IndexFirstLinkedinAfterInvitation = (
  steps: SequenceStep[] | TaskTodo[] | null | undefined,
) => {
  if (!steps) return -1;
  let firstInvitationFound = false;

  for (let i = 0; i < steps.length; i++) {
    if (steps[i].type === TaskTodoType.LINKEDIN && firstInvitationFound) {
      return i;
    }
    if (
      steps[i].type === TaskTodoType.LINKEDIN &&
      steps[i].subtype === TaskTodoSubtype.LinkedinInvitation
    ) {
      firstInvitationFound = true;
    }
  }
  return -1;
};

export const setToMidDayOfPassedDate = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(12, 0, 0, 0);
  return newDate;
};

export const parseFileError = (error: FileError) => {
  let message = 'An error occurred while uploading the file';
  if (error.fileSizeToolarge) {
    message = 'The file is too large' + (error.name ? ': ' + error.name : '');
  } else if (error.imageWidthTooBig || error.imageHeightTooBig) {
    message = 'The image is too big' + (error.name ? ': ' + error.name : '');
  } else if (error.maxLimitExceeded) {
    message = `You can upload up to ${FeConstants.maxFilesPerTransaction} files`;
  }
  return message;
};

export const filterNullProperties = <T extends object>(obj: T): Partial<T> => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key as keyof T] !== null) {
      acc[key as keyof T] = obj[key as keyof T];
    }
    return acc;
  }, {} as Partial<T>);
};

export const getExternalImage = (resource: ImageResources) => {
  return `${process.env.REACT_APP_S3_URL}/${resource}`;
};

export const generateRandomString = (length = 10) => {
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const getDateDiff = (date: Date) => {
  let diff = moment(moment()).diff(date, 'months');
  let time = 'm';

  if (diff === 0) {
    diff = moment(moment()).diff(date, 'weeks');
    time = 'w';
    if (diff === 0) {
      diff = moment(moment()).diff(date, 'days');
      time = 'd';
      if (diff === 0) {
        diff = moment(moment()).diff(date, 'hours');
        time = 'h';
      }
    }
  }

  return diff === 0 ? 'Just now' : `${diff}${time} ago`;
};

export const removeBackgroundColors = (content: string) => {
  // Create a temporary div to hold the pasted content
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = content;

  // Function to remove background color styles from elements
  function removeBackgroundColorsNested(element: any) {
    if (element.nodeType === Node.ELEMENT_NODE) {
      element.style.backgroundColor = '';
      for (const child of element.children) {
        removeBackgroundColorsNested(child);
      }
    }
  }
  removeBackgroundColorsNested(tempDiv);
  return tempDiv.innerHTML;
};

//Function to remove particular tags from an HTML string
export const removeTags = (
  htmlString: string,
  tagsToRemove: string[],
): string => {
  // Parse the HTML string to a DOM document
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Iterate over each tag to remove and remove them from the document
  tagsToRemove.forEach((tag) => {
    const elements = doc.querySelectorAll(tag);
    elements.forEach((element) => {
      // Move all child nodes of the element to its parent node before removing the element
      const parent = element.parentNode;
      if (!parent) return;
      while (element.firstChild) {
        parent.insertBefore(element.firstChild, element);
      }
      parent.removeChild(element);
    });
  });

  // Serialize the modified DOM back to an HTML string
  return doc.body.innerHTML;
};

export const parseTaskTypeToString = (
  type: TaskTodoType,
  subtype: TaskTodoSubtype | null,
) => {
  switch (type) {
    case TaskTodoType.CALL:
      return 'Call';
    case TaskTodoType.EMAIL:
      return 'Email';
    case TaskTodoType.LINKEDIN:
      if (subtype === TaskTodoSubtype.LinkedinInvitation) {
        return 'Linkedin Invitation';
      } else if (subtype === TaskTodoSubtype.LinkedinVoiceNote) {
        return 'Linkedin Voice Note';
      } else {
        return 'Linkedin Message';
      }
    case TaskTodoType.IN_MAIL:
      return 'Linkedin InMail';
  }
};

export const checkObjectIncludeValue = (object: any, value: any) => {
  return Object.values(object).includes(value);
};

export function mapSubscriptionToPlanType(subscription: string | undefined) {
  switch (subscription) {
    case SubscriptionPlanType.FREE_TRIAL:
      return Plan.FREE_TRIAL;
    case SubscriptionPlanType.STARTER:
      return Plan.STARTER;
    case SubscriptionPlanType.PRO:
      return Plan.PRO;
    case SubscriptionPlanType.ULTRA:
      return Plan.ULTRA;
    default:
      return null;
  }
}

export function mapPlanToSubscriptionType(plan: Plan | undefined) {
  switch (plan) {
    case Plan.FREE_TRIAL:
      return SubscriptionPlanType.FREE_TRIAL;
    case Plan.STARTER:
      return SubscriptionPlanType.STARTER;
    case Plan.PRO:
      return SubscriptionPlanType.PRO;
    case Plan.ULTRA:
      return SubscriptionPlanType.ULTRA;
    default:
      return null;
  }
}

export function isCronoError(error: any): error is CronoError {
  return (
    error &&
    error.message &&
    error.response &&
    error.response.data &&
    error.response.data.errors
  );
}

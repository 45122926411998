import { Tabs, Tab, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { SettingsWrapper } from './styles';
import General from './General/General';
import PasswordSettings from './Password/Password';
import { useAuth } from 'context/auth';
import Integrations from './Integrations/Integrations';
import SignatureSettings from './Signature/Signature';
import ImportData from './ImportData/ImportData';
import { useLocation } from 'react-router-dom';
import Role from 'crono-fe-common/types/enums/role';
import Subscription from './Subscription/Subscription';
import AddOns from './AddOns/AddOns';
import SuggestionsPreferences from './SuggestionsPreferences/SuggestionsPreferences';
import TagsSettings from './Tags';
import { colors } from 'crono-fe-common/theme';
import GeneralIcon from 'crono-fe-common/icons/Icon-General-Settings';
import PasswordIcon from 'crono-fe-common/icons/Icon-Password-Settings';
import IconIntegration from 'crono-fe-common/icons/Icon-Integration';
import SignatureIcon from 'crono-fe-common/icons/Icon-Signature-Settings';
import AddOnsIcon from 'crono-fe-common/icons/Icon-Add-Ons-Settings';
import TagIcon from 'crono-fe-common/icons/Icon-Tag-Settings';
import SubscriptionIcon from 'crono-fe-common/icons/Icon-Subscription-Settings';
import SuggestionsIcon from 'crono-fe-common/icons/Icon-Suggestion';
import IconOpportunity from 'crono-fe-common/icons/Icon-Opportunity';
import DealsManagement from './Deals';
import StatusSettingsIcon from 'crono-fe-common/icons/Icon-Status-Settings';
import StatusSettings from './Status';
import ImportDataIcon from 'crono-fe-common/icons/Icon-Import-Data-Settings';
import VariablesPage from './VariablesPage';
import IconVariables from 'crono-fe-common/icons/Icon-Variables';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      {...other}
      style={{
        width: value === index ? '100%' : null,
        height: value === index ? '100%' : null,
      }}
    >
      {value === index && (
        <Box p={3} height={'100%'}>
          {children}
        </Box>
      )}
    </div>
  );
}

export enum SettingsTabs {
  General = 'General',
  Password = 'Password',
  Integrations = 'Integrations',
  Signature = 'Signature',
  ImportData = 'ImportData',
  Subscriptions = 'Subscriptions',
  AddOns = 'AddOns',
  SuggestionPreferences = 'SuggestionPreferences',
  TagManagement = 'TagManagement',
  StatusManagement = 'StatusManagement',
  PipelinesManagement = 'PipelinesManagement',
  TemplateVariables = 'TemplateVariables',
}

interface IProps {
  initialTab?: SettingsTabs;
}

const Settings = ({ initialTab }: IProps) => {
  const [value, setValue] = useState<SettingsTabs>(
    initialTab ?? SettingsTabs.General,
  );
  const {
    user,
    useAuthGuard,
    highestRole,
    isSubscriptionManager,
    hasOpportunities,
  } = useAuth();
  useAuthGuard();

  const { state } = useLocation();

  useEffect(() => {
    // get state from router
    if (state && (state as any).tab) {
      const tab = (state as any).tab;
      // check if tab is inside enum
      if (Object.values(SettingsTabs).includes(tab)) {
        setValue(tab);
      }
    }
  }, [state]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  // const navigate = useNavigate();

  if (!user) return <></>;

  return (
    <SettingsWrapper>
      <div className="settings-card">
        <div className="tabs-container">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            sx={{
              width: '100%',
              '.MuiTabs-flexContainer': {
                flexDirection: 'column',
                overflowX: 'auto',
              },
              '.MuiTabs-indicator': {
                display: 'none',
              },
              '.Mui-selected': {
                backgroundColor: colors.primaryLight,
              },
              '.MuiTabs-scroller': {
                overflow: 'scroll !important',
              },
            }}
          >
            <Tab
              value={SettingsTabs.General}
              icon={
                <GeneralIcon
                  color={
                    value === SettingsTabs.General
                      ? colors.primary
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
              label="General"
              className="settings-tab"
            />
            <Tab
              value={SettingsTabs.Password}
              label="Password"
              className="settings-tab"
              icon={
                <PasswordIcon
                  color={
                    value === SettingsTabs.Password
                      ? colors.primary
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
            />
            <Tab
              value={SettingsTabs.Integrations}
              label="Integrations"
              className="settings-tab"
              icon={
                <IconIntegration
                  color={
                    value === SettingsTabs.Integrations
                      ? colors.primary
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
            />
            {user.otherSettings?.hasEmail && (
              <Tab
                value={SettingsTabs.Signature}
                label="Signature"
                className="settings-tab"
                icon={
                  <SignatureIcon
                    color={
                      value === SettingsTabs.Signature
                        ? colors.primary
                        : colors.grey11
                    }
                  />
                }
                iconPosition="start"
              />
            )}
            <Tab
              value={SettingsTabs.ImportData}
              label="Import Data"
              className="settings-tab"
              icon={
                <ImportDataIcon
                  color={
                    value === SettingsTabs.ImportData
                      ? colors.primary
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
            />
            {isSubscriptionManager && (
              <Tab
                value={SettingsTabs.Subscriptions}
                label="Subscription"
                className="settings-tab"
                icon={
                  <SubscriptionIcon
                    color={
                      value === SettingsTabs.Subscriptions
                        ? colors.primary
                        : colors.grey11
                    }
                  />
                }
                iconPosition="start"
              />
            )}
            <Tab
              value={SettingsTabs.AddOns}
              label="Add-ons"
              className="settings-tab"
              icon={
                <AddOnsIcon
                  color={
                    value === SettingsTabs.AddOns
                      ? colors.primary
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
            />
            <Tab
              value={SettingsTabs.SuggestionPreferences}
              label="Suggestions"
              className="settings-tab"
              icon={
                <SuggestionsIcon
                  color={
                    value === SettingsTabs.SuggestionPreferences
                      ? colors.primary
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
            />
            <Tab
              value={SettingsTabs.TemplateVariables}
              icon={
                <IconVariables
                  color={
                    value === SettingsTabs.TemplateVariables
                      ? colors.primary
                      : colors.grey11
                  }
                />
              }
              iconPosition="start"
              label="Template variables"
              className="settings-tab"
            />
            {highestRole === Role.MANAGER && (
              <Tab
                value={SettingsTabs.TagManagement}
                label="Tag management"
                className="settings-tab"
                icon={
                  <TagIcon
                    color={
                      value === SettingsTabs.TagManagement
                        ? colors.primary
                        : colors.grey11
                    }
                  />
                }
                iconPosition="start"
              />
            )}
            {highestRole === Role.MANAGER && (
              <Tab
                value={SettingsTabs.StatusManagement}
                label="Status management"
                className="settings-tab"
                icon={
                  <StatusSettingsIcon
                    color={
                      value === SettingsTabs.StatusManagement
                        ? colors.primary
                        : colors.grey11
                    }
                  />
                }
                iconPosition="start"
              />
            )}
            {highestRole === Role.MANAGER && hasOpportunities && (
              <Tab
                value={SettingsTabs.PipelinesManagement}
                label="Pipelines management"
                className="settings-tab"
                icon={
                  <IconOpportunity
                    color={
                      value === SettingsTabs.PipelinesManagement
                        ? colors.primary
                        : colors.grey11
                    }
                  />
                }
                iconPosition="start"
              />
            )}
          </Tabs>
        </div>

        <div className="tab-content-container">
          <TabPanel value={value} index={SettingsTabs.General}>
            <General />
          </TabPanel>
          <TabPanel value={value} index={SettingsTabs.Password}>
            <PasswordSettings />
          </TabPanel>
          <TabPanel value={value} index={SettingsTabs.Integrations}>
            <Integrations />
          </TabPanel>
          <TabPanel value={value} index={SettingsTabs.Signature}>
            <SignatureSettings />
          </TabPanel>
          <TabPanel value={value} index={SettingsTabs.ImportData}>
            <ImportData />
          </TabPanel>
          <TabPanel value={value} index={SettingsTabs.Subscriptions}>
            <Subscription />
          </TabPanel>
          <TabPanel value={value} index={SettingsTabs.AddOns}>
            <AddOns />
          </TabPanel>
          <TabPanel value={value} index={SettingsTabs.SuggestionPreferences}>
            <SuggestionsPreferences />
          </TabPanel>
          <TabPanel value={value} index={SettingsTabs.TemplateVariables}>
            <VariablesPage />
          </TabPanel>
          <TabPanel value={value} index={SettingsTabs.TagManagement}>
            <TagsSettings />
          </TabPanel>
          <TabPanel value={value} index={SettingsTabs.StatusManagement}>
            <StatusSettings />
          </TabPanel>
          <TabPanel value={value} index={SettingsTabs.PipelinesManagement}>
            <DealsManagement />
          </TabPanel>
        </div>
      </div>
    </SettingsWrapper>
  );
};

export default Settings;

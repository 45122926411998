import {
  CronoGatewayAction,
  CronoGatewayParamsAccount,
  CronoGatewayParamsProspect,
  CronoGatewayResponse,
  requiredExtensionVersionByActionTargetAndMethodName,
} from '../../types/crono-extension';
import { useCallback, useState } from 'react';
import {
  CronoGatewayLinkedinGetActivitiesAction,
  CronoGatewayLinkedinGetCompanyInfoAction,
  CronoGatewayLinkedinGetConversationAction,
  CronoGatewayLinkedinGetProfileIdFromLeadIdAction,
  CronoGatewayLinkedinGetProfileInfoAction,
  CronoGatewayLinkedinGetStatusAction,
  CronoGatewayLinkedinGetThreadReactionsAction,
  CronoGatewayLinkedinHasSalesNavAction,
  CronoGatewayLinkedinSearchCompaniesAction,
  CronoGatewayLinkedinSearchLeadsAction,
  CronoGatewayLinkedinSearchSalesNavLeadsAction,
  CronoGatewayLinkedinSearchSalesNavCompaniesAction,
  CronoGatewayLinkedinSendInvitationAction,
  CronoGatewayLinkedinSendMessageAction,
  CronoGatewayLinkedinGetInvitationsLimitAction,
  CronoGatewayLinkedinSendVoiceMessageAction,
  CronoGatewayLinkedinGetInMailCreditsAction,
  CronoGatewayLinkedinSendInMailMessageAction,
  CronoGatewayLinkedinSendMessageWithAttachmentAction,
  CronoGatewayLinkedinDownloadFileAction,
  CronoGatewayLinkedinGetSalesNavConversationAction,
  CronoGatewayLinkedinGetProfileViewAction,
  CronoGatewayLinkedinGetContactCommentsAction,
  CronoGatewayLinkedinGetCompanyActivitiesAction,
  CronoGatewayLinkedinGetCompanyExtendedInfoAction,
  CronoGatewayLinkedinGetCompanyJobsAction,
  CronoGatewayLinkedinGetContactSocialInteractionsAction,
  CronoGatewayLinkedinGetContactExperiencesAction,
  CronoGatewayLinkedinGetFilterValuesAction,
  CronoGatewayLinkedinAction,
  CronoGatewayLinkedinGetCompanyInfoByNumericIdAction,
  CronoGatewayLinkedinGetUserExperienceAction,
  CronoGatewayLinkedinRefreshCurrentSalesNavCookie,
} from 'crono-fe-common/types/crono-extension/linkedin';
import {
  CronoGatewayBackgroundOpenOrFocusTabAction,
  CronoGatewayBackgroundEnqueueAsyncJobs,
  CronoGatewayBackgroundReloadExtensionAction,
  CronoGatewayBackgroundClearAsyncJobsAction,
  CronoGatewayBackgoundSendNotificationToCronoApp,
  CronoGatewayBackgroundMarkJobsAsReadAction,
  CronoGatewayBackgroundEnqueueAsyncEnrichJobs,
  CronoGatewayBackgroundMarkEnrichJobsAsReadAction,
} from 'crono-fe-common/types/crono-extension/background-script';
import { Analytics } from '@june-so/analytics-next';
import { compareVersions } from 'crono-fe-common/utils';
import { CronoGatewayGoogleSearchPlacesActon } from 'crono-fe-common/types/crono-extension/google';

// linkedin

export const useLinkedinGetStatus =
  createIpcHook<CronoGatewayLinkedinGetStatusAction>(
    'linkedin',
    'getStatus',
    'prospect',
  );

export const useLinkedinSendInvitation =
  createIpcHook<CronoGatewayLinkedinSendInvitationAction>(
    'linkedin',
    'sendInvitation',
    'prospect',
  );

export const useLinkedinSendMessage =
  createIpcHook<CronoGatewayLinkedinSendMessageAction>(
    'linkedin',
    'sendMessage',
    'prospect',
  );

export const useLinkedinSendMessageWithAttachment =
  createIpcHook<CronoGatewayLinkedinSendMessageWithAttachmentAction>(
    'linkedin',
    'sendMessageWithAttachment',
    'prospect',
  );

export const useLinkedinSendVoiceMessage =
  createIpcHook<CronoGatewayLinkedinSendVoiceMessageAction>(
    'linkedin',
    'sendVoiceMessage',
    'prospect',
  );

export const useLinkedinSearchCompanies =
  createIpcHook<CronoGatewayLinkedinSearchCompaniesAction>(
    'linkedin',
    'searchCompanies',
    null,
  );

export const useLinkedinGetActivities =
  createIpcHook<CronoGatewayLinkedinGetActivitiesAction>(
    'linkedin',
    'getActivities',
    'prospect',
  );

export const useLinkedinGetCompanyActivities =
  createIpcHook<CronoGatewayLinkedinGetCompanyActivitiesAction>(
    'linkedin',
    'getCompanyActivities',
    'account',
  );

export const useLinkedinGetContactComments =
  createIpcHook<CronoGatewayLinkedinGetContactCommentsAction>(
    'linkedin',
    'getContactComments',
    'prospect',
  );

export const useLinkedinGetContactExperiences =
  createIpcHook<CronoGatewayLinkedinGetContactExperiencesAction>(
    'linkedin',
    'getContactExperiences',
    'prospect',
  );

export const useLinkedinGetContactSocialInteractions =
  createIpcHook<CronoGatewayLinkedinGetContactSocialInteractionsAction>(
    'linkedin',
    'getContactSocialInteractions',
    'prospect',
  );

export const useLinkedinGetCompanyInfo =
  createIpcHook<CronoGatewayLinkedinGetCompanyInfoAction>(
    'linkedin',
    'getCompanyInfo',
    'account',
  );

export const useLinkedinGetProfileInfo =
  createIpcHook<CronoGatewayLinkedinGetProfileInfoAction>(
    'linkedin',
    'getProfileInfo',
    'prospect',
  );

export const useLinkedinGetUserExperiences =
  createIpcHook<CronoGatewayLinkedinGetUserExperienceAction>(
    'linkedin',
    'getUserExperience',
    'prospect',
  );

export const useLinkedinGetConversation =
  createIpcHook<CronoGatewayLinkedinGetConversationAction>(
    'linkedin',
    'getConversation',
    'prospect',
  );

export const useLinkedinGetSalesNavConversation =
  createIpcHook<CronoGatewayLinkedinGetSalesNavConversationAction>(
    'linkedin',
    'getSalesNavConversation',
    'prospect',
  );

export const useLinkedinSearchLeads =
  createIpcHook<CronoGatewayLinkedinSearchLeadsAction>(
    'linkedin',
    'searchLeads',
    null,
  );

export const useLinkedinSearchSalesNavLeads =
  createIpcHook<CronoGatewayLinkedinSearchSalesNavLeadsAction>(
    'linkedin',
    'searchSalesNavLeads',
    null,
  );

export const useLinkedinSearchSalesNavCompanies =
  createIpcHook<CronoGatewayLinkedinSearchSalesNavCompaniesAction>(
    'linkedin',
    'searchSalesNavCompanies',
    null,
  );

export const useLinkedinGetProfileView =
  createIpcHook<CronoGatewayLinkedinGetProfileViewAction>(
    'linkedin',
    'getProfileView',
    'prospect',
  );

export const useLinkedinGetExtendedCompanyInfo =
  createIpcHook<CronoGatewayLinkedinGetCompanyExtendedInfoAction>(
    'linkedin',
    'getCompanyExtendedInfo',
    'account',
  );

export const useLinkedinGetCompanyJobs =
  createIpcHook<CronoGatewayLinkedinGetCompanyJobsAction>(
    'linkedin',
    'getCompanyJobs',
    'account',
  );

export const useLinkedinGetPublicIdFromLeadId =
  createIpcHook<CronoGatewayLinkedinGetProfileIdFromLeadIdAction>(
    'linkedin',
    'getPublicIdFromLeadId',
    null,
  );

export const useLinkedinGetCompanyInfoFromNumericId =
  createIpcHook<CronoGatewayLinkedinGetCompanyInfoByNumericIdAction>(
    'linkedin',
    'getCompanyInfoByNumericId',
    null,
  );

export const useLinkedinGetThreadReactions =
  createIpcHook<CronoGatewayLinkedinGetThreadReactionsAction>(
    'linkedin',
    'getThreadReactions',
    null,
  );

export const useLinkedinHasSalesNav =
  createIpcHook<CronoGatewayLinkedinHasSalesNavAction>(
    'linkedin',
    'hasSalesNav',
    null,
  );

export const useLinkedinGetInvitationsLimit =
  createIpcHook<CronoGatewayLinkedinGetInvitationsLimitAction>(
    'linkedin',
    'getInvitationsLimit',
    null,
  );

export const useLinkedinGetInMailCredits =
  createIpcHook<CronoGatewayLinkedinGetInMailCreditsAction>(
    'linkedin',
    'getInMailCredits',
    null,
  );

export const useLinkedinSendInMailMessage =
  createIpcHook<CronoGatewayLinkedinSendInMailMessageAction>(
    'linkedin',
    'sendInMailMessage',
    'prospect',
  );

export const useLinkedinDownloadFile =
  createIpcHook<CronoGatewayLinkedinDownloadFileAction>(
    'linkedin',
    'downloadFile',
    null,
  );

// background
export const useBackgroundOpenOrFocusTab =
  createIpcHook<CronoGatewayBackgroundOpenOrFocusTabAction>(
    'background-script',
    'openOrFocusTab',
  );

export const useEnqueueAsyncJob =
  createIpcHook<CronoGatewayBackgroundEnqueueAsyncJobs>(
    'background-script',
    'enqueueAsyncJobs',
  );

export const useBackgroundClearAsyncJobs =
  createIpcHook<CronoGatewayBackgroundClearAsyncJobsAction>(
    'background-script',
    'clearAsyncJobs',
  );

export const useBackgroundMarkJobsAsRead =
  createIpcHook<CronoGatewayBackgroundMarkJobsAsReadAction>(
    'background-script',
    'markJobsAsRead',
  );

export const useEnqueueAsyncEnrichJob =
  createIpcHook<CronoGatewayBackgroundEnqueueAsyncEnrichJobs>(
    'background-script',
    'enqueueAsyncEnrichJobs',
  );

export const useBackgroundMarkEnrichJobsAsRead =
  createIpcHook<CronoGatewayBackgroundMarkEnrichJobsAsReadAction>(
    'background-script',
    'markEnrichJobsAsRead',
  );

export const useBackgroundSendNotificationToCronoApp =
  createIpcHook<CronoGatewayBackgoundSendNotificationToCronoApp>(
    'background-script',
    'sendNotificationToCronoApp',
  );

export const useBackgroundReloadExtension =
  createIpcHook<CronoGatewayBackgroundReloadExtensionAction>(
    'background-script',
    'reloadExtension',
  );

export const useGetLinkedinFilterValues =
  createIpcHook<CronoGatewayLinkedinGetFilterValuesAction>(
    'linkedin',
    'getLinkedinFilterValues',
    null,
  );

export const useRefreshCurrentSalesNavCookie =
  createIpcHook<CronoGatewayLinkedinRefreshCurrentSalesNavCookie>(
    'linkedin',
    'refreshCurrentSalesNavCookie',
    null,
  );

// google
export const useGoogleSearchPlaces =
  createIpcHook<CronoGatewayGoogleSearchPlacesActon>('google', 'searchPlaces');

type CreateIpcHookReturnObject<
  T extends CronoGatewayAction,
  U extends T['params'],
> = {
  call: (params: U) => Promise<T['result'] | null>;
  resetData: () => void;
  error: string | null;
  isLoading: boolean;
  result: T['result'] | null;
};

//This overload signature is used to enforce the types of the parameters depending on the type passed to acceptedIdentifiers
export function createIpcHook<T extends CronoGatewayLinkedinAction>(
  target: T['target'],
  methodName: T['methodName'],
  acceptedIdentifiers: 'prospect',
): () => CreateIpcHookReturnObject<T, CronoGatewayParamsProspect<T>>;

export function createIpcHook<T extends CronoGatewayLinkedinAction>(
  target: T['target'],
  methodName: T['methodName'],
  acceptedIdentifiers: 'account',
): () => CreateIpcHookReturnObject<T, CronoGatewayParamsAccount<T>>;

export function createIpcHook<T extends CronoGatewayLinkedinAction>(
  target: T['target'],
  methodName: T['methodName'],
  acceptedIdentifiers: null,
): () => CreateIpcHookReturnObject<T, T['params']>;

//The generic one for non linkedinAction
export function createIpcHook<
  T extends Exclude<CronoGatewayAction, CronoGatewayLinkedinAction>,
>(
  target: T['target'],
  methodName: T['methodName'],
): () => CreateIpcHookReturnObject<T, T['params']>;

export function createIpcHook<T extends CronoGatewayAction>(
  target: T['target'],
  methodName: T['methodName'],
  acceptedIdentifiers?: 'account' | 'prospect' | null,
) {
  return () => {
    const [hookData, setHookData] = useState<HookData<T>>({
      error: null,
      isLoading: false,
      result: null,
    });

    async function call(params: T['params']): Promise<T['result'] | null> {
      setHookData({
        error: null,
        isLoading: true,
        result: null,
      });
      try {
        const result = await gatewayService.execute({
          target,
          methodName,
          params,
          //We attach the identifier here so that it can be passed to the chrome runtime
          identifiers: acceptedIdentifiers,
        });
        setHookData({
          error: null,
          isLoading: false,
          result,
        });
        return result;
      } catch (e: any) {
        if (e?.message === 'missingCookie') {
          setHookData({
            error: 'Not logged in in Crono',
            isLoading: false,
            result: null,
          });
        } else if (e?.message) {
          setHookData({
            error: e.message,
            isLoading: false,
            result: null,
          });
        } else {
          setHookData({
            error: 'Unknown error',
            isLoading: false,
            result: null,
          });
        }
        return null;
      }
    }

    return {
      ...hookData,
      call: useCallback(call, []),
      resetData: useCallback(
        () =>
          setHookData({
            error: null,
            isLoading: false,
            result: null,
          }),
        [],
      ),
    };
  };
}

type HookData<T extends CronoGatewayAction> = {
  error: string | null;
  isLoading: boolean;
  result: T['result'] | null;
};

class CronoGatewayService {
  private analytics: Analytics | null = null;
  private usePostMessage = false;

  public setJuneAnalytics(analytics: Analytics | null) {
    this.analytics = analytics;
  }

  public switchToPostMessageStrategy() {
    this.usePostMessage = true;
    console.log('[CRONO-GATEWAY-SERVICE] switched to postMessage strategy');
  }

  public execute<
    T extends CronoGatewayAction,
    _T extends Omit<CronoGatewayAction, 'result'> = Omit<T, 'result'>,
  >(action: _T): Promise<T['result']> {
    if (this.usePostMessage) {
      return this.executeUsingPostMessage(action);
    } else {
      return this.executeUsingChromeRuntime(action);
    }
  }

  private executeUsingChromeRuntime<
    T extends CronoGatewayAction,
    _T extends Omit<CronoGatewayAction, 'result'> = Omit<T, 'result'>,
  >(action: _T): Promise<T['result']> {
    return new Promise((resolve, reject) => {
      try {
        const callback = (response: CronoGatewayResponse<T>) => {
          // console.log("CRONO GATEWAY RECEIVE", action, response);
          this.logErrorToAnalyticsIfNeeded(action, response).then();
          mapResponseToPromiseResult(resolve, reject, response);
        };

        // console.log("CRONO GATEWAY SEND", action);
        chrome.runtime.sendMessage(
          process.env.REACT_APP_CRONO_CHROME_EXTENSION_ID!,
          action,
          callback,
        );
      } catch (e) {
        reject(new Error('extensionNotInstalled'));
      }
    });
  }

  private executeUsingPostMessage<
    T extends CronoGatewayAction,
    _T extends Omit<CronoGatewayAction, 'result'> = Omit<T, 'result'>,
  >(action: _T): Promise<T['result']> {
    return new Promise((resolve, reject) => {
      const cancelToken = { cancelled: false };

      const timeout = setTimeout(() => {
        if (!cancelToken.cancelled) {
          cancelToken.cancelled = true;
          reject(new Error('extensionNotInstalled'));
        }
      }, 10000);

      const id = crypto.randomUUID();
      const listener = (event: any) => {
        if (
          event.data.type &&
          event.data.type === 'CRONO_FROM_CONNECTOR' &&
          event.data.id === id &&
          !cancelToken.cancelled
        ) {
          window.removeEventListener('message', listener);
          clearTimeout(timeout);

          const response = event.data.data;
          this.logErrorToAnalyticsIfNeeded(action, response).then();
          mapResponseToPromiseResult(resolve, reject, response);
        } else if (cancelToken.cancelled) {
          window.removeEventListener('message', listener);
        }
      };
      window.addEventListener('message', listener, false);

      window.postMessage({ type: 'CRONO_FROM_PAGE', data: action, id }, '*');
    });
  }

  private async logErrorToAnalyticsIfNeeded<
    T extends CronoGatewayAction,
    _T extends Omit<CronoGatewayAction, 'result'> = Omit<T, 'result'>,
  >(action: _T, response?: CronoGatewayResponse<T>) {
    if (!this.analytics || response?.status === 'callSuccess') {
      return;
    }

    const methodSignature = `${action.target}:${action.methodName}` as const;
    const requiredVersion =
      requiredExtensionVersionByActionTargetAndMethodName[methodSignature];
    const installedVersion = await this.getExtensionVersion();

    if (
      (action.target === 'background-script' &&
        action.methodName === 'getExtensionVersion') ||
      (requiredVersion &&
        installedVersion &&
        compareVersions(requiredVersion, installedVersion) > 0)
    ) {
      await this.analytics.track('extension-not-up-to-date', {
        methodSignature,
        requiredVersion,
        installedVersion,
      });
    } else {
      await this.analytics.track('crono-gateway-error', {
        action,
        response,
        installedVersion,
      });
    }
  }

  private async getExtensionVersion(): Promise<string | null> {
    return new Promise<string | null>((resolve) => {
      try {
        chrome.runtime.sendMessage(
          process.env.REACT_APP_CRONO_CHROME_EXTENSION_ID!,
          {
            target: 'background-script',
            methodName: 'getExtensionVersion',
          },
          (response) => {
            resolve(response.result?.version || null);
          },
        );
      } catch (e) {
        resolve(null);
      }
    });
  }
}

function mapResponseToPromiseResult<T extends CronoGatewayAction>(
  resolve: (value: T['result']) => void,
  reject: (reason?: any) => void,
  response: CronoGatewayResponse<T>,
) {
  if (response.status !== 'callSuccess') {
    reject(new Error(response.reason ?? response.status));
  } else if (
    !response.result &&
    (!('result' in response) || typeof response.result !== 'boolean')
  ) {
    reject(new Error('unknownError'));
  } else {
    resolve(response.result);
  }
}

export const gatewayService = new CronoGatewayService();

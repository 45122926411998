import React, { useRef, useState } from 'react';
import { FC, useEffect, useMemo } from 'react';
import { BlurInsightsCard, InsightsCardWrapper } from './styles';
import {
  CircularProgress,
  Typography,
  Avatar,
  IconButton,
  TextField,
  TextareaAutosize,
  Tooltip,
} from '@mui/material';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import { colors } from 'crono-fe-common/theme';
import {
  CloseButton,
  MainButton,
  MainPrimarySmallButton,
} from 'crono-fe-common/components/CronoButton';
import {
  checkIfValidLinkedinCompanyUrl,
  checkIfValidLinkedinProfileUrl,
  getImageFromUrl,
  stringAvatarAccount,
  stringAvatarProspect,
} from 'utils/fe-utils';
import {
  useLinkedinGetCompanyInfo,
  useLinkedinGetCompanyJobs,
  useLinkedinGetContactSocialInteractions,
  useLinkedinGetExtendedCompanyInfo,
  useLinkedinGetInvitationsLimit,
  useLinkedinGetProfileView,
  useLinkedinSearchCompanies,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import {
  CronoGatewayLinkedinGetContactSocialInteractionsAction,
  LinkedinCompany,
  LinkedinProfileExtendedInfo,
} from 'crono-fe-common/types/crono-extension/linkedin';
import { Account } from 'crono-fe-common/types/account';
import { Prospect } from 'crono-fe-common/types/prospect';
import LinkedinUrlUtils from 'crono-fe-common/utils/LinkedinUrlUtils';
import InsightsAccordion from 'pages/home/insightsAccordion';
import InnerTextAccordion from 'pages/home/insightsAccordion/innerTextAccordion';

import { ReactComponent as IconSuitcase } from 'crono-fe-common/icons/Icon-suitcase.svg';
import { ReactComponent as IconLocationPin } from 'crono-fe-common/icons/Icon-Location-Pin.svg';
import { ReactComponent as IconPin } from 'crono-fe-common/icons/Icon-pin.svg';
import { ReactComponent as IconKeyTopics } from 'crono-fe-common/icons/Icon-key-topics.svg';
import { ReactComponent as IconBuilding } from 'crono-fe-common/icons/Icon_building.svg';
import { ReactComponent as LinkedinInvalidUrlIcon } from 'assets/images/Linkedin_Invalid_url.svg';
import LinkedinIcon from 'crono-fe-common/icons/Icon-Linkedin';
import RemoveIcon from 'crono-fe-common/icons/Icon-Remove';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import useEditAccount from 'hooks/services/account/useEditAccount';
import { AccountInfoInputs } from 'crono-fe-common/types/DTO/accountInfoInputs';
import SearchIcon from 'crono-fe-common/icons/Icon-Search';
import useEditProspect from 'hooks/services/prospect/useEditProspect';
import { UpdateProspect } from 'crono-fe-common/types/DTO/updateProspect';
import IconRedirect from 'crono-fe-common/icons/Icon-Redirect';
import SearchLinkedin from 'pages/taskBoard/suggestionsBox/suggestionCards/preconditionLinkedinCard/SearchLinkedin';
import {
  BlurBackgroundDivAbsolute,
  BlurBackgroundDivFixed,
} from 'crono-fe-common/components/Layout/BlurBackgroundDiv';
import { ReactComponent as IconOpenAI } from 'assets/images/Icon_OpenAI.svg';
import { ReactComponent as IconMessage } from 'assets/images/Icon_Message.svg';
import CronoCheckbox from 'crono-fe-common/components/CronoCheckbox';
import { RewriteMessageInput } from 'crono-fe-common/types/DTO/rewriteMessage';
import useRewriteMessage from 'hooks/services/assistant/useRewriteMessage';
import { useJuneAnalytics } from 'context/june';
import { useConditionalSnackBar } from 'context/snackbar';
import CronoEditor from 'components/CronoEditor/CronoEditor';
import ReactQuill from 'react-quill';
import { useAuth } from 'context/auth';
import { useSafeStrategyOverviewContext } from 'context/strategyDetail';
import {
  CompanyInfoToUse,
  RewriteBaseInput,
} from 'crono-fe-common/types/DTO/rewriteBaseInput';
import { RewriteSequenceInstanceInput } from 'crono-fe-common/types/DTO/rewriteSequenceInstanceInput';
import IconBackArrow from 'crono-fe-common/icons/Icon-Back-Arrow';
import useGetSequenceInstance from 'hooks/services/sequence/useGetSequenceInstance';
import { RevertRewrittenSequenceInstanceInput } from '../../../crono-fe-common/types/DTO/revertRewrittenSequenceInstanceInput';
import { Constants } from '../../../crono-fe-common/constants/constants';

export enum InsightType {
  PROFILE_VIEW = 'PROFILE_VIEW',
  SOCIAL_PRESENCE = 'SOCIAL_PRESENCE',
  KEY_TOPICS = 'KEY_TOPICS',
  JOB_OPENINGS = 'JOB_OPENINGS',
  COMPANY_NEWS = 'COMPANY_NEWS',
  FUNDING_ROUNDS = 'FUNDING_ROUNDS',
}

export interface RewriteSubmittedContext {
  rewriteRequest: RewriteMessageInput;
  rewriteResponse: string;
  recipientProfile: string;
}

export type ISocialPresence =
  CronoGatewayLinkedinGetContactSocialInteractionsAction['result'];

interface IProps {
  close: () => void;
  rewriteEnabled?: boolean;
  wholeMessage?: string;
  partToRewrite?: string;
  showSelection?: () => void;
  replaceSelectedText?: (text: string) => void;
  isRewriteResponseHtml?: boolean;
  style?: React.CSSProperties;
  setRewriteSubmittedContext?: React.Dispatch<
    React.SetStateAction<RewriteSubmittedContext | null>
  >;
  //To fill variables
  prospect: Prospect | null;
  account: Account | null;
}

const InsightsCard: FC<IProps> = ({
  close,
  prospect,
  account,
  rewriteEnabled = false,
  wholeMessage = '',
  partToRewrite = '',
  showSelection = () => {},
  replaceSelectedText = (text: string) => {},
  isRewriteResponseHtml = false,
  setRewriteSubmittedContext,
  style,
}) => {
  const {
    insightsContext: {
      rewriteEnabledMode,
      isRewriteSequenceInstanceInProgress,
      rewriteSequenceInstance,
      revertRewrittenSequenceInstance,
      isRevertInProgress,
    },
  } = useSafeStrategyOverviewContext();

  rewriteEnabled = rewriteEnabled || rewriteEnabledMode !== null;

  const [linkedinUrlProspect, setLinkedinUrlProspect] = useState<string>('');

  const [linkedinUrlAccount, setLinkedinUrlAccount] = useState<string>('');

  // Data is fetched upon expanding each InsightAccordion
  // contact logic ----------------
  const memoizedProspectPublicId = useMemo(() => {
    if (prospect) {
      return LinkedinUrlUtils.getIdFromLinkedinProfileUrl(prospect.linkedin);
    }
  }, [prospect]);

  const memoizedCompanyPublicId = useMemo(() => {
    if (account) {
      return (
        LinkedinUrlUtils.getIdFromLinkedinCompanyUrl(account?.linkedin)[0] ?? ''
      );
    }
  }, [account]);

  const {
    call: getProfileView,
    result: getProfileViewResult,
    isLoading: getProfileViewLoading,
  } = useLinkedinGetProfileView();

  const {
    call: getContactSocialInteractions,
    result: getContactSocialInteractionsResult,
    isLoading: getContactSocialInteractionsLoading,
  } = useLinkedinGetContactSocialInteractions();

  const handleGetProfileView = (publicId: string | null) => {
    if (publicId) {
      getProfileView({ publicId: publicId });
    }
  };

  const handleGetSocialInteractions = async () => {
    if (memoizedProspectPublicId && prospect) {
      await getContactSocialInteractions({
        publicId: memoizedProspectPublicId,
        url: prospect.linkedin,
        activities: true,
        comments: true,
        events: false,
      });
    }
  };

  useEffect(() => {
    if (memoizedProspectPublicId && !getProfileViewResult) {
      handleGetProfileView(memoizedProspectPublicId);
    }
  }, [memoizedProspectPublicId, getProfileViewResult, prospect]);

  const { mutate: updateProspect, isLoading: updateProspectLoading } =
    useEditProspect();

  const [isLinkedinContactUrlInvalid, setIsLinkedinContactUrlInvalid] =
    useState<boolean>(false);

  const handlePatchProspectLinkedinUrl = () => {
    if (prospect && prospect.objectId) {
      if (!checkIfValidLinkedinProfileUrl(linkedinUrlProspect)) {
        setIsLinkedinContactUrlInvalid(true);
        return;
      }
      const prospectPatch: UpdateProspect = {
        prospectId: prospect?.objectId,
      };
      if (linkedinUrlProspect) {
        prospectPatch.linkedin = linkedinUrlProspect;
        updateProspect(prospectPatch);
      }
    }
  };

  // company logic -------------

  const {
    data: updatedAccount,
    mutate: updateAccount,
    isLoading: updateAccountLoading,
  } = useEditAccount();

  const [isLinkedinCompanyUrlInvalid, setIsLinkedinCompanyUrlInvalid] =
    useState<boolean>(false);

  const handlePatchCompanyLinkedinUrl = (
    url?: string,
    numericId?: string | null,
  ) => {
    const finalUrl = url ?? linkedinUrlAccount;
    if (account && account.objectId && finalUrl) {
      if (!checkIfValidLinkedinCompanyUrl(finalUrl)) {
        setIsLinkedinCompanyUrlInvalid(true);
        return;
      }
      const accountPatch: AccountInfoInputs = {
        accountId: account?.objectId,
        linkedin: finalUrl,
        ...(numericId && { linkedinNumericId: numericId }),
      };

      updateAccount(accountPatch);
    }
  };

  const {
    call: getExtendedCompanyInfo,
    result: getExtendedCompanyInfoResult,
    isLoading: getExtendedCompanyInfoLoading,
  } = useLinkedinGetExtendedCompanyInfo();

  const {
    call: getCompanyJobs,
    result: getCompanyJobsResult,
    isLoading: getCompanyJobsLoading,
  } = useLinkedinGetCompanyJobs();

  const handleGetCompanyInfo = async (
    numericId?: string,
    publicId?: string,
    url?: string,
  ) => {
    if (numericId || publicId || url) {
      await getExtendedCompanyInfo({ numericId, publicId, url });
    }
  };

  const handleGetCompanyJobs = async () => {
    if (memoizedCompanyPublicId && account) {
      await getCompanyJobs({
        numericId: account?.linkedinNumericId,
        publicId: memoizedCompanyPublicId,
        url: account?.linkedin,
      });
    }
  };

  useEffect(() => {
    if (memoizedCompanyPublicId && !getExtendedCompanyInfoResult) {
      handleGetCompanyInfo(
        account?.linkedinNumericId,
        memoizedCompanyPublicId,
        account?.linkedin,
      );
    }
  }, [memoizedCompanyPublicId, getExtendedCompanyInfoResult, account]);

  useEffect(() => {
    if (updatedAccount) {
      handleGetCompanyInfo(
        updatedAccount?.data?.data?.linkedinNumericId,
        memoizedCompanyPublicId,
        updatedAccount?.data?.data?.linkedin,
      );
    }
  }, [updatedAccount, updateAccountLoading]);

  const { user } = useAuth();
  const {
    result: linkedinCompanyInfo,
    call: getLinkedinCompanyInfo,
    isLoading: isLoadingCompanyInfo,
  } = useLinkedinGetCompanyInfo();

  useEffect(() => {
    if (
      user?.company?.linkedinUrl &&
      !linkedinCompanyInfo &&
      !isLoadingCompanyInfo
    ) {
      getLinkedinCompanyInfo({ url: user.company.linkedinUrl }).then();
    }
  }, [user]);

  const [additionalInformation, setAdditionalInformation] =
    useState<string>('');
  const [feedbackInputValue, setFeedbackInputValue] = useState<
    string | number | readonly string[] | undefined
  >(undefined);

  const [selectedInsights, setSelectedInsights] = useState({
    profileDescription: false,
    workExperiences: [] as number[],
    keyTopics: false,
    activities: [] as number[],

    companyDescription: false,
    fundingData: false,
    news: [] as number[],
    jobOpenings: [] as number[],
  });

  const {
    mutate: rewriteMessage,
    data: rewriteMessageResult,
    isLoading: isRewriteMessageInProgress,
  } = useRewriteMessage();

  const [rewriteMessageRequest, setRewriteMessageRequest] =
    useState<null | RewriteMessageInput>(null);
  useEffect(() => {
    if (rewriteMessageRequest) {
      rewriteMessage(rewriteMessageRequest);
    }
  }, [rewriteMessageRequest]);

  const getRewriteBaseInput = () => {
    const allProspectInsights = getProfileViewResult?.profile;
    const allSocialInteractions = getContactSocialInteractionsResult;

    const selectedProspectInsights: Partial<LinkedinProfileExtendedInfo> = {};
    const selectedProspectSocialInteractions: Partial<ISocialPresence> = {};

    if (selectedInsights.profileDescription) {
      selectedProspectInsights.description = allProspectInsights?.description;
    }

    if (selectedInsights.workExperiences.length) {
      selectedProspectInsights.workExperience =
        allProspectInsights?.workExperience?.filter((_, index) =>
          selectedInsights.workExperiences.includes(index),
        );
    }

    if (selectedInsights.keyTopics) {
      if (!allSocialInteractions) {
        return;
      }
      selectedProspectSocialInteractions.allHashtags =
        allSocialInteractions.allHashtags;
    }

    if (selectedInsights.activities.length) {
      if (!allSocialInteractions) {
        return;
      }
      selectedProspectSocialInteractions.activities =
        allSocialInteractions.activities?.filter((_, index) =>
          selectedInsights.activities.includes(index),
        );
    }

    const allCompanyInsights = getExtendedCompanyInfoResult?.company;
    const selectedCompanyInsights: CompanyInfoToUse = {};

    if (selectedInsights.companyDescription) {
      selectedCompanyInsights.description = allCompanyInsights?.description;
    }

    if (selectedInsights.news.length) {
      if (!selectedCompanyInsights.linkedinActivities) {
        selectedCompanyInsights.linkedinActivities = [];
      }

      selectedCompanyInsights.linkedinActivities =
        allCompanyInsights?.activities?.activities?.filter((_, index) =>
          selectedInsights.news.includes(index),
        ) ?? [];
    }

    if (selectedInsights.jobOpenings.length) {
      selectedCompanyInsights.jobs =
        getCompanyJobsResult?.jobs?.filter((_, index) =>
          selectedInsights.jobOpenings.includes(index),
        ) ?? [];
    }

    if (selectedInsights.fundingData) {
      selectedCompanyInsights.fundingData = allCompanyInsights?.fundingData;
    }

    const senderCompany = linkedinCompanyInfo?.company;
    if (allProspectInsights && allCompanyInsights) {
      const rewriteBaseInput: RewriteBaseInput = {
        additionalContext: additionalInformation,
        recipientInfo: {
          firstName: allProspectInsights.firstName,
          lastName: allProspectInsights.lastName,
          overallExperienceTime: allProspectInsights.overallExperienceTime,
          location: allProspectInsights.location,
          linkedinProfileDescription: allProspectInsights.description,
          linkedinHashTags: allSocialInteractions?.allHashtags
            ? Object.keys(allSocialInteractions?.allHashtags)
            : undefined,
          workExperience: allProspectInsights?.workExperience?.map((w) => ({
            position: w.position,
            companyName: w.company.name,
            timePeriod: w.timePeriod,
            description: w.description,
          })),
        },
        recipientInfoToUse: {
          linkedinProfileDescription: selectedProspectInsights.description,
          linkedinHashTags: selectedProspectSocialInteractions?.allHashtags
            ? Object.keys(selectedProspectSocialInteractions?.allHashtags)
            : null,
          workExperience: selectedProspectInsights?.workExperience?.map(
            (w) => ({
              position: w.position,
              companyName: w.company.name,
              timePeriod: w.timePeriod,
              description: w.description,
            }),
          ),
          linkedinActivities: selectedProspectSocialInteractions?.activities,
        },
        recipientCompanyInfo: allCompanyInsights,
        senderCompanyInfo: senderCompany ?? undefined,
        recipientCompanyInfoToUse: selectedCompanyInsights,
      };

      return rewriteBaseInput;
    }

    return null;
  };

  const onRewriteSubmit = () => {
    showSelection();
    const rewriteBaseInput = getRewriteBaseInput();

    if (rewriteBaseInput) {
      const rewriteMessageInput: RewriteMessageInput = {
        ...rewriteBaseInput,
        wholeMessage,
        partToPersonalize: partToRewrite,
      };

      if (analytics) analytics.track('rewrite-clicked', {});

      setRewriteMessageRequest(rewriteMessageInput);
    }
  };

  const [rewriteResponse, setRewriteResponse] = useState<string | null>(null);

  useEffect(() => {
    if (rewriteMessageResult?.data?.data) {
      setRewriteResponse(rewriteMessageResult.data.data);
    }
  }, [rewriteMessageResult?.data?.data]);

  const [rewriteSequenceInstanceRequest, setRewriteSequenceInstanceRequest] =
    useState<null | RewriteSequenceInstanceInput>(null);

  useEffect(() => {
    if (rewriteSequenceInstanceRequest) {
      rewriteSequenceInstance(rewriteSequenceInstanceRequest);
    }
  }, [rewriteSequenceInstanceRequest]);

  const [maxLinkedinInvitationChars, setMaxLinkedinInvitationChars] =
    useState<number>(Constants.linkedinInvitationMaxChars);

  const { call: getLinkedinInvitationLimits } =
    useLinkedinGetInvitationsLimit();

  useEffect(() => {
    getLinkedinInvitationLimits()
      .then((linkedinInvitationLimits) => {
        setMaxLinkedinInvitationChars(
          linkedinInvitationLimits?.maxMessageLength ??
            Constants.linkedinInvitationMaxChars,
        );
      })
      .catch();
  }, []);

  const onRewriteSequenceInstanceSubmit = () => {
    const rewriteBaseInput = getRewriteBaseInput();

    if (rewriteBaseInput && prospect?.sequenceInstanceId) {
      const rewriteSequenceInstanceInput: RewriteSequenceInstanceInput = {
        ...rewriteBaseInput,
        prospectId: prospect.objectId,
        sequenceInstanceId: prospect.sequenceInstanceId,
        invitationCharsLimit: maxLinkedinInvitationChars,
      };

      if (analytics) analytics.track('rewrite-sequence-clicked', {});

      setRewriteSequenceInstanceRequest(rewriteSequenceInstanceInput);
    }
  };

  const renderInsightAccordion = (type: InsightType, selectable = false) => {
    switch (type) {
      case InsightType.PROFILE_VIEW:
        return (
          <>
            {getProfileViewResult?.profile?.description && (
              <InnerTextAccordion
                text={getProfileViewResult?.profile?.description}
                fontSize="13px"
                toggleSelectedOption={
                  selectable
                    ? () => {
                        setSelectedInsights((prev) => ({
                          ...prev,
                          profileDescription: !prev.profileDescription,
                        }));
                      }
                    : undefined
                }
                isSelected={selectedInsights.profileDescription}
              />
            )}
            <InsightsAccordion
              options={getProfileViewResult?.profile}
              icon={<IconSuitcase />}
              type={type}
              collapsedHeight="auto"
              publicId={memoizedProspectPublicId}
              companyPublicId={memoizedCompanyPublicId}
              selectedArray={
                selectable ? selectedInsights.workExperiences : undefined
              }
              setSelectedArray={
                selectable
                  ? (arr: number[]) =>
                      setSelectedInsights((prev) => ({
                        ...prev,
                        workExperiences: arr,
                      }))
                  : undefined
              }
            />
          </>
        );
      case InsightType.SOCIAL_PRESENCE:
        return (
          <InsightsAccordion
            options={getContactSocialInteractionsResult?.activities?.slice(
              0,
              5,
            )}
            fetchOptionsLoading={getContactSocialInteractionsLoading}
            icon={<IconPin />}
            title="Social presence"
            type={type}
            collapsedHeight="fit-content"
            fetchOptions={handleGetSocialInteractions}
            publicId={memoizedProspectPublicId}
            style={{
              gap: '8px',
              border: `1px solid ${colors.grey444}`,
              borderRadius: `12px`,
            }}
            selectedArray={selectable ? selectedInsights.activities : undefined}
            setSelectedArray={
              selectable
                ? (arr: number[]) =>
                    setSelectedInsights((prev) => ({
                      ...prev,
                      activities: arr,
                    }))
                : undefined
            }
          />
        );
      case InsightType.KEY_TOPICS:
        return (
          <InsightsAccordion
            options={
              getContactSocialInteractionsResult?.allHashtags
                ? Object.entries(
                    getContactSocialInteractionsResult?.allHashtags,
                  )
                    .sort((a, b) => Number(b[1]) - Number(a[1]))
                    .slice(0, 7)
                    .map((entry) => entry[0])
                : undefined
            }
            fetchOptionsLoading={getContactSocialInteractionsLoading}
            icon={
              selectable ? (
                <CronoCheckbox
                  checked={selectedInsights.keyTopics}
                  onClick={async () => {
                    if (!getContactSocialInteractionsResult) {
                      await handleGetSocialInteractions();
                    }
                    setSelectedInsights((prev) => ({
                      ...prev,
                      keyTopics: !prev.keyTopics,
                    }));
                  }}
                />
              ) : (
                <IconKeyTopics />
              )
            }
            title="Key topics"
            type={type}
            collapsedHeight="fit-content"
            fetchOptions={handleGetSocialInteractions}
            style={{
              gap: '8px',
              border: `1px solid ${colors.grey444}`,
              borderRadius: `12px`,
            }}
          />
        );
      case InsightType.JOB_OPENINGS:
        return (
          <InsightsAccordion
            prospect={prospect}
            options={getCompanyJobsResult}
            fetchOptionsLoading={getCompanyJobsLoading}
            icon={<IconSuitcase />}
            title="Job openings"
            type={type}
            collapsedHeight="fit-content"
            fetchOptions={handleGetCompanyJobs}
            style={{
              gap: '8px',
              border: `1px solid ${colors.grey444}`,
              borderRadius: `12px`,
            }}
            growth={getExtendedCompanyInfoResult?.company?.growth ?? null}
            selectedArray={
              selectable ? selectedInsights.jobOpenings : undefined
            }
            setSelectedArray={
              selectable
                ? (arr: number[]) =>
                    setSelectedInsights((prev) => ({
                      ...prev,
                      jobOpenings: arr,
                    }))
                : undefined
            }
          />
        );
      case InsightType.COMPANY_NEWS:
        return (
          <InsightsAccordion
            options={getExtendedCompanyInfoResult?.company}
            fetchOptionsLoading={getExtendedCompanyInfoLoading}
            icon={<IconPin />}
            title="News"
            type={type}
            collapsedHeight="fit-content"
            style={{
              gap: '8px',
              border: `1px solid ${colors.grey444}`,
              borderRadius: `12px`,
            }}
            selectedArray={selectable ? selectedInsights.news : undefined}
            setSelectedArray={
              selectable
                ? (arr: number[]) =>
                    setSelectedInsights((prev) => ({
                      ...prev,
                      news: arr,
                    }))
                : undefined
            }
          />
        );
      case InsightType.FUNDING_ROUNDS:
        return (
          <InsightsAccordion
            options={getExtendedCompanyInfoResult?.company?.fundingData}
            fetchOptionsLoading={getExtendedCompanyInfoLoading}
            icon={<IconPin />}
            title="Funding"
            type={type}
            collapsedHeight="fit-content"
            style={{
              gap: '8px',
              border: `1px solid ${colors.grey444}`,
              borderRadius: `12px`,
            }}
            toggleSelectedOption={
              selectable
                ? () => {
                    setSelectedInsights((prev) => ({
                      ...prev,
                      fundingData: !prev.fundingData,
                    }));
                  }
                : undefined
            }
            isSelected={selectedInsights.fundingData}
          />
        );
    }
  };

  const insightProspectOptions = useMemo(() => {
    return [
      InsightType.PROFILE_VIEW,
      InsightType.SOCIAL_PRESENCE,
      InsightType.KEY_TOPICS,
    ].map((option) => renderInsightAccordion(option, rewriteEnabled));
  }, [
    getProfileViewResult,
    getContactSocialInteractionsResult,
    getContactSocialInteractionsLoading,
    selectedInsights,
    rewriteEnabled,
  ]);

  const insightCompanyOptions = useMemo(() => {
    return [
      InsightType.JOB_OPENINGS,
      InsightType.COMPANY_NEWS,
      InsightType.FUNDING_ROUNDS,
    ].map((option) => renderInsightAccordion(option, rewriteEnabled));
  }, [
    getExtendedCompanyInfoResult,
    getCompanyJobsResult,
    getCompanyJobsLoading,
    selectedInsights,
    rewriteEnabled,
  ]);

  const overallExperienceTimeJSX = useMemo(() => {
    const obj: any = getProfileViewResult?.profile?.overallExperienceTime;
    const years = obj?.years ?? '';
    const months = obj?.months ?? '';

    let seniority = `${years} ${years === 1 ? 'year' : 'years'}`;
    if (months >= 7) {
      seniority = `${years + 1} ${years + 1 === 1 ? 'year' : 'years'}`;
    }
    return seniority;
  }, [getProfileViewResult]);

  const getLocation = useMemo(() => {
    let location = getProfileViewResult?.profile?.location;
    if (location?.includes(',')) {
      const temp = location.split(',');

      if (temp[temp.length - 1] !== ' undefined') {
        location = `${temp[temp.length - 2]}, ${temp[temp.length - 1]}`;
      } else {
        location = temp[temp.length - 2];
      }
    }
    return location;
  }, [getProfileViewResult]);

  const {
    call: searchCompanies,
    result: searchCompaniesResult,
    isLoading: isSearchCompaniesLoading,
  } = useLinkedinSearchCompanies();

  const [fetchedCompanies, setFetchedCompanies] = useState<
    LinkedinCompany[] | null
  >(null);

  useEffect(() => {
    if (account && account.name) {
      searchCompanies({
        keywords: account.name,
      });
    }
  }, []);

  useEffect(() => {
    if (searchCompaniesResult) {
      setFetchedCompanies(searchCompaniesResult.companies ?? []);
    }
  }, [searchCompaniesResult, isSearchCompaniesLoading]);

  const [showSearchContactModal, setShowSearchContactModal] =
    useState<boolean>(false);

  const [isFeedbackView, setIsFeedbackView] = useState(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const analytics = useJuneAnalytics();

  const sendFeedback = () => {
    if (feedbackInputValue && rewriteResponse && analytics) {
      analytics.track('rewrite-feedback', {
        feedback: feedbackInputValue,
        rewriteRequest: rewriteMessageRequest,
        rewriteResponse: rewriteResponse,
        recipientProfile: memoizedProspectPublicId,
      });

      setFeedbackInputValue('');
      setIsFeedbackView(false);

      setIsFeedbackSent(true);
      setTimeout(() => {
        setIsFeedbackSent(false);
      }, 2000);
    }
  };

  useConditionalSnackBar([
    {
      condition: isFeedbackSent,
      message: 'Your feedback was sent successfully!',
      severity: 'success',
    },
  ]);

  const allowToRewrite = useMemo(() => {
    const recipientProfile = getProfileViewResult?.profile;
    const recipientCompany = getExtendedCompanyInfoResult?.company;

    if (
      (!partToRewrite && rewriteEnabledMode !== 'sequenceInstance') ||
      (!recipientProfile && !getProfileViewLoading) ||
      (!recipientCompany && !getExtendedCompanyInfoLoading)
    ) {
      return false;
    }

    if (
      selectedInsights.profileDescription ||
      selectedInsights.keyTopics ||
      selectedInsights.workExperiences.length ||
      selectedInsights.activities.length ||
      selectedInsights.jobOpenings.length ||
      selectedInsights.news.length ||
      selectedInsights.companyDescription ||
      selectedInsights.fundingData ||
      additionalInformation
    ) {
      return true;
    }

    return false;
  }, [
    selectedInsights,
    partToRewrite,
    rewriteEnabledMode,
    getExtendedCompanyInfoResult,
    getProfileViewResult,
    additionalInformation,
  ]);

  const editorRef = useRef<ReactQuill | null>(null);

  const {
    insightsContext: { insightsCardRef },
  } = useSafeStrategyOverviewContext();

  const { data: sequenceInstance } = useGetSequenceInstance(
    prospect ? prospect.sequenceInstanceId ?? 0 : 0,
    prospect ? prospect.objectId ?? '' : '',
    true,
  );

  const showRevertButton = useMemo(() => {
    if (!sequenceInstance?.data?.data?.tasks) return false;

    const activeTasks = sequenceInstance.data.data.tasks.filter(
      (task) => !task.completed,
    );

    return (
      rewriteEnabledMode === 'sequenceInstance' &&
      activeTasks.every((task) => !task.templateId || task.personalizedWithAI)
    );
  }, [sequenceInstance]);

  return (
    <div ref={insightsCardRef}>
      <BlurInsightsCard id="background" onClick={close} />
      <InsightsCardWrapper style={style}>
        {showSearchContactModal && account && prospect && (
          <>
            <BlurBackgroundDivFixed>
              <SearchLinkedin
                account={account}
                prospect={prospect}
                setOpenModal={setShowSearchContactModal}
                callOnSuccess={() => {
                  setShowSearchContactModal(false);
                }}
              />
            </BlurBackgroundDivFixed>
          </>
        )}
        <div className="insight-card-container">
          <div className="insight-card-header">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 18,
              }}
            >
              <CloseButton onClick={close}>
                <CloseMIcon color={colors.grey11} />
              </CloseButton>
              <Typography variant="h5" fontWeight={700}>
                Insights
              </Typography>
            </div>
          </div>
          <div className="divider" />
          {getProfileViewLoading || updateProspectLoading ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : getProfileViewResult ? (
            <>
              <div
                style={{
                  height: rewriteEnabled
                    ? 'calc(100% - 80px - 150px)'
                    : 'calc(100% - 80px)',
                  overflow: 'hidden auto',
                }}
              >
                <div className="insight-card-body">
                  <div className="insight-prospect-header">
                    <Avatar
                      src={getProfileViewResult?.profile?.pictureUrl ?? ''}
                    >
                      {prospect && stringAvatarProspect(prospect)}
                    </Avatar>
                    <div className="prospect-name-title">
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          fontSize={'14px'}
                          fontWeight={600}
                          lineHeight={'22px'}
                          className="contact-name"
                          minWidth={'fit-content'}
                          noWrap
                        >
                          {prospect?.name}
                        </Typography>
                        {getProfileViewResult?.profile?.connectionLevel && (
                          <Typography
                            fontSize={'14px'}
                            fontWeight={400}
                            color={colors.grey11}
                            noWrap
                          >
                            - {getProfileViewResult?.profile?.connectionLevel}
                          </Typography>
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        <span className="blue-badge">
                          <IconSuitcase />{' '}
                          {getProfileViewResult?.profile?.seniorityLevel}:{' '}
                          {overallExperienceTimeJSX}
                        </span>
                        <span className="blue-badge">
                          <IconLocationPin /> {getLocation}
                        </span>
                      </div>
                    </div>
                  </div>
                  {insightProspectOptions}
                </div>
                <div className="horizontal-divider" />
                {getExtendedCompanyInfoLoading ? (
                  <FlexDiv height="50%">
                    <CircularProgress />
                  </FlexDiv>
                ) : getExtendedCompanyInfoResult ? (
                  <div className="insight-card-body">
                    <div className="insight-company-header">
                      <div
                        style={{
                          display: 'flex',
                          gap: '12px',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Avatar
                          variant="rounded"
                          sx={{
                            width: '32px',
                            height: '32px',
                          }}
                          src={
                            getExtendedCompanyInfoResult?.company?.imageUrl ??
                            (account?.website &&
                              getImageFromUrl(account.website, null))
                          }
                        >
                          {account && stringAvatarAccount(account?.name || '')}
                        </Avatar>
                        <div className="company-name-industry">
                          <Typography
                            fontSize={'14px'}
                            fontWeight={600}
                            lineHeight={'22px'}
                            className="contact-name"
                            noWrap
                          >
                            {getExtendedCompanyInfoResult?.company?.name}
                          </Typography>
                          <Typography
                            fontSize={'12px'}
                            fontWeight={400}
                            color={colors.grey11}
                            noWrap
                          >
                            {getExtendedCompanyInfoResult?.company?.category}
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        <span className="blue-badge">
                          <IconBuilding />{' '}
                          {getExtendedCompanyInfoResult?.company?.industry}
                        </span>
                        <span className="blue-badge">
                          <IconLocationPin />{' '}
                          {getExtendedCompanyInfoResult?.company?.city}
                        </span>
                      </div>
                    </div>
                    <InnerTextAccordion
                      text={getExtendedCompanyInfoResult?.company?.description}
                      fontSize="13px"
                      toggleSelectedOption={
                        rewriteEnabled
                          ? () => {
                              setSelectedInsights((prev) => ({
                                ...prev,
                                companyDescription: !prev.companyDescription,
                              }));
                            }
                          : undefined
                      }
                      isSelected={selectedInsights.companyDescription}
                    />
                    {insightCompanyOptions}
                  </div>
                ) : account && account?.name ? (
                  <div className="insight-card-body">
                    {/*These 2 parts are different depending on the result of the fetch companies */}
                    {isSearchCompaniesLoading || updateAccountLoading ? (
                      <FlexDiv>
                        <CircularProgress />
                      </FlexDiv>
                    ) : fetchedCompanies && fetchedCompanies.length ? (
                      <>
                        <Typography fontSize={'16px'} fontWeight={600}>
                          LinkedIn companies for "{account.name}" :
                        </Typography>
                        <Typography fontSize={'14px'} color={colors.grey11}>
                          Please confirm the company LinkedIn page to gather
                          additional information:
                        </Typography>
                        {fetchedCompanies.map((company, index) => {
                          return (
                            <div key={index} className="confirm-company-card">
                              <Avatar
                                src={company.logoUrl}
                                variant="rounded"
                                sx={{
                                  width: '32px',
                                  height: '32px',
                                }}
                              />
                              <div
                                className="company-name-industry"
                                style={{ gap: '0px' }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    gap: '10px',
                                  }}
                                >
                                  <Typography
                                    fontSize={'14px'}
                                    fontWeight={600}
                                    lineHeight={'22px'}
                                    className="contact-name"
                                    noWrap
                                  >
                                    {company.title}
                                  </Typography>
                                  <IconButton
                                    style={{
                                      width: 20,
                                      height: 20,
                                      padding: 1,
                                    }}
                                    onClick={() => {
                                      if (company.url)
                                        window.open(company.url, '_blank');
                                    }}
                                  >
                                    <LinkedinIcon color={colors.intenseBlue} />
                                  </IconButton>
                                </div>
                                <Typography
                                  fontSize={'12px'}
                                  fontWeight={400}
                                  color={colors.grey11}
                                  noWrap
                                >
                                  {company?.primarySubtitle}
                                </Typography>
                              </div>
                              <span
                                className="right-side-button-wrapper remove-icon"
                                onClick={() => {
                                  setFetchedCompanies((prev) =>
                                    (prev ?? [])?.filter(
                                      (c) => c.numericId !== company.numericId,
                                    ),
                                  );
                                }}
                              >
                                <RemoveIcon color={colors.inactive} />
                              </span>
                              <span
                                className="right-side-button-wrapper select-icon"
                                onClick={() =>
                                  handlePatchCompanyLinkedinUrl(
                                    company.url,
                                    company.numericId,
                                  )
                                }
                              >
                                <CheckMarkIcon
                                  color={colors.darkGreen}
                                  strokeWidth={2}
                                />
                              </span>
                            </div>
                          );
                        })}
                        <Typography fontSize={'14px'} fontWeight={600}>
                          Or insert link manually:
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography fontSize={'16px'} fontWeight={600}>
                          Missing company LinkedIn URL:
                        </Typography>
                        <Typography fontSize={'14px'} color={colors.grey11}>
                          Please insert link manually
                        </Typography>
                      </>
                    )}
                    {updateAccountLoading ? (
                      <FlexDiv height="50%">
                        <CircularProgress />
                      </FlexDiv>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            border: `1px solid ${colors.grey444}`,
                            borderRadius: '12px',
                          }}
                        >
                          <TextField
                            name={'company-linkedin'}
                            variant="standard"
                            autoComplete="off"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            fullWidth
                            placeholder="Insert LinkedIn URL"
                            onChange={(ev) => {
                              if (isLinkedinCompanyUrlInvalid) {
                                setIsLinkedinCompanyUrlInvalid(false);
                              }
                              setLinkedinUrlAccount(ev.target.value);
                            }}
                            value={linkedinUrlAccount}
                            sx={{
                              padding: '6px',
                            }}
                          />
                          {linkedinUrlAccount && (
                            <IconRedirect
                              onClick={() => {
                                if (linkedinUrlAccount) {
                                  window.open(linkedinUrlAccount, '_blank');
                                }
                              }}
                              className="redirect-icon-linkedin-url"
                            />
                          )}
                          {linkedinUrlAccount && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4,
                                padding: 6,
                                borderLeft: `1px solid ${colors.grey444}`,
                              }}
                            >
                              <span
                                className="right-side-button-wrapper remove-icon"
                                onClick={() => {
                                  setLinkedinUrlAccount('');
                                }}
                              >
                                <RemoveIcon color={colors.inactive} />
                              </span>
                              <span
                                className="right-side-button-wrapper select-icon"
                                onClick={() => {
                                  handlePatchCompanyLinkedinUrl();
                                }}
                              >
                                <CheckMarkIcon
                                  color={colors.darkGreen}
                                  strokeWidth={2}
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        {isLinkedinCompanyUrlInvalid && (
                          <Typography
                            color={colors.inactive}
                            fontSize={'11px'}
                            lineHeight={'14px'}
                            fontWeight={400}
                          >
                            Linkedin URL is invalid
                          </Typography>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {rewriteEnabled && !rewriteResponse && (
                <div className="insights-rewrite-section">
                  <div className="rewrite-inner-wrapper">
                    <TextareaAutosize
                      value={additionalInformation}
                      onChange={(e) => setAdditionalInformation(e.target.value)}
                      id="rewrite-textarea"
                      minRows={2}
                      maxRows={2}
                      className="rewrite-textarea dark-scrollbar"
                      placeholder="Provide additional information..."
                    />

                    {showRevertButton && (
                      <Tooltip
                        enterDelay={400}
                        enterNextDelay={400}
                        title={'Back to original templates'}
                        arrow
                        placeholder={'top'}
                      >
                        {isRevertInProgress ? (
                          <div className={'revert-button'}>
                            <CircularProgress />
                          </div>
                        ) : (
                          <IconButton
                            className={'revert-button'}
                            onClick={() => {
                              if (prospect?.sequenceInstanceId) {
                                const request: RevertRewrittenSequenceInstanceInput =
                                  {
                                    prospectId: prospect.objectId,
                                    sequenceInstanceId:
                                      prospect.sequenceInstanceId,
                                  };

                                if (analytics)
                                  analytics.track(
                                    'revert-rewritten-sequence',
                                    request,
                                  );

                                revertRewrittenSequenceInstance(request);
                              }
                            }}
                          >
                            <IconBackArrow color={colors.black} />
                          </IconButton>
                        )}
                      </Tooltip>
                    )}

                    <Tooltip
                      enterDelay={400}
                      enterNextDelay={400}
                      title={
                        (!getProfileViewResult && !getProfileViewLoading) ||
                        (!getExtendedCompanyInfoResult &&
                          !getExtendedCompanyInfoLoading)
                          ? 'Make sure that both the contact and the company include valid LinkedIn URLs'
                          : ''
                      }
                      arrow
                      placeholder={'top'}
                    >
                      <div>
                        <MainPrimarySmallButton
                          onClick={
                            rewriteEnabledMode === 'sequenceInstance'
                              ? onRewriteSequenceInstanceSubmit
                              : onRewriteSubmit
                          }
                          disabled={!allowToRewrite}
                        >
                          <IconOpenAI style={{ marginRight: '8px' }} />
                          {rewriteEnabledMode === 'sequenceInstance'
                            ? 'Rewrite All'
                            : 'Rewrite'}
                        </MainPrimarySmallButton>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              )}
              {(rewriteResponse ||
                isRewriteMessageInProgress ||
                isRewriteSequenceInstanceInProgress) && (
                <BlurBackgroundDivAbsolute>
                  <div className="insights-rewrite-response-modal">
                    {rewriteResponse ? (
                      <div className="response dark-scrollbar">
                        {isRewriteResponseHtml ? (
                          <CronoEditor
                            toolbarId={'insights-editor-toolbar'}
                            editorRef={editorRef}
                            value={rewriteResponse}
                            imageSupport={true}
                            readOnly={true}
                          />
                        ) : (
                          <Typography fontSize={14} lineHeight={'24px'}>
                            {rewriteResponse?.split('\n')?.map((line) => (
                              <>
                                {line}
                                <br />
                              </>
                            ))}
                          </Typography>
                        )}
                      </div>
                    ) : (
                      <FlexDiv>
                        <CircularProgress />
                      </FlexDiv>
                    )}

                    {rewriteEnabledMode !== 'sequenceInstance' && (
                      <div className={'buttons-container'}>
                        <IconButton
                          className={'feedback-icon'}
                          onClick={() => setIsFeedbackView((prev) => !prev)}
                        >
                          <IconMessage color={colors.inactive} />
                        </IconButton>
                        <IconButton
                          className={'delete-icon'}
                          disabled={isRewriteMessageInProgress}
                          onClick={() => {
                            if (
                              analytics &&
                              rewriteMessageRequest &&
                              rewriteResponse
                            ) {
                              analytics.track('rewrite-discarded', {
                                rewriteRequest: rewriteMessageRequest,
                                rewriteResponse: rewriteResponse,
                                recipientProfile: memoizedProspectPublicId,
                              });
                            }
                            setRewriteResponse(null);
                          }}
                        >
                          <RemoveIcon color={colors.inactive} />
                        </IconButton>
                        <IconButton
                          className="confirm-icon"
                          disabled={isRewriteMessageInProgress}
                          onClick={() => {
                            if (
                              rewriteMessageRequest &&
                              rewriteResponse &&
                              memoizedProspectPublicId &&
                              setRewriteSubmittedContext
                            ) {
                              setRewriteSubmittedContext({
                                rewriteResponse,
                                rewriteRequest: rewriteMessageRequest,
                                recipientProfile: memoizedProspectPublicId,
                              });
                            }

                            replaceSelectedText(rewriteResponse ?? '');
                            setRewriteResponse(null);
                          }}
                        >
                          <CheckMarkIcon color={colors.callScheduled} />
                        </IconButton>
                      </div>
                    )}

                    {isFeedbackView && (
                      <>
                        <hr className={'feedback-divider'} />
                        <div className={'feedback-container'}>
                          <input
                            placeholder={'How the result could be improved?'}
                            value={feedbackInputValue}
                            onInput={(e: any) =>
                              setFeedbackInputValue(e.target.value)
                            }
                          />
                          <MainButton
                            disabled={!feedbackInputValue}
                            onClick={sendFeedback}
                          >
                            <Typography
                              fontSize={14}
                              lineHeight={18}
                              fontWeight={500}
                            >
                              Send
                            </Typography>
                          </MainButton>
                        </div>
                      </>
                    )}
                  </div>
                </BlurBackgroundDivAbsolute>
              )}
            </>
          ) : (
            <FlexDiv
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap="20px"
              style={{ paddingBottom: 100 }}
            >
              <LinkedinInvalidUrlIcon />
              <Typography
                fontSize="16px"
                fontWeight={600}
                textAlign="center"
                lineHeight="24px"
              >
                The Linkedin URL of this contact is invalid.
                <br />
                Please change it and try again.
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  width: '90%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    border: `1px solid ${colors.grey444}`,
                    borderRadius: '12px',
                    gap: '8px',
                  }}
                >
                  <TextField
                    name={'prospect-linkedin'}
                    variant="standard"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    fullWidth
                    placeholder="Insert LinkedIn URL"
                    onChange={(ev) => {
                      if (isLinkedinContactUrlInvalid) {
                        setIsLinkedinContactUrlInvalid(false);
                      }
                      setLinkedinUrlProspect(ev.target.value);
                    }}
                    value={linkedinUrlProspect}
                    sx={{
                      padding: '6px',
                    }}
                  />
                  {linkedinUrlProspect && (
                    <IconRedirect
                      onClick={() => {
                        if (linkedinUrlProspect) {
                          window.open(linkedinUrlProspect, '_blank');
                        }
                      }}
                      className="redirect-icon-linkedin-url"
                    />
                  )}
                  {linkedinUrlProspect ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 4,
                        padding: 6,
                        borderLeft: `1px solid ${colors.grey444}`,
                      }}
                    >
                      <span
                        className="right-side-button-wrapper remove-icon"
                        onClick={() => {
                          setLinkedinUrlProspect('');
                        }}
                      >
                        <RemoveIcon color={colors.inactive} />
                      </span>
                      <span
                        className="right-side-button-wrapper select-icon"
                        onClick={() => {
                          handlePatchProspectLinkedinUrl();
                        }}
                      >
                        <CheckMarkIcon
                          color={colors.darkGreen}
                          strokeWidth={2}
                        />
                      </span>
                    </div>
                  ) : (
                    <div
                      className="search-prospect-url"
                      onClick={() => setShowSearchContactModal(true)}
                    >
                      <Typography fontSize={'14px'} color={colors.white}>
                        Find
                      </Typography>
                      <SearchIcon color={colors.white} />
                    </div>
                  )}
                </div>
                {isLinkedinContactUrlInvalid && (
                  <Typography
                    color={colors.inactive}
                    fontSize={'11px'}
                    lineHeight={'14px'}
                    fontWeight={400}
                  >
                    Linkedin URL is invalid
                  </Typography>
                )}
              </div>
            </FlexDiv>
          )}
        </div>
      </InsightsCardWrapper>
    </div>
  );
};

export default InsightsCard;

import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import React, { useMemo } from 'react';
import TagsBoxSingleTagShowAll from './tagsBoxSingleTag';
import { ExternalProperty } from 'crono-fe-common/types/externalProperty';
import { TagsBoxWrapper } from './style';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';

interface IProps {
  // The type of tags that we want to show, Account, prospect and leads, Account and prospects/lead related, opportunity or all of them
  type:
    | 'Account'
    | 'Prospect'
    | 'Lead'
    | 'AccountAndProspect'
    | 'Opportunity'
    | 'All';
  onlyInsert?: boolean;
  currentSituation?: (string | null)[];
  handleClickTag?: (
    option: string | null,
    externalProperty: ExternalProperty,
    index: number,
  ) => void;
  //If this is set to true, it means that the tags marked as isEditable false should be disabled (because we
  // are in accout/prospect page for example).
  // This is false by default, because for strategies and templates we want to show and edit all the tags, even if they are not editable
  blockIsEditable?: boolean;
  //If true we do not allow edit. Default false
  disableEdit?: boolean;
  //If the currents are tags maybe already selected, not a screen
  //from which I always start empty (such inserts)
  currentSituationTags?: { externalPropertyId: number; option: string }[];

  showRemovable?: boolean;

  //If true, the mandatory tags will be shown with a star
  showMandatoryAsStar?: boolean;

  whiteBackground?: boolean;
  customStyle?: any;
}

const TagsBoxShowAll = ({
  type,
  onlyInsert,
  currentSituation,
  currentSituationTags,
  handleClickTag,
  blockIsEditable,
  disableEdit = false,
  showRemovable = true,
  showMandatoryAsStar = false,
  whiteBackground = false,
  customStyle,
}: IProps) => {
  const { user } = useAuth();
  const { data: tagsAPI } = useGetExternalProperty(
    type === 'All' || type === 'AccountAndProspect'
      ? null
      : type === 'Lead' && user?.integrationType !== IntegrationType.SALESFORCE
        ? 'Prospect'
        : type,
    true,
    onlyInsert === true ? true : null,
    null,
    true,
  );

  const tags = useMemo(() => {
    if (!tagsAPI?.data?.data) return [];
    let tagsToReturn = tagsAPI.data.data;
    if (type === 'AccountAndProspect') {
      tagsToReturn = tagsAPI.data.data.filter(
        (tag) =>
          tag.tableType === 'Account' ||
          tag.tableType === 'Prospect' ||
          tag.tableType === 'Lead',
      );
    }
    return tagsToReturn;
  }, [tagsAPI]);

  const groupedTags = useMemo(() => {
    return tags.reduce(
      (acc, tag) => {
        if (tag.tableType === 'Account') {
          acc.accounts.push(tag);
        } else if (tag.tableType === 'Prospect') {
          acc.prospects.push(tag);
        } else if (tag.tableType === 'Lead') {
          acc.leads.push(tag);
        }
        return acc;
      },
      {
        accounts: [] as ExternalProperty[],
        prospects: [] as ExternalProperty[],
        leads: [] as ExternalProperty[],
      },
    );
  }, [tags]);

  const renderTags = (tagsArray: ExternalProperty[]) => {
    return tagsArray.map((externalProperty, indexOfTag) => {
      const isEditable =
        (externalProperty.isEditable || !blockIsEditable) && !disableEdit;

      const option = currentSituation
        ? currentSituation[indexOfTag]
        : currentSituationTags?.find(
            (tag) => tag.externalPropertyId === externalProperty.id,
          )?.option ?? null;

      // since the order is alphabetical: Account -> Prospect -> Lead
      let indexGeneralArray = 0;
      switch (externalProperty.tableType) {
        case 'Account':
          indexGeneralArray = indexOfTag;
          break;
        case 'Prospect':
          indexGeneralArray = indexOfTag + groupedTags.accounts.length;
          break;
        case 'Lead':
          indexGeneralArray =
            indexOfTag +
            groupedTags.accounts.length +
            groupedTags.prospects.length;
          break;
      }

      // If the tag cannot be edited and there is no value it doesn't make sense to show it
      if (option === null && !isEditable) return null;

      return (
        <TagsBoxSingleTagShowAll
          key={externalProperty.id}
          indexOfTag={indexOfTag}
          indexGeneralArray={indexGeneralArray}
          isEditable={isEditable}
          option={option}
          tag={externalProperty}
          handleClickTag={handleClickTag}
          showRemovable={showRemovable}
          showMandatoryStar={showMandatoryAsStar}
          whiteBackground={whiteBackground}
          customStyle={customStyle}
        />
      );
    });
  };

  return (
    <TagsBoxWrapper>
      {renderTags(groupedTags.accounts)}
      {renderTags(groupedTags.prospects)}
      {renderTags(groupedTags.leads)}
    </TagsBoxWrapper>
  );
};
export default TagsBoxShowAll;

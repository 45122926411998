import { FC, useEffect, useMemo, useState } from 'react';
import { GeneralWrapper } from '../styles';
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import useGetPipelines from 'hooks/services/pipeline/useGetPipelines';
import { colors } from 'crono-fe-common/theme';
import PlusIcon from 'crono-fe-common/icons/Icon-Plus';
import { MainPrimaryButton } from 'crono-fe-common/components/CronoButton';
import AddPipelineContainer from 'pages/opportunities/addPipeline/container';
import AddStageContainer from 'pages/opportunities/addStage/container';
import { useAuth } from 'context/auth';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';
import ResyncButton from 'crono-fe-common/components/resyncButton';
import useSyncCrmPipelines from 'hooks/services/pipeline/useSyncCrmPipelines';
import { getColorsStages } from 'utils/fe-utils';
import { stagesColors } from 'constants/FeConstants';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { Stage } from 'crono-fe-common/types/stage';
import usePatchStage from 'hooks/services/stage/usePatchStage';
import CheckMarkIcon from 'crono-fe-common/icons/Icon-CheckMark';
import CloseMIcon from 'crono-fe-common/icons/Icon-Close';
import EditPencilIcon from 'crono-fe-common/icons/Icon-Edit-pencil';
import TrashCanDeleteIcon from 'crono-fe-common/icons/Icon-Trash-Can';
import IconDrag from 'crono-fe-common/icons/Icon-Drag';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from '@hello-pangea/dnd';
import useDeleteStage from 'hooks/services/stage/useDeleteStage';
import { useConditionalSnackBar } from 'context/snackbar';
import { getError } from 'crono-fe-common/utils';
import { useConfirmModal } from 'context/confirmModal';
import { AxiosError } from 'axios';
import { CronoErrorCode } from 'crono-fe-common/types/enums/cronoErrorCode';
import { useJuneAnalytics } from 'context/june';

const customSelectStyle = {
  style: { zIndex: 35001 },
  PaperProps: {
    sx: {
      borderRadius: '8px',
      paddingInline: '6px',
      border: '1px solid #dadde9',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
      '& .MuiMenuItem-root': {
        fontSize: '12px',
        lineHeight: '16px',
        height: 'fit-content',
        padding: '8px',
        cursor: 'pointer',
        width: '100%',
        borderRadius: '8px',
        '&:hover': {
          background: colors.primaryLight,
          color: colors.primary,
        },
      },
      '& .Mui-selected': {
        color: colors.primary,
        backgroundColor: 'transparent !important',
      },
    },
  },
};

const DealsManagement: FC = () => {
  const { user } = useAuth();
  const [pipelineModal, setPipelineModal] = useState(false);
  const [stageModal, setStageModal] = useState(false);

  const analytics = useJuneAnalytics();

  const { openModal: openConfirmModal } = useConfirmModal();

  // Pipeline/stage logic ----------------------------
  const [selectedPipelineId, setSelectedPipelineId] = useState<number | null>(
    null,
  );
  const { data: pipelines, isLoading: loadingPipelines } = useGetPipelines();

  const existingPipelines = useMemo(() => {
    return (
      pipelines?.data?.data.map(
        (pipeline) => pipeline.publicName?.toLowerCase().trim() ?? '',
      ) ?? []
    );
  }, [pipelines]);

  const selectedPipeline = useMemo(() => {
    const a = pipelines?.data?.data.find(
      (pipeline) => pipeline.id === selectedPipelineId,
    );
    return a;
  }, [selectedPipelineId, pipelines]);

  useEffect(() => {
    //When loaded set the initial pipeline to the first one
    if (pipelines?.data?.data.length && selectedPipelineId === null) {
      setSelectedPipelineId(pipelines?.data?.data[0].id);
    }
  }, [pipelines]);

  //The stages that has to be shown in the correct order
  const [stages, wonLostStages] = useMemo(() => {
    if (!selectedPipeline?.stages) return [];
    //I order them by stageOrder
    const entries = Object.entries(selectedPipeline?.stages ?? []);
    entries.sort((a, b) => {
      return a[1].displayOrder - b[1].displayOrder;
    });

    const stages = entries
      .map((entry) => entry[1])
      .filter((stage) => !stage.isClosed && !stage.isWon);
    const wonLost = entries
      .map((entry) => entry[1])
      .filter(
        (stage) =>
          (stage.isClosed && stage.isWon) || (stage.isClosed && !stage.isWon),
      );

    return [stages, wonLost];
  }, [selectedPipeline]);

  const existingStages = useMemo(() => {
    return (
      [...(stages ?? []), ...(wonLostStages ?? [])].map(
        (stage) => stage.publicName?.toLowerCase().trim() ?? '',
      ) ?? []
    );
  }, [stages, wonLostStages]);

  const totalNumberOfStages = useMemo(() => {
    //Return the max stageOrder in the array of stages
    return stages?.reduce((prev, curr) => {
      return Math.max(prev, curr.displayOrder);
    }, 0);
  }, [stages]);

  //  Sync CRM logic ----------------------------

  const {
    mutate: syncPipelines,
    isLoading: synchingPipelines,
    error: errorSyncPipelines,
  } = useSyncCrmPipelines();

  const handleSyncCRM = () => {
    syncPipelines();
    if (analytics) {
      analytics.track('sync-pipeline', {});
    }
  };

  // Edit/delete stages standalone ---------------------
  const { mutate: patchStage, error: patchStageError } = usePatchStage();
  const [localStages, setLocalStages] = useState<Stage[] | undefined>(
    undefined,
  );
  const [localWonLost, setLocalWonLost] = useState<Stage[] | undefined>(
    undefined,
  );
  const [stageFocused, setStageFocused] = useState<number | null>(null);

  const [stageName, setStageName] = useState<string | null>('');

  useEffect(() => {
    if (stages) {
      setLocalStages([...stages]);
    }
  }, [stages]);

  useEffect(() => {
    if (wonLostStages) {
      setLocalWonLost(wonLostStages);
    }
  }, [wonLostStages]);

  const handleChangeName = (
    name: string | null,
    index: number,
    localStages: Stage[] | undefined,
    isWonLostStage = false,
  ) => {
    //Copy the array of steps (normal or won/lost stages)
    const stages = [...(localStages ?? [])];
    const currentName = stages[index].publicName;
    if (name === '' || name?.trim() === currentName) return;

    // Update locally and make patch request
    // If the stage is a won/lost stage, the displayOrder can't be updated (Not allowed by API)
    if (selectedPipelineId) {
      patchStage({
        pipelineId: selectedPipelineId,
        stageId: stages[index].id,
        publicName: name,
        displayOrder: isWonLostStage ? undefined : stages[index].displayOrder,
      });
      if (analytics) {
        analytics.track('patch-pipeline-stage', {
          field: 'name',
        });
      }
      stages[index].publicName = name;

      if (isWonLostStage) {
        setLocalWonLost(stages);
      } else {
        setLocalStages(stages);
      }
    }
  };

  const { mutateAsync: deleteStageAsync, error: deleteStageError } =
    useDeleteStage();

  const handleDeleteStage = async (index: number) => {
    const stages = [...(localStages ?? [])];
    const stage = stages[index];
    try {
      if (selectedPipelineId) {
        await deleteStageAsync({
          PipelineId: selectedPipelineId,
          StageId: stage.id,
        });
        if (analytics) {
          analytics.track('delete-pipeline-stage', {});
        }
        stages.splice(index, 1);
      }
    } catch (error: unknown) {
      // Not the best way to handle this error, but the modal figma design requires it to be like this
      if (error instanceof AxiosError) {
        const title =
          error?.response?.data.errors![0]!.code ===
          CronoErrorCode.CronoConflictError
            ? 'Move existing deals first'
            : 'Error deleting the stage';
        openConfirmModal({
          title: title,
          text:
            error?.response?.data.errors![0]!.message ??
            'Error deleting the stage',
          cancelText: 'Cancel',
          cancelFunction: () => {},
          styles: {
            cancelButton: {
              width: '120px',
            },
          },
        });
      }
    } finally {
      setLocalStages(stages);
    }
  };
  const handleChangeProbability = (
    probability: number,
    index: number,
    localStages: Stage[] | undefined,
    isWonLostStage = false,
  ) => {
    //Copy the array of steps (normal or won/lost stages)
    const stages = [...(localStages ?? [])];
    const currentProbability = stages[index].probability;
    if (probability === currentProbability) return;

    // Update locally and make patch request
    // If the stage is a won/lost stage, the displayOrder can't be updated (Not allowed by API)
    if (selectedPipelineId) {
      patchStage({
        pipelineId: selectedPipelineId,
        stageId: stages[index].id,
        probability: probability,
        displayOrder: isWonLostStage ? undefined : stages[index].displayOrder,
      });
      if (analytics) {
        analytics.track('patch-pipeline-stage', {
          field: 'probability',
        });
      }
      stages[index].probability = probability;

      if (isWonLostStage) {
        setLocalWonLost(stages);
      } else {
        setLocalStages(stages);
      }
    }
  };

  // Drag and drop logic ----------------------------
  const reorder = (list: Stage[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return { result, removed };
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const { result: items, removed } = reorder(
      localStages ?? [],
      result.source.index,
      result.destination.index,
    );
    setLocalStages(items);

    if (selectedPipelineId) {
      patchStage({
        pipelineId: selectedPipelineId,
        stageId: removed.id,
        displayOrder: result.destination.index + 1,
      });
      if (analytics) {
        analytics.track('patch-pipeline-stage', {
          field: 'order',
        });
      }
    }
  };
  useConditionalSnackBar([
    {
      condition: !!patchStageError,
      message: getError(patchStageError) ?? 'Error updating the stage',
      severity: 'error',
    },
    {
      condition: !!errorSyncPipelines,
      message: getError(errorSyncPipelines) ?? 'Error syncing with CRM',
      severity: 'error',
    },
    // {
    //   condition: !!deleteStageError,
    //   message: getError(deleteStageError) ?? "Error deleting the stage",
    //   severity: "error"
    // }
  ]);

  const syncedWithCrm = useMemo(() => {
    return (
      !!user?.company && user?.company.integrationType !== IntegrationType.CRONO
    );
  }, [user]);

  return (
    <GeneralWrapper>
      {pipelineModal && (
        <AddPipelineContainer
          close={() => setPipelineModal(false)}
          existingPipelines={existingPipelines}
        />
      )}
      {selectedPipeline?.id && stageModal && (
        <AddStageContainer
          close={() => setStageModal(false)}
          pipelineId={selectedPipeline.id}
          existingStages={existingStages}
          setLocalStages={setLocalStages}
        />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          minWidth: '50%',
        }}
      >
        {loadingPipelines ? (
          <FlexDiv>
            <CircularProgress />
          </FlexDiv>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography fontSize={24} fontWeight={700} lineHeight={'30px'}>
                Pipeline management
              </Typography>
              {syncedWithCrm && user?.company?.integrationType ? (
                synchingPipelines ? (
                  <CircularProgress />
                ) : (
                  <div className="resync-crm-pipelines" onClick={handleSyncCRM}>
                    <ResyncButton
                      CRM={user?.company?.integrationType}
                      title={''}
                    />
                    <Typography
                      fontSize={'16px'}
                      fontWeight={500}
                      color={colors.intenseBlue}
                    >
                      Sync with CRM
                    </Typography>
                  </div>
                )
              ) : (
                <MainPrimaryButton
                  startIcon={<PlusIcon color={colors.white} />}
                  onClick={() => setPipelineModal(true)}
                  style={{ height: '40px' }}
                >
                  New pipeline
                </MainPrimaryButton>
              )}
            </div>
            <div className="pipeline-stage-container">
              <div className="form-box">
                <Typography
                  fontSize={'16px'}
                  fontWeight={600}
                  lineHeight={'22px'}
                >
                  Pipeline name
                </Typography>
                {pipelines?.data?.data &&
                pipelines?.data?.data?.length === 1 ? (
                  <div className="default-pipeline">
                    <Typography fontSize={'14px'}>
                      {pipelines?.data?.data[0].publicName}
                    </Typography>
                  </div>
                ) : (
                  <Select
                    value={selectedPipeline?.id || ''}
                    onChange={(ev) => {
                      setSelectedPipelineId(ev.target.value as number);
                    }}
                    className="select"
                    MenuProps={customSelectStyle}
                  >
                    <MenuItem value={0} disabled>
                      Select pipeline
                    </MenuItem>
                    {pipelines?.data?.data.map((pipeline) => {
                      return (
                        <MenuItem value={pipeline.id} key={pipeline.id}>
                          {pipeline.publicName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </div>
              <div className="form-box">
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    fontSize={'16px'}
                    fontWeight={600}
                    lineHeight={'22px'}
                  >
                    Stages
                  </Typography>
                  {!syncedWithCrm ? (
                    <MainPrimaryButton
                      startIcon={<PlusIcon color={colors.white} />}
                      onClick={() => setStageModal(true)}
                      style={{ height: '40px' }}
                    >
                      New stage
                    </MainPrimaryButton>
                  ) : null}
                </div>
                {stages && stages.length ? (
                  <div
                    style={{
                      display: 'flex',
                      height: '100%',
                      width: '100%',
                      gap: '24px',
                      marginTop: '8px',
                    }}
                  >
                    {syncedWithCrm ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          height: '100%',
                          width: '100%',
                          gap: '4px',
                        }}
                      >
                        <Typography fontSize={'12px'} color={colors.grey11}>
                          Stage name
                        </Typography>
                        <div
                          className="stages-container"
                          style={{
                            border: `1px solid ${colors.borderGrey}`,
                            borderRadius: '8px',
                          }}
                        >
                          {stages?.map((stage) => {
                            const colorsStage = getColorsStages(
                              stage.displayOrder,
                              (totalNumberOfStages ?? 0) + 2,
                            );
                            return (
                              <div
                                key={stage.id}
                                className="stage-row"
                                style={{
                                  backgroundColor: colorsStage.light,
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: colorsStage.dark,
                                    width: '9px',
                                    height: '9px',
                                    borderRadius: '10px',
                                  }}
                                />
                                <Typography
                                  fontSize={'12px'}
                                  fontWeight={500}
                                  color={colors.black}
                                  noWrap
                                >
                                  {stage.publicName}
                                </Typography>
                              </div>
                            );
                          })}
                        </div>
                        <div className="stages-container">
                          {wonLostStages?.map((stage) => {
                            const won = stagesColors.green;
                            const lost = stagesColors.red;
                            return (
                              <div
                                key={stage.id}
                                className="stage-row"
                                style={{
                                  backgroundColor: stage.isWon
                                    ? won.light
                                    : lost.light,
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: stage.isWon
                                      ? won.dark
                                      : lost.dark,
                                    width: '9px',
                                    height: '9px',
                                    borderRadius: '10px',
                                  }}
                                />
                                <Typography
                                  fontSize={'12px'}
                                  fontWeight={500}
                                  color={colors.black}
                                  noWrap
                                >
                                  {stage.publicName}
                                </Typography>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          height: '100%',
                          width: '100%',
                          gap: '4px',
                        }}
                      >
                        {/* Editable stages */}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            width: '100%',
                            gap: '16px',
                          }}
                        >
                          <DragDropContext onDragEnd={handleDragEnd}>
                            <Typography fontSize={'12px'} color={colors.grey11}>
                              Stage name
                            </Typography>
                            <Droppable droppableId={'droppable-id'}>
                              {(provided) => {
                                return (
                                  <div
                                    className="stages-container"
                                    style={{
                                      flex: 1,
                                      border: `1px solid ${colors.borderGrey}`,
                                      borderRadius: '8px',
                                    }}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {localStages?.map((stage, index) => {
                                      const colorsStage = getColorsStages(
                                        stage.displayOrder,
                                        (totalNumberOfStages ?? 0) + 2,
                                      );
                                      return (
                                        <Draggable
                                          key={
                                            stage.id + (stage.publicName ?? '')
                                          }
                                          draggableId={stage.id + ''}
                                          index={index}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                  ...provided.draggableProps
                                                    .style,
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  gap: '8px',
                                                }}
                                              >
                                                <div
                                                  className="stage-row"
                                                  style={{
                                                    backgroundColor:
                                                      colorsStage.light,
                                                    justifyContent:
                                                      'space-between',
                                                    filter: snapshot.isDragging
                                                      ? 'brightness(0.95)'
                                                      : 'none',
                                                    opacity:
                                                      stageFocused === null
                                                        ? 1
                                                        : stageFocused ===
                                                            stage.id
                                                          ? 1
                                                          : 0.6,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        colorsStage.dark,
                                                      width: '10px',
                                                      height: '9px',
                                                      borderRadius: '10px',
                                                    }}
                                                  />
                                                  <TextField
                                                    placeholder="Add a stage name"
                                                    defaultValue={
                                                      stage.publicName || ''
                                                    }
                                                    onChange={(ev) =>
                                                      setStageName(
                                                        ev.target.value,
                                                      )
                                                    }
                                                    fullWidth
                                                    variant="standard"
                                                    sx={{
                                                      '& .MuiInput-input': {
                                                        padding: 0,
                                                      },
                                                    }}
                                                    onFocus={() =>
                                                      setStageFocused(stage.id)
                                                    }
                                                    onBlur={() => {
                                                      handleChangeName(
                                                        stageName,
                                                        index,
                                                        localStages,
                                                      );
                                                      setStageName('');
                                                      setStageFocused(null);
                                                    }}
                                                    InputProps={{
                                                      style: {
                                                        fontSize: '12px',
                                                        fontWeight: 500,
                                                        color: colors.black,
                                                      },
                                                      disableUnderline: true,
                                                      classes: {
                                                        input: 'title',
                                                      },
                                                    }}
                                                  />
                                                  <IconButton
                                                    className="action-buttons"
                                                    style={{
                                                      padding: '2px',
                                                      cursor: 'grab',
                                                    }}
                                                    onClick={() => {
                                                      setStageFocused(null);
                                                    }}
                                                  >
                                                    <IconDrag
                                                      color={colors.grey11}
                                                    />
                                                  </IconButton>
                                                </div>
                                                <div className="action-buttons-container">
                                                  {stageFocused === stage.id ? (
                                                    <>
                                                      <IconButton
                                                        className="action-buttons delete-stage"
                                                        onClick={() => {
                                                          setStageFocused(null);
                                                          setStageName(
                                                            stage.publicName,
                                                          );
                                                        }}
                                                      >
                                                        <CloseMIcon
                                                          strokeWidth="2"
                                                          color={
                                                            colors.inactive
                                                          }
                                                        />
                                                      </IconButton>
                                                      <IconButton
                                                        className="action-buttons edit-stage"
                                                        onClick={() => {
                                                          setStageFocused(null);
                                                          handleChangeName(
                                                            stageName,
                                                            index,
                                                            localStages,
                                                          );
                                                        }}
                                                      >
                                                        <CheckMarkIcon
                                                          strokeWidth="2"
                                                          color={
                                                            colors.callScheduled
                                                          }
                                                        />
                                                      </IconButton>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <IconButton
                                                        className="action-buttons"
                                                        onClick={() => {
                                                          setStageFocused(
                                                            stage.id,
                                                          );
                                                        }}
                                                      >
                                                        <EditPencilIcon
                                                          color={colors.grey11}
                                                        />
                                                      </IconButton>
                                                      <IconButton
                                                        className="action-buttons"
                                                        style={{
                                                          padding: '2px',
                                                        }}
                                                        onClick={() =>
                                                          handleDeleteStage(
                                                            index,
                                                          )
                                                        }
                                                      >
                                                        <TrashCanDeleteIcon
                                                          color={colors.grey11}
                                                        />
                                                      </IconButton>
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    })}
                                    {provided.placeholder}
                                  </div>
                                );
                              }}
                            </Droppable>
                          </DragDropContext>
                        </div>
                        {/* Non deleteable stages */}
                        <div className="stages-container">
                          {localWonLost?.map((stage, index) => {
                            const won = stagesColors.green;
                            const lost = stagesColors.red;
                            return (
                              <div
                                key={'' + stage.id + index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '8px',
                                }}
                              >
                                <div
                                  key={'' + stage.id + index}
                                  className="stage-row"
                                  style={{
                                    backgroundColor: stage.isWon
                                      ? won.light
                                      : lost.light,
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: stage.isWon
                                        ? won.dark
                                        : lost.dark,
                                      width: '9px',
                                      height: '9px',
                                      borderRadius: '10px',
                                    }}
                                  />
                                  <TextField
                                    placeholder="Add a stage name"
                                    defaultValue={stage.publicName || ''}
                                    onChange={(ev) =>
                                      setStageName(ev.target.value)
                                    }
                                    fullWidth
                                    variant="standard"
                                    sx={{
                                      '& .MuiInput-input': {
                                        padding: 0,
                                      },
                                    }}
                                    onFocus={() => setStageFocused(stage.id)}
                                    onBlur={() => {
                                      handleChangeName(
                                        stageName,
                                        index,
                                        localWonLost,
                                        true,
                                      );
                                      setStageName('');
                                      setStageFocused(null);
                                    }}
                                    InputProps={{
                                      style: {
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: colors.black,
                                      },
                                      disableUnderline: true,
                                      classes: {
                                        input: 'title',
                                      },
                                    }}
                                  />
                                </div>
                                <div className="action-buttons-container">
                                  {stageFocused === stage.id ? (
                                    <>
                                      <IconButton
                                        className="action-buttons delete-stage"
                                        onClick={() => {
                                          setStageFocused(null);
                                        }}
                                      >
                                        <CloseMIcon
                                          strokeWidth="2"
                                          color={colors.inactive}
                                        />
                                      </IconButton>
                                      <IconButton
                                        className="action-buttons edit-stage"
                                        onClick={() => {
                                          setStageFocused(null);
                                        }}
                                      >
                                        <CheckMarkIcon
                                          strokeWidth="2"
                                          color={colors.callScheduled}
                                        />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <>
                                      <IconButton
                                        className="action-buttons"
                                        onClick={() => {
                                          setStageFocused(stage.id);
                                        }}
                                      >
                                        <EditPencilIcon color={colors.grey11} />
                                      </IconButton>
                                      <Tooltip
                                        arrow
                                        placement="top"
                                        title={`${stage.publicName} stage can’t be deleted`}
                                      >
                                        <div>
                                          <IconButton
                                            className="action-buttons"
                                            style={{ padding: '2px' }}
                                            disabled
                                          >
                                            <TrashCanDeleteIcon
                                              color={colors.grey33}
                                            />
                                          </IconButton>
                                        </div>
                                      </Tooltip>
                                    </>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        gap: '4px',
                        minWidth: '100px',
                      }}
                    >
                      <Typography fontSize={'12px'} color={colors.grey11}>
                        Deal probability
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                          gap: '8px',
                          marginTop: syncedWithCrm ? '6px' : '18px',
                        }}
                      >
                        {localStages?.map((stage, index) => {
                          return (
                            <Select
                              //Editable only if we have a pipeline not synced with CRM
                              disabled={syncedWithCrm}
                              key={stage.id}
                              value={stage.probability ?? 0}
                              onChange={(ev) => {
                                handleChangeProbability(
                                  ev.target.value as number,
                                  index,
                                  localStages,
                                );
                              }}
                              className="select select-small-width"
                              MenuProps={customSelectStyle}
                              displayEmpty
                              sx={{
                                height: syncedWithCrm
                                  ? '42px !important'
                                  : '48px !important',
                                fontSize: '12px',
                                padding: '8px',
                              }}
                            >
                              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]?.map(
                                (probability, index) => {
                                  return (
                                    <MenuItem
                                      value={probability as number}
                                      key={index}
                                    >
                                      {probability}%
                                    </MenuItem>
                                  );
                                },
                              )}
                            </Select>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Typography>No stages found</Typography>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </GeneralWrapper>
  );
};

export default DealsManagement;

import {
  TableContainer,
  Table,
  TableBody,
  CircularProgress,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
  Typography,
  Checkbox,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CustomTableHead from '../tableHead';
import { DataProspect } from '../types';
import { getProspectHeadCells } from '../columns';
import useSearchProspects from 'hooks/services/prospect/useSearchProspects';
import Pagination from '../pagination';
import {
  ProspectSearch,
  ProspectSearchSort,
} from 'crono-fe-common/types/DTO/prospectSearch';
import { Prospect } from 'crono-fe-common/types/prospect';
import useGetExternalProperty from 'hooks/services/externalProperty/useGetExternalProperty';
import { FlexDiv } from 'crono-fe-common/components/Layout/FlexDiv';
import { useAuth } from 'context/auth';
import { Order } from 'crono-fe-common/types/userPreferences';
import usePatchUserPreferences from 'hooks/services/user/usePatchUserPreferences';
import { useFilters } from 'pages/home/context/filters';
import { getError } from 'crono-fe-common/utils';
import { colors } from 'crono-fe-common/theme';
import {
  ProspectAndAccountIds,
  ProspectAndFromContact,
} from 'crono-fe-common/types/DTO/ProspectAndAccountIds';
import { isComplete } from 'crono-fe-common/types/sequenceInstance';
import { TaskTodo } from 'crono-fe-common/types/cronoTaskTodo';
import SequenceInstanceDisplay from 'pages/taskBoard/sequenceInstance';
import RescheduleCalendar from 'pages/taskBoard/rescheduleCalendar';
import usePatchTask from 'hooks/services/task/usePatchTask';
import { useConditionalSnackBar } from 'context/snackbar';
import { useJuneAnalytics } from 'context/june';
import { ReactComponent as NoContactsFoundFilters } from 'assets/images/IllustrationNoContactsFound.svg';
import { ReactComponent as NoResultsFoundCrono } from 'assets/images/IllustractionNoResultsCrono.svg';
import { ReactComponent as NoContacts } from '../../../assets/images/No_contacts_imported.svg';

import ProspectSelectionBottom from './contactTableFooter/prospectSelectionBottom';

import { Constants } from 'crono-fe-common/constants/constants';
import IntegrationType from 'crono-fe-common/types/enums/integrationType';

import { rowPlaceholderFillOpacities } from 'constants/FeConstants';
import ContactRowPlaceholder from '../../../assets/images/Contact-row-placeholder.png';
import StrokeDivider from '../../../assets/images/Stroke-divider.png';
import ContactsIconS from 'crono-fe-common/icons/Icon-Contacts-S';
import IconSearchInCRM from 'crono-fe-common/icons/Icon-Search-In-CRM';
import useSearchProspectsExternal from 'hooks/services/prospect/useSearchProspectExternal';
import { ReactComponent as IllustractionContactsNotFound } from 'assets/images/IllustractionContactNotFound.svg';
import CrmIcon from 'components/CrmIcon';
import ProspectExternalRow from './externalProspectRow';
import { trimObject } from 'crono-fe-common/utils/object';
import ProspectRow from './prospectRow';
import IconUnchecked from 'crono-fe-common/icons/Icon-Unchecked';
import { ReactComponent as CheckedIcon } from 'crono-fe-common/icons/Checked.svg';
import IconIndeterminate from 'crono-fe-common/icons/Icon-Indeterminate';
import { useQueryClient } from 'react-query';
import { ENDPOINTS } from 'config/endpoints';
import { useNavigate } from 'react-router-dom';
import PATH from 'routing/path';
import { TabValue } from 'pages/home';
import { extractDomainFromWebsite } from 'utils/fe-utils';
import { usePollerContext } from 'context/poller';
import EditColumnsDropdown, {
  EditListColumnsType,
} from '../editColumnsDropdown';
import { DashboardTabType } from '../index';

export interface IInformationForSelectedScrapeMissing {
  prospectId: string;
  firstName: string | null;
  lastName: string | null;
  linkedinUrl: string | null;
  domain: string | null;
}
export interface IProps {
  tabState: DashboardTabType;
}

const ContactTable = ({ tabState }: IProps) => {
  // We must use the userPreferences, contained in this object, to obtain the previous settings for the table
  // at user.userPreferences.prospectTableFilters, which is a string containing a json that has to be handled
  // front-end side
  const { user } = useAuth();

  const analytics = useJuneAnalytics();

  // This object is gonna be usefull to be stringyfied each time the patch of the tablePreferences will be called
  const userPreferences: ProspectSearch = JSON.parse(
    user?.userPreferences?.prospectTableFilters || '{}',
  );
  const {
    searchProspectParameters,
    searchLeadParameters,
    setSearchProspectParameters,
    setSearchLeadParameters,
    resetProspectFilters: resetFilters,
    resetProspectFiltersAndOwnedFalse: resetFiltersAndOwner,
    areThereProspectFiltersApplied: areProspectFiltersModified,
    areThereLeadFiltersApplied: areLeadFiltersModified,
    defaultFilters,
    updateUserPreferences,
  } = useFilters();
  const searchParameters =
    tabState === 'Lead' ? searchLeadParameters : searchProspectParameters;

  // If there are already preferences saved inside the userPreferences I set them in the current states
  // This effect runs only when the entire variable user changes, not when one of its property does (no run on each
  // update of sort, sortby and so on)
  useEffect(() => {
    // console.log("UserPreferences: ", userPreferences);
    if (userPreferences.sort) {
      //Based on the userPreferences I set the sort and the sortby
      setOrder(userPreferences.sort.includes('Desc') ? 'desc' : 'asc');
      setOrderBy(
        userPreferences.sort.replace('Desc', '') as keyof DataProspect,
      );
    }
  }, [user]);

  //For a coherent visualization (about the checkbox and the fact that we allow max 50 selected rows at each moment), when the filters change clear the selection
  useEffect(() => {
    clearSelected();
  }, [searchParameters]);

  // Paginate handling
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (tabState === 'Contact') {
      setSearchProspectParameters((prev) => ({ ...prev, offset: itemOffset }));
    } else if (tabState === 'Lead') {
      setSearchLeadParameters((prev) => ({ ...prev, offset: itemOffset }));
    }
    clearSelected();
  }, [itemOffset]);

  //To handle the filters and the sort
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof DataProspect>('Name');
  const onRequestSort = (property: keyof DataProspect) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder: Order = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    // Reset to the first page
    setItemOffset(0);
    sortAPIs(newOrder, property);
  };
  // I get what I need from the customHook for the prospects
  const {
    data: prospects,
    isLoading,
    error,
  } = useSearchProspects({
    ...searchParameters,
    fromContact:
      user?.integrationType === IntegrationType.SALESFORCE
        ? searchParameters.fromContact
        : undefined,
  });

  useEffect(() => {
    if (error && error.response?.status === 400) {
      resetFilters(false, tabState);
    }
  }, [error]);

  const { data: externalProperties } = useGetExternalProperty(
    tabState === 'Lead' ? 'Lead' : 'Prospect',
    true,
    false,
  );

  const { mutate: patchUserPreferences } = usePatchUserPreferences();
  //In this function I call the APIs to change the sort. I have to change the search parameters (to obtain the new
  // order requested) and the userPreferences
  // I also update the user status of the app
  const sortAPIs = (newOrder: Order, property: keyof DataProspect) => {
    // I create the correct format for the sort
    const newSort: ProspectSearchSort = property.concat(
      newOrder === 'asc' ? '' : 'Desc',
    ) as ProspectSearchSort;
    // I update the search parameters
    if (tabState === 'Contact') {
      setSearchProspectParameters((prev) => ({ ...prev, sort: newSort }));
    } else if (tabState === 'Lead') {
      setSearchLeadParameters((prev) => ({ ...prev, sort: newSort }));
    }

    //Reset the selected rows
    clearSelected();

    userPreferences.sort = newSort;
    userPreferences.offset = 0;
    updateUserPreferences(tabState, userPreferences);
  };

  //Selected rows
  const [selected, setSelected] = useState<ProspectAndAccountIds[]>([]);
  const [selectedToAdd, setSelectedToAdd] = useState<ProspectAndAccountIds[]>(
    [],
  );
  const [selectedToPause, setSelectedToPause] = useState<string[]>([]);
  const [selectedToStart, setSelectedToStart] = useState<string[]>([]);
  const [selectedOwned, setSelectedOwned] = useState<ProspectAndAccountIds[]>(
    [],
  );
  const [selectedEmailMissing, setSelectedEmailMissing] = useState<
    IInformationForSelectedScrapeMissing[]
  >([]);
  const [selectedPhoneMissing, setSelectedPhoneMissing] = useState<
    IInformationForSelectedScrapeMissing[]
  >([]);

  const findSelectedIndex = (prospectId: string, accountId: string) => {
    return selected.findIndex(
      (obj) => obj.prospectId === prospectId && obj.accountId === accountId,
    );
  };

  //When prospects change, the situation in the sequenceInstances may have changed. So I recompute
  //the sub select arrays
  useEffect(() => {
    const selectedToPause: string[] = [];
    const selectedToStart: string[] = [];
    const selectedToAdd: ProspectAndAccountIds[] = [];
    const selectedOwned: ProspectAndAccountIds[] = [];
    const selectedEmailMissing: IInformationForSelectedScrapeMissing[] = [];
    const selectedPhoneMissing: IInformationForSelectedScrapeMissing[] = [];
    prospects?.data?.data
      ?.filter((prospect) => prospect.objectId !== undefined)
      .forEach((prospect) => {
        //I do something only if the prospect is in the selected array
        const isSelected = selected.find((ids) => {
          return (
            ids.prospectId === prospect.objectId &&
            ids.accountId === prospect.accountId
          );
        });
        if (isSelected === undefined) return;
        if (prospect.owned) {
          selectedOwned.push({
            prospectId: prospect.objectId,
            accountId: prospect.accountId,
          });
        }
        if (
          prospect.owned &&
          prospect.sequenceInstance &&
          !isComplete(prospect.sequenceInstance)
        ) {
          if (prospect.sequenceInstance.active) {
            selectedToPause.push(prospect.objectId);
          } else {
            selectedToStart.push(prospect.objectId);
            selectedToAdd.push({
              prospectId: prospect.objectId,
              accountId: prospect.accountId,
            });
          }
        } else {
          selectedToAdd.push({
            prospectId: prospect.objectId,
            accountId: prospect.accountId,
          });
        }
        if (prospect.email === null || prospect.email === '') {
          selectedEmailMissing.push({
            prospectId: prospect.objectId,
            firstName: prospect.firstName ?? null,
            lastName: prospect.lastName ?? null,
            domain: extractDomainFromWebsite(prospect.account?.website ?? ''),
            linkedinUrl: prospect.linkedin ?? null,
          });
        }
        if (prospect.phone === null || prospect.phone === '') {
          selectedPhoneMissing.push({
            prospectId: prospect.objectId,
            firstName: prospect.firstName ?? null,
            lastName: prospect.lastName ?? null,
            domain: extractDomainFromWebsite(prospect.account?.website ?? ''),
            linkedinUrl: prospect.linkedin ?? null,
          });
        }
      });

    setSelectedToPause(selectedToPause);
    setSelectedToStart(selectedToStart);
    setSelectedToAdd(selectedToAdd);
    setSelectedOwned(selectedOwned);
    setSelectedEmailMissing(selectedEmailMissing);
    setSelectedPhoneMissing(selectedPhoneMissing);
  }, [prospects]);

  const handleSelected = (
    prospectId: string,
    accountId: string,
    additionalInformation: IInformationForSelectedScrapeMissing,
    inSequence = false,
    sequenceStopped = false,
  ) => {
    const newObj = { prospectId, accountId };
    const newSelected =
      selected.filter((ids) => {
        return ids.prospectId !== prospectId || ids.accountId !== accountId;
      }) ?? [];
    const newSelectedToPause =
      selectedToPause.filter((id) => {
        return id !== prospectId;
      }) ?? [];
    const newSelectedToStart =
      selectedToStart.filter((id) => {
        return id !== prospectId;
      }) ?? [];
    const newSelectedToAdd =
      selectedToAdd.filter((ids) => {
        return ids.prospectId !== prospectId || ids.accountId !== accountId;
      }) ?? [];
    const newSelectedOwned =
      selectedOwned.filter((ids) => {
        return ids.prospectId !== prospectId || ids.accountId !== accountId;
      }) ?? [];

    const newSelectedEmailMissing = selectedEmailMissing.filter(
      (ids) => ids.prospectId !== prospectId,
    );
    const newSelectedPhoneMissing = selectedPhoneMissing.filter(
      (ids) => ids.prospectId !== prospectId,
    );

    if (newSelected.length === selected.length) {
      if (inSequence) {
        newSelectedToPause.push(prospectId);
      } else if (sequenceStopped) {
        newSelectedToStart.push(prospectId);
        newSelectedToAdd.push(newObj);
      } else {
        newSelectedToAdd.push(newObj);
      }
      if (
        prospects?.data?.data.find(
          (prospect) => prospect.objectId === prospectId,
        )?.owned
      ) {
        newSelectedOwned.push(newObj);
      }
      const prospect = prospects?.data?.data.find(
        (prospect) => prospect.objectId === prospectId,
      );

      if (prospect?.email === null || prospect?.email === '') {
        newSelectedEmailMissing.push(additionalInformation);
      }
      if (prospect?.phone === null || prospect?.phone === '') {
        newSelectedPhoneMissing.push(additionalInformation);
      }
      newSelected.push(newObj);
    }
    setSelected(newSelected);
    setSelectedToPause(newSelectedToPause);
    setSelectedToStart(newSelectedToStart);
    setSelectedToAdd(newSelectedToAdd);
    setSelectedOwned(newSelectedOwned);
    setSelectedEmailMissing(newSelectedEmailMissing);
    setSelectedPhoneMissing(newSelectedPhoneMissing);
  };

  const clearSelected = () => {
    setSelected([]);
    setSelectedToPause([]);
    setSelectedToAdd([]);
    setSelectedToStart([]);
    setSelectedOwned([]);
    setSelectedEmailMissing([]);
    setSelectedPhoneMissing([]);
    setSelectedExternal([]);
  };

  //SEQUENCE INSTANCE VIEW AND MODIFY FORM PROGRESS -------------

  const {
    mutate: patchTask,
    isLoading: patchLoading,
    isSuccess: patchSuccess,
    error: errorPatchTask,
  } = usePatchTask();

  const [
    selectedSequenceInstanceToDisplay,
    setSelectedSequenceInstanceToDisplay,
  ] = useState<{ sequenceInstanceId: number; prospect: Prospect } | null>(null);

  const handleShowSequenceInstance = (
    sequenceInstanceId: number,
    prospect: Prospect,
  ) => {
    setSelectedSequenceInstanceToDisplay({ sequenceInstanceId, prospect });
  };
  const [showRescheduleTask, setShowRescheduleTask] = useState<{
    index: number;
    initialDate: Date;
  } | null>(null);

  useEffect(() => {
    if (patchSuccess) setShowRescheduleTask(null);
  }, [patchSuccess]);

  const handleOpenDateCalendar = (task: TaskTodo) => {
    //handleChangeTaskDate(index);
    setShowRescheduleTask({ index: task.id, initialDate: task.activityDate });
  };
  const handleChangeTaskDate = (id: number, newDate: Date) => {
    patchTask({
      id: id,
      activityDate: newDate,
    });

    if (analytics) {
      analytics.track('patch-task', {
        type: 'reschedule',
      });
    }
  };
  const handleChangeDate = (newDate: Date) => {
    handleChangeTaskDate(showRescheduleTask!.index, newDate);
  };

  const handleClickSelectAll = () => {
    if (selected.length === prospects?.data?.data.length) {
      clearSelected();
    } else {
      const selectedToPause: string[] = [];
      const selectedToStart: string[] = [];
      const selectedToAdd: ProspectAndAccountIds[] = [];
      const selectedOwned: ProspectAndAccountIds[] = [];
      const selectedEmailMissing: IInformationForSelectedScrapeMissing[] = [];

      const selectedPhoneMissing: IInformationForSelectedScrapeMissing[] = [];
      setSelected(
        (prospects?.data?.data
          ?.filter((prospect) => prospect.objectId !== undefined)
          .map((prospect) => {
            if (prospect.owned) {
              selectedOwned.push({
                prospectId: prospect.objectId,
                accountId: prospect.accountId,
              });
            }
            if (
              prospect.owned &&
              prospect.sequenceInstance &&
              !isComplete(prospect.sequenceInstance)
            ) {
              if (prospect.sequenceInstance.active) {
                selectedToPause.push(prospect.objectId);
              } else {
                selectedToStart.push(prospect.objectId);
                selectedToAdd.push({
                  prospectId: prospect.objectId,
                  accountId: prospect.accountId,
                });
              }
            } else {
              selectedToAdd.push({
                prospectId: prospect.objectId,
                accountId: prospect.accountId,
              });
            }
            if (prospect.email === null || prospect.email === '') {
              selectedEmailMissing.push({
                prospectId: prospect.objectId,
                firstName: prospect.firstName ?? null,
                lastName: prospect.lastName ?? null,
                domain: extractDomainFromWebsite(
                  prospect.account?.website ?? '',
                ),
                linkedinUrl: prospect.linkedin ?? null,
              });
            }
            if (prospect.phone === null || prospect.phone === '') {
              selectedPhoneMissing.push({
                prospectId: prospect.objectId,
                firstName: prospect.firstName ?? null,
                lastName: prospect.lastName ?? null,
                domain: extractDomainFromWebsite(
                  prospect.account?.website ?? '',
                ),
                linkedinUrl: prospect.linkedin ?? null,
              });
            }
            return {
              prospectId: prospect.objectId,
              accountId: prospect.accountId,
            };
          }) ?? []) as ProspectAndAccountIds[],
      );
      setSelectedToPause(selectedToPause);
      setSelectedToStart(selectedToStart);
      setSelectedToAdd(selectedToAdd);
      setSelectedOwned(selectedOwned);
      setSelectedEmailMissing(selectedEmailMissing);
      setSelectedPhoneMissing(selectedPhoneMissing);
    }
  };

  const rowPlaceholder = rowPlaceholderFillOpacities.map((el, index) => {
    return (
      <div key={index} className="empty-state-placeholder">
        <img
          src={StrokeDivider}
          alt="Grey horizontal stroke"
          style={{ opacity: el, width: '100%' }}
        />
        <img
          src={ContactRowPlaceholder}
          alt="Empty contact placeholder"
          style={{ opacity: el, width: '100%' }}
        />
      </div>
    );
  });

  const navigate = useNavigate();

  const handleOpenSearch = () => {
    navigate(PATH.HOME, {
      state: {
        initialTab: TabValue.FIND_NEW,
        initialTabFindNew: 'contact',
      },
    });
  };

  const onlyMineShown = searchParameters.userId === user?.id;

  const {
    mutate: searchProspectExtrnal,
    isLoading: searchingProspectExternal,
    data: dataProspectExternal,
    reset: resetDataProspectExternal,
  } = useSearchProspectsExternal();

  const [selectedExternal, setSelectedExternal] = useState<
    ProspectAndFromContact[]
  >([]);
  //When the name field changes I enable again the search in CRM
  useEffect(() => {
    if (dataProspectExternal) {
      resetDataProspectExternal();
      setSelectedExternal([]);
    }
  }, [searchParameters.name]);

  const [errorSearchExternal, setErrorSearchExternal] = useState<string | null>(
    null,
  );

  const searchInCrm = () => {
    searchProspectExtrnal(
      trimObject({
        name: searchParameters.name,
        getAccountInCrm: true,
        fromContact:
          user?.integrationType === IntegrationType.SALESFORCE
            ? tabState === 'Contact'
            : undefined,
      }),
    );
  };

  const handleSearchInAllContacts = useCallback(() => {
    if (onlyMineShown) {
      if (tabState === 'Contact') {
        setSearchProspectParameters((prev) => ({ ...prev, userId: null }));
      } else if (tabState === 'Lead') {
        setSearchLeadParameters((prev) => ({ ...prev, userId: null }));
      }
    } else {
      if (!searchParameters.name) {
        setErrorSearchExternal('Please enter a name to search in all contacts');
        setTimeout(() => {
          setErrorSearchExternal(null);
        }, 3000);
        return;
      }
      searchInCrm();
    }
  }, [onlyMineShown, searchParameters.name]);

  const handleSelectedExternal = (prospectId: string, fromContact: boolean) => {
    const newSelected =
      selectedExternal.findIndex(
        (selected) => selected.objectId === prospectId,
      ) >= 0
        ? selectedExternal.filter((s) => s.objectId !== prospectId)
        : [...selectedExternal, { objectId: prospectId, fromContact }];
    setSelectedExternal(newSelected);
  };

  const handleClickSelectAllExternal = () => {
    if (selectedExternal.length === availableToSync.length) {
      setSelectedExternal([]);
    } else {
      setSelectedExternal(
        availableToSync.map((prospect) => ({
          objectId: prospect.objectId,
          fromContact: prospect.fromContact,
        })),
      );
    }
  };

  //This state is used to store the prospects that are being synced, to show the syncing badge while waiting for the BE to import them
  //This state is refreshed when changing page
  const [idsSyncing, setIdsSyncing] = useState<string[]>([]);

  const availableToSync = useMemo(() => {
    return (
      dataProspectExternal?.data?.data.filter(
        (selected) => !idsSyncing.includes(selected.objectId),
      ) ?? []
    );
  }, [dataProspectExternal, idsSyncing]);

  //Remove the already synced prospect from the selected
  const checkSelectedExternal = (prospectId: string) => {
    const newSelected = [...selectedExternal].filter(
      (selected) => selected.objectId !== prospectId,
    );
    return setSelectedExternal(newSelected);
  };

  const queryClient = useQueryClient();

  const handleSuccessfullImport = (accountId: string) => {
    setIdsSyncing((prev) => prev.filter((id) => id !== accountId));
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey.toString().startsWith(ENDPOINTS.prospect.search.main),
    });
    searchInCrm();
  };

  const handleCompleteMultipleSync = (ids: ProspectAndFromContact[]) => {
    const idsProspect = ids.map((id) => id.objectId);
    setIdsSyncing((prev) => prev.filter((id) => !idsProspect.includes(id)));
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey.toString().startsWith(ENDPOINTS.prospect.search.main),
    });
    searchInCrm();
  };

  //This call is used to know if there is at least one contact in Crono for the empty state
  const { data: atLeastOneContact } = useSearchProspects({
    ...(defaultFilters as ProspectSearch),
    userId: null,
    limit: 1,
    name: null,
  });

  useConditionalSnackBar([
    {
      condition: !!errorPatchTask,
      message: getError(errorPatchTask) ?? 'Error updating task',
      severity: 'error',
    },
    {
      condition: !!errorSearchExternal,
      message: errorSearchExternal ?? 'Error searching in all contacts',
      severity: 'info',
    },
  ]);

  //To enable or disable the checkbox if the other type of import is selected
  const enableSelectionExternal = selected.length === 0;

  const enableSelectionInternal = selectedExternal.length === 0;

  //States to allow only one search of phone/email at a time
  const [alreadySearchingEmail, setAlreadySearchingEmail] = useState(false);
  const [alreadySearchingPhone, setAlreadySearchingPhone] = useState(false);

  //These 2 states are used to show the loading spinner while searching for email/phone. If the search is started from the footer we can check if the id of the prospect is in the selected arraies for email/phone
  //and in case we show the spinner
  const [searchingEmailStartedIds, setSearchingEmailStartedIds] = useState<
    string[] | null
  >(null);
  const [searchingPhoneStartedIds, setSearchingPhoneStartedIds] = useState<
    string[] | null
  >(null);

  const { pollingEnrichInBulk } = usePollerContext();

  //When the polling is done we can clear the currently searched
  useEffect(() => {
    if (!pollingEnrichInBulk) {
      setSearchingEmailStartedIds(null);
      setSearchingPhoneStartedIds(null);
    }
  }, [pollingEnrichInBulk]);

  const [showEditColumns, setShowEditColumns] = useState<boolean>(false);

  return (
    <>
      {selectedSequenceInstanceToDisplay && (
        <SequenceInstanceDisplay
          sequenceInstanceId={
            selectedSequenceInstanceToDisplay.sequenceInstanceId
          }
          prospect={selectedSequenceInstanceToDisplay.prospect}
          onClose={() => setSelectedSequenceInstanceToDisplay(null)}
          handleOpenDateCalendar={handleOpenDateCalendar}
        />
      )}
      {showRescheduleTask && (
        <RescheduleCalendar
          isRescheduleLoading={patchLoading}
          close={() => setShowRescheduleTask(null)}
          handleChangeDate={handleChangeDate}
          initialDate={showRescheduleTask.initialDate}
        />
      )}
      {atLeastOneContact?.data?.data.length === 0 ? (
        //No contacts for this user, initial empty state
        <>
          <FlexDiv
            direction="column"
            position="relative"
            justifyContent="start"
            style={{ overflow: 'hidden', padding: '0px 20px 20px 20px' }}
          >
            {rowPlaceholder}
            <FlexDiv
              className="table-container"
              position="absolute"
              style={{ paddingBottom: '100px' }}
            >
              <div className="error-container">
                <div>
                  <NoContacts />
                </div>
                <Typography
                  fontSize={'16px'}
                  fontWeight={600}
                  lineHeight={'30px'}
                  textAlign={'center'}
                >
                  You don't have any contacts yet
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={500}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  align="center"
                >
                  Click{' '}
                  <span className="find-new" onClick={handleOpenSearch}>
                    Find new
                  </span>{' '}
                  button to add contacts or integrate them from your CRM by
                  going to the settings page.
                </Typography>
              </div>
            </FlexDiv>
          </FlexDiv>
        </>
      ) : prospects?.data?.data.length === 0 &&
        (dataProspectExternal?.data?.data.length ?? 0) === 0 ? (
        (areProspectFiltersModified && tabState === 'Contact') ||
        (areLeadFiltersModified && tabState === 'Lead') ? (
          <FlexDiv
            direction="column"
            position="relative"
            justifyContent="start"
            style={{ overflow: 'hidden', padding: '0px 20px 20px 20px' }}
          >
            {rowPlaceholder}
            <FlexDiv
              className="table-container"
              position="absolute"
              style={{ paddingBottom: '100px' }}
            >
              <div className="error-container">
                <div>
                  <NoContactsFoundFilters />
                </div>
                <Typography
                  fontSize={'16px'}
                  fontWeight={600}
                  lineHeight={'30px'}
                  textAlign={'center'}
                >
                  No {tabState === 'Contact' ? 'contacts' : 'leads'} found with
                  these filters
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontWeight={500}
                  lineHeight={'24px'}
                  color={colors.grey11}
                  align="center"
                  whiteSpace={'pre-line'}
                >
                  Try to change the applied filters to see different <br />
                  {tabState === 'Contact' ? 'contacts' : 'leads'} or{' '}
                  <span
                    className="find-new"
                    onClick={() => resetFiltersAndOwner(false, tabState)}
                  >
                    Click here
                  </span>{' '}
                  to search among all <br />{' '}
                  {tabState === 'Contact' ? 'contacts' : 'leads'} and get more
                  results
                </Typography>
              </div>
            </FlexDiv>
          </FlexDiv>
        ) : (
          //If I have not search external I show the message to search for them
          <FlexDiv
            direction="column"
            position="relative"
            justifyContent="start"
            style={{ overflow: 'hidden', padding: '0px 20px 20px 20px' }}
          >
            {rowPlaceholder}
            <FlexDiv
              className="table-container"
              position="absolute"
              style={{ paddingBottom: '100px' }}
            >
              <div className="error-container">
                {dataProspectExternal === undefined ? (
                  <>
                    <div>
                      <NoResultsFoundCrono />
                    </div>
                    <Typography
                      fontSize={'16px'}
                      fontWeight={600}
                      lineHeight={'24px'}
                      textAlign={'center'}
                    >
                      No results found with this request in Crono
                    </Typography>
                    {onlyMineShown ? (
                      <div className="search-in-crm-row">
                        <div
                          className="search-in-crm-row-clickable"
                          onClick={handleSearchInAllContacts}
                        >
                          <ContactsIconS
                            color={colors.darkGreen}
                            className="icon"
                          />
                          <Typography
                            fontSize={'14px'}
                            lineHeight={'18px'}
                            fontWeight={500}
                            color={colors.darkGreen}
                          >
                            Click here to search in all contacts
                          </Typography>
                        </div>
                      </div>
                    ) : user?.integrationType !== IntegrationType.CRONO &&
                      (searchParameters.name?.length ?? 0) > 2 ? (
                      <div className="search-in-crm-row">
                        <div
                          className={`search-in-crm-row-clickable ${
                            searchingProspectExternal
                              ? 'search-in-crm-row-clickable-disabled'
                              : ''
                          }`}
                          onClick={() => {
                            if (!searchingProspectExternal)
                              handleSearchInAllContacts();
                          }}
                        >
                          <IconSearchInCRM
                            color={colors.darkGreen}
                            className="icon"
                          />
                          <Typography
                            fontSize={'14px'}
                            lineHeight={'18px'}
                            fontWeight={500}
                            color={colors.darkGreen}
                          >
                            Search in CRM for more results
                          </Typography>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <IllustractionContactsNotFound />
                    <Typography
                      fontSize={'16px'}
                      lineHeight={'24px'}
                      fontWeight={600}
                      whiteSpace={'pre-line'}
                      textAlign={'center'}
                    >
                      Sorry, we didn't find any results
                      <br /> for this request in you CRM
                    </Typography>
                  </>
                )}
              </div>
            </FlexDiv>
          </FlexDiv>
        )
      ) : (
        <>
          {showEditColumns && (
            <div
              style={{
                position: 'absolute',
                zIndex: 100,
                top: '123px',
                right: '12px',
                maxHeight: 'calc(100% - 135px)',
                display: 'flex',
              }}
            >
              <EditColumnsDropdown
                editColumnsType={EditListColumnsType.Contacts}
                onClose={() => setShowEditColumns(false)}
                onApply={() => setShowEditColumns(false)}
                tabState={tabState}
              />
            </div>
          )}
          <TableContainer className="table-container">
            <Table stickyHeader>
              <CustomTableHead
                toggleEditDropdown={() => setShowEditColumns(!showEditColumns)}
                order={order}
                orderBy={orderBy}
                onRequestSort={onRequestSort}
                headCells={getProspectHeadCells(
                  externalProperties?.data?.data,
                  user?.userPreferences?.listProspectColumns || null,
                )}
                onlyOwned={user ? searchParameters.userId === user?.id : false}
                showSelectAll={true}
                selectedStatus={
                  !prospects?.data?.data || selected.length === 0
                    ? 0
                    : selected.length !== prospects?.data?.data.length
                      ? 1
                      : 2
                }
                disableCheckbox={!enableSelectionInternal}
                clickSelectAll={handleClickSelectAll}
              />
              {isLoading ? (
                <FlexDiv height="80%" style={{ position: 'fixed' }}>
                  <CircularProgress />
                </FlexDiv>
              ) : (
                <TableBody style={{ width: '100%' }}>
                  {prospects?.data?.data.map((prospect: Prospect) => {
                    return (
                      <ProspectRow
                        prospect={prospect}
                        externalProperties={externalProperties?.data?.data}
                        key={prospect.id}
                        handleSelected={handleSelected}
                        handleShowSequenceInstance={handleShowSequenceInstance}
                        selected={
                          findSelectedIndex(
                            prospect.objectId,
                            prospect.accountId,
                          ) >= 0
                        }
                        onlyOwned={
                          user ? searchParameters.userId === user.id : false
                        }
                        disableCheckbox={!enableSelectionInternal}
                        alreadySearchingEmail={alreadySearchingEmail}
                        setAlreadySearchingEmail={setAlreadySearchingEmail}
                        alreadySearchingPhone={alreadySearchingPhone}
                        setAlreadySearchingPhone={setAlreadySearchingPhone}
                        enrichingEmailInProgress={
                          (pollingEnrichInBulk &&
                            searchingEmailStartedIds &&
                            searchingEmailStartedIds.findIndex(
                              (el) => el === prospect.objectId,
                            ) >= 0) ??
                          false
                        }
                        enrichingPhoneInProgress={
                          (pollingEnrichInBulk &&
                            searchingPhoneStartedIds &&
                            searchingPhoneStartedIds?.findIndex(
                              (el) => el === prospect.objectId,
                            ) >= 0) ??
                          false
                        }
                      />
                    );
                  })}
                </TableBody>
              )}
            </Table>
            {!isLoading &&
              (dataProspectExternal === undefined ? (
                //We shown the search in CRM only if the name is at least 3 characters long
                (searchParameters.name?.length ?? 0) > 2 ? (
                  onlyMineShown ? (
                    <div className="search-in-crm-row">
                      <div
                        className="search-in-crm-row-clickable"
                        onClick={handleSearchInAllContacts}
                      >
                        <ContactsIconS
                          color={colors.darkGreen}
                          className="icon"
                        />
                        <Typography
                          fontSize={14}
                          lineHeight={'18px'}
                          fontWeight={500}
                          color={colors.darkGreen}
                        >
                          Click here to search in all contacts
                        </Typography>
                      </div>
                    </div>
                  ) : user?.integrationType !== IntegrationType.CRONO ? (
                    <div className="search-in-crm-row">
                      <div
                        className={`search-in-crm-row-clickable ${
                          searchingProspectExternal
                            ? 'search-in-crm-row-clickable-disabled'
                            : ''
                        }`}
                        onClick={() => {
                          if (!searchingProspectExternal)
                            handleSearchInAllContacts();
                        }}
                      >
                        <IconSearchInCRM
                          color={colors.darkGreen}
                          className="icon"
                        />
                        <Typography
                          fontSize={14}
                          lineHeight={'18px'}
                          fontWeight={500}
                          color={colors.darkGreen}
                        >
                          Search in CRM for more results
                        </Typography>
                      </div>
                    </div>
                  ) : null
                ) : null
              ) : dataProspectExternal.data?.data.length === 0 ? (
                <div className="search-in-crm-row-no-results">
                  <IllustractionContactsNotFound />
                  <Typography
                    fontSize={16}
                    lineHeight={'24px'}
                    fontWeight={600}
                    whiteSpace={'pre-line'}
                    textAlign={'center'}
                  >
                    Sorry, we didn't find any results
                    <br /> for this request in you CRM
                  </Typography>
                </div>
              ) : (
                <div className="search-in-crm-results">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      padding: '6px 16px 6px 4px',
                      gap: 4,
                      borderBlock: `1px solid ${colors.grey4}`,
                      width: '100%',
                    }}
                  >
                    <label>
                      <Checkbox
                        checked={
                          !(
                            !availableToSync.length ||
                            selectedExternal.length === 0
                          ) &&
                          selectedExternal.length === availableToSync.length
                        }
                        indeterminate={
                          !(
                            !availableToSync.length ||
                            selectedExternal.length === 0
                          ) &&
                          selectedExternal.length !== availableToSync.length
                        }
                        disabled={!enableSelectionExternal}
                        onClick={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          handleClickSelectAllExternal();
                        }}
                        icon={
                          <IconUnchecked
                            color={
                              !enableSelectionExternal
                                ? colors.grey4
                                : colors.grey3
                            }
                          />
                        }
                        checkedIcon={<CheckedIcon />}
                        indeterminateIcon={
                          <IconIndeterminate color={colors.mainDark} />
                        }
                        color="secondary"
                        sx={{
                          padding: 'inherit',
                          margin: 'inherit',
                          '&:hover': {
                            backgroundColor: colors.grey4,
                          },
                          '&.Mui-checked': {
                            '&:hover': {
                              opacity: 0.7,
                            },
                          },
                          marginRight: 1,
                          marginLeft: 1,
                          // "&.MuiCheckbox-root": {
                          //   color: colors.grey33 + " !important",
                          // },
                        }}
                      />
                    </label>
                    <CrmIcon color={colors.grey11} />
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      lineHeight={'16px'}
                      color={colors.grey11}
                    >
                      Results in CRM (
                      {dataProspectExternal.data?.data.length ?? 0})
                    </Typography>
                  </div>
                  {dataProspectExternal.data?.data.map(
                    (prospectExternal: Prospect, index: number) => {
                      return (
                        <ProspectExternalRow
                          prospect={prospectExternal}
                          key={index}
                          selected={
                            selectedExternal.findIndex(
                              (selected) =>
                                selected.objectId === prospectExternal.objectId,
                            ) >= 0
                          }
                          handleSelectedExternal={handleSelectedExternal}
                          disableCheckbox={!enableSelectionExternal}
                          onAdd={() => {
                            setIdsSyncing((prev) => [
                              ...prev,
                              prospectExternal.objectId,
                            ]);
                            checkSelectedExternal(prospectExternal.objectId);
                          }}
                          syncing={idsSyncing.includes(
                            prospectExternal.objectId,
                          )}
                          onErrorImporting={() => {
                            setIdsSyncing((prev) =>
                              prev.filter(
                                (id) => id !== prospectExternal.objectId,
                              ),
                            );
                          }}
                          onSuccessImporting={() =>
                            handleSuccessfullImport(prospectExternal.objectId)
                          }
                        />
                      );
                    },
                  )}
                </div>
              ))}
          </TableContainer>
          <Pagination
            total={prospects?.data?.total}
            count={prospects?.data?.count}
            itemOffset={searchParameters.offset ?? 0}
            setItemOffset={setItemOffset}
            limit={Constants.defaultLimit}
            selectionComponent={
              <ProspectSelectionBottom
                selected={selected}
                tabState={tabState}
                clearSelected={clearSelected}
                selectedToPause={selectedToPause}
                selectedToStart={selectedToStart}
                selectedToAdd={selectedToAdd}
                selectedOwned={selectedOwned}
                selectedExternal={selectedExternal}
                selectedEmailMissing={selectedEmailMissing}
                selectedPhoneMissing={selectedPhoneMissing}
                setSearchingEmailStarted={setSearchingEmailStartedIds}
                setSearchingPhoneStarted={setSearchingPhoneStartedIds}
                onExternalAdd={() => {
                  setIdsSyncing((prev) => [
                    ...prev,
                    ...selectedExternal.map((selected) => selected.objectId),
                  ]);
                  setSelectedExternal([]);
                }}
                onCompleteSync={handleCompleteMultipleSync}
              />
            }
            style={{
              height: 56,
            }}
          />
        </>
      )}
    </>
  );
};

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    arrow={props.arrow ? props.arrow : false}
    {...props}
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    //In order to align it with the button
    marginLeft: 250,
    maxWidth: 350,
    border: `1px solid ${colors.grey4}`,
    borderRadius: 16,
    padding: 0,
    WebkitBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    MozBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    OBoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
    BoxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
  },
}));

export default ContactTable;

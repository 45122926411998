import styled from '@emotion/styled';
import { Button, IconButton } from '@mui/material';
import { colors } from 'crono-fe-common/theme';

export const CronoButton = styled(Button)`
  && {
    background-color: ${colors.primary} !important;
    text-transform: capitalize !important;
    color: ${colors.white} !important;
  }
`;

export const ErrorButton = styled(Button)`
  && {
    background-color: ${colors.error} !important;
    text-transform: capitalize !important;
    color: ${colors.white} !important;
  }
`;

export const RemoveButton = styled(Button)`
  && {
    background-color: ${colors.inactiveLight} !important;
    text-transform: unset !important;
    color: ${colors.inactive} !important;
  }
`;

export const SuccessButton = styled(Button)`
  && {
    background-color: ${colors.success} !important;
    text-transform: capitalize !important;
    color: ${colors.white} !important;
  }
`;

export const MainPrimaryButton = styled(Button)<{ disabled?: boolean | null }>`
  && {
    background-color: ${(props) =>
      props.disabled ? colors.grey11 : colors.primary} !important;
    color: ${colors.white} !important;
    border-radius: 9999px;
    margin: 0px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-transform: unset !important;
    padding-inline: 16px;
    padding-block: 12px;
  }
`;

export const AddInputButton = styled(Button)<{
  disabled?: boolean | null;
}>`
  && {
    background-color: ${colors.primaryLight} !important;
    color: ${colors.darkGreen} !important;
    border-radius: 8px;
    width: max-content;
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-transform: unset !important;
    padding: 12px;
    &:hover {
      background-color: ${colors.primaryLightHover} !important;
    }
  }
`;

export const ChipNearNameButton = styled(Button)`
  && {
    // background-color: ${colors.primary} !important;
    // color: ${colors.white} !important;
    gap: 4px !important;
    border-radius: 9999px;
    margin: 0px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-transform: unset !important;
    padding-inline: 8px;
    padding-block: 6px;
    & > span {
      margin-inline: 0px !important;
      & > svg {
        font-size: 16px !important;
      }
    }
  }
`;

export const MainPrimarySmallButton = styled(Button)<{
  disabled?: boolean | null;
  height?: string;
}>`
  && {
    ${({ height }) => height && `height: ${height};`}
    background-color: ${(props) =>
      props.disabled ? colors.grey11 : colors.primary} !important;
    color: ${colors.white} !important;
    border-radius: 9999px;
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-transform: unset !important;
    padding-inline: 16px;
    padding-block: 7px;
    &:hover {
      background-color: ${colors.primaryDark} !important;
    }
  }
`;

export const MainLightPrimarySmallButton = styled(Button)`
  && {
    background-color: ${colors.primaryLightHover} !important;
    color: ${colors.primary} !important;
    border-radius: 9999px;
    margin: 0px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-transform: unset !important;
    padding-inline: 16px;
    padding-block: 7px;
    &:hover {
      background-color: ${colors.primaryLightHoverHover} !important;
    }
  }
`;

export const MainButton = styled(Button)<{
  disabled?: boolean | null;
  width?: string;
  height?: string;
  margin?: string;
  fontWeight?: string;
  fontSize?: string;
}>`
  && {
    background-color: ${(props) =>
      props.disabled ? colors.grey11 : colors.mainDark};
    color: ${colors.white} !important;
    border-radius: 9999px;
    margin: ${(props) => props.margin || '6px'};
    font-size: ${(props) => props.fontSize || '16px'};
    font-weight: ${(props) => props.fontWeight || '500'};
    line-height: 24px;
    ${(props) => (props.width ? { width: props.width } : {})};
    height: ${(props) => props.height || '48px'};
    text-transform: unset !important;
    padding-inline: 20px;
    &:hover {
      background-color: ${colors.black}cc;
    }
  }
`;

export const GreyButton = styled(Button)<{ disabled?: boolean | null }>`
  && {
    text-transform: unset !important;
    background: ${colors.grey4};
    color: ${colors.black};
    font-weight: 600;
    font-size: 14px;
    padding: 13px 17px;
    border-radius: 8px;
    width: 200px;
    height: 50px;
    line-height: 1px;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  }
  &:hover {
    background: ${colors.greyButtonHover} !important;
  }
`;

export const CancelButton = styled(Button)<{
  fontWeight?: string;
  width?: string;
}>`
  && {
    background: ${colors.white} !important;
    color: ${colors.black} !important;
    border-radius: 9999px;
    margin: 6px;
    font-size: 16px;
    font-weight: ${(props) => props.fontWeight || '500'};
    line-height: 24px;
    height: 48px;
    ${(props) => (props.width ? { width: props.width } : {})};
    text-transform: unset !important;
    border: 2px solid ${colors.grey4};
    padding-inline: 20px;
  }
  &&:hover {
    background: ${colors.grey4} !important;
  }
`;

export const CancelSmallButton = styled(Button)<{ width?: string }>`
  && {
    background: ${colors.white} !important;
    color: ${colors.black} !important;
    border-radius: 9999px;
    margin: 0px;
    font-weight: 500;
    text-transform: unset !important;
    border: 2px solid ${colors.grey4};
    ${(props) => (props.width ? { width: props.width } : {})};

    font-size: 14px;
    line-height: 18px;
    height: 32px;
    min-width: 83px;
  }
  &&:hover {
    background: ${colors.grey4} !important;
  }
`;

export const CloseButton = styled(IconButton)`
  && {
    background: ${colors.grey6} !important;
    color: ${colors.grey11} !important;
    border-radius: 10px;
  }
`;

export const CloseButtonDarker = styled(IconButton)`
  && {
    background: ${colors.greyBackgroundScheduled} !important;
    color: ${colors.grey11} !important;
    border-radius: 10px;
  }
`;

export const TabSelectionButton = styled(Button)<{ selected: boolean }>`
  && {
    background: ${(props) =>
      props.selected ? colors.primary : colors.primaryLight};
    border-radius: 8px !important;
    padding: 8px 14px;
    text-transform: unset !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    height: 40px;
    color: ${(props) =>
      props.selected ? colors.white : colors.black} !important;
    &:hover {
      background: ${(props) =>
        props.selected ? colors.primaryDark : colors.primaryLightHover};
    }
    z-index: ${(props) => (props.selected ? 10 : 9)};
  }
`;

export const TaskTabButton = styled(Button)<{ selected: boolean }>`
  && {
    color: ${(props) => (props.selected ? colors.black : colors.grey11)};
    border-bottom: ${(props) =>
      props.selected ? `2px solid ${colors.primary}` : ''};
    background: transparent;
    border-radius: 0px !important;
    text-transform: unset !important;
    line-height: 22px;
    padding: 8px 0px;
  }
`;

export const RedButton = styled(Button)<{ disabled?: boolean | null }>`
  && {
    background-color: ${(props) =>
      props.disabled ? colors.inactiveLight : colors.inactive};
    color: ${colors.white} !important;
    border-radius: 9999px;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    height: 40px;
    text-transform: unset !important;
    padding-inline: 20px;
    &:hover {
      background-color: ${colors.inactiveHover};
    }
  }
`;

import React, { useEffect, useMemo, useState } from 'react';
import {
  CronoExtensionStatus,
  useGetExtensionStatus,
} from 'hooks/services/crono-extension/useGetExtensionStatus';
import { ExtensionStatusBannerWrapper } from 'pages/dashboard/ExtensionStatusBanner/style';
import { Typography } from '@mui/material';
import {
  CancelButton,
  MainButton,
} from 'crono-fe-common/components/CronoButton';
import PATH from 'routing/path';
import { SettingsTabs } from 'pages/settings';
import { useNavigate } from 'react-router-dom';
import CronoExtensionWarning from 'assets/images/crono_extension_warning.svg';
import SalesNavWarning from 'crono-fe-common/icons/WarningSalesNav.svg';
import { useAuth } from 'context/auth';
import useGetExtensionVersion from 'hooks/services/crono-extension/useGetExtensionVersion';
import {
  useBackgroundReloadExtension,
  useRefreshCurrentSalesNavCookie,
} from 'crono-fe-common/hooks/crono-extension/gateway';
import usePatchUserOnboardingIntegrations from 'hooks/services/user/usePatchUserOnboardingIntegrations';
import { OnboardingFeatures } from 'context/onboardingFeatures';
import { NO_SALES_NAV_CHECK_FROM_USER } from 'config/localStorage';

const ExtensionStatusBanner = () => {
  const { user, hasSalesNav, setHasSalesNavCookie } = useAuth();
  const { data: status, isLoading } = useGetExtensionStatus();
  const { data: extensionVersion, refetch } = useGetExtensionVersion();
  const { call: reloadExtension } = useBackgroundReloadExtension();
  const [reloadingExtension, setReloadingExtension] = useState(false);

  const extensionOutdated = extensionVersion?.isUpToDate === false;
  const [message, setMessage] = useState<string | null>(null);

  const [noSalesNavLicense, setNoSalesNavLicense] = useState(false);

  useEffect(() => {
    setNoSalesNavLicense(!!localStorage.getItem(NO_SALES_NAV_CHECK_FROM_USER));
  }, []);

  useEffect(() => {
    if (isLoading || !status) {
      return;
    } else if (!user?.otherSettings?.hasLinkedin) {
      setMessage(null);
      return;
    }
    const extensionStatus = status.status;
    setMessage(
      extensionOutdated || reloadingExtension
        ? 'Your extension is outdated, please update it now to the latest version.'
        : extensionStatus === CronoExtensionStatus.NOT_CHROME_BASED_BROWSER
          ? 'Your browser is currently not supported by the Crono Chrome extension'
          : extensionStatus === CronoExtensionStatus.NOT_INSTALLED
            ? 'Crono Chrome extension is not enabled'
            : extensionStatus ===
                CronoExtensionStatus.INSTALLED_WITHOUT_LINKEDIN_COOKIE
              ? 'Crono Chrome extension is enabled. Please open Linkedin and log in to complete the integration'
              : extensionStatus ===
                  CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE //If we need to suggest the user to open salesNav we show a different message
                ? !status.hasSalesNavCookie && hasSalesNav && !noSalesNavLicense
                  ? 'A premium LinkedIn license was detected. Can you confirm if you have a LinkedIn SalesNavigator license?'
                  : null
                : null,
    );
  }, [
    status,
    isLoading,
    user,
    hasSalesNav,
    extensionOutdated,
    reloadingExtension,
    noSalesNavLicense,
  ]);

  //After we detect the presence of the salesNav cookie we can update the auth context so that it can be used around
  //This call (and in general part of the logic regarding the check of salesNav) should be probably moved to the auth/index
  //context but for now we keep it here
  useEffect(() => {
    if (!status) return;
    setHasSalesNavCookie(!!status.hasSalesNavCookie);
  }, [status]);

  const { onboardingFeatures } = OnboardingFeatures();

  const { mutate: patchUserOnboardingIntegrations } =
    usePatchUserOnboardingIntegrations();

  //If we detect that the extension is installed we check if we already have set the onboarding feature
  useEffect(() => {
    const extensionStatus = status?.status;
    if (
      (extensionStatus &&
        extensionStatus ===
          CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE) ||
      extensionStatus === CronoExtensionStatus.INSTALLED_WITHOUT_LINKEDIN_COOKIE
    ) {
      if (
        onboardingFeatures &&
        onboardingFeatures.linkedinIntegrated === false
      ) {
        patchUserOnboardingIntegrations({
          linkedinConnected: true,
        });
      }
    }
  }, [status]);

  useEffect(() => {
    if (reloadingExtension) {
      if (extensionVersion?.isUpToDate === true) {
        setReloadingExtension(false);
      } else {
        const cancelToken = { cancelled: false };
        setTimeout(() => {
          if (!cancelToken.cancelled) {
            refetch();
          }
        }, 1_000);
        return () => {
          cancelToken.cancelled = true;
        };
      }
    }
  }, [reloadingExtension, extensionVersion, refetch]);

  const navigate = useNavigate();
  const handleClick = () => {
    if (extensionOutdated) {
      if (!reloadingExtension) {
        setReloadingExtension(true);
        reloadExtension({});
      }
    } else {
      // Go to settings in the connect tab using the state
      navigate(PATH.SETTINGS, { state: { tab: SettingsTabs.Integrations } });
    }
  };

  //If the user is a salesNav user but we don't have the cookie we need to suggest the user to open salesNav page
  const needToOpenSalesNav = useMemo(() => {
    return (
      status?.status === CronoExtensionStatus.INSTALLED_WITH_LINKEDIN_COOKIE &&
      !status.hasSalesNavCookie &&
      hasSalesNav &&
      !noSalesNavLicense
    );
  }, [status, hasSalesNav, noSalesNavLicense]);

  const { call: refreshCurrentSalesNavCookie } =
    useRefreshCurrentSalesNavCookie();

  const handleRedirectSalesNav = () => {
    refreshCurrentSalesNavCookie({});
    window.open('https://www.linkedin.com/sales', '_blank');
  };

  const srcImage = needToOpenSalesNav ? SalesNavWarning : CronoExtensionWarning;

  const handleDeclineSalesNavLicense = () => {
    //Store in the localStorage that the user has clicked the button
    localStorage.setItem(NO_SALES_NAV_CHECK_FROM_USER, '1');
    setNoSalesNavLicense(true);
  };

  return message ? (
    <ExtensionStatusBannerWrapper>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: 18 }}>
        <img src={srcImage} className="warning-icon" alt="Linkedin" />

        <Typography fontSize={14} lineHeight={'22px'} fontWeight={600}>
          {message}
        </Typography>
      </div>
      {needToOpenSalesNav ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 8,
          }}
        >
          <MainButton
            disabled={reloadingExtension}
            style={{ margin: 0, height: 40 }}
            onClick={handleRedirectSalesNav}
          >
            Yes
          </MainButton>
          <CancelButton
            disabled={reloadingExtension}
            style={{ margin: 0, height: 40 }}
            onClick={handleDeclineSalesNavLicense}
          >
            No
          </CancelButton>
        </div>
      ) : (
        <MainButton
          disabled={reloadingExtension}
          style={{ margin: 0, height: 40 }}
          onClick={handleClick}
        >
          {extensionOutdated ? 'Update' : 'Settings'}
        </MainButton>
      )}
    </ExtensionStatusBannerWrapper>
  ) : null;
};

export default ExtensionStatusBanner;
